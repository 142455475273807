<section class="section faq-area ptb_100" id="faq">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Les applications au tir à l'arc</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomOne">
                                                    Meilleure application de tir à l’arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomOne" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">CapTarget Archery est selon nous la meilleure application pour le suivi tir à l’arc. En plus d'être connecté à votre compte FFTA, l'application vous propose des jeux seul ou à plusieurs, un suivi complet qui est envoyé au coach mais aussi tout un ensemble d'outils (calendrier, classement national, chronomètre, fiche matériel ...) <br><br> L'application est construite par des archers et des coachs et en constante évolution.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomSecond1">
                                                    Application de tir à l’arc
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSecond1" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer" itemref="sameAsQuestion1">
                                                <div class="card-body">
                                                    <p itemprop="text">CapTarget Archery est une application mobile gratuite et construite pour vous permettre de progresser en vous amusant. <br><br> En plus d'être connecté à votre compte FFTA, l'application vous propose des jeux seul ou à plusieurs, un suivi complet qui est envoyé au coach mais aussi tout un ensemble d'outils (calendrier, classement national, chronomètre, fiche matériel ...) <br><br> L'application est construite par des archers et des coachs et en constante évolution.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomThree">
                                                    Pourquoi utiliser une application de tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomThree" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Pour défier ses amis, jouer à des jeux, s'amuser, suivre les compétitions et les classements, et sauvegarder son matériel. Mais surtout, pour progresser ! <br><br> Avec CapTarget Archery, tu peux analyser tes statistiques et voir ta progression au fil du temps. Tu peux aussi partager tes stats avec ton coach pour qu'il t'aide à atteindre tes objectifs. <br><br> CapTarget t'offre également la possibilité de définir un plan d'entraînement et de tenir un carnet pour enregistrer tes séances. En somme, l'appli de tir à l'arc est l'outil parfait pour s'améliorer tout en s'amusant !</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomFour3">
                                                    Application Fédération Française de Tir à l'Arc (FFTA)
                                                </button>
                                            </h2>
                                            <div id="collapseCustomFour3" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text"> CapTarget Archery est reliée à la Fédération Française de Tir à l'arc et te permet de récupérer tous tes scores, ainsi que le calendrier des compétitions et ton classement. <br><br> Il ne s'agit pas d'une application officiellement reconnue par la FFTA.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
