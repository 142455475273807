import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions-where',
  templateUrl: './questions-where.component.html',
  styleUrls: ['./questions-where.component.css']
})
export class QuestionsWhereComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
