import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'app-content-one',
    templateUrl: './content-one.component.html',
    styleUrls: ['./content-one.component.css']
})
export class ContentOneComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
