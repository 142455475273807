import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pt-conf',
  templateUrl: './pt-conf.component.html',
  styleUrls: ['./pt-conf.component.css']
})
export class PtConfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
