<section class="section faq-area ptb_100" id="faq">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Pratiquer le tir à l'arc</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomOne4">
                                                    Les différentes disciplines du tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomOne4" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l’extérieur, le tir en salle, le tir 3D, le tir campagne, le tir beursault, le tir nature, le run archery</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomSecond4">
                                                    Les différentes types d'arcs
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSecond4" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">l'arc classique, l'arc à poulies, l'arc nu (barebow), le longbow, l'arc chasse</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomThree3">
                                                    Les règles du tir à l'arc
                                                </button>
                                            </h2>
                                            <div id="collapseCustomThree3" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Les règles du tir à l'arc peuvent varier en fonction des compétitions et des fédérations sportives, mais voici quelques règles générales :
                                                    <p itemprop="text">•	Les archers doivent respecter les consignes de sécurité et ne jamais pointer leur arc vers une personne ou un objet non-ciblé.</p>
                                                    <p itemprop="text">•	Les flèches doivent être tirées uniquement vers la cible et ne jamais être dirigées vers le haut ou vers le bas.</p>
                                                    <p itemprop="text">•	Les archers doivent se tenir derrière la ligne de tir et attendre que le signal soit donné pour tirer.</p>
                                                    <p itemprop="text">•	L'arc ne doit pas dépasser une certaine limite de puissance et la taille des flèches doit être réglementée.</p>
                                                    <p itemprop="text">•	Les compétitions peuvent être divisées en différentes distances et les archers doivent s'adapter aux règles spécifiques à chaque distance.</p>
                                                    <p itemprop="text">•	En compétition, les scores sont attribués en fonction de la distance des tirs et des zones touchées sur la cible.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomFour1">
                                                    Comment tirer à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomFour1" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">1. Tenez l'arc correctement : saisissez la poignée de l'arc avec la main qui ne tire pas et le câble de la corde avec les doigts de votre main de tir.</p>
                                                    <p itemprop="text">2. Placez la flèche sur la corde de l'arc en positionnant l'encoche de la flèche sous le repose-flèche.</p>
                                                    <p itemprop="text">3. Étendez votre bras de tir devant vous en gardant votre coude légèrement plié.</p>
                                                    <p itemprop="text">4. Visez la cible en alignant la pointe de la flèche avec votre œil dominant.</p>
                                                    <p itemprop="text">5. Tirez la corde en utilisant les muscles de votre dos plutôt que ceux de votre bras.</p>
                                                    <p itemprop="text">6. Relâchez la corde et laissez la flèche voler vers la cible.</p>
                                                    <p itemprop="text">7. Répétez ces étapes pour chaque flèche.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseEight">
                                                    Comment bien viser à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseEight" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Pour une bonne précision de tir à l'arc, la position de l'archer est primordiale. Elle se caractérise par le placement des pieds et du reste du corps sur la ligne de tir. <br><br> Pour atteindre la cible avec précision, il est recommandé d'appliquer les techniques utilisées par les grands archers, en soignant la position, la visée, la tenue d'arme et le lâcher. <br><br> L'entraînement régulier est également important pour intégrer ces techniques et les rendre automatiques. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseNine">
                                                    Tir à l'arc avec les pieds
                                                </button>
                                            </h2>
                                            <div id="collapseNine" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc avec les pieds est une discipline dans laquelle l'archer utilise ses pieds pour tenir l'arc et tirer la flèche. <br><br>Cette pratique est souvent pratiquée par des personnes qui ont des difficultés à utiliser leurs bras, comme les personnes handicapées. <br><br> Pour pratiquer le tir à l'arc avec les pieds, l'archer doit d'abord choisir un arc adapté à sa taille et à sa force. Ensuite, il doit apprendre à utiliser ses pieds pour tenir l'arc et tirer la flèche. <br><br> Cela peut prendre du temps et de la pratique pour maîtriser cette technique.
                                                        En fin de compte, le tir à l'arc avec les pieds est une pratique qui permet à tout le monde de participer à cette discipline, peu importe ses capacités physiques. C'est une belle manière de découvrir cette activité et de la rendre accessible à tous.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTen">
                                                    Tir à l'arc pour aveugles
                                                </button>
                                            </h2>
                                            <div id="collapseTen" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc est également une activité accessible aux personnes aveugles ou malvoyantes. Cette pratique s'effectue généralement avec un arc équipé d'un système de repérage sonore ou tactile pour aider l'archer à viser. <br><br>
                                                        Les archers aveugles ou malvoyants peuvent également être assistés par un guide, qui les aide à se positionner et à viser en utilisant des descriptions verbales ou des signaux tactiles. Les guides peuvent également aider à récupérer les flèches et à évaluer la distance jusqu'à la cible.
                                                        <br><br> En somme, le tir à l'arc est une activité inclusive qui permet à toutes les personnes, quelle que soit leur situation de handicap, de pratiquer un sport de précision et de concentration.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven">
                                                    Tir à l'arc pour personnes handicapées
                                                </button>
                                            </h2>
                                            <div id="collapseEleven" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc est un sport parfaitement accessible aux personnes handicapées grâce à des adaptations techniques. <br><br> Les archers handicapés peuvent également utiliser des chaises spéciales pour s'asseoir et tirer ou des arcs spécialement conçus pour leur permettre de tirer à l'aide de leur bouche ou de leur menton. <br><br> Des compétitions et des événements paralympiques sont organisés pour les archers handicapés, offrant des opportunités de compétition et de développement des compétences dans ce sport passionnant.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve">
                                                    Tir à l'arc au féminin
                                                </button>
                                            </h2>
                                            <div id="collapseTwelve" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc est un sport pratiqué par de nombreuses femmes dans le monde entier. Les femmes ont réussi à se faire une place dans ce sport traditionnellement dominé par les hommes. <br><br> Des initiatives ont également été lancées pour encourager les femmes à pratiquer ce sport, notamment par la création de programmes de formation et de compétitions exclusivement féminins, ainsi que par la mise en place de mentorats pour les jeunes archères en devenir.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen">
                                                    Tir à l'arc à cheval
                                                </button>
                                            </h2>
                                            <div id="collapseThirteen" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc à cheval, également connu sous le nom de "tir à l'arc équestre", est un sport équestre qui consiste à tirer à l'arc tout en étant à cheval. Cette discipline demande une grande coordination entre le cavalier et le cheval, ainsi qu'une grande précision de tir. <br><br> Les compétitions peuvent se dérouler sur des parcours spécialement conçus pour cet exercice, avec des cibles fixes ou mobiles, et peuvent être individuelles ou par équipe. Des arcs spécialement conçus pour cette discipline sont utilisés, ainsi que des flèches en matériaux adaptés pour minimiser les risques de blessures pour le cheval. <br><br> Le tir à l'arc à cheval est un sport qui gagne en popularité dans de nombreux pays, avec des compétitions nationales et internationales organisées régulièrement.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomSix1">
                                                    Comment être fort au tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSix1" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Pour devenir fort au tir à l'arc, il est recommandé de s'entraîner régulièrement, avec un volume hebdomadaire supérieur à 400 flèches. Travailler avec un entraîneur de tir à l'arc et un préparateur mental est également bénéfique, tout comme la lecture de livres sur la pratique. <br><br>
                                                        Pour améliorer ses compétences au tir à l'arc, il est important de travailler la force physique, la technique de tir et la concentration. Le renforcement musculaire des épaules, des bras et des abdominaux est essentiel, ainsi que le travail de la technique de tir, la visée et le lâcher. <br><br>
                                                        L'entraînement régulier est également crucial pour progresser et développer les muscles et la technique. Enfin, la concentration et la relaxation sont des éléments clés pour une bonne performance, avec une gestion efficace du stress et de la respiration.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
