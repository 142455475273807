import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-hero-five',
    templateUrl: './hero-five.component.html',
    styleUrls: ['./hero-five.component.css']
})
export class HeroFiveComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    openMail(): void {
        const email = 'support@captarget-archery.com';
        const subject = 'CapTarget advertising platform';
        const emailBody = `Hello CapTarget, %0D%0A %0D%0A
My name is [FILL IN], I represent the company [FILL IN] whose business is [FILL IN]. %0D%0A %0D%0A
I would like to display ads on your CapTarget Archery mobile application. %0D%0A
I enclose the images and/or videos I wish to display. %0D%0A %0D%0A
I would like these ads to be displayed from [DATE START] to [DATE END]. %0D%0A
The maximum amount not to be exceeded is [FILL IN]€. %0D%0A %0D%0A
I've read the technical specifications and my documents correspond to them. %0D%0A
I would like to take advantage of the following offer: For the first €150 spent, €150 free. %0D%0A %0D%0A
Best regards, %0D%0A %0D%0A`;
        window.open(`mailto:${email}?subject=${subject}&body=${emailBody}`, '_system');
        alert(email);
    }
}
