import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pt',
  templateUrl: './pt.component.html',
  styleUrls: ['./pt.component.css']
})
export class PtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
