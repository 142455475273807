<footer class="section footer-area style-two overflow-hidden">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo" src="assets/img/logo_white.svg" alt="CapTarget Archery">
                        </a>
                        <p class="text-white mt-2 mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel laboriosam vitae.</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" href="#">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                            <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a class="google-plus" href="#">
                                <i class="fab fa-google-plus-g"></i>
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                            <a class="vine" href="#">
                                <i class="fab fa-vine"></i>
                                <i class="fab fa-vine"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">Home</a></li>
                            <li class="py-2"><a class="text-white" href="#">About Us</a></li>
                            <li class="py-2"><a class="text-white" href="#">Services</a></li>
                            <li class="py-2"><a class="text-white" href="#">Blog</a></li>
                            <li class="py-2"><a class="text-white" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Product Help</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">FAQ</a></li>
                            <li class="py-2"><a class="text-white" href="#">Privacy Policy</a></li>
                            <li class="py-2"><a class="text-white" href="#">Support</a></li>
                            <li class="py-2"><a class="text-white" href="#">Terms &amp; Conditions</a></li>
                            <li class="py-2"><a class="text-white" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Download</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">For Windows</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Linux</a></li>
                            <li class="py-2"><a class="text-white" href="#">For IOS</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Android</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Mac</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Top -->
        <div class="shape-top">
            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
                <path d="M0 0L2600 0 2600 69.1 0 0z"></path>
                <path style="opacity:0.5" d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
                <path style="opacity:0.25" d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
            </svg>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white">&copy; Copyrights 2021 Appia All rights reserved.</div>
                        <!-- Copyright Right -->
                        <div class="copyright-right text-white">Made with <i class="icofont-heart-alt"></i> By <a class="text-white" href="#">Themeland</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>