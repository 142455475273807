import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../Services/language.service';

@Component({
    selector: 'app-faq-one',
    templateUrl: './faq-one.component.html',
    styleUrls: ['./faq-one.component.css']
})
export class FaqOneComponent implements OnInit {

    constructor(public languageService: LanguageService) {
    }

    ngOnInit(): void {
    }

    sendMail(): void {
        const email = 'support@captarget-archery.com';
        const subject = 'Contact depuis le site de CapTarget';
        const emailBody = 'Bonjour CapTarget ! 🏹';
        window.open(`mailto:${email}?subject=${subject}&body=${emailBody}`, '_system',);
        alert(email);
    }
}
