import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.css']
})
export class CguComponent implements OnInit {
  languages = [
    'Français',
    'English',
  ];

  selectedLanguage = 'Français';

  constructor() { }

  ngOnInit(): void {
  }


  // Function to handle language selection
  // tslint:disable-next-line:typedef
  selectLanguage(language: string) {
    this.selectedLanguage = language;
  }


}
