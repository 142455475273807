<div>
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <div class="d-flex flex-column align-items-center justify-content-between py-4 p-sm-4">
            <!-- ***** Newsletter Area Start ***** -->
            <div class="mb-5 mb-lg-0">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-10 col-lg-7 mx-auto">
                            <div class="text-center">
                                <h2 class="pt-5 fw-4">Politique de confidencialité</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-10 col-lg-7 mx-auto">
                <style>
                    div.container {
                        background-color: #ffffff;
                    }
                    div.container p {
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        text-transform: none;
                        color: #000000;
                        background-color: #ffffff;
                    }
                </style>

                <div class="language-selector" style="margin-bottom: 25px;">
                    <div *ngFor="let language of languages" class="language-item" (click)="selectLanguage(language)">
                        {{ language }}
                    </div>
                </div>

                <label
                        style="font-size: 100%; margin-top: 10px; margin-bottom: 10px; margin-left: 10px;" *ngIf="selectedLanguage !== 'Français'">
                    *Traduit automatiquement depuis le français
                </label>

                <app-fr-conf *ngIf="selectedLanguage === 'Français' || !selectedLanguage"></app-fr-conf>
                <app-en-conf *ngIf="selectedLanguage === 'English' || !selectedLanguage"></app-en-conf>
                <app-es-conf *ngIf="selectedLanguage === 'Español' || !selectedLanguage"></app-es-conf>
                <app-it-conf *ngIf="selectedLanguage === 'Italiano' || !selectedLanguage"></app-it-conf>
                <app-da-conf *ngIf="selectedLanguage === 'Dansk' || !selectedLanguage"></app-da-conf>
                <app-pt-conf *ngIf="selectedLanguage === 'Português' || !selectedLanguage"></app-pt-conf>
                <app-ja-conf *ngIf="selectedLanguage === '日本語' || !selectedLanguage"></app-ja-conf>
                <app-ko-conf *ngIf="selectedLanguage === '한국어' || !selectedLanguage"></app-ko-conf>
                <app-zh-conf *ngIf="selectedLanguage === '中文' || !selectedLanguage"></app-zh-conf>
                <app-et-conf *ngIf="selectedLanguage === 'Eesti' || !selectedLanguage"></app-et-conf>
                <app-tr-conf *ngIf="selectedLanguage === 'Türkçe' || !selectedLanguage"></app-tr-conf>
                <app-nl-conf *ngIf="selectedLanguage === 'Nederlands' || !selectedLanguage"></app-nl-conf>
                <app-de-conf *ngIf="selectedLanguage === 'Deutsch' || !selectedLanguage"></app-de-conf>

                </div>
            </div>

        <app-footer-five></app-footer-five>
    </div>

    <!-- pour générer les paragraphes : https://www.quackit.com/html/html_generators/html_text_generator.cfm-->
