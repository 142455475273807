<header class="navbar navbar-sticky navbar-expand-lg navbar-dark" (window:resize)="onResize($event)">
    <div class="container position-relative">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-brand-regular" src="assets/img/logo_white.svg" alt="brand-logo" style="height: 50px; object-fit: contain" *ngIf="displayFullLogo">
            <img class="navbar-brand-regular" src="assets/img/logo_white_little.png" alt="brand-logo" style="height: 40px; object-fit: contain" *ngIf="!displayFullLogo">
            <img class="navbar-brand-sticky" src="assets/img/logo.svg" alt="sticky brand-logo"  style="height: 40px; object-fit: contain" *ngIf="displayFullLogo">
            <img class="navbar-brand-sticky" src="assets/img/favicon.png" alt="sticky brand-logo"  style="height: 40px; object-fit: contain" *ngIf="!displayFullLogo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
<!--                    <li class="nav-item">-->
<!--                        <a class="nav-link scroll" href="#home"></a>-->
<!--                    </li>-->
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#screenshots">{{ 'TRANSLATE.screenshot_keyword_1' | translate }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#features">{{ 'TRANSLATE.features_keyword_2' | translate }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#coachs">{{ 'TRANSLATE.coachs_keyword_3' | translate }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#faq">{{ 'TRANSLATE.faq_keyword_4' | translate }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#reviews">{{ 'TRANSLATE.reviews_keyword_5' | translate }}</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#partners">{{ 'TRANSLATE.partners_keyword_6' | translate }}</a>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link scroll">
                            <select (change)="onSelect($event)" [(ngModel)]="languageService.currentLanguage" style="background: transparent; border: 0; color: #458AC9">
                                <option *ngFor="let lang of languages" [value]="lang.value" style="color: #0d0d0d">{{ lang.text }}</option>
                            </select>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
