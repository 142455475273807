<section class="section service-area overflow-hidden ptb_180">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">{{ 'TRANSLATE.title_add' | translate }}</h2>
                    <!-- Service List -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-camera"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step1' | translate }}</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-coins"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step2' | translate }}</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-mail-bulk"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step3' | translate }}</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-database"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step4' | translate }}</p>
                            </div>
                        </li>
                    </ul>
                    <a href="/adds" class="btn btn-bordered mt-4" style="cursor: pointer">{{ 'TRANSLATE.now_more' | translate }}</a>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
<!--                    &lt;!&ndash; Thumb Top &ndash;&gt;-->
<!--                    <div class="thumb-top">-->
<!--                        <img src="assets/img/service_thumb_top.png" alt="">-->
<!--                    </div>-->
                    <!-- Thumb Certer -->
                    <div class="thumb-center">
                        <img src="assets/img/service_thumb.png" alt="CapTarget add">
                    </div>
                    <!-- Thumb Bottom -->
<!--                    <div class="thumb-bottom">-->
<!--                        <img src="assets/img/service_thumb_bottom.png" alt="">-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</section>
