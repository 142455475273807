<html>

<head>
    <meta charset="UTF-8">
    <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    <style>
        <!--
        /* Font Definitions */
        @font-face
        {font-family:Wingdings;
            panose-1:5 0 0 0 0 0 0 0 0 0;}
        @font-face
        {font-family:"MS Mincho";
            panose-1:2 2 6 9 4 2 5 8 3 4;}
        @font-face
        {font-family:"Cambria Math";
            panose-1:2 4 5 3 5 4 6 3 2 4;}
        @font-face
        {font-family:Calibri;
            panose-1:2 15 5 2 2 2 4 3 2 4;}
        @font-face
        {font-family:Times;
            panose-1:2 2 6 3 5 4 5 2 3 4;}
        @font-face
        {font-family:"PT Sans";}
        @font-face
        {font-family:"Amasis MT Pro";}
        @font-face
        {font-family:Poppins;
            panose-1:0 0 5 0 0 0 0 0 0 0;}
        @font-face
        {font-family:Roboto;
            panose-1:0 0 0 0 0 0 0 0 0 0;}
        @font-face
        {font-family:"\@MS Mincho";
            panose-1:2 2 6 9 4 2 5 8 3 4;}
        /* Style Definitions */
        p.MsoNormal, li.MsoNormal, div.MsoNormal
        {margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:0cm;
            line-height:115%;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;}
        p
        {margin-right:0cm;
            margin-left:0cm;
            font-size:12.0pt;
            font-family:"Times New Roman",serif;}
        p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
        {margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:36.0pt;
            line-height:115%;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;}
        p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
        {margin-top:0cm;
            margin-right:0cm;
            margin-bottom:0cm;
            margin-left:36.0pt;
            line-height:115%;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;}
        p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
        {margin-top:0cm;
            margin-right:0cm;
            margin-bottom:0cm;
            margin-left:36.0pt;
            line-height:115%;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;}
        p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
        {margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:36.0pt;
            line-height:115%;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;}
        .MsoChpDefault
        {font-size:10.0pt;}
        /* Page Definitions */
        @page WordSection1
        {size:595.3pt 841.9pt;
            margin:70.85pt 70.85pt 70.85pt 70.85pt;}
        div.WordSection1
        {page:WordSection1;}
        /* List Definitions */
        ol
        {margin-bottom:0cm;}
        ul
        {margin-bottom:0cm;}
        -->
    </style>

</head>

<body lang=FR link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>

<div class=WordSection1>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Terms and Conditions of Use and Subscription</span></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>CapTarget Archery is a web
and mobile application (the &quot;<b>Application</b>&quot;) operated by Appli
Magine, a société par actions simplifiée (simplified joint stock company) with
share capital of 100 euros, whose registered office is located at 5, jardin
Jean de Ockeghem, Tours (37000), registered with the Tours Trade and Companies
Registry under identification number 948 141 593 and whose intracommunity VAT
number is FR89948141593 (the &quot;<b>Company</b>&quot;). The said Application
is dedicated, among other things, to tracking the performance of its users in
their archery practice.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The purpose of these Terms
and Conditions of Use (the &quot;<b>Terms</b>&quot; or, taken as a whole, the
&quot;<b>Agreement</b>&quot;) is to define the terms and conditions governing
relations between Users and the Company. These Conditions constitute a
contractual agreement for an indefinite period from the date of acceptance by
the User of the present Contract. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Each time the User visits
the Application, he/she undertakes to comply with all of the present Conditions
without reservation. Consequently, the User acknowledges having read the
Conditions and agrees to be bound by them. If the User accesses the Application
on behalf of a company or any other legal entity, he/she is nevertheless
personally bound by the present Contract. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 1 - Definitions and rules of interpretation</span></b></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify'><b><i><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>1.1 Definition of key terms</span></i></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Unless
defined elsewhere in this Agreement, capitalized terms used herein shall have
the following meanings:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Subscription</span></b><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; has the
meaning assigned to it in Article 3 ;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Application</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; has the meaning
attributed to it in the foreword;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Archer</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; refers to any User
holding an &quot;Archer&quot; account, which is reserved for natural persons
practising archery;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Coach</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; refers to any User
with a &quot;Coach&quot; account, which is reserved for archery coaches who
have subscribed to a Coach Subscription;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Contract</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; has the meaning
assigned to it in the foreword;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Group</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; refers to a group of
Users created on the Application by a User;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;Service(s)</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; refers to the
service(s) offered on the Application as described in article 3;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&quot;<b>Company</b>&quot;
has the meaning ascribed to it in the Foreword; and</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&quot;User</span></b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&quot; refers to any
individual or legal entity using the Application. </span></p>

    <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
text-align:justify'><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal><b><i><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>1.2 Rules of interpretation</span></i></b></p>

    <p class=MsoNormal><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>The following rules shall apply to the interpretation of
this Agreement:</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
titles of the articles and appendices are included for convenience and in no
way affect the interpretation of any of the stipulations of this Contract;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
use of the expressions &quot;including&quot;, &quot;in particular&quot;, or
&quot;notably&quot; implies that the enumeration which follows them is not
restrictive or exhaustive;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
term &quot;or&quot; is not exclusive;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
definition attributed to a singular term also applies to that term when used in
the plural, and vice versa. The same applies to the use of the masculine or
feminine gender;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
calculation of periods expressed in days, months or years must be made in
accordance with the provisions of articles 640 to 642 of the Code of Civil
Procedure;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>any
reference to a party includes a reference to its heirs, successors and assigns;
and</span></p>

    <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>any
reference to a document means such document as it may be amended or replaced
(other than in violation of the provisions of this Agreement).</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></i></b></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 2 - Eligibility for Services</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>2.1 Service
eligibility</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>To be eligible for the
Service, the User must be a natural person who has reached the age of 18 and
has full </span><span style='font-size:9.5pt;line-height:115%;font-family:"Amasis MT Pro",serif'>legal
capacity.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>The
User's medical condition and physical constitution must also allow him/her to
practice sport and, in particular, to use the Services.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>In the
event that the User's medical situation or physical constitution should no
longer allow him/her to practice the sport and/or use the Services, the User
must strictly comply with all medical recommendations that may have been sent
to him/her and take all measures to avoid harming his/her health or that of
third parties.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>2.2
</span></i></b><b><i><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Creating an account</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Creating a member account
on the Application is a prerequisite for using the Services. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>To create a member account,
the User must complete the registration form available on the Application,
providing accurate, up-to-date and complete information. This information must
be regularly updated by the User in order to maintain its accuracy.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User must choose a
username consisting of a valid e-mail address and a password and must provide
the following information:</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Full
name ;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Age
and gender ;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Weaponry
and discipline.</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>User account access codes
are strictly confidential. In the event of unauthorized use of his account or
any breach of the confidentiality and security of his means of identification,
the User must inform the Company without delay.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Each User undertakes to
create only one account corresponding to his/her profile. </span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>2.3
Typology of member accounts</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>In the event
that an &quot;Archer&quot; member account is created by a User, the following
rules apply:</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-left:2.0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif;color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>the Archer's name, first name, club, country, age category,
gender, main weapon and avatar are visible to all Users.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>In addition, the User can choose to make visible his or her main
statistics on competitions, training sessions and use of the Application's
exercises.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>If you add a friend, the following information will be visible to
them: the week's volume, scores achieved and shooting statistics.</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:2.0cm;text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;color:black'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>the Archer's information relating to all statistics, sessions,
exercises, assessments, objectives and rankings are visible to the User's
coaches. </span></p>

    <p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:2.0cm;text-align:justify'><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>In the event
that a &quot;Coach&quot; member account is created by a User, the following
rules apply:</span></p>

    <p class=MsoListParagraph style='margin-left:2.0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif;color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>the Coach's name, first name and avatar are visible to all Users,
whether or not they are attached to the Coach.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The information
communicated by the User on the Application is the sole responsibility of the
User, who is therefore responsible for verifying its accuracy. In any event,
the User undertakes to provide accurate and up-to-date information. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In any event, the Company
reserves the right to refuse or delete at its own discretion any information
that it considers inappropriate or contrary to the spirit of the Application.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 3 - Services</span></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>On the Application, several
Services are offered to Users. Access to certain Services is subject to
subscription, the conditions of which are set out in article 4 (the &quot;<b>Subscription</b>&quot;).</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>3.1 Non-Subscription
Archers services</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Application gives
archers free access to an archery performance tracking service, including :</span></p>

    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>training
data entry ;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>exercises
suggested by the Application ; </span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>management
of training and competition sessions;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>management
of shooting equipment;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;background:white'>goal management </span><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;background:white'>access to national rankings, the national
calendar and license statistics (for French riders only)</span><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;background:white'>the creation of groups and conversations between
Archers ;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;background:white'>the establishment of a training log </span><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;background:white'>access to your friends' training and competition
data</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
creation of Groups; and</span></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>access
to a summary of the Archer's performance and statistics via the data entered by
the Archer on the Application.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>3.2. Archers services
requiring Archer Subscription</span></i></b></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Subscriptions
give subscribers access to the following services:</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>access
to additional exercises;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>access
to more detailed statistics ; </span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal'><span style='font-size:9.0pt;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>group exercises with real-time ranking</span><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;line-height:normal'><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal'><span style='font-size:9.0pt;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>access to the advanced web version for more
statistics</span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;line-height:normal'><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal'><span style='font-size:9.0pt;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>drawing up a competition and advanced training
report ;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;line-height:normal'><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal'><span style='font-size:9.0pt;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>the ability to place ads without waiting time</span><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;line-height:normal'><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal'><span style='font-size:9.0pt;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>development of team matches;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;line-height:normal'><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-indent:-18.0pt;
line-height:normal'><span style='font-size:9.0pt;font-family:"Times",serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>arrow sorting </span><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;color:black'>;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;line-height:normal'><span
            style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>inviting
friends or other Users to share the exercises. The User then fills in the
creation form on the Application. Once invited, said third parties must create
an Archer account on the Application.</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>3.3. Coach services</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Application allows
Coaches to access various services subject to subscribing to a &quot;Coach
Subscription&quot;.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Coach Subscription
allows you to track the performance of Archers on the Application. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>To this end, the Coach may
invite any natural person to join the Application as an Archer or any Archer
who is already a User via their first and last name or e-mail address. If this
invitation is accepted, the Coach will have access to all the following
information about the Archer: all statistics, sessions, exercises, assessments,
objectives and rankings. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Coach is informed that
any Archer may cease, on his own initiative or on the Coach's initiative, to be
affiliated with the Coach. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Coach may not monetize
access to the Application without the prior express authorization of the
Company.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>3.3 Message exchange
- Group creation</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>On the Application, an
instant messaging service is made available to Users to enable them to discuss
their performances to the exclusion of any other purpose. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Users are informed that the
Company has no access to the content of this messaging system and therefore has
no control over the messages exchanged. In order to ensure a minimum of
cordiality in exchanges, an anti-insult module automatically deletes any insults
identified as such by the module. However, the Company does not guarantee the
effectiveness of this module.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>On the Application, Users
can create Groups with friendly Users. To do so, the User fills in the creation
form provided on the Application. Any participant in the Group must have
accepted the request to add the User who created the Group. The object and purpose
of the Group must comply with the spirit of the Application, i.e. to improve
archery performance to the exclusion of any other purpose. The User agrees not
to create Groups unintentionally.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company may not be held
liable for exchanges on the Application. Users </span><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>undertake not to
enter any content likely to offend public order or morality, to provoke
protests from third parties, or to contravene current legal provisions. The
Company reserves the right to close any Group without prior notice. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 4 - Archer - Coach Subscription conditions</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.1 Subscription
duration</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Application offers
several subscription formulas (the &quot;<b>Subscription(s)</b>&quot;):</span></p>

    <p class=MsoNormal style='text-align:justify'><i><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(i) Monthly subscription
with no commitment period, cancellable at any time </span></i></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>By subscribing to this
subscription formula, the Subscriber makes no commitment as to the duration of
the subscription, which may not be less than one month. The Subscriber may
cancel the Subscription at any time, at his or her own discretion, provided that
he or she notifies the Company of his or her intention to cancel in accordance
with the procedure set out on the Application. Once the cancellation procedure
has been followed, the Subscription will be automatically cancelled at the end
of the Subscription period already paid for by the Subscriber.</span></p>

    <p class=MsoNormal style='text-align:justify'><i><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>(ii) 12-month subscription</span></i></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>By subscribing to this
subscription formula, the Subscriber commits to a twelve-month subscription
period starting from the Subscription subscription date. This Subscription is
tacitly renewable for the same duration (1 year) without limitation unless one
of the two Parties notifies its intention via the procedure provided for this
purpose on the Application, within one (1) month before the end of the
Subscription, of its intention not to have the Subscription tacitly renewed.</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>As the
User is solely responsible for choosing and subscribing to a Subscription, it
is his or her responsibility to check the accuracy of his or her subscription
and to report any errors immediately. </span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>The
subscription will only be considered definitive once the User has received
confirmation of acceptance of his subscription from the Company by e-mail. </span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.2 Price list</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Subscription is offered
at the current price shown on the Application at the time of subscription.
Prices are in euros and include all taxes.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Rates take into account any
discounts granted by the Company on the Application. They are firm and
non-revisable during their period of validity as indicated on the Application,
the Company reserving the right, outside this period of validity, to modify rates
at any time at its discretion. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Any under-use or non-use of
the Subscription does not justify any claim for reimbursement.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.3 Terms of payment</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In the case of a monthly
Subscription, the monthly amount of the Subscription must be paid by the User
on a monthly basis by direct debit from the bank account provided at the time
of subscription. The first monthly instalment will be paid and debited immediately
upon subscription.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>When subscribing to an
annual Subscription, the annual amount of the Subscription must be paid
immediately upon subscription.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Payments made by the User
will not be considered final until the Company has received the sums due. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>An invoice is issued by the
Company and made available on the application.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Without prejudice to any
damages, failure by the User to pay a single instalment by the due date will
automatically result in the application of late payment interest equal to three
times the legal interest rate from the first day of delay, plus any additional
bank and administration charges. The User will also cease to have access to
Subscription services.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User is hereby informed
that in the event of subscription via :</span></b></p>

    <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"PT Sans",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Stripe's payment module, Subscription is accessible via
the web and mobile platform with premium functions; </span></b></p>

    <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><b><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></b></p>

    <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt'><span
            style='font-size:9.0pt;line-height:115%;font-family:"PT Sans",sans-serif'>-<span
            style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><b><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>the payment module of the Stores (Android or iOs), the
Subscription is accessible (i) via the mobile platform with premium functions
and (ii) via the web platform without access to premium functions. The User may
not claim any reduction in the price of the Subscription.</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.4 Warranties</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User is hereby informed
that, pursuant to Article 15 of French Law 2004-575 of June 21, 2004, the
Company is automatically responsible for the proper performance of the
obligations arising from the present contract, whether these obligations are to
be performed by the User or by other service providers, without prejudice to
the User's right of recourse against the latter.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>However, the Company may be
exonerated from all or part of its liability by proving that the
non-performance or improper performance hereof is attributable either to the
User, or to the unforeseeable and insurmountable act of a third party unrelated
to the provision of the services hereunder, or to a case of force majeure.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.5 Right of
withdrawal</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In accordance with the
legal provisions in force, when the User is a consumer within the meaning of
the French Consumer Code, he or she has a 14-day cooling-off period from the
date of subscription to a Subscription. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>However, by accepting the
present Conditions, the User expressly requests the immediate execution of the
provisions herein before the expiration of the withdrawal period, and expressly
waives his right of withdrawal. Consequently, in application of Article L.
221-28 13° of the French Consumer Code, the Subscriber may not retract his or
her commitment entered into on the Application. </span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.6 Pre-contractual
information - User acceptance</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User acknowledges
having been informed, prior to subscription, in a legible and comprehensible
manner, of the present Conditions and of all the information and details
referred to in articles L111-1 to L111-8 of the French Consumer Code, and in
particular :</span></p>

    <p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-indent:
-7.1pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-the
           essential characteristics of the Subscription taken out, taking into
account the communication medium used and the services concerned;</span></p>

    <p class=MsoNormal style='margin-left:1.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-the
           price of the Subscription and ancillary costs ; </span></p>

    <p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-indent:
-7.1pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>   information
about the Company's identity, postal address, telephone number and e-mail
address, and its activities, if not obvious from the context;</span></p>

    <p class=MsoNormal style='margin-left:1.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>   -Information
on legal and contractual warranties and how to make use of them; </span></p>

    <p class=MsoNormal style='margin-left:1.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-the
           functionalities of the digital content and, where applicable, its
interoperability; </span></p>

    <p class=MsoNormal style='margin-left:1.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-the
           possibility of resorting to conventional mediation in the event of a
dispute ; </span></p>

    <p class=MsoNormal style='margin-left:1.0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>   -information
on the right of withdrawal.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Subscribing on the
Application implies full acceptance of these Conditions, which is expressly
acknowledged by the User, who waives the right to rely on any contradictory
document that may be unenforceable against the Company.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 5 - Use of Application services</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>5.1
</span></i></b><b><i><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Right of access to the Application</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company hereby grants
Users a limited, revocable, non-exclusive, non-transferable right of access to
the Application services on a strictly personal basis. Any use of the
Application contrary to its intended purpose is strictly prohibited and constitutes
a breach of the present provisions. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Use of the Application
requires an Internet connection. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In order to guarantee the
proper functioning of the Application, it is specified that it is optimized for
:</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-left:38.35pt;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"PT Sans",sans-serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
latest versions of the Chrome and Firefox browsers; and</span></p>

    <p class=MsoListParagraphCxSpMiddle style='margin-left:38.35pt;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-left:38.35pt;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"PT Sans",sans-serif'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>the
latest versions of Android or iOS mobile.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>All hardware and software
required to access the Application and use the Service are the sole
responsibility of the User. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company reserves the
right to suspend, modify, replace, deny access to or delete User accounts at
its sole discretion. </span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>5.2
</span></i></b><b><i><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>User obligations </span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Users agree not to:</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>1.
    transmit, publish, distribute, store or destroy any material, in particular
the contents of the Application, in violation of the laws or regulations in
force concerning the collection, processing or transfer of personal
information;</span></p>

    <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>2.     create
fictitious profiles ;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>3.
    provide inaccurate information on the form or fail to update it regularly;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.
    disseminate data, information or content that is defamatory, abusive,
obscene, offensive, violent or inciting to violence, or of a political, racist
or xenophobic nature, and in general any content that is contrary to the laws
and regulations in force or morality;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>5.
    reference or create links to any content or information available from the
Application, except with the express prior written consent of the Company;</span></p>

    <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>6.     obtain
passwords or personal identification data from other Users;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>7.
    use information, content or any data present on the Application in order to
offer a service considered, at the Company's sole discretion, to be competitive
with the Application; </span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.
    sell, exchange or monetize information, content or any data present on the
Application or service offered on the Application, without the express written
consent of the Company;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>9.
    reverse engineer, decompile, disassemble, decipher or otherwise attempt to
obtain source code in connection with any underlying intellectual property used
to provide all or part of the Application Services; </span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>10.
  use manual or automated software or devices, robots or other means to access,
explore, retrieve or index any page of the Application;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>11.
endanger  or attempt to endanger the security of a Company website or
application. This includes attempts to monitor, scan or test the vulnerability
of a system or network or to breach security or authentication measures without
express prior authorization;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>12.
  infringe or use the Company's products, logos, trademarks or any other
elements protected by the Company's intellectual property rights;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>13.
  simulate the appearance or operation of the Company's sites or applications,
for example by using a mirror effect;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>14.
  disrupt or interfere, directly or indirectly, with the Application or
application services, or impose a disproportionate load on the Application
infrastructure or attempt to transmit or activate computer viruses via or on
the Application.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Violations of system or
network security may lead to civil or criminal prosecution. The Company
verifies the absence of such violations and may call upon the legal authorities
to prosecute Users who have participated in such violations.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Users undertake to use the
Application in a fair manner, in accordance with its professional purpose and
in compliance with legal and regulatory provisions, the present Conditions and
current practices. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>It is specified that the
Company reserves the right to publish or not to publish or to delete in whole
or in part a notice or comment from a User on the Application. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 6 - Use of the Application</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The entire content of the
Application, in particular the designs, texts, graphics, images, videos,
information, logos, button icons, software, audio files and others, belongs to
the Company, which is the sole owner of all related intellectual property rights.
</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Any representation and/or
reproduction and/or partial or total exploitation of the content and services
offered by the Company, by any means whatsoever, without the prior written
authorization of the Company, is strictly forbidden and may give rise to legal
proceedings.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company grants the User
a personal, non-exclusive, non-assignable and non-transferable right to use the
Application and application services for the duration of their contractual
relationship.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The present contract does
not confer any ownership rights to the Application and its services, even in
the case of specific development carried out at the request of a User. The
temporary provision of the Application and services shall not be construed as
the transfer of any intellectual property rights to a User.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The right to use the
Application is granted for the sole and exclusive purpose of enabling the User
to use the Application and its services, to the exclusion of any other purpose,
in accordance with their intended purpose.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 7 - Personal data</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>7.1
Collected data</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>In order to
enable all Users to take full advantage of the Application's services and
functionalities, the Company collects various types of data. By registering on
the Application, the User expressly accepts that this data will be collected,
regardless of the country from which he/she connects. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>These data are
collected at different times and using different methods:</span></p>

    <p class=MsoListParagraph style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif;color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>When registering on the Application: e-mail, language, country,
license number (for France), first and last name, gender, age and weapon
category, disciplines, IP address;</span></p>

    <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>&nbsp;</span></p>

    <p class=MsoListParagraph style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif;color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>Log files and Internet Protocol (IP) address: Each time the User
connects to the Application's website, the Company receives the link to the
site from which the User arrived, and the link to which the User is directed
when he or she leaves the site. The Company also receives the User's Internet
Protocol (IP) address, as well as certain information relating to the User's
computer operating system or Internet browser (device identification number and
name);</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>&nbsp;</span></p>

    <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;text-align:justify;
text-indent:-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:
"Times",serif;color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>Cookies: The Company uses cookies, which can be defined as text
files that may be stored on a terminal when a browser is used to consult an
online service. During its period of validity, which may not exceed 13 months,
a cookie file enables its issuer to recognize the terminal concerned each time
this terminal accesses digital content containing cookies from the same issuer.
It is not possible to deactivate the use of cookies.</span></p>

    <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;text-align:justify'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>&nbsp;</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>7.2
Retention of collected data</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>Personal data
is stored by the Company on its servers for processing in connection with the
use of the Services. It is kept for as long as is necessary to provide the
services and functions offered by the Application. Consequently, as long as a
User has a member account, the data collected will be retained. In the event of
de-registration from the Application, the data collected will be deleted by the
Company and will be kept solely for archival purposes, for the purpose of
establishing proof of a right or contract, which may be archived in accordance
with the provisions of the French Commercial Code relating to the retention
period for books and documents created in the course of business activities. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>The User
remains at all times the owner of the information concerning him/her that
he/she transmits to the Company. In accordance with law no. 78-17 of January 6,
1978, amended by law no. 2004-801 of August 6, 2004, the User has the right to
access, rectify and delete personal data concerning him or her, as well as the
right to object to the communication of such data to third parties for valid
reasons. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>The User may
exercise these rights by writing to the following e-mail address:
support@captarget-archery.com or to the following postal address: Appli Magine
- </span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>5,
jardin Jean de Ockeghem à Tours (37000)<span style='color:black'>.</span></span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>A reply to the
User's request will be sent within 30 days.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>7.3
Purpose of data collection</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>Personal data
is collected from Users in order (i) to enable the User to take full advantage
of the Services and functions offered by the Application, (ii) to prevent fraud
and (iii) for statistical purposes.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>These data may
be communicated by the Company to any third party entrusted with the execution,
processing and management of the Services. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black'>However, in
various cases, the Company may disclose or share a User's personal data with
other third parties, including :</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;
color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>with the User's consent;</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;
color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>in order to comply with applicable laws, regulations, legal
proceedings, court orders or other mandatory disclosures; or</span></p>

    <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;
color:black'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black'>to protect the rights, property or safety of the Application, its
members or the public.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 8 - Liability</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.1 Use of the
Application</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company's role is
limited to making application services available to Users. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User is solely
responsible for the consequences of using the Application. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>For all the Services
offered on the Application, the Company is in no way acting as a sports coach
to Users, its role being limited to the technical facilitation of performance
monitoring. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company shall not be
bound by any obligation of result in terms of improving User performance.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User is responsible for
ensuring that his or her use of the Application complies with legal and
regulatory provisions, as well as with the general conditions of use of the
Application. The Company gives no warranty to the User as to the compliance of
his or her use of the Application with national or international laws and
regulations.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User is advised of the
technical hazards and access interruptions that may occur on the Application.
Consequently, the Company cannot be held responsible for any unavailability or
slowdown of application services. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;background:white'>All
information, advice, guides and any other data displayed on the Application are
not intended to constitute advice on the basis of which a decision could be
made by the User. The Company cannot therefore be held responsible for the
consequences of using the data and information displayed on the Application. </span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.2 Relations between
Users</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company only plays a
technical role in the relationship between Users. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Consequently, Users are
required to organize the conditions of their relations between themselves, for
which they will be solely responsible. They must ensure compliance with legal
and regulatory requirements. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In addition, the Company
shall </span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>not
be held liable under any circumstances, in particular :</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>1. the consequences of
virtual or real encounters between Users as a result of using the Application;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>2. conditions for carrying
out exercises on the Application ;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>4. the violation by a User
of his legal or regulatory obligations;</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>5. damage of any kind
suffered by a User, including bodily injury, during any exercise; and</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>6. any loss resulting from
a fault on the part of the User or from an event attributable to a third party
or a case of force majeure.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.3 The data </span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company has no general
obligation to monitor the data and content imported by Users, nor any
obligation to delete content that does not appear manifestly illicit,
notwithstanding its notification. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User undertakes not to
enter any content likely to offend public order or morality, to provoke
protests from third parties, or to contravene current legal provisions.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Consequently, it is
expressly agreed that in the event that the Company is called into question,
for any reason whatsoever, in any country whatsoever, by a third party on the
basis of industrial and/or intellectual property rights relating to an element supplied
by a User, the User undertakes to fully indemnify the Company against the
direct and/or indirect economic and financial consequences (including the costs
of proceedings and defense) arising from such claims.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Users are reminded that
data published and information shared by them may be captured and used by other
Users or third parties. In this respect, the Company does not guarantee that
the ownership of such data will be respected, and it is the User's responsibility
to take all necessary steps to ensure that the ownership of his or her data is
preserved. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User must ensure that
data is sent to the Application, and may not blame the Company on any grounds
whatsoever for the non-receipt or loss of transmitted data. The User shall keep
a backup of the data transmitted. </span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.4 Performance
analysis - Statistics</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The analyses and
performance data provided on the Application are for information purposes only.
The Company makes no commitment as to their accuracy. Consequently, the User is
solely responsible for the consequences of using these analyses and data. The
User shall not hold the Company liable on any grounds whatsoever and for any
reason whatsoever, in particular for any consequences arising directly or
indirectly from these analyses and data. </span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.5 General
provisions</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In no event shall the
Company be liable for any indirect or unforeseeable loss or damage to Users or
third parties, including but not limited to any lost profits, inaccuracy or
corruption of files or data, or loss of opportunity arising in any way out of
or in connection with this Agreement.           </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company shall not be
liable for any delay or non-performance of this Contract caused by force
majeure, as defined by the case law of the French courts.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 9 - Termination </span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User may terminate this
Agreement at any time by visiting the Application and following the procedure
provided for this purpose. Please note that once a User's member account has
been closed, the data transmitted and all data generated may be destroyed by
the Company without prior notice to the User.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Termination of this
Contract shall not give rise to any obligation to repay any sums received by
the Company.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Once the User's member
account has been closed for any reason whatsoever (voluntary or involuntary
deregistration), all data will be deleted by the Company without prior notice
to the User. The User shall then, for the entire duration of the Contract, at
his or her own expense and on his or her own initiative, keep a backup of the
data transmitted and/or stored on the Application. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company reserves the
right to restrict, suspend or delete, without notice or refund, the account of
any User who makes improper or inappropriate use of the application services.
The assessment of the User's behavior is reserved to the sole discretion of the
Company. </span></p>

    <p class=MsoNormal><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 10 - Evidence agreement</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Computer systems and files
are authoritative in relations between the Company and the User. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Thus, the Company may
validly produce in any proceedings, for the purposes of proof, the data, files,
programs, recordings or other items, received, issued or stored by means of the
computer systems operated by the Company, on any digital or analog media, and
rely on them unless there is an obvious error. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 11 - Miscellaneous provisions</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>11.1
</span></i></b><b><i><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Indivisibility of the Contract</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The fact that any provision
of the Contract is or becomes illegal or unenforceable shall in no way affect
the validity or enforceability of the remaining provisions of the Contract.</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>11.2
</span></i></b><b><i><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Contract modification</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The Company reserves the
right to modify, supplement or replace the present Conditions. Should the User
refuse to accept the new provisions, he/she is reminded that he/she may
terminate the present Contract at any time, in accordance with Article 9 (Termination). 
</span></p>

    <p class=MsoNormal style='text-align:justify'><b><i><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>11.3
</span></i></b><b><i><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Language</span></i></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In the event that these
terms and conditions are translated into a language other than French, it is
expressly agreed that the French language alone shall prevail between the
parties.</span></p>

    <p class=MsoNormal align=center style='text-align:center'><b><span
            style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 12 - Settlement of disputes</span></b></p>

    <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>&nbsp;</span></b></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The conclusion,
interpretation and validity of this Agreement shall be governed by French law,
regardless of the User's country of origin or the country from which the User
accesses the Company, and notwithstanding the principles of conflict of laws. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>In the event of a dispute
concerning the validity, performance or interpretation of this Contract being
brought before the civil courts, it will be submitted to the exclusive
jurisdiction of the French courts, to which jurisdiction is expressly granted, even
in the event of summary proceedings or multiple defendants. </span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>The User is hereby informed
that he/she may in any event have recourse to conventional mediation or any
other alternative dispute resolution method (conciliation, for example) in the
event of a dispute.</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Pursuant to Article 14.1 of
Regulation (EU) No. 524/2013 of the European Parliament and of the Council of
May 21, 2013, the User is hereby informed that he may consult the following
page for further information on the steps to be taken in the event of a dispute:
</span></p>

    <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=FR.</span></p>

    <p style='line-height:18.0pt;background:white'><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;color:black'>The parties agree to seek an
amicable solution before taking any legal action. In the event of a complaint
that cannot be resolved amicably between the parties, the User may have
recourse, free of charge, to a consumer mediation service. The following mediator
is proposed: </span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black;background:white'>Sas Médiation Solution: </span><span
            style='color:black'>https:</span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
color:black'>//www.sasmediationsolution-conso.fr.</span></p>

    <p class=MsoNormal align=center style='text-align:center'><span
            style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>***</span></p>

    <p class=MsoNormal><span style='font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal><span style='font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

    <p class=MsoNormal>&nbsp;</p>

</div>

</body>

</html>
