<section class="section team-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.team_title_1' | translate }}</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-sm-4 col-md-2 col-lg-2 " style=""
                 *ngFor="let member of members; let i = index" [class.offset-lg-1]="" [class.offset-md-1]="">
                <!-- Single Team -->
                <div class="single-team text-center m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb d-inline-block position-relative overflow-hidden">
                        <img class="rounded-circle" [src]="'assets/img/team/' + member.photo" alt="">
                        <!-- Team Overlay -->
                        <div class="team-overlay bg-overlay rounded-circle">
                            <h4 class="team-name text-white" style="font-size: 100%">{{ member.name }}</h4>
                            <h5 class="team-post text-white mt-2 mb-3" style="font-size: 90%">{{ member.title }}</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" [href]="member.facebook" *ngIf="member.facebook"><i
                                        class="fab fa-facebook-f"></i></a>
                                <a class="p-2" [href]="member.instagram" *ngIf="member.instagram"><i
                                        class="fab fa-instagram"></i></a>
                                <a class="p-2" [href]="member.website" *ngIf="member.website"><i
                                        class="fal fa-browser"></i></a>
                                <a class="p-2" [href]="member.linkedin" *ngIf="member.linkedin"><i
                                        class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading" style="text-align: justify; text-justify: inter-word; margin-top: 50px;">
                        {{ 'TRANSLATE.team_description_2' | translate }}
                        <hr class="border-0">
                        {{ 'TRANSLATE.help_best_way' | translate }} <a href="https://play.google.com/store/apps/details?id=captarget.archery">Android</a> ou <a href="https://apps.apple.com/us/app/captarget-archery/id1584542210">Ios</a> 😁🏹
                </div>
            </div>
        </div>
    </div>
</section>
