import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeroOneComponent} from './components/hero/hero-one/hero-one.component';
import {HeroTwoComponent} from './components/hero/hero-two/hero-two.component';
import {HeroThreeComponent} from './components/hero/hero-three/hero-three.component';
import {HeroFourComponent} from './components/hero/hero-four/hero-four.component';
import {HeroFiveComponent} from './components/hero/hero-five/hero-five.component';
import {HeroSixComponent} from './components/hero/hero-six/hero-six.component';
import {PromoOneComponent} from './components/promo/promo-one/promo-one.component';
import {PromoTwoComponent} from './components/promo/promo-two/promo-two.component';
import {PromoThreeComponent} from './components/promo/promo-three/promo-three.component';
import {PromoFourComponent} from './components/promo/promo-four/promo-four.component';
import {ThemeOneComponent} from './themes/theme-one/theme-one.component';
import {FeatureOneComponent} from './components/features/feature-one/feature-one.component';
import {FeatureTwoComponent} from './components/features/feature-two/feature-two.component';
import {FeatureThreeComponent} from './components/features/feature-three/feature-three.component';
import {ScreenshotOneComponent} from './components/screenshots/screenshot-one/screenshot-one.component';
import {ScreenshotTwoComponent} from './components/screenshots/screenshot-two/screenshot-two.component';
import {ContentOneComponent} from './components/content/content-one/content-one.component';
import {ContentTwoComponent} from './components/content/content-two/content-two.component';
import {ContentThreeComponent} from './components/content/content-three/content-three.component';
import {ContentFourComponent} from './components/content/content-four/content-four.component';
import {ContentFiveComponent} from './components/content/content-five/content-five.component';
import {ContentSixComponent} from './components/content/content-six/content-six.component';
import {ContentSevenComponent} from './components/content/content-seven/content-seven.component';
import {ContentEightComponent} from './components/content/content-eight/content-eight.component';
import {ContentNineComponent} from './components/content/content-nine/content-nine.component';
import {PricingOneComponent} from './components/pricing/pricing-one/pricing-one.component';
import {PricingTwoComponent} from './components/pricing/pricing-two/pricing-two.component';
import {PricingThreeComponent} from './components/pricing/pricing-three/pricing-three.component';
import {ReviewComponent} from './components/review/review.component';
import {TeamOneComponent} from './components/team/team-one/team-one.component';
import {TeamTwoComponent} from './components/team/team-two/team-two.component';
import {CtaOneComponent} from './components/cta/cta-one/cta-one.component';
import {CtaTwoComponent} from './components/cta/cta-two/cta-two.component';
import {DownloadOneComponent} from './components/download/download-one/download-one.component';
import {DownloadTwoComponent} from './components/download/download-two/download-two.component';
import {FaqOneComponent} from './components/faq/faq-one/faq-one.component';
import {FaqTwoComponent} from './components/faq/faq-two/faq-two.component';
import {ContactComponent} from './components/contact/contact.component';
import {FooterOneComponent} from './components/footer/footer-one/footer-one.component';
import {FooterTwoComponent} from './components/footer/footer-two/footer-two.component';
import {FooterThreeComponent} from './components/footer/footer-three/footer-three.component';
import {FooterFourComponent} from './components/footer/footer-four/footer-four.component';
import {FooterFiveComponent} from './components/footer/footer-five/footer-five.component';
import {ScrollupComponent} from './components/scrollup/scrollup.component';
import {HeaderOneComponent} from './components/header/header-one/header-one.component';
import {HeaderTwoComponent} from './components/header/header-two/header-two.component';
import {ThemeTwoComponent} from './themes/theme-two/theme-two.component';
import {ThemeThreeComponent} from './themes/theme-three/theme-three.component';
import {ThemeFourComponent} from './themes/theme-four/theme-four.component';
import {ThemeFiveComponent} from './themes/theme-five/theme-five.component';
import {ThemeSixComponent} from './themes/theme-six/theme-six.component';
import {VideoComponent} from './components/video/video.component';
import {AboutOneComponent} from './components/about/about-one/about-one.component';
import {AboutTwoComponent} from './components/about/about-two/about-two.component';
import {CounterOneComponent} from './components/counter/counter-one/counter-one.component';
import {CounterTwoComponent} from './components/counter/counter-two/counter-two.component';
import {BrandingComponent} from './components/branding/branding.component';
import {BreadcrumbOneComponent} from './components/breadcrumb/breadcrumb-one/breadcrumb-one.component';
import {BreadcrumbTwoComponent} from './components/breadcrumb/breadcrumb-two/breadcrumb-two.component';
import {BreadcrumbThreeComponent} from './components/breadcrumb/breadcrumb-three/breadcrumb-three.component';
import {BreadcrumbFourComponent} from './components/breadcrumb/breadcrumb-four/breadcrumb-four.component';
import {BreadcrumbFiveComponent} from './components/breadcrumb/breadcrumb-five/breadcrumb-five.component';
import {BreadcrumbSixComponent} from './components/breadcrumb/breadcrumb-six/breadcrumb-six.component';
import {BreadcrumbSevenComponent} from './components/breadcrumb/breadcrumb-seven/breadcrumb-seven.component';
import {BreadcrumbEightComponent} from './components/breadcrumb/breadcrumb-eight/breadcrumb-eight.component';
import {BreadcrumbNineComponent} from './components/breadcrumb/breadcrumb-nine/breadcrumb-nine.component';
import {BreadcrumbTenComponent} from './components/breadcrumb/breadcrumb-ten/breadcrumb-ten.component';
import {FooterSixComponent} from './components/footer/footer-six/footer-six.component';
import {MapComponent} from './components/map/map.component';
import {BlogOneComponent} from './components/blog/blog-one/blog-one.component';
import {BlogTwoComponent} from './components/blog/blog-two/blog-two.component';
import {BlogThreeComponent} from './components/blog/blog-three/blog-three.component';
import {BlogFourComponent} from './components/blog/blog-four/blog-four.component';
import {BlogFiveComponent} from './components/blog/blog-five/blog-five.component';
import {BlogSixComponent} from './components/blog/blog-six/blog-six.component';
import {PricingPageComponent} from './components/inner-pages/pricing-page/pricing-page.component';
import {ReviewPageComponent} from './components/inner-pages/review-page/review-page.component';
import {FaqPageComponent} from './components/inner-pages/faq-page/faq-page.component';
import {DownloadPageComponent} from './components/inner-pages/download-page/download-page.component';
import {ThankYouComponent} from './components/inner-pages/thank-you/thank-you.component';
import {NewsletterComponent} from './components/inner-pages/newsletter/newsletter.component';
import {ErrorOneComponent} from './components/inner-pages/error-page/error-one/error-one.component';
import {ErrorTwoComponent} from './components/inner-pages/error-page/error-two/error-two.component';
import {ContactPageComponent} from './components/inner-pages/contact-page/contact-page.component';
import {MaintenanceComponent} from './components/inner-pages/maintenance/maintenance.component';
import {ComingSoonComponent} from './components/inner-pages/coming-soon/coming-soon.component';
import {BlogTwoColumnComponent} from './components/inner-pages/blog-page/blog-two-column/blog-two-column.component';
import {
    BlogThreeColumnComponent
} from './components/inner-pages/blog-page/blog-three-column/blog-three-column.component';
import {
    BlogLeftSidebarComponent
} from './components/inner-pages/blog-page/blog-left-sidebar/blog-left-sidebar.component';
import {
    BlogRightSidebarComponent
} from './components/inner-pages/blog-page/blog-right-sidebar/blog-right-sidebar.component';
import {
    BlogDetailsLeftSidebarComponent
} from './components/inner-pages/blog-page/blog-details-left-sidebar/blog-details-left-sidebar.component';
import {
    BlogDetailsRightSidebarComponent
} from './components/inner-pages/blog-page/blog-details-right-sidebar/blog-details-right-sidebar.component';
import {LoginComponent} from './components/inner-pages/accounts/login/login.component';
import {SignupComponent} from './components/inner-pages/accounts/signup/signup.component';
import {ForgotComponent} from './components/inner-pages/accounts/forgot/forgot.component';
import {FaqThreeComponent} from './components/faq/faq-three/faq-three.component';
import {FaqComponent} from './components/faq/faq.component';
import {PartenairesComponent} from './components/partenaires/partenaires.component';
import {CguComponent} from './components/cgu/cgu.component';
import {ConfidencialityComponent} from './components/confidenciality/confidenciality.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {VideoCoachComponent} from './components/video-coach/video-coach.component';
import { QuestionsArcheryComponent } from './components/faq/questions-archery/questions-archery.component';
import { QuestionsPracticeComponent } from './components/faq/questions-practice/questions-practice.component';
import { QuestionsMaterialComponent } from './components/faq/questions-material/questions-material.component';
import { QuestionsWhereComponent } from './components/faq/questions-where/questions-where.component';
import { QuestionsApplicationComponent } from './components/faq/questions-application/questions-application.component';
import { FrComponent } from './cgus/fr/fr.component';
import { EnComponent } from './cgus/en/en.component';
import { EsComponent } from './cgus/es/es.component';
import { ItComponent } from './cgus/it/it.component';
import { DeComponent } from './cgus/de/de.component';
import { PtComponent } from './cgus/pt/pt.component';
import { KoComponent } from './cgus/ko/ko.component';
import { ZhComponent } from './cgus/zh/zh.component';
import { TrComponent } from './cgus/tr/tr.component';
import { JaComponent } from './cgus/ja/ja.component';
import { EtComponent } from './cgus/et/et.component';
import { NlComponent } from './cgus/nl/nl.component';
import { DaComponent } from './cgus/da/da.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SuccessPaymentComponent } from './components/success-payment/success-payment.component';
import {FormsModule} from '@angular/forms';
import { AddsComponent } from './components/adds/adds.component';
import { ZhConfComponent } from './confidentiality/zh-conf/zh-conf.component';
import { FrConfComponent } from './confidentiality/fr-conf/fr-conf.component';
import { DaConfComponent } from './confidentiality/da-conf/da-conf.component';
import { DeConfComponent } from './confidentiality/de-conf/de-conf.component';
import { EnConfComponent } from './confidentiality/en-conf/en-conf.component';
import { EsConfComponent } from './confidentiality/es-conf/es-conf.component';
import { EtConfComponent } from './confidentiality/et-conf/et-conf.component';
import { ItConfComponent } from './confidentiality/it-conf/it-conf.component';
import { JaConfComponent } from './confidentiality/ja-conf/ja-conf.component';
import { KoConfComponent } from './confidentiality/ko-conf/ko-conf.component';
import { NlConfComponent } from './confidentiality/nl-conf/nl-conf.component';
import { PtConfComponent } from './confidentiality/pt-conf/pt-conf.component';
import { TrConfComponent } from './confidentiality/tr-conf/tr-conf.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


@NgModule({
    declarations: [
        AppComponent,
        HeroOneComponent,
        HeroTwoComponent,
        HeroThreeComponent,
        HeroFourComponent,
        HeroFiveComponent,
        HeroSixComponent,
        PromoOneComponent,
        PromoTwoComponent,
        PromoThreeComponent,
        PromoFourComponent,
        ThemeOneComponent,
        FeatureOneComponent,
        FeatureTwoComponent,
        FeatureThreeComponent,
        ScreenshotOneComponent,
        ScreenshotTwoComponent,
        ContentOneComponent,
        ContentTwoComponent,
        ContentThreeComponent,
        ContentFourComponent,
        ContentFiveComponent,
        ContentSixComponent,
        ContentSevenComponent,
        ContentEightComponent,
        ContentNineComponent,
        PricingOneComponent,
        PricingTwoComponent,
        PricingThreeComponent,
        ReviewComponent,
        TeamOneComponent,
        TeamTwoComponent,
        CtaOneComponent,
        CtaTwoComponent,
        DownloadOneComponent,
        DownloadTwoComponent,
        FaqOneComponent,
        FaqTwoComponent,
        ContactComponent,
        FooterOneComponent,
        FooterTwoComponent,
        FooterThreeComponent,
        FooterFourComponent,
        FooterFiveComponent,
        ScrollupComponent,
        HeaderOneComponent,
        HeaderTwoComponent,
        ThemeTwoComponent,
        ThemeThreeComponent,
        ThemeFourComponent,
        ThemeFiveComponent,
        ThemeSixComponent,
        VideoComponent,
        AboutOneComponent,
        AboutTwoComponent,
        CounterOneComponent,
        CounterTwoComponent,
        BrandingComponent,
        BreadcrumbOneComponent,
        BreadcrumbTwoComponent,
        BreadcrumbThreeComponent,
        BreadcrumbFourComponent,
        BreadcrumbFiveComponent,
        BreadcrumbSixComponent,
        BreadcrumbSevenComponent,
        BreadcrumbEightComponent,
        BreadcrumbNineComponent,
        BreadcrumbTenComponent,
        FooterSixComponent,
        MapComponent,
        BlogOneComponent,
        BlogTwoComponent,
        BlogThreeComponent,
        BlogFourComponent,
        BlogFiveComponent,
        BlogSixComponent,
        PricingPageComponent,
        ReviewPageComponent,
        FaqPageComponent,
        DownloadPageComponent,
        ThankYouComponent,
        NewsletterComponent,
        ErrorOneComponent,
        ErrorTwoComponent,
        ContactPageComponent,
        MaintenanceComponent,
        ComingSoonComponent,
        BlogTwoColumnComponent,
        BlogThreeColumnComponent,
        BlogLeftSidebarComponent,
        BlogRightSidebarComponent,
        BlogDetailsLeftSidebarComponent,
        BlogDetailsRightSidebarComponent,
        LoginComponent,
        SignupComponent,
        ForgotComponent,
        FaqThreeComponent,
        FaqComponent,
        PartenairesComponent,
        CguComponent,
        ConfidencialityComponent,
        RedirectComponent,
        VideoCoachComponent,
        QuestionsArcheryComponent,
        QuestionsPracticeComponent,
        QuestionsMaterialComponent,
        QuestionsWhereComponent,
        QuestionsApplicationComponent,
        FrComponent,
        EnComponent,
        EsComponent,
        ItComponent,
        DeComponent,
        PtComponent,
        KoComponent,
        ZhComponent,
        TrComponent,
        JaComponent,
        EtComponent,
        NlComponent,
        DaComponent,
        SuccessPaymentComponent,
        AddsComponent,
        ZhConfComponent,
        FrConfComponent,
        DaConfComponent,
        DeConfComponent,
        EnConfComponent,
        EsConfComponent,
        EtConfComponent,
        ItConfComponent,
        JaConfComponent,
        KoConfComponent,
        NlConfComponent,
        PtConfComponent,
        TrConfComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        FormsModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {


    constructor() {
        this.initialize();
    }


    private initialize(): void {
        const firebaseConfig = {
            apiKey: 'AIzaSyBQnfOpRlfIvYy-yKGnHuQ5yU-lyJgMR64',
            authDomain: 'cap-target-archery-website.firebaseapp.com',
            projectId: 'cap-target-archery-website',
            storageBucket: 'cap-target-archery-website.appspot.com',
            messagingSenderId: '534220259191',
            appId: '1:534220259191:web:9fa4d16e58e1c33a0aab72',
            measurementId: 'G-EXGCQL9NYC'
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
    }
}
