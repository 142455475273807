<div class="container">
    <h4>Tanımlar</h4>
    <p> </p>
    <p>Aşağıdaki terimler, Kullanıcı Sözleşmesi'nde büyük harfle yazılmış ilk harf ile başlar ve bu makalede belirtilen anlamı taşır.</p>
    <p> </p>
    <p>Abonelik: Platformun hizmetlerine ücretsiz veya ücretli olarak abone olma durumunu ifade eder.</p>
    <p> </p>
    <p>Okçu: Okçuluk yapan ve uygulamanın hizmetlerini kullanan veya kullanmayan Kullanıcı.</p>
    <p> </p>
    <p>Koç: Okçuluk koçu, "Koç" türünde ücretsiz veya ücretli bir abonelik almış ve buna bağlı hizmetlere erişim sağlayan antrenör.</p>
    <p> </p>
    <p>Uygulama: Android ve iOS'te bulunan "CapTarget Archery" mobil uygulamasını ifade eder.</p>
    <p> </p>
    <p>Site: CapTarget Archery vitrini sitesini ifade eder.</p>
    <p> </p>
    <p>Koç Sitesi: Koçlara özel erişim sağlanan siteyi ifade eder.</p>
    <p> </p>
    <p>Kullanım Koşulları: Platformun genel kullanım koşullarını ifade eder.</p>
    <p> </p>
    <p>Hesap: Kullanıcıya Platforma erişim ve çeşitli hizmetlere erişim sağlayan, kişisel bir kimlik ve erişim kodu ile ilişkilendirilmiş hesabı ifade eder.</p>
    <p> </p>
    <p>Grup: CapTarget Archery uygulamasında oluşturulan ve Kullanıcıları ve Koçları içeren bir grup ifade eder.</p>
    <p> </p>
    <p>Ortak: CapTarget Archery ile ortaklık kurmuş ve Platform aracılığıyla Kullanıcılara sunulan bir kişi veya kuruluşu ifade eder.</p>
    <p> </p>
    <p>Platform: Siteler ve/veya Uygulama üzerinden erişilebilen tüm hizmetleri ifade eder.</p>
    <p> </p>
    <p>Kullanıcı: Platforma erişimi olan ve kullanıp kullanmayan herhangi bir fiziksel veya tüzel kişiyi ifade eder.</p>
    <p></p>
    <p>Taşlar: CapTarget Archery'de kullanılan sanal bir para birimini ifade eder.</p>
    <p> </p>
    <h4>Amaç</h4>
    <p> </p>
    <p>Kullanıcı Sözleşmesi, Platform'un tüm Kullanıcıları için geçerli olan terimleri ve koşulları tanımlamayı amaçlar.</p>
    <p> </p>
    <p>Herhangi bir kayıt, erişim ve/veya Platform'un kullanımı, Kullanıcının tüm Kullanıcı Sözleşmesi'ni koşulsuz kabul ettiğini ve uyacağını gösterir.</p>
    <p> </p>
    <p>Herhangi bir küçük kullanıcı, Platform'a kaydolmak için ebeveynlerinin veya velayet hakkına sahip olanların iznini aldığını beyan eder ve kabul eder.</p>
    <p> </p>
    <p>Otomatik olarak bir robot veya başka bir yöntemle oluşturulan herhangi bir kayıt talebi reddedilecektir.</p>
    <p> </p>
    <h4>Uygulama Tanıtımı</h4>
    <p> </p>
    <p>Uygulama öncelikle okçuluk yapanlar veya koçlar için tasarlanmış olup sporla ilgili etkinlikleri yönetmeyi, organize etmeyi, üyeler arasında bilgi iletişimi sağlamayı ve alanın güncel haberlerini paylaşmayı amaçlamaktadır.</p>
    <p> </p>
    <p>Uygulama aracılığıyla Kullanıcılar özellikle aşağıdaki işlevlere sahip olurlar:</p>
    <p> </p>
    <p>Eğitim ve yarışma oturumlarının yönetimi</p>
    <p>Hesaplanan atışlar ve maçların yönetimi</p>
    <p>Okçuluk malzemelerinin yönetimi</p>
    <p>İstatistikler sağlamak için verilerin kullanımı</p>
    <p>Sosyal ve eğlenceli etkileşimler, Kullanıcının Koçlarına verilerin sunumu</p>
    <p>Grup üyeleri arasında sosyal ve eğlenceli etkileşimler</p>
    <p>Deneyimin özelleştirilmesi için sanal para birimi "Taş" ile öğelerin satın alınması</p>
    <p>"Taş" adıyla bilinen sanal para birimi ile ödeme sistemi üzerinden "Taş" satın alımı</p>
    <p>Mesajlaşma</p>
    <p> </p>
    <h4>Bir Kullanıcının Kaydı</h4>
    <p> </p>
    <p>Her Kullanıcı, Platform'a erişim sağlayan ve hizmetleri kullanmasına olanak tanıyan (ebeveynlik yetkisi sahibi olduğunda) bir Hesap (kişisel veya bir Minder üyesi adına) oluşturma seçeneğine sahiptir.</p>
    <p> </p>
    <p>Kayıt işlemi, Kullanıcı tarafından Uygulama üzerinden Platform'da gerçekleştirilir. Bunun için kayıt formunun farklı alanlarını doldurması gerekecektir, özellikle geçerli bir e-posta adresi, adı ve soyadı ve okçuluk uygulamasına ilişkin çeşitli bilgiler gereklidir. Kullanıcı, Kullanıcı Sözleşmesi ve Veri Gizliliği Politikası'nı okuyup kabul etmelidir.</p>
    <p> </p>
    <p>Kayıt formunu doldurarak, Kullanıcı'nın sadece doğru, güncel ve eksiksiz bilgiler sağlayacağına taahhüt etmesi gerekmektedir.</p>
    <p> </p>
    <p>Kullanıcı, Hesap erişim kodunu gizli tutmayı taahhüt eder. Hesap erişim kodunun yetkisiz kullanımı durumunda, Kullanıcı bunu derhal CapTarget Archery'ye bildirmelidir.</p>
    <p> </p>
    <p>Kullanıcı, Hesabının kullanımından yalnızca kendisinin sorumlu olduğunu kabul eder. Platform üzerinden yapılan her bağlantı veya veri iletimi, Kullanıcı tarafından gerçekleştirilmiş ve yalnızca onun sorumluluğu altında olmuş olarak kabul edilir.</p>
    <p> </p>
    <p>CapTarget Archery, Kullanıcı'nın kayıt verilerinin CapTarget Archery'ye ulaşmamasından veya verilerin CapTarget Archery'ye ulaştığında işlenemeyecek bir formda olmasından dolayı sorumlu tutulamaz (örneğin, Kullanıcı'nın internet bağlantısında bir sorun, sunucularının geçici bir arızası vb. gibi bir nedenle).</p>
    <p> </p>
    <p>Tüm kayıtlar süresiz olarak yapılmış olup, Kullanıcı Hesabı kapatılıncaya kadar aktif kalır.</p>
    <p> </p>
    <h4>Bir Grubun Kaydı</h4>
    <p> </p>
    <p>Bir Kullanıcının grup işlevlerine erişebilmesi için bir Grup oluşturması veya katılması gerekmektedir.</p>
    <p> </p>
    <p>Kayıt formunu doldurarak, yönetici'nin yalnızca doğru, güncel ve eksiksiz bilgiler sağlayacağına taahhüt etmesi gerekmektedir. Yönetici, iletişim bilgilerini paylaştığı Üyelerin e-posta adreslerini ve telefon numaralarını ilettiğini ve bunları istedikleri zaman CapTarget Archery'ye silmeleri için serbest bıraktıklarını temin eder (Gizlilik Politikamıza bakınız).</p>
    <p> </p>
    <p>Yöneticiler, Grup'un kullanımından yalnızca kendilerinin sorumlu olduğunu kabul ederler.</p>
    <p> </p>
    <p>Ayrıca belirtmek gerekir ki, Yönetici, Örgütünün Etki Alanını tamamen ve yalnızca kendisi ve herhangi bir üçüncü taraf tarafından kullanımından yalnızca kendisinin sorumlu olduğu bir şekilde kullanır.</p>
    <p> </p>
    <p>CapTarget Archery, Örgütün kayıt verilerinin CapTarget Archery'ye ulaşmamasından veya verilerin CapTarget Archery'ye ulaştığında işlenemeyecek bir formda olmasından dolayı sorumlu tutulamaz (örneğin, Yönetici'nin internet bağlantısında bir sorun, geçici olarak sunucu hatası vb. bir nedenle) veya veriler CapTarget Archery'ye işlenemez bir formatta ulaşırsa (örneğin, Yönetici'nin yetersiz donanım veya yazılım kullanıyor olması durumunda).</p>
    <p> </p>
    <p>Tüm kayıtlar süresiz olarak yapılmış olup, Grup, yöneticilerden biri tarafından kapatılana kadar aktif kalır.</p>
    <p> </p>
    <p> </p>
    <h4>Bir Koçun Kaydı</h4>
    <p>Koçların işlevlerine erişebilmek için Kullanıcı, CapTarget Archery koç hizmetlerine erişim lisansı elde etmek için web sitesi ve/veya mobil uygulama üzerinden ücretsiz veya ücretli bir abonelik başvurusu yapmalıdır.</p>
    <p>Erkekliğini taşıyan bir Kullanıcı için lisans verilebilir ve abonelik yerine kullanıcının adına abone olan bir üçüncü taraf tarafından sağlanabilir. Bir erkeklik lisansı, kayıt yapan kullanıcı için özel olarak sağlanır. Hesap başka kişilerle paylaşılamaz.</p>
    <p>Farklı tekliflerin avantajları değişebilir. Bir abonelik devam ederken bu değişiklikler etkili olmayacak veya kullanıcı lehine değerlendirilecektir.</p>
    <p>Kayıt işlemi, Kullanıcı tarafından web sitesi üzerinden gerçekleştirilir. Bunun için kayıt formunun farklı alanlarını doldurması gerekecektir, özellikle geçerli bir e-posta adresi, adı ve soyadı ve okçuluk uygulamasına ilişkin çeşitli bilgiler gereklidir. Kullanıcı, Kullanıcı Sözleşmesi, Satış Koşulları ve Veri Gizliliği Politikası'nı okuyup kabul etmelidir.</p>
    <p>Kayıt formunu doldurarak, koç ve lisansı satın alan tüketici'nin yalnızca doğru, güncel ve eksiksiz bilgiler sağlayacağına taahhüt etmesi gerekmektedir. Lisansı kullanan kullanıcı, Hesabının kullanımından yalnızca kendisinin sorumlu olduğunu kabul eder. Platform üzerinden yapılan her bağlantı veya veri iletimi, Kullanıcı tarafından gerçekleştirilmiş ve yalnızca onun sorumluluğu altında olmuş olarak kabul edilir.</p>
    <p>CapTarget Archery, Kullanıcı'nın kayıt verilerinin CapTarget Archery'ye ulaşmamasından veya verilerin CapTarget Archery'ye ulaştığında işlenemeyecek bir formda olmasından dolayı sorumlu tutulamaz (örneğin, Kullanıcı'nın internet bağlantısında bir sorun, sunucularının geçici bir arızası vb. gibi bir nedenle).</p>
    <p>Ücretli bir aboneliğe katılım süresiz olarak gerçekleşir, kullanıcının açıkça isteği olmadıkça, sözleşme süresinin minimum taahhüt süresi sona erdikten sonra otomatik olarak yenilenecektir. Daha fazla bilgi için Satış Koşullarına başvurmanızı öneririz.</p>
    <p> </p>


    <h4>Veri Paylaşımı</h4>
    <p>CapTarget Archery üzerinden veri paylaşımı işlevlerine erişmek için uygulamamız, bir hayran ve takip sistemi sunmaktadır. Siz, bir kullanıcı olarak, davetiyeleri kabul etme veya diğer kullanıcıları ağınıza davet etme olanağına sahipsiniz.</p>
    <p>Halka açık bir profil durumunda, diğer kullanıcıların sizi takip etmesi için onay gerekmez. Bir kullanıcı sizi takip ettiğinde, bazı verileriniz otomatik olarak veya manuel olarak paylaşılabilir. Bu veriler şunları içerebilir:</p>
    <p>-Yarışmalarınızın veya antrenmanlarınızın sonuçları.</p>
    <p>-Lisans süreniz, en iyi sonucunuz veya sıralamanız gibi çeşitli spor istatistikleri.</p>
    <p>-Uygulama kullanımınıza ilişkin veriler, örneğin tercih ettiğiniz oyunlar ve egzersizler.</p>
    <p>-Kulüp bilgileriniz, diliniz, ülkeniz gibi bilgiler ve adınız, soyadınız, kullandığınız yay türü veya atış kategoriniz gibi diğer veriler.</p>
    <p>Bu verilerin bazılarını paylaşmamayı tercih edebilirsiniz, bunun için kişisel ayarlarınızı veya her durumun ayarlarını değiştirerek seçenekleriniz vardır.</p>
    <p>Hesabınızı oluşturduğunuz anda, CapTarget platformunda görünür olmayı kabul etmiş olursunuz. Diğer kullanıcılar adınızı ve soyadınızı kullanarak sizi arayabilir. Profiliniz, sporcu meydan okumaları veya takip bağlantıları oluşturma amacıyla diğer kullanıcılara otomatik olarak önerilebilir.</p>
    <p>Lütfen CapTarget Archery üzerinde CapTarget, iş ortakları, reklamverenler veya diğer kullanıcılar tarafından paylaşılan bilgilere maruz kalabileceğinizi unutmayın. Verilerinizi nasıl yönettiğimiz hakkında daha fazla ayrıntı için gizlilik politikamızı incelemenizi öneririz.</p>
    <p>Bu maddelerin tümünün kişisel verilerin korunması ve gizlilikle ilgili mevcut yasalara ve düzenlemelere uygun olduğunu vurgulamak isteriz. Endişeleriniz veya ek sorularınız varsa, bizimle iletişime geçmekten çekinmeyin.</p>
    <p> </p>
    <p> </p>

    <p> </p>
    <h4>Uyarı</h4>
    <p>CapTarget Archery platformlarının koç sürümlerine erişim, web sürümü için bilgisayar, tablet veya telefon ve mobil sürüm için ANDROID veya IOS telefon veya tablet gibi bilgisayar araçlarının kullanılmasını gerektirir. Verilere erişmek için internet bağlantısı gereklidir. Kullanıcı, bu hizmetlere erişmek için bu gerekliliğin farkında olduğunu onaylar.</p>
    <p>Hizmetlerde sunulan egzersizler ve farklı durumlar, kullanıcıların iyi fiziksel durumda ve bunları gerçekleştirmeye uygun olduğunu varsayar. Abone, sağlığını, güvenliğini ve diğer kişilerin sağlığını koruyarak etkinliği gerçekleştirmek için gerekli tüm önlemleri almayı taahhüt eder.</p>
    <p> </p>
    <p> </p>

    <p></p>
    <p></p>

    <h4>Satış Koşulları</h4>
    <p>CapTarget Archery tarafından sunulan dijital hizmetlerden veya ürünlerden birini satın alarak veya bir abonelik başlatarak aşağıda açıklanan satış koşullarını kabul etmiş olursunuz. Bu koşulları kabul etmiyorsanız, lütfen uygulamamızdan veya web sitemizden herhangi bir şey satın almayın.</p>
    <h6>Abonelikler</h6>
    <p>CapTarget'dan içerik satın aldığınızda, CapTarget size sınırlı, iptal edilebilir, devredilemez, özel olmayan ve devredilemez bir lisans verir; böylece uygulamamızın veya web sitemizin bazı ücretli işlevlerine erişebilirsiniz. Uygulamamız, otomatik yenilemeli dijital hizmetlere erişim sağlamak için aylık ve yıllık abonelikler sunar. Abonelik ücretleri abonelik tarihinde seçtiğiniz ödeme yönteminden otomatik olarak tahsil edilir. CapTarget Archery'nin sanal para birimi olan "gemme" ile ödenen abonelikler otomatik olarak yenilenmez.</p>
    <p>Eğer aboneliğinizi yenilemek istemiyorsanız, abonelik süresinin bitiminden en az 24 saat önce hesap ayarlarınıza giderek bunu devre dışı bırakmanız gerekmektedir.</p>
    <h6>Ücretler</h6>
    <p>Abonelik ücretlerimiz uygulamamızda yayınlanır ve önceden bildirim yapılmaksızın herhangi bir zamanda değiştirilebilir. Abonelik ücretleri tüm geçerli vergileri içerir. Uygulamamızın kullanımıyla ilgili tüm masraflardan (internet erişim ücretleri ve mobil veri ücretleri dahil) siz sorumlusunuzdur. Tüm çabalarımıza rağmen, katalogumuzdaki sınırlı sayıda ürün hatalı bir şekilde fiyatlandırılmış olabilir. Ücretler, mevcut aboneler için bile değiştirilebilir. Seçtiğiniz sisteme (Apple, Google, Stripe veya diğerleri) bağlı olarak, geçerli aboneliğinizde ücret artışı olması durumunda size bildirim gönderilebilir ve yenileme öncesinde isterseniz aboneliği iptal edebilirsiniz.</p>
    <h6>Kayıp Riski</h6>
    <p>CapTarget içeriği size sunulduktan sonra, içeriğin kaybı riskini satın alarak kabul edersiniz, bu, dosya bozulması veya cihaz veya sabit disk arızası nedeniyle meydana gelen kayıplar dahil olmak üzere içerik CapTarget'ın sizin için kullanılabilir hale getirilmesinden sonra meydana gelebilir. Satın alınan CapTarget içeriği genellikle CapTarget hesabınızda bulunur, ancak çeşitli nedenlerle kullanılamayabilir. Bu gibi durumlarda, CapTarget gelecekteki CapTarget içeriğinin kullanılamazlığından size karşı sorumlu olmayacak ve zaten ödemiş olduğunuz ücretin tamamını veya bir kısmını iade alma hakkınız olmayacaktır.</p>
    <h6>Teslimat</h6>
    <p>CapTarget içeriğine erişim, ürünü veya aboneliği satın aldığınızda sağlanır. İçerik, CapTarget hesabınızda kullanılabilir durumda olacaktır. Uygulamanın yeniden başlatılması gerekebilir ve hizmetlerin kullanıma sunulması için makul bir süre (genellikle birkaç dakika) gerekebilir.</p>
    <h6>Ödeme</h6>
    <p>Ödeme işlevlerimizin kullanılabilmesi için CapTarget hesabınızda geçerli bir ödeme yöntemi (örneğin, kredi kartı veya diğer yetkilendirilmiş ödeme yöntemleri) olması gerekmektedir. Aboneliğinizin ödemesi, Apple veya Google Play hesabınızdaki ödeme yöntemi veya web sitemizden abonelik satın aldıysanız diğer ödeme yöntemine dayalı olarak otomatik olarak hesabınızdan tahsil edilir. Kesin ve güncel ödeme bilgilerini sağlamayı kabul edersiniz. Herhangi bir nedenle ödeme yapılmazsa, aboneliğinizi askıya almak veya iptal etme hakkını saklı tutarız.</p>
    <h6>Uygulama Kullanımı</h6>
    <p>Uygulamamızı kişisel ve ticari olmayan amaçlarla kullanmanıza izin verilir. Uygulamamızın içeriğini önceden yazılı iznimiz olmadan değiştiremez, dağıtamaz, satamaz veya aktaramazsınız. Uygulamamızı, uygulanabilir yasaları ihlal eden, üçüncü taraf haklarını ihlal eden veya zarar veren bir şekilde kullanamazsınız.</p>
    <h6>İade</h6>
    <p>Aboneliklerimiz 3 haftalık bir deneme süresi boyunca test edilebilir ve bu nedenle iade edilemez. Bir aboneliği veya ürünü satın almadan önce her bir abonelik hizmetinin özelliklerini kontrol ediniz. Satışa sunulan dijital ürünler açık ve iade edilemezdir.</p>
    <h6>İptal</h6>
    <p>Satın alma işlemiyle bir abonelik satın aldığınızda, satış koşullarını kabul etmiş olursunuz. </p>

    <h6>Bize Ulaşın</h6>
    <p>Sorularınız veya endişeleriniz varsa, lütfen bize şu adresten ulaşın: support@captarget-archery.com</p>
    <p>Probleminizi çözmek için elimizden geleni yapacağız ve her iki tarafın da tatmin edici bir çözüm bulmasını sağlayacağız.</p>
    <p></p>
    <p></p>
    <h4>Platform Kullanım Ücretleri</h4>
    <p></p>
    <p>Hesap ve Grup oluşturma işlemi Kullanıcılar için ücretsizdir. Ancak, Platform kullanımıyla ilgili bağlantı ve iletişim ücretleri tamamen Kullanıcıya aittir.</p>
    <p>Antrenör sürümlerine erişim, deneme sürümleri ve beta sürümü hariç, ücretlidir. Tarifeler, versiyonun çıkışından itibaren internet sitesinde bulunmaktadır.
    </p>
    <h4>Platform Üzerinden Alımlar</h4>
    <p></p>
    <p>Gerçek para kullanarak sınırlı, kişisel, devredilemez, alt lisans verilemez ve iptal edilebilir bir lisans satın alabilirsiniz; böylece CapTarget Archery oyunları için tasarlanmış "sanal para" olan özellikle sanal para veya elmas da dahil olmak üzere sanal kaynakları kullanabilirsiniz. Ayrıca, bu sanal kaynakları etkinlikler, yarışmalar, oyunlar, reklamları izleme veya platformdaki hedeflere ulaşma gibi durumlarda kazanabilir veya CapTarget Archery tarafından dağıtılabilir.</p>
    <p></p>
    <p>Bu sanal para, uygulama tarafından satılan farklı ürünleri satın almak için kullanılabilir.</p>
    <p></p>
    <p>Sanal Ögelerin transferi, hizmette açıkça izin verilmediği sürece yasaktır. Hizmette açıkça izin verilen durumlar haricinde, Sanal Ögeleri başka bir kişiye veya kuruluşa satmak, satın almak, dönüştürmek veya transfer etmekten kaçınmalısınız; bunun içinde CapTarget Archery, diğer kullanıcılar veya üçüncü taraflar da dahil olmak üzere bu eylemlerden herhangi birini gerçekleştirmekten veya denemekten kaçınmalısınız.</p>
    <p></p>
    <p></p>
    <p>CapTarget Archery, Sanal Ögeleri herhangi bir zamanda, önceden bildirimde bulunarak veya bulunmaksızın yönetebilir, düzenleyebilir, denetleyebilir, değiştirebilir veya silebilir. CapTarget Archery, bu haklarını kullanırken size veya üçüncü taraflara karşı sorumlu olmayacaktır.</p>
    <p></p>
    <p>Üçüncü taraf Sanal Ögelerinin alım ve dönüşümleri, hizmet aracılığıyla yapıldığında kesindir ve iade edilemez.</p>
    <p></p>
    <p>Adınıza kayıtlı bir hesabı kullanan siz veya başka bir kişi tarafından ödenmesi gereken tüm geçerli ücretler ve vergileri ödeme konusunda taahhütte bulunursunuz. CapTarget Archery, hizmet aracılığıyla sunulan Sanal Ögelerin fiyatını herhangi bir zamanda değiştirme hakkına sahiptir. CapTarget Archery, herhangi bir nedenle ödeme yapma yükümlülüğü olmadığını kabul edersiniz ve hesap kapatıldığında kullanılmayan Sanal Ögelerin tutarı veya başka bir tazminat talep etmeyeceğinizi kabul edersiniz.</p>
    <p></p>
    <h4>Reklamlar ve İş Ortağı</h4>
    <p></p>
    <p>CapTarget Archery, farklı sunulan hizmetlerde diğer reklam sitelerine ve/veya iş ortaklarına bağlantılar sağlayabilir.</p>
    <p></p>
    <p>CapTarget Archery, bu sitelerin ve dış kaynakların kullanılabilirliği konusunda taahhütte bulunmadığı için, bu sitelerde ve kaynaklarda bulunan içerik, reklamlar, ürünler, hizmetler veya diğer materyallere ilişkin sorumlu değildir.</p>
    <p></p>
    <p>Aynı şekilde, CapTarget Archery, bu reklam sitelerinde, İş Ortağı sitelerinde veya dış kaynaklarda mevcut olan içerik veya hizmetlerin kullanımından kaynaklanan veya bu içerik veya hizmetlerle ilgili olarak ortaya çıkan gerçek veya iddia edilen herhangi bir zarar veya kayıptan sorumlu tutulamaz.</p>
    <p></p>
    <h4>Hesap Kapatma</h4>
    <p></p>
    <p>Bir Kullanıcı, herhangi bir zamanda kendi Hesabını veya bir altındaki Hesabı kapatabilir. Veri Gizliliği Politikamıza uygun olarak, bir Hesabın kapatılması, ilgili kişisel verilerin silinmesine yol açar.</p>
    <p></p>
    <p>Bununla birlikte, CapTarget Archery'nin talep edebileceği tazminatın yanı sıra, CapTarget Archery, aşağıdaki durumlarda önceden bildirim yapmaksızın veya tazminat ödemeksizin bir Kullanıcının Hesabını geçici veya kalıcı olarak kapama hakkını saklı tutar:</p>
    <p></p>
    <p>Kullanım Koşulları'na uyulmaması</p>
    <p>Kayıt sırasında yanlış bilgi verilmesi;</p>
    <p>CapTarget Archery'nin ticari çıkarlarına aykırı hareket edilmesi durumunda.</p>
    <p></p>
    <p>CapTarget Archery'nin inisiyatifiyle Kullanıcı Hesabının veya CGV'ye aykırı olarak İş Ortağı Hesabının kapatılması, tazminat veya iade gerektirmez. Abonelik için ödenen tüm miktarlar CapTarget Archery'nin mülkiyetinde kalacaktır.</p>
    <p></p>
    <p>Bir Koç Erişiminin kapatılması, ilgili CGV'ye uygun olarak gerçekleştirilebilir. Kullanıcıları, ücretli bir Koç Erişiminin kapatılması için ilgili CGV'ye başvurmaları konusunda teşvik ederiz. Bir deneme sürümü otomatik olarak süresi dolduğunda askıya alınır ve bir abonelikle yeniden etkinleştirilebilir. Beta sürümü, CapTarget Archery'nin takdirine göre yeniden edinilebilir. Bir deneme sürümü veya beta sürümünü kapatmak için lütfen support@captarget-archery.com adresine bir e-posta gönderin.</p>
    <p></p>
    <h4>Bir Grubun Kaldırılması</h4>
    <p></p>
    <p>Bir Yönetici herhangi bir zamanda bir Grubu kaldırabilir. Bir Kuruluş Alanının kaldırılması, bu alana bağlı tüm verilerin ve avantajların silinmesine yol açar.</p>
    <p></p>
    <p>CapTarget Archery'nin İnisiyatifiyle Bir Kuruluş Alanının Kaldırılması</p>
    <p></p>
    <p>Bununla birlikte, CapTarget Archery'nin talep edebileceği tazminatın yanı sıra, CapTarget Archery, aşağıdaki durumlarda önceden bildirim yapmaksızın veya tazminat ödemeksizin bir Kuruluş Alanını geçici veya kalıcı olarak kaldırma hakkını saklı tutar:</p>
    <p></p>
    <p>CGV'ye uyulmaması;</p>
    <p>Kayıt sırasında yanlış bilgi verilmesi;</p>
    <p>CapTarget Archery'nin ticari çıkarlarına aykırı hareket edilmesi durumunda.</p>
    <p></p>
    <p>CapTarget Archery'nin inisiyatifiyle bir Kuruluş Alanının kaldırılması, tazminat veya iade gerektirmez. Abonelik için ödenen tüm miktarlar CapTarget Archery'nin mülkiyetinde kalacaktır ve bir Kuruluş Alanının kaldırılması, Aboneliği sona erdirmek için Kuruluş/Administrator'un yükümlülüğünü sona erdirmez.</p>
    <p></p>
    <h4>Platform Bilgileri ve İçeriği</h4>
    <p></p>
    <p>Kullanıcı, Platformu kullanırken özellikle kendi üyeliği olduğu bir Grup içerisinde yer alan bilgi ve içerikleri değerlendirirken sağduyulu olmayı taahhüt eder.</p>
    <p></p>
    <p>Bu bağlamda, Kullanıcı, Platform'da mevcut bilgi ve içeriklerin kapsamlı olmadığını ve özellikle bu içeriğin asıl amacının Kuruluş Yöneticisine organizasyon yönetiminde yardımcı olmak olduğunu kabul eder.</p>
    <p></p>
    <p>Kullanıcı, Platform'da mevcut olan içeriğin herhangi bir bölümünü veya tamamını satmak, yeniden satmak veya herhangi bir şekilde kullanmak yasaklar.</p>
    <p></p>
    <p>Kullanıcı, Platform aracılığıyla yayınladığı ve ilettiği iletişim, bilgi, dosya, videolar, fotoğraflar, belgeler veya diğer içeriklerden yalnızca kendisi sorumludur.</p>
    <p></p>
    <p>Kullanıcının Platform üzerinde yayınladığı iletişim, bilgi, dosya, videolar, fotoğraflar, belgeler veya diğer içerikler, yalnızca Kullanıcının kişisel görüşlerini ve görüşlerini yansıtır ve CapTarget Archery veya çalışanları ve temsilcilerinin görüşlerini yansıtmaz.</p>
    <p></p>
    <p>Platform kapsamında, Kullanıcı, yalnızca objektif, eksiksiz ve doğru bilgiler sağlayacağını, hoşgörü, nezaket ve diğer insanlara saygı kurallarına uygun olarak hareket edeceğini taahhüt eder.</p>
    <p></p>
    <p>CapTarget Archery, Kullanıcının Platform'a göndermeyi düşündüğü iletişim, bilgi, dosya, videolar, fotoğraflar, belgeler veya diğer içeriklerin ifadesinde ölçülü ve dikkatli davranmalarını teşvik eder. Bu kapsamda Kullanıcı, Platform'da yayınlamak istediği içeriğin, içerik veya hizmetlerin kullanılmasına ilişkin yürürlükteki yasalara ve düzenlemelere aykırı olmamasını taahhüt eder.</p>
    <p></p>
    <p>CapTarget Archery, Kullanıcı'nın Platform'da yayınlamak istediği içeriğin ifadesinde ölçülü ve dikkatli olmalarını ve özellikle aşağıdaki hallerde Kullanıcının Platform'a yayınladığı içerik veya hizmetlere aykırı olanları yayınlamamalarını teşvik eder:</p>
    <p></p>
    <p>Toplum düzenine veya ahlak kurallarına aykırı bilgileri yayınlamak;</p>
    <p>Platform'un amacını propaganda, ajitasyon, iş yeri veya teklif amaçlı kullanmak;</p>
    <p>Reklam veya tanıtım amaçlı veya tütün, alkol veya diğer düzenlenmiş maddeler, ürünler veya hizmetlere propagandalar içeren içerikler yayınlamak;</p>
    <p>Üçüncü şahısların kişilik haklarına veya hakaret, iftira, ahlaka aykırı, küçümseyici, cinsel içerikli, saldırgan, ayrımcılığı teşvik eden, siyasi şiddeti teşvik eden, ırkçılığı, yabancı düşmanlığını, cinsiyet ayrımcılığını, homofobiyi teşvik eden içerikler yayınlamak;</p>
    <p>Kişisel verilerin korunması yasalarına aykırı olarak kimliklerini belirlemeye yönelik verileri yayınlamak, özellikle soyadları, posta veya e-posta adresleri, telefon numaraları, fotoğrafları, ses veya video kayıtları;</p>
    <p>Üçüncü tarafların fikri mülkiyet haklarına zarar vermek, özellikle telif hakkı veya ticari marka ile ilgili herhangi bir bilgi veya imzayı kaldırmak veya silmek ve diğer kısıtlayıcı ifadeleri kaldırmak veya silmek.</p>
    <p></p>
    <p>Kullanıcı, başkalarının haklarına saygı duymayı da taahhüt eder ve özellikle</p>
    <p></p>
    <p>kişisel haklara (görüntü hakkı, özel hayat hakkı);</p>
    <p>ticari marka haklarına;</p>
    <p>telif hakkına (özellikle yazılım, ses, görüntü, fotoğraf, metin, hareketli görüntüler üzerinde); ve türetilmiş haklara (sanatçılar, fonogram ve video kayıt üreticileri ve veritabanı üreticilerinin özgün hakları) saygı duymayı taahhüt eder;</p>
    <p>genel olarak insan haklarına ve mülkiyet haklarına saygı duymayı taahhüt eder.</p>
    <p></p>

    <p>Kullanıcı ayrıca, önceden yazılı ve onaylı izin olmadan, bu liste kesin olmamakla birlikte, aşağıdaki faaliyetleri yapmayı taahhüt eder:</p>
    <p> </p>
    <p>Platformu promosyon amaçlı kullanmak ve genel olarak doğrudan veya dolaylı olarak kendisine ödeme yapan ürün veya hizmetleri sunmak;</p>
    <p>Platform içeriklerinin arşiv dosyalarını oluşturmak;</p>
    <p>Platform üzerinde bulunan içeriklerin, markaların, logoların, ayırt edici işaretlerin tamamını veya bir kısmını çoğaltmak, temsil etmek, kullanmak, referans vermek (özellikle arama motorlarının meta-etiketlerinde) veya başka şekillerde kullanmak.</p>
    <p> </p>
    <p>Bu Kullanım Koşullarına uyulmaması, incelenen içeriğin derhal ve otomatik olarak silinmesine, bir uyarıya ve/veya Kullanıcının Hesabının kapatılmasına yol açabilir, bunlar dışında herhangi bir yasal işlem ve/veya tazminat talebinin yanı sıra CapTarget Archery'nin haklarını veya taleplerini ileri sürme hakkı saklıdır.</p>
    <p> </p>
    <p>CapTarget Archery, Platformun içeriğinden, dosyalarından, videolarından, fotoğraflarından, belgelerinden veya diğer her türlü içeriğinden, hukuka aykırı içeriklerden, hatalardan veya eksikliklerden, veya bu materyalin herhangi bir Kullanıcı tarafından kullanılmasından kaynaklanan kayıp veya zararlardan sorumlu değildir.</p>
    <p> </p>
    <p>Site'nin ikincil sayfalarına yönlendiren her türlü bağlantı kesinlikle yasaktır, ancak Kullanıcı tarafından alınan CapTarget Archery'nin açık izni hariç. Ayrıca, CapTarget Archery'nin basit bir isteği üzerine her türlü bağlantıyı Kullanıcı kaldırmalıdır.</p>
    <p> </p>
    <h4>Yöneticinin Moderasyon Rolü</h4>
    <p> </p>
    <p>Yönetici, Grubun koordinatörü ve denetleyicisi olarak, Üyelerin CGU şartlarını uygun şekilde yerine getirmelerini sağlamayı taahhüt eder. Bilgilendirdiği anda, yasaya ve/veya kamu düzenine ve/veya bu Kullanım Koşullarına aykırı olabilecek içeriği (varsa) bildirmeyi veya kaldırmayı taahhüt eder; özellikle şiddet içeren, pornografik, ırkçı, antisemitik, yabancı düşmanı, insan haklarına veya onuruna saldıran, gizliliği ihlal eden, telif hakkına, yakın haklarına, marka haklarına, üçüncü taraf kişilik haklarına zarar veren içerik.</p>
    <p> </p>
    <p>Bu kurallar ve kullanımlara uyulmaması durumunda, CapTarget Archery, sorumlu Yönetici ve/veya Üyeyi hariç tutma ve Platform'un yanlış kullanımından kaynaklanan haksız durumu sona erdirmeye yönelik herhangi bir yasal işlem başlatma hakkını saklı tutar.</p>
    <p> </p>
    <h4>E-posta, Bildirimler ve SMS Gönderimi</h4>
    <p> </p>
    <p>Sunulan hizmetler kapsamında, CapTarget Archery, Üyeler arasında Organizasyon ile ilgili bilgileri iletmek için e-posta, Uygulama üzerinde bildirimler ve SMS iletişimleri sunar. Bu iletişimler yalnızca Üyelerin Organizasyon ile ilgili bilgi alışverişi yapmak amacıyla kullanılabilir ve Üyelerin Hesaplarını oluştururken sağladıkları cep telefonu numaraları ve e-posta adresleri aracılığıyla gerçekleştirilir.</p>
    <p> </p>
    <p>Kullanıcı, dilediği zaman e-posta, bildirim veya SMS almayı reddetmeye karar verebilir, cihaz ayarlarını değiştirerek veya destek birimine talepte bulunarak bu ayarları değiştirerek.</p>
    <p> </p>
    <h4>Platform Erişimi - Sorumluluk ve Taahhütler</h4>
    <p> </p>
    <p>CapTarget Archery, Platformun kesintisiz olarak 7 gün 24 saat erişilebilir olmasını sağlamak için çaba sarf eder, önceden planlanan bakım işlemleri ve bu makalede belirtilen hükümler saklı kalmak üzere.</p>
    <p> </p>
    <p>CapTarget Archery, Platformun gerektiğinde teknik gelişmeler göz önünde bulundurularak gerekli göreceği tüm değişiklikleri ve iyileştirmeleri yapma hakkını saklı tutar. Bu nedenle, CapTarget Archery, önceden bildirim yapmaksızın veya tazminat ödemeksizin, geçici olarak Platform erişimini durdurma hakkına sahiptir, Platformun geliştirme veya düzeltme amacıyla bakım işlemleri gerçekleştirmek için. Kullanıcı, bu konuda CapTarget Archery'yi her türlü sorumluluktan muaf tutar ve bu konuda herhangi bir talepte bulunmayacağını kabul eder.</p>
    <p> </p>
    <p>CapTarget Archery, Service ve CapTarget Archery oyunlarının cihazınıza veya bilgisayarınıza yüklediği güncellemeleri kabul etmenizi isteyebilir. CapTarget Archery Service ve oyunlarını güncelleyebilir ve bunları size bildirim yaparak veya bildirim yapmaksızın güncelleyebilir. CapTarget Archery Service'i alabilmeniz ve CapTarget Archery oyunlarını oynayabilmeniz için üçüncü taraf yazılımları güncellemeniz gerekebilir.</p>
    <p> </p>
    <p>CapTarget Archery, özellikle belirli bir amaca uygunluğa ve Platformun kalitesine ilişkin garanti dahil olmak üzere, açık veya zımni herhangi bir garanti vermez.</p>
    <p> </p>
    <p>CapTarget Archery, aşırı güç veya hukuka uygun bir nedenle, kesintisiz çalışma ve/veya Platformun sürekliliğini sağlama konusunda hiçbir garanti vermez; bu tür durumlarda, CapTarget Archery'nin sorumluluğu devreye girmeyecektir.</p>
    <p> </p>
    <p>Kullanıcı tarafından bu Kullanım Koşulları kapsamındaki yükümlülüklerden birinin ihlal edilmesi durumunda, CapTarget Archery'nin Kullanıcı'ya karşı sorumluluğu, doğrudan, kişisel, belirli ve tahmin edilebilir zararlarla sınırlı olacaktır.</p>
    <p> </p>
    <p>CapTarget Archery, üçüncü tarafların Platformda yayınlanan bilgilerin uygunluğunu ve doğruluğunu garanti etmez.</p>
    <p> </p>
    <p>CapTarget Archery, CapTarget Archery tarafından oluşturulmayan içerikle ilgili olarak üçüncü tarafların sahip olduğu herhangi bir hak, özellikle mülkiyet hakları, nedeniyle üçüncü tarafın ihtilafları, eylemleri veya taleplerinden sorumlu değildir.</p>
    <p> </p>
    <p>Veri toplama ve işleme konusundaki koşullar CapTarget Archery'nin veri gizliliği politikasında ayrıntılı olarak açıklanmıştır.</p>
    <p> </p>
    <p>CapTarget Archery, özellikle şu zarar türlerini içeren, ancak bunlarla sınırlı olmayan, herhangi bir dolaylı, tesadüfi, ardışık veya benzeri zarara karşı size herhangi bir sorumluluk taşımayacaktır: gelir kaybı, kâr eksikliği, veri kaybı veya işletme kaybı veya başka bir maddi olmayan zarar (bu tür zararlar için hangi nitelendirmenin kullanıldığına bakılmaksızın) veya bu Hizmetle ilgili olarak size özel veya cezai zararlar ödeme yükümlülüğü, bu zararın temelini oluşturan bir anlaşma, suistimal veya başka bir temel, ve CapTarget Archery'nin bu tür bir zararın mümkün olduğu veya haberdar edildiği bir durumda bile (veya böyle bir zararın meydana gelme ihtimali olsun olmasın). CapTarget Archery'nin size olan sorumluluğu, bu tür bir dönemde CapTarget Archery'ye ödemiş olduğunuz miktarı aşmayacaktır ve CapTarget Archery'ye herhangi bir ücret ödemediyseniz, böyle bir dönemdeki herhangi bir anlaşmazlıkla ilgili olarak, özel veya cezai tazminat almanız (ve CapTarget Archery'nin tek ve münhasır sorumluluğu), hizmeti kullanmayı durdurmak ve hesabınızı kapatmaktır.</p>
    <p>Özellikle, bu Hizmetin ve hizmetle ilgili bu Hizmetin her türlü ihmal veya sahtekarlık nedeniyle kaynaklanan ölüm veya kişisel yaralanmadan kaynaklanan yasal haklarınızı etkilemeyeceğini kabul edersiniz.</p>
    <p> </p>
    <p>Kullanıcı, bu Hizmetin kullanılmasından veya bu Şartlara aykırılıktan kaynaklanan her thangi bir iddiadan, talepten, zarardan veya diğer kayıplardan (makul bir miktar avukat ücreti de dahil olmak üzere) CapTarget Archery'yi garanti altına almayı, savunmayı ve zararsız tutmayı taahhüt eder. Buna rağmen, yukarıdakiler, kasti veya ihmal dolaylı hakkınızda haklı bir ihlale yol açıyorsa, uygulanmaz.</p>
    <p> </p>
    <p>CapTarget Archery, meşru olarak kontrolünden çıkan nedenlerle meydana gelen gecikmeler veya eksiklikler nedeniyle hiçbir sorumluluk üstlenmez ve özellikle, 1218 sayılı Fransız Medeni Kanunu'nun tanımladığı gibi, öngörülemeyen koşullar veya CapTarget Archery'nin kontrolünden çıkan nedenlerle, mevcut yasalar gereği neden meydana gelirse gelsin bu tür ihlallerde CapTarget Archery'nin sorumluluğu devreye girmeyecektir; doğal afetler, savaş, terörizm, isyan, ambargolar, sivil veya askeri otoritelerin tedbirleri, yangın, sel, kaza, grev veya taşıma araçlarının, yakıtın, enerjinin, işgücünün veya malzemenin eksikliği gibi koşullar veya olaylar.</p>
    <p> </p>
    <p>Uygulamada açıklanan etkinlik uygulamalarına ilişkin tavsiyeler, göstermelik niteliktedir; kullanıcılar bu tavsiyeleri sağlıklarına uyarlama veya bir doktora danışma sorumluluğuna sahiptir. Spor yapma sorumluluğu kullanıcıya aittir, CapTarget Archery, uygulamanın yardımıyla yapılan bir etkinlik sonucu oluşabilecek fiziksel veya zihinsel yaralanmalardan sorumlu değildir.</p>
    <p> </p>
    <p>Kullanıcı, güvenlik açığı tespit ederse, bunları kendi kazancı veya başka bir kişi/kuruluş adına kullanmayacağını taahhüt eder. Kullanıcı, hizmetlerin düzgün çalışmasını engelleyebilecek eylemler gerçekleştirmeyeceğini ve mevcut hatalardan yararlanmayacağını taahhüt eder.</p>
    <p> </p>
    <h4>Fikri Mülkiyet</h4>
    <p> </p>
    <p>Platformda görünen veya mevcut olan hizmetler ve içerikleri (veriler, veritabanları, yazılımlar, fotoğraflar, bilgiler, illüstrasyonlar, logolar, markalar vb.) mevcut tüm fikri mülkiyet hakları, veritabanı üreticisi hakları ve/veya yürürlükteki yasalar tarafından tanınan diğer haklarla korunmaktadır.</p>
    <p> </p>
    <p>İzin alınmaksızın tamamlayıcı veya kısmi olarak herhangi bir hizmetin ve/veya Platform içeriğinin kopyalanması, çoğaltılması, temsil edilmesi, adapte edilmesi, değiştirilmesi veya dağıtılması, bu içerikler CapTarget Archery'ye, bir Kullanıcıya, bir Organizasyona veya üçüncü bir tarafa ait olsa bile, yasadışıdır ve bu tür içeriğin yazarının cezai ve sivil sorumluluğunu doğurabilir.</p>
    <p> </p>
    <p>CapTarget Archery, Kullanıcıya, yalnızca kendi ihtiyaçları için Platformu kullanma hakkını verir ve ticari amaçla kullanımı hariç tutar.</p>
    <p> </p>
    <p>Kullanıcı, bir içeriği Platforma yüklediğinde, bu içeriğin CapTarget Archery'ye ücretsiz olarak kullanımı için otomatik olarak kişisel ve münhasır bir lisans verir.</p>
    <p> </p>
    <p>Bu lisans, dünya çapında, tüm kullanım şekillerinde ve bugüne kadar bilinen veya bilinmeyen her türlü yayınlama tekniği ile tüm iletim yöntemlerini içerecek şekilde verilir ve telif hakkı ve fikri mülkiyet haklarının yasal koruma süresi boyunca (yenilemeler ve uzatmalar dahil) geçerlidir:</p>
    <p> </p>
    <p>Çoğaltma hakkı: İçeriğin sınırsız sayıda kopyasını, bilinen veya bilinmeyen herhangi bir biçimde veya destekte, örneğin, broşürler, basın belgeleri, afişler, web sayfaları, raporlar ve tüm kayıt ortamları gibi (örneğin, CD, DVD, disketler, dahili veya harici sabit diskler veya diğer kayıt ortamları gibi) tüm teknik yöntemlerle veya bilinen veya bilinmeyen herhangi bir teknik yöntemle yapma veya yaptırma hakkı;</p>
    <p>Temsil hakkı: İçeriği, herhangi bir iletişim veya yayınlama yoluyla, Üyelerin Organizasyonu arasında, bilinen veya bilinmeyen herhangi bir yöntemle iletmek, yayınlamak, iletmek veya yaptırmak hakkı (örneğin, her türlü iletişim ağı veya bilinen veya bilinmeyen herhangi bir okuma, bilgi değişimi ve iletişim aracı veya teknolojisi gibi);</p>
    <p>Adaptasyon hakkı: İçeriği, sahibinin rızası olmaksızın veya sahibinin rızasıyla uygun bir şekilde, uyarlama, değiştirme, uyarlama veya düzeltme hakkı;</p>
    <p>Halka sunma hakkı: İçeriği kamuya iletmek, yayınlamak veya iletmek hakkı;</p>
    <p>Türev eserlerin hakları: İçerikten, başka bir içerik türetmek veya bu içeriği (örneğin, özgün veya özgün olmayan bir iş gibi) çoğaltmak ve yayınlamak hakkı.</p>
    <p> </p>
    <h4>Sözleşmeli Belgeler</h4>
    <p></p>
    <p>Bu Kullanım Koşulları, CapTarget Archery, Kullanıcı ve/veya Kuruluş arasındaki tüm ticari ilişkileri düzenler; Platform'un kullanımı ve CapTarget Archery tarafından sunulan Aboneliklerin satın alınması ile ilgili sözleşmeli veya önsözleşmeli ilişkileri kapsar.</p>
    <p></p>
    <p>Bu Kullanım Koşulları, taraflar arasındaki herhangi bir karşıt anlaşma veya düzenlemeyi geçerli kılar. Bu, taraflar arasındaki ilişkileri düzenleyen önceki tüm Kullanım Koşulları'nı iptal eder ve yerine koyar.</p>
    <p></p>
    <p>Bu Kullanım Koşulları, taraflar arasındaki tüm sözleşmeli belgeleri oluşturur.</p>
    <p></p>
    <p>CapTarget Archery, Kullanım Koşulları'nın içeriğini her zaman değiştirme hakkını saklı tutar. Bu değişiklikler, bundan sonra sunulan tüm hizmetlere otomatik olarak uygulanacak yeni bir sürüme yol açar.</p>
    <p></p>
    <h4>Uygulanabilir Hukuk, Arabuluculuk ve Yargı</h4>
    <p></p>
    <p>Kullanım Koşulları Fransız hukukuna tabidir.</p>
    <p></p>
    <p>Taraflar, herhangi bir yargı yoluna başvurmadan önce uzlaşma yolunu aramayı kabul eder. Taraflar arasında uzlaşılamayan bir şikayet durumunda, Kullanıcı, tüketici arabuluculuğuna ücretsiz olarak başvurabilir. Önerilen arabulucu: Paris Arabuluculuk ve Tahkim Merkezi (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p></p>
    <p>Kullanıcı, ayrıca Avrupa Komisyonu tarafından sunulan çevrimiçi anlaşmazlık çözüm platformunu kullanabilir. Platformun adresi: www.ec.europa.eu/consumers/odr/.</p>
</div>
