<section class="section faq-area ptb_100" id="faq">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Le matériel au tir à l'arc</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomOne3">
                                                    Comment choisir son arc pour le tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomOne3" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Pour bien choisir son arc, il est conseillé de se rendre dans une archerie et de prendre en compte sa taille, son budget et l'utilisation prévue. <br><br> Le choix entre un arc classique, à poulies ou arc nu dépendra également de la discipline pratiquée. Pour apprendre les bases, il est préférable de débuter avec un arc peu puissant avant d'augmenter la puissance pour améliorer sa précision.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomSecond3">
                                                    Tir à l'arc : quelle puissance ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSecond3" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">La puissance de l'arc dépend de l'allonge et de la force physique de l'archer. Pour les débutants, une puissance de 10 à 30 livres convient. La puissance des arcs actuels se situe en moyenne entre 35 et 50 livres. <br><br> Pour une femme en compétition, optez pour une puissance aux alentours de 35 à 45 livres Pour les hommes, choisir un arc dans les 40 à 50 livres. Ces chiffres sont à adapter à chaque physique et objectifs associés. Il est important de commencer avec une puissance faible pour pouvoir corriger les erreurs et éviter de se fatiguer rapidement.<br><br> Une bonne technique peut être acquise en ajustant les tirs. Les flèches plus rapides et une puissance plus élevée peuvent être utilisées lorsque l'archer est plus expérimenté et physiquement préparé.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomThree2">
                                                    Quelle est la portée d'un arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomThree2" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Un arc à poulies peut tirer une flèche à plus de 300 mètres.<br><br>
                                                        On peut estimer environ 200 à 250m pour un arc classique puissant.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
