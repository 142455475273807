<section id="home" class="section overlay-dark d-flex align-items-center welcome-area" style="height: 100vh">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-7">
                <div class="welcome-intro">
                    <h1 class="text-white">CapTarget Archery</h1>
                    <p class="text-white my-4" style="font-size: 18px">{{ 'TRANSLATE.best_app' | translate }}</p>
                    <p class="text-white my-4" style="font-size: 18px">{{ 'TRANSLATE.best_app_2' | translate }}</p>
                    <a href="https://captarget-coach.com/router/pc/sign-in" class="text-hover" target="_blank">
                        <label style="font-size: medium; margin-top: 5px !important; margin-bottom: 5px !important; text-decoration: underline" class="text-white text-hover my-4">
                            {{ 'TRANSLATE.discover_coach_web' | translate }}
                        </label>
                    </a>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex">
                        <a href="https://play.google.com/store/apps/details?id=captarget.archery">
                            <img src="assets/img/google-play.svg" alt="CapTarget Archery">
                        </a>
                        <a href="https://apps.apple.com/us/app/captarget-archery/id1584542210">
                            <img src="assets/img/app-store.svg" alt="CapTarget Archery">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-5">
                <!-- Welcome Thumb -->
                <div style="height: 90vh; padding-top: 5vh" class="single-screenshot">
                    <img src="assets/img/screens/home_oriented_1.png" alt="" style="height: 90vh; object-fit: contain">
                    <div class="screenshots-overlay">
                        <a href="../../../../assets/img/screens/Image1.png" data-fancybox="images"><i
                                class="icofont-image"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div style="position: fixed; bottom: 20px; left: 30px;">-->
        <!--            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>-->
        <!--            <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_uzoyW6.json"  background="transparent"  speed="1"  style="width: 300px; height: 300px;"  loop controls autoplay></lottie-player>-->
        <!--        </div>-->
        <label style="position: absolute ; bottom: 20px; right: 50px;">Photo By <a href=" https://www.facebook.com/profile.php?id=100011791813775">@Ange</a></label>
    </div>

</section>
