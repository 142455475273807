import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-hero-six',
    templateUrl: './hero-six.component.html',
    styleUrls: ['./hero-six.component.css']
})
export class HeroSixComponent implements OnInit {
    is2Var = 0;
    width = 1000;

    constructor() {
        this.is2Var = Math.round(Math.random());
        this.width = window.outerWidth;
    }

    ngOnInit(): void {
    }
}
