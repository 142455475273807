<div>
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <app-breadcrumb-two></app-breadcrumb-two>
        <app-counter-two></app-counter-two>
        <app-review></app-review>
        <app-cta-two></app-cta-two>
        <app-footer-three></app-footer-three>
    </div>
</div>