<div>
    <app-scrollup></app-scrollup>
    <div class="main" style="margin-bottom: 150px">
        <app-header-two></app-header-two>
        <div class="d-flex flex-column align-items-center justify-content-between py-4 p-sm-4">
            <!-- ***** Newsletter Area Start ***** -->
            <div class="mb-5 mb-lg-0">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-10 col-lg-7 mx-auto">
                            <div class="text-center">
                                <h2 class="pt-5 fw-4">Conditions d’utilisation</h2>
                                <p class="my-4">
                                    La plateforme CapTarget Archery est éditée par la société Appli Magine.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-10 col-lg-7 mx-auto">
                <style>
                    div.container {
                        background-color: #ffffff;
                    }
                    div.container p {
                        font-family: Arial;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: none;
                        text-transform: none;
                        color: #000000;
                        background-color: #ffffff;
                    }
                </style>

                <div class="language-selector" style="margin-bottom: 25px;">
                    <div *ngFor="let language of languages" class="language-item" (click)="selectLanguage(language)">
                        {{ language }}
                    </div>
                </div>

                <label
                        style="font-size: 100%; margin-top: 10px; margin-bottom: 10px; margin-left: 10px;" *ngIf="selectedLanguage !== 'Français'">
                    *Translated from French
                </label>

                <app-fr *ngIf="selectedLanguage === 'Français' || !selectedLanguage"></app-fr>
                <app-en *ngIf="selectedLanguage === 'English' || !selectedLanguage"></app-en>
            </div>
        </div>
    </div>
    <app-footer-five></app-footer-five>
</div>

<!-- pour générer les paragraphes : https://www.quackit.com/html/html_generators/html_text_generator.cfm-->
