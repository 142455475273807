<div class="container">
    <h4>定义</h4>
    <p> </p>
    <p>以下术语在CGU中首字母大写，具有本条款中所赋予的含义。</p>
    <p> </p>
    <p>订阅：指的是对平台服务的任何免费或付费订阅。</p>
    <p> </p>
    <p>射箭手：指使用或不使用应用程序的弓箭运动员用户。</p>
    <p> </p>
    <p>教练：指已订阅“教练”类型免费或付费订阅，并因此获得相关服务权限的射箭教练。</p>
    <p> </p>
    <p>应用程序：指适用于Android和iOS的移动应用程序“CapTarget Archery”。</p>
    <p> </p>
    <p>网站：指CapTarget Archery的官方网站。</p>
    <p> </p>
    <p>教练网站：指专供教练访问的网站。</p>
    <p> </p>
    <p>使用条款：指平台的使用条款。</p>
    <p> </p>
    <p>帐户：指为用户分配的帐户，可访问平台和各种服务，与个人标识和访问代码相关联。</p>
    <p> </p>
    <p>团体：指在CapTarget Archery应用程序上创建的一个团体，汇集了用户和教练。</p>
    <p> </p>
    <p>合作伙伴：指与CapTarget Archery达成合作伙伴关系的个人或法人，使其可以通过平台向用户展示。</p>
    <p> </p>
    <p>平台：指在网站和/或应用程序上提供的所有服务。</p>
    <p> </p>
    <p>用户：指任何通过帐户或其他方式访问并使用平台的自然人或法人。</p>
    <p> </p>
    <p>宝石：指在CapTarget Archery上使用的虚拟货币。</p>
    <p> </p>
    <h4>目的</h4>
    <p> </p>
    <p>CGU的目的是定义适用于所有平台用户的术语和条件。</p>
    <p> </p>
    <p>任何注册、访问和/或使用平台都意味着用户无条件地接受并遵守所有CGU。</p>
    <p> </p>
    <p>任何未成年用户声明并承认，他们已经获得父母或监护人的授权，可以在平台上注册。</p>
    <p> </p>
    <p>由机器人自动生成的任何注册请求，或通过任何其他方法生成的请求，将被拒绝。</p>
    <p> </p>
    <h4>应用程序介绍</h4>
    <p> </p>
    <p>该应用程序主要面向弓箭运动员或教练，主要用于管理和组织与体育相关的活动，以及在团体成员之间或有关领域动态之间传递信息。</p>
    <p> </p>
    <p>通过应用程序，用户可以享受以下主要功能：</p>
    <p> </p>
    <p>管理训练和比赛</p>
    <p>管理计分射击和比赛</p>
    <p>管理射箭装备</p>
    <p>利用数据生成统计信息</p>
    <p>社交和娱乐互动，向用户的教练提供数据</p>
    <p>团体成员之间的社交和娱乐互动</p>
    <p>通过通用虚拟货币“宝石”购买个性化体验物品</p>
    <p>通过移动支付系统（安卓或苹果）购买“宝石”</p>
    <p>消息传递</p>
    <p> </p>
    <h4>用户注册</h4>
    <p> </p>
    <p>每个用户都可以创建帐户（个人或代表未成年成员的帐户，前提是用户具有监护权），该帐户将使其能够访问应用程序和其服务。</p>
    <p> </p>
    <p>注册是由用户在平台上完成的，从应用程序开始。为此，他必须填写注册表单的各个字段，包括有效的电子邮件地址、姓名和姓氏，以及与他的弓箭运动相关的各种信息。他必须了解并接受CGU和数据隐私政策。</p>
    <p> </p>
    <p>通过填写注册表单，用户承诺只提供准确、更新和完整的信息。</p>
    <p> </p>
    <p>用户承诺保密帐户的访问代码。如果访问代码被滥用使用，用户将立即通知CapTarget Archery。</p>
    <p> </p>
    <p>用户独自对其帐户的使用负责。通过平台连接或数据传输将被视为用户已经执行，并且完全由用户自行负责。</p>
    <p> </p>
    <p>如果用户的注册数据因不可归责于CapTarget Archery的原因而无法传送给CapTarget Archery（例如，用户的互联网连接问题、服务器临时故障等），或者如果数据以不可处理的形式传送给CapTarget Archery（例如，用户使用不适当的硬件或软件），CapTarget Archery不承担责任。</p>
    <p> </p>
    <p>每个注册的有效期是不确定的，用户的帐户将保持活动状态直至关闭。</p>
    <p> </p>
    <p> </p>
    <h4>团体注册</h4>
    <p> </p>
    <p>为了访问团体功能，用户必须创建或加入一个团体。</p>
    <p> </p>
    <p>在填写注册表单时，管理员承诺只提供准确、更新和完整的信息。管理员保证CapTarget Archery已经事先获得了团体成员的同意，包括他们的电子邮件地址和电话号码，这些成员随时可以要求CapTarget Archery删除这些信息（请参阅我们的隐私政策）。</p>
    <p> </p>
    <p>管理员独自对团体的使用负责。</p>
    <p>同时需要指出，管理员完全和专属地对自己和任何其他方使用组织空间的行为负责。</p>
    <p> </p>
    <p>如果与组织注册相关的数据因不可归责于CapTarget Archery的原因而无法传送给CapTarget Archery（例如，由于管理员的任何原因造成的互联网连接问题，服务器临时故障等），或者如果数据以不可处理的形式传送给CapTarget Archery（例如，管理员使用不适当的计算机硬件或注册软件），CapTarget Archery不承担责任。</p>
    <p> </p>
    <p>每个注册的有效期是不确定的，团体将保持活动状态直至由管理员之一关闭。</p>
    <p> </p>
    <p> </p>
    <h4>教练注册</h4>
    <p>为了访问教练功能，用户必须订阅允许其通过网站和/或应用程序访问CapTarget Archery教练服务的许可证，具体取决于所选择的套餐。</p>
    <p>许可证也可以由代表用户订阅许可证的第三方提供。许可证严格属于使用该许可证进行注册的用户。帐户不得与其他人共享。</p>
    <p>不同套餐的优势可能会发生变化。如果订阅仍在进行中，这些更改将不会生效，或者应被视为对用户的有利变化。</p>
    <p>注册是由用户在网站上完成的。为此，他必须填写注册表单的各个字段，包括有效的电子邮件地址、姓名和姓氏，以及与他的弓箭运动相关的各种信息。他必须了解并接受CGU、CGV和数据隐私政策。</p>
    <p>通过填写注册表单，教练和订阅许可证的消费者承诺只提供准确、更新和完整的信息。</p>
    <p>使用许可证的用户自行对其帐户的使用负责。通过平台连接或数据传输将被视为用户已经执行，并且完全由用户自行负责。</p>
    <p>如果使用者的注册数据因不可归责于CapTarget Archery的原因而无法传送给CapTarget Archery（例如，使用者的互联网连接问题、服务器临时故障等），或者如果数据以不可处理的形式传送给CapTarget Archery（例如，使用者使用不适当的硬件或软件），CapTarget Archery不承担责任。</p>
    <p>任何付费订阅都是无限期的，除非用户明确要求，合同将在最低承诺期后自动续订。请参阅有关取消订阅方式的CGV。</p>
    <p> </p>

    <h4>数据共享</h4>
    <p>通过CapTarget Archery，我们的应用提供了一个粉丝和追踪系统，用于访问数据共享功能。作为用户，您有权选择接受邀请或邀请其他用户加入您的网络。</p>
    <p>对于公共个人资料，其他用户无需获得批准即可关注您。一旦有用户关注您，您的某些数据可能会自动或手动地共享，包括：</p>
    <p>- 您比赛或训练的成绩。</p>
    <p>- 各种体育统计数据，如您的许可证期限、最佳成绩或排名。</p>
    <p>- 与您在应用程序中使用相关的数据，如您喜欢的游戏和练习。</p>
    <p>- 关于您的俱乐部、语言、国家等信息，以及其他数据，如您的姓名、姓氏、使用的弓种或射击类别。</p>
    <p>您可以通过修改个人设置或每种情况的设置来选择不共享其中一些数据。</p>
    <p>在创建您的帐户时，您同意在CapTarget平台上可见。其他用户可以使用您的名字和姓氏搜索您。您的个人资料还可能被自动推荐给其他用户，以便创建体育挑战或追踪连接。</p>
    <p>请注意，您可能会在CapTarget Archery上被CapTarget、合作伙伴、广告商或其他用户共享的信息所曝光。我们鼓励您查阅我们的隐私政策，了解有关管理个人数据的更多详细信息。</p>
    <p>我们要强调，所有这些条款都符合现行有关个人数据保护和隐私的法律法规。如果您有任何顾虑或额外的问题，请随时与我们联系。</p>
    <p> </p>
    <p> </p>

    <p> </p>
    <h4>警告</h4>
    <p>访问CapTarget Archery平台的教练版本需要使用计算机、平板电脑或手机等计算工具，以便在Web版本中进行使用，以及使用Android或iOS手机或平板电脑等计算工具以进行移动版本。需要互联网连接来下载所需数据。用户确认了解这种访问服务的必要性。</p>
    <p>在所提供的服务中，假定用户处于良好的身体状况并能够执行这些操作。用户承诺在进行活动时采取一切必要的预防措施，以保护自己以及他人的健康和安全。</p>
    <p> </p>
    <p> </p>

    <p></p>
    <p></p>

    <h4>销售条款</h4>
    <p>通过购买CapTarget Archery提供的数字服务或数字产品的订阅，您接受以下所述的销售条款。如果您不同意这些条款，请不要在我们的应用程序或网站上购买任何内容。</p>
    <h6>订阅</h6>
    <p>当您购买CapTarget的内容时，CapTarget授予您有限、可撤销、非独占和不可转让的许可，以便在我们的应用程序或网站上访问某些付费功能。我们的应用程序提供月度和年度订阅，自动续订数字服务。订阅费将在订阅的周年纪念日自动从您选择的付款方式中扣除。使用虚拟货币（CapTarget Archery的虚拟货币）支付的订阅不会自动续订。</p>
    <p>如果您不希望续订订阅，请在订阅期结束前至少提前24小时禁用它，方法是访问您的帐户设置。</p>
    <h6>价格</h6>
    <p>我们的订阅价格在我们的应用程序上公布，并可能随时无需预先通知进行更改。订阅价格包括所有适用的税费。您需要支付与使用我们的应用程序相关的所有费用，包括上网和移动数据的费用。尽管我们已尽一切努力，但我们的目录中仍然可能有少量商品定价错误。定价可能会根据现有订阅者而发生变化。根据您选择的系统（Apple、Google、Stripe或其他），您可能会在当前订阅费用增加时收到通知，并且可以在续订之前取消订阅。</p>
    <h6>损失风险</h6>
    <p>一旦CapTarget内容提供给您，您将承担购买后CapTarget内容的风险，包括因文件损坏或设备或硬盘失效而导致的损失。购买的CapTarget内容通常会保留在您的CapTarget帐户上，但由于各种原因可能不再可用。在这种情况下，对于未来CapTarget内容的不可用性，CapTarget不对您负责，您无权全额或部分地获得退款。</p>
    <h6>交付</h6>
    <p>购买产品或订阅后，您将获得CapTarget内容的访问权限。内容在您的CapTarget帐户中可用。可能需要重新启动应用程序，并且需要合理的时间使服务可用。（通常不超过几分钟）</p>
    <h6>支付</h6>
    <p>如果您想使用付款功能，您必须在您的CapTarget帐户中提供有效的付款方式（如信用卡或其他授权付款方式）。您的订阅费用将根据您在Apple或Google Play帐户或我们网站上输入的付款方式自动从您的帐户中扣除。您同意提供准确和最新的付款信息。如果由于任何原因未进行付款，我们保留暂停或取消您的订阅的权利。</p>
    <h6>应用使用</h6>
    <p>您有权以个人非商业用途使用我们的应用程序。未经我们事先书面许可，您不得修改、分发、销售或转让我们的应用程序中的任何内容。您不得以违反适用法律、侵犯第三方权利或对第三方造成损害的方式使用我们的应用程序。</p>
    <h6>退款</h6>
    <p>我们的订阅可以在前期进行3周的测试，因此不予退款。在订阅任何内容之前，请务必检查每个订阅的功能。出售的数字产品是明确的，不接受退款。</p>
    <h6>终止</h6>
    <p>如果您违反这些销售条款或使用条款，或者我们有理由相信您以任何方式使用我们的应用程序，我们保留终止您对我们的应用程序的访问权限的权利。购买我们的应用程序的订阅即表示您同意这些销售条款。</p>

    <h6>联系我们</h6>
    <p>如果您有任何问题或疑虑，请通过以下地址与我们联系：support@captarget-archery.com</p>
    <p>我们将尽力解决您的问题，并确保双方都能满意。</p>
    <p></p>
    <p></p>
    <h4>平台使用成本</h4>
    <p> </p>
    <p>创建帐户和小组对用户来说是免费的。然而，与使用平台相关的连接和通信费用由用户独自承担。</p>
    <p>教练版本的访问是付费的，测试版本和测试版除外。价格从版本发布之日开始在网站上公布。</p>
    <h4>通过平台购买</h4>
    <p> </p>
    <p>您可以用“真实”货币购买有限、个人、不可转让、不可再许可和可撤销的许可，以便使用虚拟货币等产品，包括虚拟货币或虚拟钻石，这些都是用于CapTarget Archery游戏的。也可以在活动、比赛、游戏中获得这些货币，通过观看广告，或在平台上实现目标时获得奖励，或由CapTarget Archery分发。</p>
    <p> </p>
    <p>此货币可用于购买应用程序中提供的不同产品。</p>
    <p> </p>
    <p>未经服务明确授权，不得转让虚拟物品。服务明确授权之外的转让，您必须避免将虚拟物品出售、购买、兑换或以其他方式转让给任何个人、实体，或者尝试上述操作，特别是将虚拟物品出售、购买、兑换或以其他方式转让给CapTarget Archery、其他用户或第三方。</p>
    <p> </p>
    <p>CapTarget Archery可以随时行使权利，管理、监管、控制、修改或删除虚拟物品，无需事先通知。在行使这些权利时，CapTarget Archery对您或第三方不承担任何责任。</p>
    <p> </p>
    <p>通过服务购买和兑换的第三方虚拟物品是终局的，不可退款。</p>
    <p> </p>
    <p>您同意支付所有适用的费用和税款，这些费用是您或使用以您名义注册的帐户的任何人应付的。CapTarget Archery可以随时修改通过服务提供的虚拟物品的价格。您承认CapTarget Archery没有义务基于任何原因退款，而且在关闭帐户时，无论帐户是自愿关闭还是非自愿关闭，您都不会收到任何未使用虚拟物品的金额或其他补偿。</p>
    <p> </p>
    <h4>广告和合作伙伴</h4>
    <p> </p>
    <p>CapTarget Archery可能在不同的服务中提供链接到其他广告网站和/或合作伙伴。</p>
    <p> </p>
    <p>由于CapTarget Archery无法控制这些网站，CapTarget Archery对这些网站和外部来源的可用性不作任何保证，并且不对这些网站上的内容、广告、产品、服务或其他任何材料负责。</p>
    <p> </p>
    <p>同样，CapTarget Archery对使用这些广告网站、合作伙伴或外部来源提供的内容或服务所造成的任何实际或声称的损害或损失不承担责任。</p>
    <p> </p>
    <h4>关闭帐户</h4>
    <p> </p>
    <p>用户可以随时关闭其帐户或代表未成年人管理其帐户。根据我们的数据隐私政策，关闭帐户将导致相关的个人数据被删除。</p>
    <p> </p>
    <p>除了CapTarget Archery可能要求的损害赔偿外，CapTarget Archery保留在以下情况下随时关闭用户帐户的权利，无需事先通知或补偿：</p>
    <p> </p>
    <p>违反使用条款；</p>
    <p>在注册时提供虚假信息；</p>
    <p>行为损害CapTarget Archery的商业利益。</p>
    <p> </p>
    <p>CapTarget Archery在关闭帐户时，不会为使用合同条款或销售条款，包括付费订阅的情况下提供任何补偿或退款。所有付款将仍为CapTarget Archery所有。</p>
    <p> </p>
    <h4>删除小组</h4>
    <p> </p>
    <p>管理员可以随时删除小组。删除组织空间将导致所有相关数据和优势被删除。</p>
    <p> </p>
    <p>根据CapTarget Archery可能要求的损害赔偿，CapTarget Archery保留在以下情况下随时删除组织空间的权利，无需事先通知或补偿：</p>
    <p> </p>
    <p>违反合同条款；</p>
    <p>在注册组织时提供虚假信息；</p>
    <p>行为损害CapTarget Archery的商业利益。</p>
    <p> </p>
    <p>根据CapTarget Archery的决定，删除组织空间不会提供任何补偿或退款，包括付费订阅的情况。所有付款将仍为CapTarget Archery所有，删除组织空间并不解除组织/管理员支付订阅费的义务。</p>
    <p> </p>
    <h4>平台信息和内容</h4>
    <p> </p>
    <p>用户在使用平台时应谨慎，特别是在评估其作为成员所在小组的信息和内容时。</p>
    <p> </p>
    <p>在此方面，用户承认平台上提供的信息和内容并不是全面的，其主要目的是帮助管理员管理组织。</p>
    <p> </p>
    <p>用户禁止以任何方式出售、转售或利用平台上可用的全部或部分内容。</p>
    <p> </p>
    <p>用户对通过平台发布和传输的通信、信息、文件、视频、照片、文件或其他内容负全部责任。</p>
    <p> </p>
    <p>用户在平台上发布的通信、信息、文件、视频、照片、文件或其他内容仅代表用户个人的观点和意见，绝不代表CapTarget Archery或其员工和代理的观点。</p>
    <p> </p>
    <p>在平台中，用户承诺只提供客观、完整和真实的信息，遵循礼貌、文明和尊重他人的规则。</p>
    <p> </p>
    <p>CapTarget Archery鼓励用户在发布在平台上的通信、信息、文件、视频、照片、文件或其他内容时保持克制和谨慎。在这方面，用户禁止发布违反现行法律法规的内容，特别是：</p>
    <p> </p>
    <p>传播违反公共秩序或道德的信息；</p>
    <p>将平台的目的转向宣传或劝诱，招揽或鼓励；发布商业或广告信息，或宣传烟草、酒精或其他受管制的物质、产品或服务的信息；</p>
    <p>传播侵犯第三方人格权或具有诽谤、侮辱、淫秽、色情、攻击性、暴力性或煽动歧视、政治暴力、种族主义、仇恨女性或同性恋的信息；</p>
    <p>发布违反个人数据保护法律的信息，未经个人同意，包括其姓氏、邮寄和/或电子邮件地址、电话号码、照片、录音或音视频记录；</p>
    <p>侵犯第三方知识产权，特别是删除或抹去版权或商标标识以及任何限制性说明。</p>
    <p> </p>
    <p>用户还必须尊重他人的权利，特别是：</p>
    <p> </p>
    <p>人格权利（如肖像权、隐私权）；</p>
    <p>商标权；</p>
    <p>著作权（尤其是关于软件、声音、图像、照片、文字、动画图像的版权）和邻接权（表演者、音像制品和录像制品制作者的权利，以及数据库制作人的专有权利）；</p>
    <p>总的来说，人身和财产权利。</p>
    <p> </p>

    <p>除非经CapTarget Archery事先书面授权，否则用户禁止以下行为，但不限于：</p>
    <p> </p>
    <p>将平台用于促销目的，总之，提供直接或间接为其支付报酬的产品和服务；</p>
    <p>从平台内容创建存档文件；</p>
    <p>复制、展示、使用、引用（尤其是在搜索引擎的元标签中）平台上的内容、商标、标志、标识的全部或部分。</p>
    <p> </p>
    <p>不遵守本用户协议可能导致即时和自动删除涉及内容、警告和/或用户帐户关闭，而不论CapTarget Archery是否有权提起诉讼和/或索赔。</p>
    <p> </p>
    <p>CapTarget Archery对平台上的信息、文件、视频、照片、文档或其他内容，包括但不限于任何非法内容、错误或遗漏，以及任何用户使用此材料造成的损失或损害不负任何责任。</p>
    <p> </p>
    <p>未经CapTarget Archery明确授权，严禁在网站上链接到次要页面，CapTarget Archery明确授权的情况除外。此外，任何链接都必须在CapTarget Archery的要求下撤销。</p>
    <p> </p>
    <h4>管理员的审查职责</h4>
    <p> </p>
    <p>管理员作为小组的协调员和监督者，承诺确保成员遵守用户协议的条款。一旦获知，管理员承诺报告或删除（如果可能的话）违反法律和/或公共秩序和/或用户协议的内容，尤其是任何暴力、色情、种族主义、反犹太主义、仇外、侵犯人的尊严或尊严、侵犯隐私权、侵犯版权、邻接权、商标权、第三方人格权的内容。</p>
    <p> </p>
    <p>如果违反这些规则和惯例，CapTarget Archery保留排除违规管理员和/或成员以及采取任何可能使违法使用平台困扰显然违法的行为，可能涉及到法律行动。</p>
    <p> </p>
    <h4>发送电子邮件、推送通知和短信</h4>
    <p> </p>
    <p>在提供的服务中，CapTarget Archery提供通过电子邮件、应用程序的推送通知和短信进行通信的功能，以便组织的成员之间进行交流，目的仅为交换与组织相关的信息，通过成员在创建帐户时提供的手机号码和电子邮件地址。</p>
    <p> </p>
    <p>用户可以随时决定不再接收来自CapTarget Archery的电子邮件、推送通知或短信，只需修改其设备设置或向支持部门提出申请。</p>
    <p> </p>
    <h4>访问平台 - 责任与承诺</h4>
    <p> </p>
    <p>CapTarget Archery努力使平台24小时、7天全年无休地提供，但排除了计划维护操作和本条款的规定。</p>
    <p> </p>
    <p>CapTarget Archery保留根据技术进步的发展而认为必要的情况下对平台进行修改和改进的权利。因此，CapTarget Archery保留无需事先通知或补偿即可暂时暂停访问平台进行进步性或校正性维护的权利。在这方面，用户免除CapTarget Archery的任何责任，并放弃就此提出任何索赔。</p>
    <p> </p>
    <p>CapTarget Archery可能要求您接受对您已安装在设备或计算机上的CapTarget Archery服务和游戏进行更新的情况。您确认并同意，CapTarget Archery可能会更新CapTarget Archery服务和游戏，无论是否通知您。您可能需要更新第三方软件，以便能够使用CapTarget Archery服务并玩CapTarget Archery的游戏。</p>
    <p> </p>
    <p>CapTarget Archery不提供任何明示或暗示的保证，包括但不限于关于平台适合特定用途的保证。</p>
    <p> </p>
    <p>CapTarget Archery不保证平台的无间断运行和/或连续性，无法保证在不可抗力或法国民法典第1218条所定义的不可预见或CapTarget Archery控制范围外的情况下，平台的无间断运行和/或连续性将得到保障，CapTarget Archery不承担任何责任。</p>
    <p> </p>
    <p>如果因您在使用平台时违反了您在本用户协议下的任何义务而使CapTarget Archery的责任成立，修复范围将限于直接、个人、特定和可预见的损害。</p>
    <p> </p>
    <p>CapTarget Archery不保证由第三方发布在平台上的信息的相关性和准确性。</p>
    <p> </p>
    <p>CapTarget Archery不对可能在未经CapTarget Archery创建的内容上存在的第三方权利的争议、行动或索赔负责。</p>
    <p> </p>
    <p>CapTarget Archery收集和处理数据的条件在数据隐私政策中有详细说明。</p>
    <p> </p>
    <p>CapTarget Archery不对您产生的任何间接、附带、后果性或类似性的损害承担责任，特别是对任何收入损失、利润损失、数据丢失、业务损失或其他非物质损失（无论给予这些损失何种资格），与本服务条款或服务本身以及其他方面有关，也不负责向您支付特殊或惩罚性的损害赔偿，无论是在合同、侵权还是其他方面的基础上，而无论CapTarget Archery是否已被告知或是否可能发生这种损害。CapTarget Archery对您的责任不会超过您根据本服务条款在首次提出索赔的六（6）个月内支付给CapTarget Archery的金额。您承认并同意，如果在该期间内未支付任何款项给CapTarget Archery，您与CapTarget Archery的任何争议的唯一救济方法（以及CapTarget Archery的唯一责任）将是停止使用服务并关闭您的帐户。</p>
    <p>尤其是，本服务条款的任何规定都不得损害任何消费者的法定权利，也不得排除或限制CapTarget Archery在因疏忽或欺诈引起的死亡或身体伤害方面的任何责任。</p>
    <p> </p>
    <p>您承诺保护、捍卫和使CapTarget Archery（以及其开发人员、管理员和内容创作者）免受因您使用服务或违反本服务条款而引起的索赔、要求、损害或其他损失的责任，包括合理律师费，第三方将依赖并导致您使用服务或违反本服务条款。但是，如果违反的权利与您故意或疏忽的行为无关，则上述情况不适用。</p>
    <p> </p>
    <p>CapTarget Archery对因合理地逃避其控制的原因而导致的任何延误或不履行的责任不负责，尤其是由于不可抗力或法律上所定义的不可预见的情况或CapTarget Archery无法控制的原因，如自然灾害、战争、恐怖主义行为、骚乱、禁运、民事或军事当局的措施、火灾、洪水、事故、罢工或运输工具、燃料、能源、劳动力或材料短缺。</p>
    <p> </p>
    <p>有关应用中所述活动的实践建议仅供参考，用户有责任根据自身健康状况进行适应或咨询医生。体育锻炼的责任由用户承担，CapTarget Archery对通过应用进行活动而导致的身体或心理伤害不承担任何责任。</p>
    <p> </p>
    <p>用户承诺报告任何安全漏洞，不会为自己或他人/实体使用它们。用户承诺不会采取可能损害CapTarget Archery所提供的服务正常运行的行动，并且不会利用可能的漏洞获益。</p>
    <p> </p>
    <h4>知识产权</h4>
    <p> </p>
    <p>出现在平台上或平台上可用的服务及其内容（包括但不限于数据、数据库、软件、照片、信息、插图、标志、商标等）都受到现有的所有知识产权、数据库制作者权和/或法律规定的任何其他权利的保护。</p>
    <p> </p>
    <p>未经许可，未经授权、部分或全部复制、复制、展示、改编、改变、修改或未经许可的传播，无论这些内容是属于CapTarget Archery、用户、组织还是第三方的，都是非法的，可能导致其作者的刑事和民事责任。</p>
    <p> </p>
    <p>CapTarget Archery授予用户使用平台的权利，以满足其自身需求，不包括盈利目的。</p>
    <p> </p>
    <p>当用户在平台上下载内容时，自动授予CapTarget Archery免费使用该内容的个人和非独家许可。</p>
    <p> </p>
    <p>此许可适用于全球范围内，适用于所有媒体和所有已知或未知的传播方法，以及知识产权合法保护的全部时间（包括续订和延长）：</p>
    <p> </p>
    <p>复制权：制作或指示制作无限数量的内容副本，通过已知或未知的任何形式或媒体，在任何已知或未知的载体上，包括但不限于纸张、磁介质、数字或其他媒体（例如，所有小册子、新闻文件、海报、网页、报告和任何记录媒体，如CD、DVD、磁盘、内部或外部硬盘或任何其他记录媒体），通过已知或未知的任何已知或未知的技术方法；</p>
    <p>表演权：通过任何已知或未知的方式，尤其是通过任何通信网络或任何阅读、信息交换和通信的方式，将内容传递、传播、传递或传播给用户组织的成员（例如，互联网、陆地网络、电缆、卫星、下载、电视传输等）；</p>
    <p>翻译权：将内容翻译成任何语言（包括所有计算机语言）。</p>
    <p>用户保证在平台上发布的内容不会侵犯第三方的任何知识产权或其他权利。</p>
    <p> </p>
    <p>因此，用户同意为CapTarget Archery辩护并对由用户发布的内容引起的任何第三方行动或索赔承担责任。</p>
    <p> </p>

    <h4>合同文件</h4>
    <p> </p>
    <p>本使用条款适用于CapTarget Archery、用户和/或组织之间与使用平台以及订阅由CapTarget Archery提供的订阅所涉及的所有商业合同关系或合同前关系。</p>
    <p> </p>
    <p>这些使用条款优于各方之间的任何相反协议或安排。它们废除并替代了可能在各方之间管理关系的所有先前CGV。</p>
    <p> </p>
    <p>这些使用条款构成了各方之间的全部合同文件。</p>
    <p> </p>
    <p>CapTarget Archery保留随时修改使用条款内容的权利。这些修改将产生新版本，将自动适用于随后提供的所有服务。</p>
    <p> </p>
    <h4>适用法律、调解和司法管辖</h4>
    <p> </p>
    <p>使用条款受法国法律管辖。</p>
    <p> </p>
    <p>各方同意在采取任何司法行动之前寻求和解办法。在各方之间无法通过和解解决的索赔情况下，用户可以免费寻求消费者调解。建议使用以下调解机构：巴黎调解与仲裁中心（CMAP），网址：www.mediateur-conso.cmap.fr。</p>
    <p> </p>
    <p>用户还可以使用欧洲委员会在以下网址上提供的在线纠纷解决平台：www.ec.europa.eu/consumers/odr/。</p>
</div>
