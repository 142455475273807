<section class="section faq-area ptb_100" id="faq">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Où pratiquer le tir à l'arc ?</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapseCustomOne5">
                                                    Tir à l'arc à Paris
                                                </button>
                                            </h2>
                                            <div id="collapseCustomOne5" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Paris et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Paris%2C%20France&lon=2.34839146597&lat=48.8534950445">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapseCustomSecond">
                                                    Tir à l'arc à Marseille
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSecond" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Marseille et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Marseille%2C%20Bouches-du-Rh%C3%B4ne%2C%20France&lon=5.36995247649&lat=43.2961742175">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse3">
                                                    Tir à l'arc à Lyon
                                                </button>
                                            </h2>
                                            <div id="collapse3" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Lyon et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Lyon%2C%20Rh%C3%B4ne%2C%20France&lon=4.83201131854&lat=45.7578136186">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse4">
                                                    Tir à l'arc à Toulouse
                                                </button>
                                            </h2>
                                            <div id="collapse4" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Toulouse et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Toulouse%2C%20Haute-Garonne%2C%20France&lon=1.44424688621&lat=43.6044621249">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse5">
                                                    Tir à l'arc à Nice
                                                </button>
                                            </h2>
                                            <div id="collapse5" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Nice et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Nice%2C%20Alpes-Maritimes%2C%20France&lon=7.26839118393&lat=43.700935735">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse6">
                                                    Tir à l'arc à Nantes
                                                </button>
                                            </h2>
                                            <div id="collapse6" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Nantes et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Nantes%2C%20Loire-Atlantique%2C%20France&lon=-1.5541362342&lat=47.2186370171">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse7">
                                                    Tir à l'arc à Strasbourg
                                                </button>
                                            </h2>
                                            <div id="collapse7" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Strasbourg et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Strasbourg%2C%20Bas-Rhin%2C%20France&lon=7.75071263081&lat=48.5846139843">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse8">
                                                    Tir à l'arc à Montpellier
                                                </button>
                                            </h2>
                                            <div id="collapse8" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Montpellier et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Montpellier%2C%20H%C3%A9rault%2C%20France&lon=3.87673369285&lat=43.6112421626">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse9">
                                                    Tir à l'arc à Bordeaux
                                                </button>
                                            </h2>
                                            <div id="collapse9" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Bordeaux et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Bordeaux%2C%20Gironde%2C%20France&lon=-0.580036458193&lat=44.8412249301">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse10">
                                                    Tir à l'arc à Lille
                                                </button>
                                            </h2>
                                            <div id="collapse10" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Lille et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Lille%2C%20Nord%2C%20France&lon=3.06352815248&lat=50.6365653205">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse11">
                                                    Tir à l'arc à Rennes
                                                </button>
                                            </h2>
                                            <div id="collapse11" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Rennes et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Rennes%2C%20Ille-et-Vilaine%2C%20France&lon=-1.68001984701&lat=48.1113386226">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse12">
                                                    Tir à l'arc à Reims
                                                </button>
                                            </h2>
                                            <div id="collapse12" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Reims et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Reims%2C%20Marne%2C%20France&lon=4.03192597121&lat=49.2577885166">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse13">
                                                    Tir à l'arc au Havre
                                                </button>
                                            </h2>
                                            <div id="collapse13" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc au Havre et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Le%20Havre%2C%20Seine-Maritime%2C%20France&lon=0.107973120704&lat=49.4938974251">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse14">
                                                    Tir à l'arc à Saint-Étienne
                                                </button>
                                            </h2>
                                            <div id="collapse14" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Saint-Étienne et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Saint-%C3%89tienne%2C%20Loire%2C%20France&lon=4.38730575764&lat=45.4401466968">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse15">
                                                    Tir à l'arc à Toulon
                                                </button>
                                            </h2>
                                            <div id="collapse15" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Toulon et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Toulon%2C%20Var%2C%20France&lon=5.93049183212&lat=43.1257310663">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse16">
                                                    Tir à l'arc à Grenoble
                                                </button>
                                            </h2>
                                            <div id="collapse16" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Grenoble et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Grenoble%2C%20Is%C3%A8re%2C%20France&lon=5.73578189778&lat=45.1875601401">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse17">
                                                    Tir à l'arc à Dijon
                                                </button>
                                            </h2>
                                            <div id="collapse17" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Dijon et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Dijon%2C%20C%C3%B4te-d%27Or%2C%20France&lon=5.04147004971&lat=47.3215805343">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse18">
                                                    Tir à l'arc à Angers
                                                </button>
                                            </h2>
                                            <div id="collapse18" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Angers et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Angers%2C%20Maine-et-Loire%2C%20France&lon=-0.551558858342&lat=47.4739883445">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse19">
                                                    Tir à l'arc à Nîmes
                                                </button>
                                            </h2>
                                            <div id="collapse19" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Nîmes et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=N%C3%AEmes%2C%20Gard%2C%20France&lon=4.36006867945&lat=43.8374248861">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse20">
                                                    Tir à l'arc à Villeurbanne
                                                </button>
                                            </h2>
                                            <div id="collapse20" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Villeurbanne et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Villeurbanne%2C%20Rh%C3%B4ne%2C%20France&lon=4.88684539383&lat=45.7733572713">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse21">
                                                    Tir à l'arc à Saint-Denis
                                                </button>
                                            </h2>
                                            <div id="collapse21" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Saint-Denis et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Saint-Denis%2C%20La%20R%C3%A9union&lon=55.4481369905&lat=-20.8799889681">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse22">
                                                    Tir à l'arc à Aix-en-Provence
                                                </button>
                                            </h2>
                                            <div id="collapse22" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Aix-en-Provence et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Aix-en-Provence%2C%20Bouches-du-Rh%C3%B4ne%2C%20France&lon=5.44747376527&lat=43.5298423996">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse23">
                                                    Tir à l'arc au Mans
                                                </button>
                                            </h2>
                                            <div id="collapse23" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc au Mans et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Le%20Mans%2C%20Sarthe%2C%20France&lon=0.196784833064&lat=48.0073848345">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse24">
                                                    Tir à l'arc à Clermont-Ferrand
                                                </button>
                                            </h2>
                                            <div id="collapse24" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Clermont-Ferrand et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Clermont-Ferrand%2C%20Puy-de-D%C3%B4me%2C%20France&lon=3.08194269083&lat=45.7774551">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse25">
                                                    Tir à l'arc à Brest
                                                </button>
                                            </h2>
                                            <div id="collapse25" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Brest et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Brest%2C%20Finist%C3%A8re%2C%20France&lon=-4.48600886911&lat=48.3905282503">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse26">
                                                    Tir à l'arc à Limoges
                                                </button>
                                            </h2>
                                            <div id="collapse26" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Limoges et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Limoges%2C%20Haute-Vienne%2C%20France&lon=1.26448465126&lat=45.8354242585">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse27">
                                                    Tir à l'arc à Tours
                                                </button>
                                            </h2>
                                            <div id="collapse27" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le club de Saint-Avertin est le plus conséquent sur Tours.
                                                        Retrouvez ici la liste de tous les clubs de tir à l’arc à Tours et aux alentours.
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Tours%2C%20Indre-et-Loire%2C%20France&lon=0.688926769154&lat=47.390047356">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse28">
                                                    Tir à l'arc à Amiens
                                                </button>
                                            </h2>
                                            <div id="collapse28" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Amiens et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Amiens%2C%20Somme%2C%20France&lon=2.29569502745&lat=49.8941707687">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse29">
                                                    Tir à l'arc à Annecy
                                                </button>
                                            </h2>
                                            <div id="collapse29" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Annecy et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Annecy%2C%20Haute-Savoie%2C%20France&lon=6.1288846909&lat=45.8992347654">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse32">
                                                    Tir à l'arc à Perpignan
                                                </button>
                                            </h2>
                                            <div id="collapse32" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Perpignan et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Perpignan%2C%20Pyr%C3%A9n%C3%A9es-Orientales%2C%20France&lon=2.89531207296&lat=42.6985303558">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse31">
                                                    Tir à l'arc à Besançon
                                                </button>
                                            </h2>
                                            <div id="collapse31" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Besançon et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Besan%C3%A7on%2C%20Doubs%2C%20France&lon=6.02436219069&lat=47.2380221798">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse34">
                                                    Tir à l'arc à Boulogne-Billancourt
                                                </button>
                                            </h2>
                                            <div id="collapse34" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Boulogne-Billancourt et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Boulogne-Billancourt%2C%20Hauts-de-Seine%2C%20France&lon=2.24020599025&lat=48.8356648925">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse33">
                                                    Tir à l'arc à Metz
                                                </button>
                                            </h2>
                                            <div id="collapse33" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Metz et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Metz%2C%20Moselle%2C%20France&lon=6.17635518043&lat=49.1196963265">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse36">
                                                    Tir à l'arc à Orléans
                                                </button>
                                            </h2>
                                            <div id="collapse36" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Orléans et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Orl%C3%A9ans%2C%20Loiret%2C%20France&lon=1.90860658514&lat=47.9027335883">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse35">
                                                    Tir à l'arc à Rouen
                                                </button>
                                            </h2>
                                            <div id="collapse35" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Rouen et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Rouen%2C%20Seine-Maritime%2C%20France&lon=1.09396572767&lat=49.4404590205">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse38">
                                                    Tir à l'arc à Mulhouse
                                                </button>
                                            </h2>
                                            <div id="collapse38" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Mulhouse et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Mulhouse%2C%20Haut-Rhin%2C%20France&lon=7.33899363074&lat=47.7467232362">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse37">
                                                    Tir à l'arc à Caen
                                                </button>
                                            </h2>
                                            <div id="collapse37" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Caen et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Caen%2C%20Calvados%2C%20France&lon=-0.363561571158&lat=49.181340279">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse40">
                                                    Tir à l'arc à Saint-Denis
                                                </button>
                                            </h2>
                                            <div id="collapse40" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Saint-Denis et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Saint-Denis%2C%20La%20R%C3%A9union&lon=55.4481369905&lat=-20.8799889681">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question"
                                             class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse"
                                                        data-target="#collapse39">
                                                    Tir à l'arc à Riom
                                                </button>
                                            </h2>
                                            <div id="collapse39" class="collapse" data-parent="#appia-accordion"
                                                 itemprop="acceptedAnswer" itemscope
                                                 itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Retrouvez ici la liste de tous les clubs de tir à
                                                        l’arc à Riom et aux alentours :
                                                        <br>Lien Fédération Française de tir à l’arc : <a
                                                                href="https://ffta.maps.arcgis.com/apps/webappviewer/index.html?id=6aad81b836e840d8913dbe46e379aebd">Cliquez
                                                            ici</a>
                                                        <br>Lien Mon club à proximité : <a
                                                                href="https://monclubpresdechezmoi.com/sport-loc/300/?name=Riom%2C%20Puy-de-D%C3%B4me%2C%20France&lon=3.1136666856&lat=45.8930679477">Cliquez
                                                            ici</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
