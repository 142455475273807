import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-promo-four',
  templateUrl: './promo-four.component.html',
  styleUrls: ['./promo-four.component.css']
})
export class PromoFourComponent implements OnInit {
  displayMore = true;

  constructor() {
    this.onResize(undefined);
  }

  onResize($event): any {
    this.displayMore = window.outerWidth >= 990;
  }

  ngOnInit(): void {
  }
}
