<div class="container">
    <h4>Definições</h4>
    <p> </p>
    <p>Os seguintes termos, cuja primeira letra está em maiúscula nas CGU, têm o significado atribuído a eles neste artigo.</p>
    <p> </p>
    <p>Assinatura: refere-se a qualquer subscrição, gratuita ou paga, dos serviços da plataforma.</p>
    <p> </p>
    <p>Arqueiro: Utilizador praticante de tiro com arco, utilizando ou não os serviços da aplicação.</p>
    <p> </p>
    <p>Coach: Treinador de tiro com arco que subscreveu uma assinatura gratuita ou paga do tipo "Coach" e assim tem acesso aos serviços associados.</p>
    <p> </p>
    <p>Aplicação: refere-se à aplicação móvel "CapTarget Archery" disponível para Android e iOS.</p>
    <p> </p>
    <p>Site: refere-se ao site de demonstração da CapTarget Archery.</p>
    <p> </p>
    <p>Site coach: refere-se ao site com acesso reservado para Coaches.</p>
    <p> </p>
    <p>Condições de Uso: refere-se às presentes condições gerais de utilização da Plataforma.</p>
    <p> </p>
    <p>Conta: refere-se à conta atribuída a um Utilizador que concede acesso à Plataforma e a vários serviços, associada a um identificador pessoal e a um código de acesso.</p>
    <p> </p>
    <p>Grupo: refere-se a um grupo criado na aplicação CapTarget Archery que inclui Utilizadores e Coaches.</p>
    <p> </p>
    <p>Parceiro: refere-se a uma pessoa física ou jurídica que estabeleceu uma parceria com a CapTarget Archery, permitindo-lhe ser apresentada aos Utilizadores através da Plataforma.</p>
    <p> </p>
    <p>Plataforma: refere-se ao conjunto de serviços acessíveis nos Sites e/ou na Aplicação.</p>
    <p> </p>
    <p>Utilizador: refere-se a qualquer pessoa física ou jurídica com acesso à Plataforma e a utilizando, quer seja ou não através de uma Conta.</p>
    <p> </p>
    <p>Gemas: refere-se a uma moeda virtual usada na CapTarget Archery.</p>
    <p> </p>
    <h4>Objeto</h4>
    <p> </p>
    <p>As CGU têm como objetivo definir os termos e condições aplicáveis a todos os Utilizadores da Plataforma.</p>
    <p> </p>
    <p>Toda inscrição, acesso e/ou utilização da Plataforma implica na aceitação incondicional e no respeito pelo Utilizador de todos os CGU.</p>
    <p> </p>
    <p>Qualquer Utilizador menor declara e reconhece ter obtido a autorização de seus pais ou do titular da autoridade parental para se inscrever na Plataforma.</p>
    <p> </p>
    <p>Qualquer pedido de inscrição gerado automaticamente por um robô, ou por qualquer outro método, será recusado.</p>
    <p> </p>
    <h4>Apresentação da aplicação</h4>
    <p> </p>
    <p>A Aplicação é principalmente dedicada aos praticantes de tiro com arco ou treinadores, permitindo gerir e organizar atividades relacionadas ao esporte, comunicar informações entre os membros de um grupo ou sobre as novidades no domínio.</p>
    <p> </p>
    <p>Através da Aplicação, os Utilizadores beneficiam das seguintes funcionalidades:</p>
    <p> </p>
    <p>Gestão de sessões de treinamento e competição</p>
    <p>Gestão de tiros contados e partidas</p>
    <p>Gestão de equipamentos de tiro</p>
    <p>Uso de dados para fornecer estatísticas</p>
    <p>Interações sociais e lúdicas, disponibilização de dados aos Coaches do utilizador</p>
    <p>Interações sociais e lúdicas entre membros de um Grupo</p>
    <p>Compra de itens de personalização da experiência através da moeda virtual comumente chamada de "Gema".</p>
    <p>Compra de "Gemas" através do sistema de pagamento móvel (ANDROID ou APPLE)</p>
    <p>Mensagens</p>
    <p> </p>
    <h4>Inscrição de um Utilizador</h4>
    <p> </p>
    <p>Cada Utilizador tem a possibilidade de criar uma Conta (pessoal ou para o titular de autoridade parental quando o Utilizador é titular da autoridade parental) que lhe permitirá aceder à aplicação e seus serviços.</p>
    <p> </p>
    <p>A inscrição é realizada na Plataforma pelo Utilizador, a partir da aplicação. Para isso, ele deve preencher os diferentes campos do formulário de inscrição, incluindo um endereço de e-mail válido, seu nome e sobrenome, bem como as diferentes informações relacionadas à sua prática de tiro com arco. Ele deve ler e aceitar os CGU e a Política de Privacidade de Dados.</p>
    <p> </p>
    <p>Ao preencher o formulário de inscrição, o Utilizador compromete-se a fornecer apenas informações corretas, atualizadas e completas.</p>
    <p> </p>
    <p>O Utilizador compromete-se a manter confidencial o código de acesso de sua Conta. Em caso de utilização fraudulenta do código de acesso, o Utilizador informará imediatamente a CapTarget Archery.</p>
    <p> </p>
    <p>O Utilizador é o único responsável pelo uso de sua Conta. Qualquer conexão ou transmissão de dados através da Plataforma será considerada como tendo sido realizada pelo Utilizador e sob sua exclusiva responsabilidade.</p>
    <p> </p>
    <p>A CapTarget Archery não será responsável se os dados de inscrição do Utilizador não chegarem à CapTarget Archery por motivo que não lhe seja imputável (por exemplo, um problema de conexão à Internet do Utilizador, uma falha temporária de seus servidores, etc.), ou se os dados chegarem à CapTarget Archery em uma forma não tratável (por exemplo, se o Utilizador tiver um hardware ou software inadequado).</p>
    <p> </p>
    <p>Toda inscrição é feita por prazo indeterminado e a Conta do Utilizador permanece ativa até o encerramento.</p>
    <p> </p>
    <p> </p>
    <h4>Inscrição de um grupo</h4>
    <p> </p>
    <p>Para acessar as funcionalidades do grupo, um Utilizador deve criar ou ingressar em um Grupo.</p>
    <p> </p>
    <p>Ao preencher o formulário de inscrição, o administrador compromete-se a fornecer apenas informações corretas, atualizadas e completas. Ele garante que a CapTarget Archery obteve o consentimento prévio dos Membros cujos endereços de e-mail e números de telefone ele fornece, e que eles têm o direito de solicitar à CapTarget Archery a exclusão deles a qualquer momento (consulte nossa Política de Privacidade).</p>
    <p> </p>
    <p>Os administradores são os únicos responsáveis pelo uso do Grupo.</p>
    <p>Também é especificado que o Administrador é inteiramente e exclusivamente responsável pelo uso do Espaço da Organização por ele mesmo e por qualquer terceiro.</p>
    <p> </p>
    <p>A CapTarget Archery não será responsável se os dados relativos à inscrição de uma Organização não chegarem à CapTarget Archery por motivo não imputável à CapTarget Archery (por exemplo, um problema de conexão à Internet causado por qualquer motivo por parte do Administrador, uma falha temporária de seus servidores, etc.) ou se os dados chegarem à CapTarget Archery em uma forma não tratável (por exemplo, se o Administrador tiver um hardware ou software inadequado).</p>
    <p> </p>
    <p>Toda inscrição é feita por prazo indeterminado e o Grupo permanece ativo até ser encerrado por um dos administradores.</p>
    <p> </p>
    <p> </p>
    <h4>Inscrição de um treinador</h4>
    <p>Para acessar as funcionalidades dos treinadores, um Utilizador deve subscrever a uma assinatura que lhe permita aceder a uma licença de acesso aos serviços de treinador da CapTarget Archery através do site e/ou da aplicação móvel de acordo com a oferta subscrita.</p>
    <p>Uma licença de acesso pode ser fornecida por um terceiro que subscreveu a assinatura em seu lugar. Uma licença de acesso é estritamente exclusiva ao Utilizador que fez a inscrição com essa licença. A conta não pode ser compartilhada com outras pessoas.</p>
    <p>As vantagens das diferentes ofertas podem ser alteradas. Se uma assinatura estiver em andamento, essas mudanças não serão eficazes ou devem ser consideradas em benefício do Utilizador.</p>
    <p>A inscrição é realizada no site pelo Utilizador. Para isso, ele deve preencher os diferentes campos do formulário de inscrição, incluindo um endereço de e-mail válido, seu nome e sobrenome, bem como as diferentes informações relacionadas à sua prática de tiro com arco. Ele deve ler e aceitar os CGU, CGV e a Política de Privacidade de Dados.</p>
    <p> </p>
    <p>Ao preencher o formulário de inscrição, o treinador e o consumidor que subscreve a licença comprometem-se a fornecer apenas informações corretas, atualizadas e completas.
        O Utilizador que utiliza sua licença é o único responsável pelo uso de sua Conta. Qualquer conexão ou transmissão de dados através da Plataforma será considerada como tendo sido realizada pelo Utilizador e sob sua exclusiva responsabilidade.
    </p>
    <p>A CapTarget Archery não será responsável se os dados de inscrição do Utilizador não chegarem à CapTarget Archery por motivo que não lhe seja imputável (por exemplo, um problema de conexão à Internet do Utilizador, uma falha temporária de seus servidores, etc.), ou se os dados chegarem à CapTarget Archery em uma forma não tratável (por exemplo, se o Utilizador tiver um hardware ou software inadequado).</p>
    <p>Toda assinatura paga é realizada por prazo indeterminado, a menos que o Utilizador faça um pedido explícito, o contrato será renovado automaticamente após o período de compromisso mínimo. Convidamos você a consultar os CGV para obter detalhes sobre as condições de cancelamento. </p>
    <p> </p>

    <h4>Compartilhamento de Dados</h4>
    <p>Para acessar as funcionalidades de compartilhamento de dados via CapTarget Archery, nossa aplicação oferece um sistema de fãs e seguidores. Como usuário, você tem a opção de aceitar convites ou convidar outros usuários para se juntarem à sua rede.</p>
    <p>No caso de um perfil público, nenhuma aprovação é necessária para que outros usuários o sigam. Uma vez que um usuário o segue, alguns dos seus dados podem ser compartilhados automaticamente ou manualmente, incluindo:</p>
    <p>-Os resultados das suas competições ou sessões de treinamento.</p>
    <p>-Várias estatísticas esportivas, como a duração da sua licença, seu melhor resultado ou classificação.</p>
    <p>-Dados relacionados ao uso da aplicação, como seus jogos e exercícios favoritos.</p>
    <p>-Informações sobre o seu clube, idioma, país, bem como outros dados como nome, sobrenome, tipo de arco utilizado ou categoria de tiro.</p>
    <p>Você tem a opção de escolher não compartilhar alguns desses dados, alterando suas configurações pessoais ou as configurações de cada situação.</p>
    <p>Desde a criação da sua conta, você concorda em ser visível na plataforma CapTarget. Outros usuários podem procurar por você usando seu nome e sobrenome. Seu perfil também pode ser sugerido automaticamente a outros usuários com o objetivo de criar desafios esportivos ou conexões de seguimento.</p>
    <p>Por favor, observe que você pode estar exposto a informações compartilhadas na CapTarget Archery por parte da CapTarget, seus parceiros, anunciantes ou outros usuários. Recomendamos que você consulte nossa política de privacidade para obter mais detalhes sobre o gerenciamento de seus dados pessoais.</p>
    <p>Gostaríamos de enfatizar que todas essas cláusulas estão em conformidade com as leis e regulamentos vigentes relacionados à proteção de dados pessoais e privacidade. Se você tiver preocupações ou perguntas adicionais, não hesite em nos contatar.</p>
    <p></p>
    <p></p>

    <p></p>
    <h4>Aviso</h4>
    <p>O acesso às versões de treinadores das plataformas CapTarget Archery requer o uso de ferramentas de computação, como computador, tablet ou telefone para a versão web, e telefone ou tablet com sistema ANDROID ou IOS para a versão móvel. É necessária uma conexão com a internet para baixar os dados necessários. O usuário declara estar ciente dessa necessidade para acessar os serviços adquiridos.</p>
    <p>Os exercícios e diferentes situações oferecidos nos serviços pressupõem que os usuários estejam em boas condições físicas e aptos a realizá-los. O Assinante compromete-se a tomar todas as precauções necessárias para praticar a atividade, preservando sua saúde, segurança e a dos outros.</p>
    <p></p>
    <p></p>

    <p></p>
    <p></p>

    <h4>Termos e Condições de Venda</h4>
    <p>Ao assinar uma assinatura ou comprar um dos serviços digitais ou produtos digitais disponibilizados pela CapTarget Archery, você concorda com os termos e condições de venda descritos abaixo. Se você não concorda com essas condições, por favor, não compre nada em nossa aplicação ou em nosso site.</p>
    <h6>Assinaturas</h6>
    <p>Ao comprar conteúdo da CapTarget, a CapTarget concede a você uma licença limitada, revogável, não exclusiva e não transferível para acessar determinadas funcionalidades pagas em nosso aplicativo ou site. Nossa aplicação oferece assinaturas mensais e anuais para acessar serviços digitais com renovação automática. As taxas de assinatura serão automaticamente debitadas do método de pagamento que você escolheu na data de aniversário da assinatura. As assinaturas pagas com gemas (a moeda virtual da CapTarget Archery) não são renovadas automaticamente.</p>
    <p>Se você não deseja renovar sua assinatura, deve desativá-la pelo menos 24 horas antes do término da assinatura, acessando as configurações da sua conta.</p>
    <h6>Tarifas</h6>
    <p>Nossas tarifas de assinatura são publicadas em nosso aplicativo e podem ser alteradas a qualquer momento sem aviso prévio. As tarifas de assinatura incluem todos os impostos aplicáveis. Você é responsável por todas as taxas associadas ao uso de nosso aplicativo, incluindo taxas de acesso à internet e dados móveis. Apesar de todos os nossos esforços, um número limitado de itens de nosso catálogo pode estar com preços incorretos. As tarifas estão sujeitas a alterações, mesmo para assinantes atuais. Dependendo do sistema escolhido (Apple, Google, Stripe ou outro), você pode ser notificado em caso de aumento de tarifa em sua assinatura em andamento e poderá cancelar a assinatura antes da renovação, se desejar.</p>
    <h6>Risco de Perda</h6>
    <p>Depois que o Conteúdo CapTarget for disponibilizado para você, você assume o risco de perda do Conteúdo CapTarget após a compra, incluindo qualquer perda devido à corrupção de arquivos ou falha do dispositivo ou disco rígido. O Conteúdo CapTarget adquirido permanecerá geralmente disponível em sua Conta CapTarget, mas pode deixar de estar disponível por várias razões. Nesses casos, a CapTarget não será responsável por indisponibilidade de Conteúdo CapTarget subsequente e você não terá direito a receber reembolso, total ou parcial, pelas taxas já pagas.</p>
    <h6>Entrega</h6>
    <p>O acesso ao Conteúdo CapTarget é fornecido no momento da compra do produto ou assinatura. O conteúdo está disponível em sua conta CapTarget. Pode ser necessário reiniciar o aplicativo e aguardar um período razoável para que os serviços fiquem disponíveis (geralmente alguns minutos, no máximo).</p>
    <h6>Pagamento</h6>
    <p>Você deve ter um método de pagamento válido (como um cartão de crédito ou outro método de pagamento autorizado) em sua conta CapTarget se desejar aproveitar as funções de pagamento. O pagamento da sua assinatura será automaticamente debitado de sua conta com base no método de pagamento inserido em sua conta Apple, Google Play, Stripe ou outro, se você comprou a assinatura em nosso site. Você concorda em fornecer informações de pagamento precisas e atualizadas. Se o pagamento não for efetuado por qualquer motivo, reservamos o direito de suspender ou cancelar sua assinatura.</p>
    <h6>Uso do Aplicativo</h6>
    <p>Você está autorizado a usar nosso aplicativo para fins pessoais e não comerciais. Você não pode modificar, distribuir, vender ou transferir qualquer conteúdo de nosso aplicativo sem nossa autorização prévia por escrito. Você não pode usar nosso aplicativo de maneira que viole a lei aplicável, infrinja os direitos de terceiros ou cause prejuízo a terceiros.</p>
    <h6>Reembolsos</h6>
    <p>Nossas assinaturas podem ser testadas antecipadamente por um período de 3 semanas e, portanto, não são reembolsáveis. Por favor, verifique as funcionalidades de cada assinatura antes de aderir a uma delas. Os produtos digitais à venda são explícitos e não podem ser reembolsados.</p>
    <h6>Ressalva</h6>
    <p>Reservamo-nos o direito de rescindir o seu acesso ao nosso aplicativo se você violar estes termos e condições de venda ou uso, ou se tivermos motivos para acreditar que você está usando nosso aplicativo de qualquer maneira inadequada. Ao comprar uma assinatura de nosso aplicativo, você concorda com estes termos e condições de venda.</p>

    <h6>Contate-nos</h6>
    <p>Se você tiver perguntas ou preocupações, entre em contato conosco pelo
        seguinte endereço: support@captarget-archery.com</p>
    <p>Faremos o possível para resolver sua questão de forma amigável e
        benéfica para todas as partes envolvidas.</p>
    <p></p>
    <p></p>
    <h4>Custos de Uso da Plataforma</h4>
    <p> </p>
    <p>A criação de uma Conta e um Grupo é gratuita para um Usuário. No entanto, os custos de conexão e comunicação associados ao uso da Plataforma são de responsabilidade exclusiva do Usuário.</p>
    <p>O acesso às versões de treinadores é pago, exceto as versões de teste e a versão beta. As tarifas estão disponíveis a partir do lançamento da versão, no site.</p>
    <h4>Compras via Plataformas</h4>
    <p> </p>
    <p>É possível comprar com dinheiro "real" uma licença limitada, pessoal, não transferível, não sublicenciável e revogável que permite o uso de (a) "moeda virtual" e, em particular, moedas ou diamantes virtuais, todos destinados a serem usados nos jogos do CapTarget Archery. Também é possível obter essa moeda em eventos, competições, jogos, assistindo a anúncios ou como recompensa por atingir metas na plataforma, distribuída pelo CapTarget Archery.</p>
    <p> </p>
    <p>Essa moeda permite a compra de diversos produtos vendidos pelo aplicativo.</p>
    <p> </p>
    <p>A transferência de Itens virtuais é proibida, exceto quando expressamente autorizada no Serviço. Salvo o que é expressamente autorizado no Serviço, você deve se abster de vender, comprar, converter ou transferir de qualquer outra forma Itens virtuais em benefício de qualquer pessoa ou entidade, ou tentar qualquer uma das ações mencionadas acima, em benefício do CapTarget Archery, de outro usuário ou de terceiros.</p>
    <p> </p>
    <p> </p>
    <p>O CapTarget Archery pode gerenciar, regular, controlar, modificar ou excluir os Itens virtuais a qualquer momento, com ou sem aviso prévio. O CapTarget Archery não se responsabilizará perante você ou terceiros pelo exercício desses direitos.</p>
    <p> </p>
    <p>Todas as compras e conversões de itens virtuais de terceiros feitas por meio do serviço são definitivas e não reembolsáveis.</p>
    <p> </p>
    <p>Você concorda em pagar todas as taxas e impostos aplicáveis devidos por você ou por qualquer pessoa que utilize uma conta registrada em seu nome. O CapTarget Archery pode alterar o preço dos itens virtuais oferecidos pelo serviço a qualquer momento. Você reconhece que o CapTarget Archery não é obrigado a fornecer reembolso por qualquer motivo e que você não receberá nenhuma quantia ou outra compensação por itens virtuais não utilizados no encerramento de uma conta, seja voluntário ou involuntário.</p>
    <p> </p>
    <h4>Anúncios e Parceiros</h4>
    <p> </p>
    <p>O CapTarget Archery pode fornecer links para outros sites publicitários e/ou parceiros em diferentes serviços oferecidos.</p>
    <p> </p>
    <p>Na medida em que o CapTarget Archery não pode controlá-los, o CapTarget Archery não assume nenhum compromisso em relação à disponibilidade desses sites e fontes externas e não pode ser responsabilizado pelo conteúdo, anúncios, produtos, serviços ou qualquer outro material disponível neles.</p>
    <p> </p>
    <p>Da mesma forma, o CapTarget Archery não será responsável por quaisquer danos ou perdas comprovados ou alegados decorrentes ou relacionados ao uso do conteúdo ou serviços disponíveis nesses sites publicitários, parceiros ou fontes externas.</p>
    <p> </p>
    <h4>Encerramento de Conta</h4>
    <p> </p>
    <p>Um Usuário pode encerrar sua Conta a qualquer momento ou a Conta que administra em nome de um menor. De acordo com nossa Política de Privacidade de Dados, o encerramento de uma Conta resultará na exclusão dos dados pessoais associados.</p>
    <p> </p>
    <p>Sem prejuízo dos danos e perdas que o CapTarget Archery possa buscar, o CapTarget Archery reserva-se o direito de encerrar, sem aviso prévio ou indenização, temporária ou permanentemente, a Conta de um Usuário em caso, nomeadamente</p>
    <p>do não cumprimento destas Condições de Uso</p>
    <p>da prestação de informações falsas no momento do registro;</p>
    <p>de ações contrárias aos interesses comerciais do CapTarget Archery.</p>
    <p> </p>
    <p>O encerramento de uma conta por iniciativa do CapTarget Archery devido ao não cumprimento destes Termos de Uso ou dos Termos e Condições de Venda não dá direito a nenhuma compensação ou reembolso, incluindo em caso de assinaturas pagas. Todos os valores pagos pela Assinatura permanecerão propriedade do CapTarget Archery.</p>
    <p> </p>
    <p>O encerramento de um acesso de treinador pode ser realizado de acordo com os Termos e Condições de Venda aplicáveis. Convidamos o usuário a consultar esses termos para o encerramento de um acesso de treinador pago. Uma versão de teste é automaticamente pausada no final do período e pode ser reativada mediante assinatura. A versão beta é renovável a critério do CapTarget Archery. Para encerrar uma versão de teste ou uma versão beta, envie um e-mail para o endereço support@captarget-archery.com</p>
    <p> </p>
    <h4>Exclusão de um Grupo</h4>
    <p> </p>
    <p>Um Administrador pode excluir um Grupo a qualquer momento. A exclusão do Espaço de uma Organização resulta na exclusão de todos os dados e benefícios associados a ele.</p>
    <p> </p>
    <p>Exclusão do Espaço de uma Organização por iniciativa do CapTarget Archery</p>
    <p> </p>
    <p>Sem prejuízo dos danos e perdas que o CapTarget Archery possa buscar, o CapTarget Archery reserva-se o direito de excluir, sem aviso prévio ou indenização, temporária ou permanentemente, o Espaço de uma Organização em caso de</p>
    <p> </p>
    <p>do não cumprimento dos Termos e Condições de Venda;</p>
    <p>da prestação de informações falsas no momento do registro da Organização;</p>
    <p>de ações contrárias aos interesses comerciais do CapTarget Archery.</p>
    <p> </p>
    <p>A exclusão do Espaço de uma Organização por iniciativa do CapTarget Archery não dá direito a nenhuma compensação ou reembolso, incluindo em caso de assinatura paga. Todos os valores pagos pela Assinatura permanecerão propriedade do CapTarget Archery, e a exclusão do Espaço de uma Organização não exime a Organização/Administrador da obrigação de pagar a Assinatura até o seu término.</p>
    <p> </p>
    <h4>Informações e Conteúdo das Plataformas</h4>
    <p> </p>
    <p>O Usuário compromete-se a usar discernimento ao utilizar a Plataforma, especialmente ao avaliar as informações e conteúdos presentes em um Grupo do qual é membro.</p>
    <p> </p>
    <p>Nesse sentido, o Usuário reconhece que as informações e conteúdos disponíveis na Plataforma não são exaustivos e têm como principal objetivo auxiliar o Administrador na gestão da Organização.</p>
    <p> </p>
    <p>O Usuário proíbe a venda, revenda ou exploração de qualquer forma e para qualquer finalidade de todo ou parte dos conteúdos disponíveis na Plataforma.</p>
    <p> </p>
    <p>O Usuário é o único responsável pelas comunicações, informações, arquivos, vídeos, fotos, documentos ou outros conteúdos que ele publica e transmite por meio da Plataforma.</p>
    <p> </p>
    <p>As comunicações, informações, arquivos, vídeos, fotos, documentos ou outros conteúdos publicados pelo Usuário na Plataforma representam as opiniões e pontos de vista do próprio Usuário e, em nenhum caso, as do CapTarget Archery ou de seus funcionários e agentes.</p>
    <p> </p>
    <p>No contexto da Plataforma, o Usuário compromete-se a fornecer apenas informações objetivas, completas e verdadeiras, de acordo com as regras de decência, cortesia e respeito aos outros.</p>
    <p> </p>
    <p>O CapTarget Archery incentiva o Usuário a agir com moderação e cuidado na expressão das comunicações, informações, arquivos, vídeos, fotos, documentos ou outros conteúdos que deseja publicar na Plataforma. Nesse sentido, o Usuário se abstém de publicar na Plataforma conteúdos contrários às leis e regulamentos em vigor, incluindo:</p>
    <p> </p>
    <p>disseminar informações contrárias à ordem pública ou à moral;</p>
    <p>desviar o propósito da Plataforma para propaganda ou proselitismo, abordagem ou solicitação; publicar informações de natureza comercial ou publicitária ou que constituam propaganda em favor do tabaco, álcool ou qualquer outra substância, produto ou serviço regulamentado;</p>
    <p>disseminar conteúdos que violem os direitos da personalidade de terceiros ou que sejam difamatórios, injuriosos, obscenos, pornográficos, ofensivos, violentos ou que incitem à discriminação, à violência política, ao racismo, à xenofobia, ao sexismo ou à homofobia;</p>
    <p>publicar informações que violem a legislação sobre a proteção de dados pessoais que permitem a identificação de pessoas físicas sem o seu consentimento, incluindo seu nome completo, endereço postal e/ou eletrônico, telefone, fotografia, gravação de áudio ou audiovisual;</p>
    <p>violar os direitos de propriedade intelectual de terceiros, incluindo a exclusão ou remoção de qualquer menção a direitos autorais ou marca registrada, bem como qualquer legenda restritiva.</p>
    <p> </p>
    <p>O Usuário também se compromete a respeitar os direitos de terceiros, incluindo</p>
    <p> </p>
    <p>os direitos da personalidade (como o direito de imagem, o direito à privacidade)</p>
    <p>os direitos das marcas</p>
    <p>os direitos autorais (incluindo em software, sons, imagens, fotografias, textos, imagens animadas) e os direitos conexos (artistas intérpretes ou executantes, produtores de fonogramas e videogramas e os direitos sui generis dos produtores de bases de dados);</p>
    <p>em geral, os direitos das pessoas e bens.</p>
    <p> </p>

    <h6>Contate-nos</h6>
    <p>Se você tiver perguntas ou preocupações, entre em contato conosco pelo
        seguinte endereço: support@captarget-archery.com</p>
    <p>Faremos o possível para resolver sua questão de forma amigável e
        benéfica para todas as partes envolvidas.</p>
    <p></p>
    <p></p>
    <h4>Custos de Uso da Plataforma</h4>
    <p> </p>
    <p>A criação de uma Conta e um Grupo é gratuita para um Usuário. No entanto, os custos de conexão e comunicação associados ao uso da Plataforma são de responsabilidade exclusiva do Usuário.</p>
    <p>O acesso às versões de treinadores é pago, exceto as versões de teste e a versão beta. As tarifas estão disponíveis a partir do lançamento da versão, no site.</p>
    <h4>Compras via Plataformas</h4>
    <p> </p>
    <p>É possível comprar com dinheiro "real" uma licença limitada, pessoal, não transferível, não sublicenciável e revogável que permite o uso de (a) "moeda virtual" e, em particular, moedas ou diamantes virtuais, todos destinados a serem usados nos jogos do CapTarget Archery. Também é possível obter essa moeda em eventos, competições, jogos, assistindo a anúncios ou como recompensa por atingir metas na plataforma, distribuída pelo CapTarget Archery.</p>
    <p> </p>
    <p>Essa moeda permite a compra de diversos produtos vendidos pelo aplicativo.</p>
    <p> </p>
    <p>A transferência de Itens virtuais é proibida, exceto quando expressamente autorizada no Serviço. Salvo o que é expressamente autorizado no Serviço, você deve se abster de vender, comprar, converter ou transferir de qualquer outra forma Itens virtuais em benefício de qualquer pessoa ou entidade, ou tentar qualquer uma das ações mencionadas acima, em benefício do CapTarget Archery, de outro usuário ou de terceiros.</p>
    <p> </p>
    <p> </p>
    <p>O CapTarget Archery pode gerenciar, regular, controlar, modificar ou excluir os Itens virtuais a qualquer momento, com ou sem aviso prévio. O CapTarget Archery não se responsabilizará perante você ou terceiros pelo exercício desses direitos.</p>
    <p> </p>
    <p>Todas as compras e conversões de itens virtuais de terceiros feitas por meio do serviço são definitivas e não reembolsáveis.</p>
    <p> </p>
    <p>Você concorda em pagar todas as taxas e impostos aplicáveis devidos por você ou por qualquer pessoa que utilize uma conta registrada em seu nome. O CapTarget Archery pode alterar o preço dos itens virtuais oferecidos pelo serviço a qualquer momento. Você reconhece que o CapTarget Archery não é obrigado a fornecer reembolso por qualquer motivo e que você não receberá nenhuma quantia ou outra compensação por itens virtuais não utilizados no encerramento de uma conta, seja voluntário ou involuntário.</p>
    <p> </p>
    <h4>Anúncios e Parceiros</h4>
    <p> </p>
    <p>O CapTarget Archery pode fornecer links para outros sites publicitários e/ou parceiros em diferentes serviços oferecidos.</p>
    <p> </p>
    <p>Na medida em que o CapTarget Archery não pode controlá-los, o CapTarget Archery não assume nenhum compromisso em relação à disponibilidade desses sites e fontes externas e não pode ser responsabilizado pelo conteúdo, anúncios, produtos, serviços ou qualquer outro material disponível neles.</p>
    <p> </p>
    <p>Da mesma forma, o CapTarget Archery não será responsável por quaisquer danos ou perdas comprovados ou alegados decorrentes ou relacionados ao uso do conteúdo ou serviços disponíveis nesses sites publicitários, parceiros ou fontes externas.</p>
    <p> </p>
    <h4>Encerramento de Conta</h4>
    <p> </p>
    <p>Um Usuário pode encerrar sua Conta a qualquer momento ou a Conta que administra em nome de um menor. De acordo com nossa Política de Privacidade de Dados, o encerramento de uma Conta resultará na exclusão dos dados pessoais associados.</p>
    <p> </p>
    <p>Sem prejuízo dos danos e perdas que o CapTarget Archery possa buscar, o CapTarget Archery reserva-se o direito de encerrar, sem aviso prévio ou indenização, temporária ou permanentemente, a Conta de um Usuário em caso, nomeadamente</p>
    <p>do não cumprimento destas Condições de Uso</p>
    <p>da prestação de informações falsas no momento do registro;</p>
    <p>de ações contrárias aos interesses comerciais do CapTarget Archery.</p>
    <p> </p>
    <p>O encerramento de uma conta por iniciativa do CapTarget Archery devido ao não cumprimento destes Termos de Uso ou dos Termos e Condições de Venda não dá direito a nenhuma compensação ou reembolso, incluindo em caso de assinaturas pagas. Todos os valores pagos pela Assinatura permanecerão propriedade do CapTarget Archery.</p>
    <p> </p>
    <p>O encerramento de um acesso de treinador pode ser realizado de acordo com os Termos e Condições de Venda aplicáveis. Convidamos o usuário a consultar esses termos para o encerramento de um acesso de treinador pago. Uma versão de teste é automaticamente pausada no final do período e pode ser reativada mediante assinatura. A versão beta é renovável a critério do CapTarget Archery. Para encerrar uma versão de teste ou uma versão beta, envie um e-mail para o endereço support@captarget-archery.com</p>
    <p> </p>
    <h4>Exclusão de um Grupo</h4>
    <p> </p>
    <p>Um Administrador pode excluir um Grupo a qualquer momento. A exclusão do Espaço de uma Organização resulta na exclusão de todos os dados e benefícios associados a ele.</p>
    <p> </p>
    <p>Exclusão do Espaço de uma Organização por iniciativa do CapTarget Archery</p>
    <p> </p>
    <p>Sem prejuízo dos danos e perdas que o CapTarget Archery possa buscar, o CapTarget Archery reserva-se o direito de excluir, sem aviso prévio ou indenização, temporária ou permanentemente, o Espaço de uma Organização em caso de</p>
    <p> </p>
    <p>do não cumprimento dos Termos e Condições de Venda;</p>
    <p>da prestação de informações falsas no momento do registro da Organização;</p>
    <p>de ações contrárias aos interesses comerciais do CapTarget Archery.</p>
    <p> </p>
    <p>A exclusão do Espaço de uma Organização por iniciativa do CapTarget Archery não dá direito a nenhuma compensação ou reembolso, incluindo em caso de assinatura paga. Todos os valores pagos pela Assinatura permanecerão propriedade do CapTarget Archery, e a exclusão do Espaço de uma Organização não exime a Organização/Administrador da obrigação de pagar a Assinatura até o seu término.</p>
    <p> </p>
    <h4>Informações e Conteúdo das Plataformas</h4>
    <p> </p>
    <p>O Usuário compromete-se a usar discernimento ao utilizar a Plataforma, especialmente ao avaliar as informações e conteúdos presentes em um Grupo do qual é membro.</p>
    <p> </p>
    <p>Nesse sentido, o Usuário reconhece que as informações e conteúdos disponíveis na Plataforma não são exaustivos e têm como principal objetivo auxiliar o Administrador na gestão da Organização.</p>
    <p> </p>
    <p>O Usuário proíbe a venda, revenda ou exploração de qualquer forma e para qualquer finalidade de todo ou parte dos conteúdos disponíveis na Plataforma.</p>
    <p> </p>
    <p>O Usuário é o único responsável pelas comunicações, informações, arquivos, vídeos, fotos, documentos ou outros conteúdos que ele publica e transmite por meio da Plataforma.</p>
    <p> </p>
    <p>As comunicações, informações, arquivos, vídeos, fotos, documentos ou outros conteúdos publicados pelo Usuário na Plataforma representam as opiniões e pontos de vista do próprio Usuário e, em nenhum caso, as do CapTarget Archery ou de seus funcionários e agentes.</p>
    <p> </p>
    <p>No contexto da Plataforma, o Usuário compromete-se a fornecer apenas informações objetivas, completas e verdadeiras, de acordo com as regras de decência, cortesia e respeito aos outros.</p>
    <p> </p>
    <p>O CapTarget Archery incentiva o Usuário a agir com moderação e cuidado na expressão das comunicações, informações, arquivos, vídeos, fotos, documentos ou outros conteúdos que deseja publicar na Plataforma. Nesse sentido, o Usuário se abstém de publicar na Plataforma conteúdos contrários às leis e regulamentos em vigor, incluindo:</p>
    <p> </p>
    <p>disseminar informações contrárias à ordem pública ou à moral;</p>
    <p>desviar o propósito da Plataforma para propaganda ou proselitismo, abordagem ou solicitação; publicar informações de natureza comercial ou publicitária ou que constituam propaganda em favor do tabaco, álcool ou qualquer outra substância, produto ou serviço regulamentado;</p>
    <p>disseminar conteúdos que violem os direitos da personalidade de terceiros ou que sejam difamatórios, injuriosos, obscenos, pornográficos, ofensivos, violentos ou que incitem à discriminação, à violência política, ao racismo, à xenofobia, ao sexismo ou à homofobia;</p>
    <p>publicar informações que violem a legislação sobre a proteção de dados pessoais que permitem a identificação de pessoas físicas sem o seu consentimento, incluindo seu nome completo, endereço postal e/ou eletrônico, telefone, fotografia, gravação de áudio ou audiovisual;</p>
    <p>violar os direitos de propriedade intelectual de terceiros, incluindo a exclusão ou remoção de qualquer menção a direitos autorais ou marca registrada, bem como qualquer legenda restritiva.</p>
    <p> </p>
    <p>O Usuário também se compromete a respeitar os direitos de terceiros, incluindo</p>
    <p> </p>
    <p>os direitos da personalidade (como o direito de imagem, o direito à privacidade)</p>
    <p>os direitos das marcas</p>
    <p>os direitos autorais (incluindo em software, sons, imagens, fotografias, textos, imagens animadas) e os direitos conexos (artistas intérpretes ou executantes, produtores de fonogramas e videogramas e os direitos sui generis dos produtores de bases de dados);</p>
    <p>em geral, os direitos das pessoas e bens.</p>
    <p> </p>

    <p>O Utilizador compromete-se igualmente, salvo autorização prévia e escrita da CapTarget Archery, sem que esta lista seja exaustiva:</p>
    <p> </p>
    <p>utilizar a Plataforma para fins promocionais e, de forma geral, oferecer produtos e serviços que o remunerem diretamente ou indiretamente;</p>
    <p>criar ficheiros de arquivo a partir dos conteúdos da Plataforma;</p>
    <p>reproduzir, representar, usar, referenciar (nomeadamente nos metatags dos motores de busca), total ou parcialmente, os conteúdos, marcas, logótipos, sinais distintivos presentes na Plataforma.</p>
    <p> </p>
    <p>O não cumprimento dos presentes Termos e Condições pode resultar na eliminação imediata e automática do conteúdo em questão, num aviso e/ou no encerramento da Conta do Utilizador, independentemente de quaisquer outras ações judiciais e/ou indemnizações que a CapTarget Archery possa intentar ou reclamar.</p>
    <p> </p>
    <p>A CapTarget Archery não é responsável pelas informações, ficheiros, vídeos, fotografias, documentos ou qualquer outro conteúdo da Plataforma, incluindo, mas não se limitando a, qualquer conteúdo ilegal, erros ou omissões, ou qualquer perda ou dano resultante do uso deste material por qualquer Utilizador.</p>
    <p> </p>
    <p>Qualquer hiperligação para páginas secundárias do Site é estritamente proibida, salvo autorização expressa da CapTarget Archery obtida pelo Utilizador. Além disso, qualquer hiperligação deve ser removida pelo Utilizador mediante pedido simples da CapTarget Archery.</p>
    <p> </p>
    <h4>Papel de moderação do Administrador</h4>
    <p> </p>
    <p>O Administrador, na qualidade de coordenador e supervisor do Grupo, compromete-se a fazer cumprir pelos Membros os termos dos Termos e Condições. Assim que tiver conhecimento, compromete-se a denunciar ou remover o conteúdo (se tiver essa possibilidade) que seja contrário à lei e/ou à ordem pública e/ou aos presentes Termos e Condições, nomeadamente qualquer conteúdo violento, pornográfico, racista, antissemita, xenófobo, que viole o respeito pela pessoa humana ou pela sua dignidade, que viole a privacidade, que viole direitos de autor, direitos conexos, direitos de marca, direitos da personalidade de terceiros.</p>
    <p> </p>
    <p>No caso de violação destas regras e práticas, a CapTarget Archery reserva-se o direito de excluir o Administrador e/ou o Membro em falta e de intentar qualquer ação judicial contra o ou os autores, que possa pôr termo à perturbação manifestamente ilícita resultante do uso inadequado da Plataforma.</p>
    <p> </p>
    <h4>Envio de e-mails, notificações push e SMS</h4>
    <p> </p>
    <p>No âmbito dos serviços propostos, a CapTarget Archery oferece comunicações por e-mail, notificações push na Aplicação e SMS para possibilitar a comunicação entre os Membros de uma Organização, com o único objetivo de trocar informações relacionadas com a Organização, através dos números de telefone móvel e endereços de e-mail fornecidos pelos Membros aquando da criação das suas Contas.</p>
    <p> </p>
    <p>O Utilizador pode decidir a qualquer momento deixar de receber e-mails, notificações push ou SMS da CapTarget Archery, alterando as configurações dos seus dispositivos ou solicitando-o ao suporte.</p>
    <p> </p>
    <h4>Acesso à Plataforma - Responsabilidade e compromissos</h4>
    <p> </p>
    <p>A CapTarget Archery faz o seu melhor para tornar a Plataforma disponível 24 horas por dia, 7 dias por semana, exceto para operações de manutenção programadas e sujeito às disposições deste artigo.</p>
    <p> </p>
    <p>A CapTarget Archery reserva-se o direito de efetuar na Plataforma todas as alterações e melhorias que julgue necessárias de acordo com a evolução das técnicas. Assim, a CapTarget Archery reserva-se o direito, sem aviso prévio ou indemnização, de suspender temporariamente o acesso à Plataforma para realizar operações de manutenção evolutiva ou corretiva. O Utilizador isenta a CapTarget Archery de qualquer responsabilidade a este respeito e renuncia a qualquer reclamação a esse respeito.</p>
    <p> </p>
    <p>A CapTarget Archery pode exigir que você aceite atualizações do Serviço e jogos da CapTarget Archery que você instalou no seu dispositivo ou computador. Você reconhece e concorda que a CapTarget Archery pode atualizar o Serviço e os jogos da CapTarget Archery, notificando-o ou não. Pode ser necessário que você atualize software de terceiros para poder receber o Serviço e jogar os Jogos da CapTarget Archery.</p>
    <p> </p>
    <p>A CapTarget Archery não oferece garantia alguma, expressa ou implícita, incluindo, mas não se limitando a, garantias de qualidade e adequação da Plataforma para um uso específico.</p>
    <p> </p>
    <p>A CapTarget Archery não garante que o funcionamento ininterrupto e/ou a continuidade da Plataforma serão assegurados em caso de força maior ou de caso fortuito, tais como definidos no artigo 1218 do Código Civil francês, situação em que a responsabilidade da CapTarget Archery não será envolvida.</p>
    <p> </p>
    <p>No caso de a responsabilidade da CapTarget Archery ser imputada ao Utilizador devido a uma violação das suas obrigações nos termos destes Termos e Condições, a reparação estará limitada a danos diretos, pessoais, certos e previsíveis.</p>
    <p> </p>
    <p>A CapTarget Archery não garante a relevância e precisão das informações publicadas na Plataforma por terceiros.</p>
    <p> </p>
    <p>A CapTarget Archery não é responsável por litígios, ações ou reivindicações de terceiros que possam ter direitos, incluindo direitos de propriedade, sobre qualquer conteúdo não criado pela CapTarget Archery.</p>
    <p> </p>
    <p>As condições sob as quais a CapTarget Archery recolhe e processa os dados estão detalhadas na política de privacidade de dados.</p>
    <p> </p>
    <p>A CapTarget Archery não será responsável por qualquer dano indireto, acessório, consequencial ou outro dano semelhante, incluindo, nomeadamente, qualquer perda de receitas, lucros cessantes, perda de dados ou perda de exploração ou outro dano não material (seja qual for a qualificação atribuída a essas perdas), relacionado de qualquer forma com estes termos de serviço ou o próprio serviço, e não será obrigada a pagar indemnizações especiais ou punitivas, quer seja com base contratual, extracontratual ou de outra natureza, e quer a CapTarget Archery tenha sido ou não informada da possibilidade de tal dano. A responsabilidade da CapTarget Archery para consigo não poderá exceder o montante que pagou à CapTarget Archery de acordo com estes termos de serviço nos seis (6) meses imediatamente anteriores à data em que apresentar pela primeira vez uma reclamação. Reconhece e concorda que, se não tiver pago nada à CapTarget Archery durante tal período, o seu único recurso (e a responsabilidade exclusiva da CapTarget Archery) relativamente a qualquer litígio com a CapTarget Archery será deixar de usar o serviço e encerrar a sua conta.</p>
    <p>Em particular, nenhuma das disposições destes termos de serviço poderá prejudicar os direitos legais de qualquer consumidor ou excluir ou limitar qualquer responsabilidade por morte ou danos corporais resultantes de negligência ou fraude da CapTarget Archery.</p>
    <p> </p>
    <p>Compromete-se a indemnizar, defender e isentar a CapTarget Archery (bem como os seus desenvolvedores, administradores e criadores de conteúdo) de qualquer responsabilidade por reclamações, pedidos, danos ou outras perdas, incluindo honorários razoáveis de advogados, feitos por terceiros e resultantes da sua utilização do Serviço ou de qualquer violação das presentes Condições de Serviço por si. No entanto, o acima mencionado não se aplica em caso de violação de direitos não imputável ao seu comportamento intencional ou negligente.</p>
    <p> </p>
    <p>A CapTarget Archery não será responsável por qualquer atraso ou incumprimento resultante de causas legitimamente fora do seu controlo, nomeadamente, qualquer incumprimento das presentes Condições devido a circunstâncias imprevisíveis ou a uma causa fora do controlo da CapTarget Archery, como catástrofes naturais, guerra, atos de terrorismo, tumultos, embargos, medidas das autoridades civis ou militares, incêndios, inundações, acidentes, greves ou escassez de meios de transporte, combustível, energia, mão-de-obra ou materiais.</p>
    <p> </p>
    <p>Os conselhos sobre as práticas das atividades descritas na aplicação são indicativos, sendo da responsabilidade do Utilizador adaptar os conselhos à sua saúde ou consultar um médico. A responsabilidade da prática desportiva é do Utilizador, a CapTarget Archery não é de forma alguma responsável por lesões físicas ou morais resultantes da prática de uma atividade com a ajuda da aplicação.</p>
    <p> </p>
    <p>O Utilizador compromete-se a denunciar quaisquer vulnerabilidades de segurança e a não utilizá-las para proveito próprio ou de terceiros. O Utilizador compromete-se a não realizar ações que possam prejudicar o bom funcionamento dos serviços oferecidos pela CapTarget Archery e a não aproveitar eventuais erros.</p>
    <p> </p>
    <h4>Propriedade Intelectual</h4>
    <p> </p>
    <p>Os serviços e os seus conteúdos (incluindo dados, bases de dados, software, fotografias, informações, ilustrações, logótipos, marcas, etc.) que aparecem ou estão disponíveis na Plataforma são protegidos por todos os direitos de propriedade intelectual existentes, os direitos dos produtores de bases de dados e/ou quaisquer outros direitos reconhecidos pela legislação em vigor.</p>
    <p> </p>
    <p>Qualquer cópia, reprodução, representação, adaptação, alteração, modificação ou difusão não autorizada, total ou parcial, dos serviços e/ou conteúdos da Plataforma, quer esses conteúdos pertençam à CapTarget Archery, a um Utilizador, a uma Organização ou a um terceiro, é ilícita e pode envolver a responsabilidade penal e civil do seu autor.</p>
    <p> </p>
    <p>A CapTarget Archery concede ao Utilizador o direito de usar a Plataforma para as suas próprias necessidades, excluindo qualquer utilização com fins lucrativos.</p>
    <p> </p>
    <p>Quando o Utilizador carrega um conteúdo na Plataforma, concede automaticamente à CapTarget Archery uma licença pessoal e não exclusiva para usar esse conteúdo gratuitamente.</p>
    <p> </p>
    <p>Esta licença é concedida em todo o mundo, para todas as utilizações em todos os suportes e por todos os processos de distribuição conhecidos ou desconhecidos até à data, e durante todo o período de proteção legal dos direitos de propriedade intelectual (incluindo renovações e prorrogações):</p>
    <p> </p>
    <p>o direito de reprodução: o direito de fazer ou de mandar fazer um número ilimitado de cópias do conteúdo, em qualquer forma ou suporte, conhecido ou desconhecido até à data, e em particular em qualquer suporte em papel, magnético, digital ou outro (por exemplo, todos os folhetos, documentos de imprensa, cartazes, páginas web, relatórios, e qualquer suporte de gravação, como CD, DVD, disquetes, discos rígidos internos ou externos ou qualquer outro suporte de gravação), por qualquer processo técnico conhecido ou desconhecido até à data;</p>
    <p>o direito de representação: o direito de comunicar, difundir, fazer comunicar ou fazer difundir os conteúdos aos membros da Organização do Utilizador por qualquer meio, conhecido ou desconhecido até à data, nomeadamente através de qualquer rede de telecomunicações ou qualquer meio de leitura, troca de informações e comunicação (Internet, rede terrestre, cabo, satélite, transferência, transmissão, etc.);</p>
    <p>o direito de traduzir os conteúdos para qualquer idioma (incluindo todas as linguagens informáticas).</p>
    <p>O Utilizador garante à CapTarget Archery que o conteúdo carregado na Plataforma não viola quaisquer direitos de propriedade intelectual ou quaisquer outros direitos pertencentes a terceiros.</p>
    <p> </p>
    <p>Consequentemente, o Utilizador aceita defender a CapTarget Archery e assumir a responsabilidade e/ou indemnizar a CapTarget Archery por qualquer dano que possa resultar de qualquer ação ou reclamação de terceiros relativa ao conteúdo publicado pelo utilizador.</p>
    <p> </p>
    <h4>Documentos contratuais</h4>
    <p> </p>
    <p>Estes Termos de Utilização regem todas as relações comerciais contratuais ou pré-contratuais entre a CapTarget Archery, o Utilizador e/ou a Organização, relacionadas com a utilização da Plataforma e a subscrição das Assinaturas propostas pela CapTarget Archery.</p>
    <p> </p>
    <p>Estes Termos e Condições prevalecem sobre qualquer acordo ou disposição contrária entre as partes. Anulam e substituem quaisquer CGV anteriores que possam ter regido as relações entre as partes.</p>
    <p> </p>
    <p>Estes Termos e Condições constituem a totalidade dos documentos contratuais entre as partes.</p>
    <p> </p>
    <p>A CapTarget Archery reserva-se o direito de modificar, a qualquer momento, o conteúdo dos Termos e Condições. Estas modificações darão origem a uma nova versão que será aplicada automaticamente a todos os serviços fornecidos a partir desse momento.</p>
    <p> </p>
    <h4>Direito aplicável, Mediação e Jurisdição</h4>
    <p> </p>
    <p>Os Termos e Condições estão sujeitos ao direito francês.</p>
    <p> </p>
    <p>As partes concordam em procurar uma solução amigável antes de qualquer ação judicial. Em caso de reclamação que não possa ser resolvida amigavelmente entre as partes, o Utilizador pode recorrer, gratuitamente, a um procedimento de mediação de consumo. É proposto recorrer ao seguinte mediador: Centro de Mediação e Arbitragem de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p> </p>
    <p>O Utilizador também pode utilizar a plataforma de resolução de litígios online disponibilizada pela Comissão Europeia no seguinte endereço: www.ec.europa.eu/consumers/odr/.</p>
</div>
