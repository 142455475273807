<section id="screenshots" class="section screenshots-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.efficiency_message' | translate }}</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
                <div class="app-screenshots">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image1.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image1.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image2.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image2.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image3.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image3.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image4.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image4.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image5.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image5.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image6.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image6.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image7.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image7.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/screens/Image8.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/Image8.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
