<div class="homepage-3">
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <app-hero-three></app-hero-three>
        <app-promo-three></app-promo-three>
        <app-about-one></app-about-one>
        <app-content-five></app-content-five>
        <app-feature-three></app-feature-three>
        <app-content-six></app-content-six>
        <app-counter-one></app-counter-one>
        <app-pricing-one></app-pricing-one>
        <app-review></app-review>
        <app-team-two></app-team-two>
        <app-cta-one></app-cta-one>
        <app-download-one></app-download-one>
        <app-faq-one></app-faq-one>
        <app-contact></app-contact>
        <app-footer-three></app-footer-three>
    </div>
</div>