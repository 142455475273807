import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-es-conf',
  templateUrl: './es-conf.component.html',
  styleUrls: ['./es-conf.component.css']
})
export class EsConfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
