<section id="coachs" class="section screenshots-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.coach_web_title_1' | translate }}</h2>
                    <p class="d-none d-sm-block mt-4">{{ 'TRANSLATE.coach_web_description_2' | translate }}</p>
                    <p class="d-none d-sm-block mt-4">{{ 'TRANSLATE.coach_web_description_3' | translate }}</p>
                    <div class="flex-column" style="display: flex">
                        <a href="https://captarget-coach.com" target="_blank"
                           class="text-hover" style="font-weight: bold; font-size: 110%; margin-top: 10px;">{{ 'TRANSLATE.discover_coach_web_link_4' | translate }}</a>
<!--                        <a (click)="openPDF()" class="text-hover" style="font-weight: bold; font-size: 110%; margin-top: 5px;">{{ 'TRANSLATE.discover_future_evolutions_link_5' | translate }}</a>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
<!--                <div class="app-screenshots">-->
<!--                    &lt;!&ndash; Single Screenshot Item &ndash;&gt;-->
<!--                    <div class="single-screenshot">-->
<!--                        <img src="assets/img/screens/coach1.png" alt="">-->
<!--                        &lt;!&ndash; Screenshots Overlay &ndash;&gt;-->
<!--                        <div class="screenshots-overlay">-->
<!--                            <a href="assets/img/screens/coach1.png" data-fancybox="images"><i class="icofont-image"></i></a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Single Screenshot Item &ndash;&gt;-->
<!--                    <div class="single-screenshot">-->
<!--                        <img src="assets/img/screens/coach2.png" alt="">-->
<!--                        &lt;!&ndash; Screenshots Overlay &ndash;&gt;-->
<!--                        <div class="screenshots-overlay">-->
<!--                            <a href="assets/img/screens/coach2.png" data-fancybox="images"><i class="icofont-image"></i></a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Single Screenshot Item &ndash;&gt;-->
<!--                    <div class="single-screenshot">-->
<!--                        <img src="assets/img/screens/coach3.png" alt="">-->
<!--                        &lt;!&ndash; Screenshots Overlay &ndash;&gt;-->
<!--                        <div class="screenshots-overlay">-->
<!--                            <a href="assets/img/screens/coach3.png" data-fancybox="images"><i class="icofont-image"></i></a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Single Screenshot Item &ndash;&gt;-->
<!--                    <div class="single-screenshot">-->
<!--                        <img src="assets/img/screens/coach4.png" alt="">-->
<!--                        &lt;!&ndash; Screenshots Overlay &ndash;&gt;-->
<!--                        <div class="screenshots-overlay">-->
<!--                            <a href="assets/img/screens/coach4.png" data-fancybox="images"><i class="icofont-image"></i></a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Single Screenshot Item &ndash;&gt;-->
<!--                    <div class="single-screenshot">-->
<!--                        <img src="assets/img/screens/coach5.png" alt="">-->
<!--                        &lt;!&ndash; Screenshots Overlay &ndash;&gt;-->
<!--                        <div class="screenshots-overlay">-->
<!--                            <a href="assets/img/screens/coach5.png" data-fancybox="images"><i class="icofont-image"></i></a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>
</section>
