<section id="features" class="section benifits-area style-two ptb_100" (window:resize)="onResize($event)">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.features_title_1' | translate }}</h2>
                    <p class="d-none d-sm-block mt-4" style="text-align: justify; text-justify: inter-word;">{{ 'TRANSLATE.features_description_2' | translate }}</p>
                    <p class="d-block d-sm-none mt-4 justify-content-center" style="text-align: justify; text-justify: inter-word;">{{ 'TRANSLATE.features_description_3' | translate }}</p>
                </div>
            </div>
        </div>
        <div class="row benifits-content">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/sessions.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_1_title_4' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_1_description_5' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="200">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/counted.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_2_title_6' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_2_description_7' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="400">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/match.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_3_title_8' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_3_description_9' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="600">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/graph.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_4_title_10' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_4_description_11' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="800">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/group.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_5_title_12' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_5_description_13' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="1000">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/coach.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_6_title_14' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_6_description_15' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="600" *ngIf="displayMore">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/rocket.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_7_title_16' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_7_description_17' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="800" *ngIf="displayMore">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/game.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_8_title_18' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_8_description_19' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="1000" *ngIf="displayMore">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/icons/blueExercice.svg" alt="" style="height: 60px; object-fit: contain">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.feature_9_title_20' | translate }}</h3>
                        <p>{{ 'TRANSLATE.feature_9_description_21' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 justify-content-center text-center" style="display: flex; margin-top: 30px">
                <p>{{ 'TRANSLATE.dl_to_discover_more' | translate }}</p>
            </div>
        </div>
    </div>
</section>
