<section class="section faq-area ptb_100" id="faq">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Débuter le tir à l'arc</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomOne2">
                                                    Qu'est-ce que le tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomOne2" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                            <div class="card-body">
                                <p itemprop="text">Le tir à l'arc est un sport de précision qui consiste à tirer des flèches avec un arc sur une cible. C'est un sport olympique pratiqué par des hommes et des femmes. <br><br> Le tir à l'arc est accessible à tous, car il est facile à apprendre et ludique. Il était historiquement utilisé pour la chasse et le combat, mais est aujourd'hui principalement une activité sportive. <br><br> Les compétiteurs cherchent à atteindre le centre de la cible en faisant preuve de concentration et de précision. Les personnes qui pratiquent ce sport sont appelées des archers.</p>
                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomSecond2">
                                                    Comment commencer le tir à l'arc
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSecond2" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Pour commencer le tir à l'arc, il est recommandé de trouver un club ou une association sportive proposant des cours pour débutants. <br><br>Il est important de recevoir un enseignement approprié pour apprendre les bonnes techniques dès le début, afin d'éviter de prendre de mauvaises habitudes. Il est également essentiel de choisir le bon matériel en fonction de son niveau et de ses objectifs, en demandant conseil à des professionnels.<br><br> Avant de commencer, il est important de s'échauffer pour éviter les blessures et de respecter les règles de sécurité. La pratique régulière du tir à l'arc peut apporter de nombreux bienfaits physiques et mentaux, mais il est important de commencer lentement et de progresser à son rythme.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomThree1">
                                                    Pourquoi faire du tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomThree1" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc est un sport accessible à tous, favorisant les échanges intergénérationnels et offrant de nombreux bienfaits physiques et mentaux. Pratiqué en équipe ou en solo, en salle ou en extérieur, il permet une progression rapide avec des évaluations régulières. <br><br> Cette activité éducative développe des qualités d'attention, améliore les perceptions kinesthésiques et favorise la responsabilisation. Les éducateurs sportifs du centre peuvent co-animer des séances adaptées en toute sécurité, suscitant la curiosité des élèves et développant leur concentration et leur vigilance.<br><br> En somme, le tir à l'arc est un sport complet, bénéfique pour le corps et l'esprit.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomFour">
                                                    Quels sont les bienfaits du tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomFour" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Pratiqué régulièrement, le tir à l'arc peut améliorer la coordination, la concentration et la force musculaire. Il peut également aider à réduire le stress et à améliorer la posture. <br><br>
                                                        De plus, le tir à l'arc est une activité sociale qui peut favoriser la confiance en soi et la communication avec les autres. Il peut être pratiqué à tout âge et peut être adapté à différents niveaux de forme physique.
                                                        <br><br> Enfin, le tir à l'arc est un sport qui peut offrir des avantages supplémentaires tels que la réduction des risques de maladies cardiovasculaires et la promotion de la santé mentale en général.
                                                        <br><br> En conclusion, le tir à l'arc est un excellent moyen d'améliorer la santé physique et mentale tout en développant des compétences sociales.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <div itemscope itemtype="https://schema.org/FAQPage">
                                        <div itemprop="mainEntity" itemscope itemtype="https://schema.org/Question" class="card-header">
                                            <h2 class="mb-0" itemprop="name">
                                                <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseCustomSix">
                                                    Quels muscles fait travailler le tir à l'arc ?
                                                </button>
                                            </h2>
                                            <div id="collapseCustomSix" class="collapse" data-parent="#appia-accordion" itemprop="acceptedAnswer" itemscope itemtype="https://schema.org/Answer">
                                                <div class="card-body">
                                                    <p itemprop="text">Le tir à l'arc permet de développer la force musculaire du haut du corps, notamment les épaules, les bras, le dos et les abdominaux, tout en entretenant leur souplesse. <br><br> Cette pratique favorise également la posture et le contrôle de la respiration, ainsi que l'équilibre et l'adresse. Le mouvement sans à-coups, en étirement, propre au tir à l'arc renforce symétriquement les muscles des épaules et du dos, ce qui permet d'obtenir une excellente tenue du buste. <br><br> En s'ancrant solidement au sol, le pratiquant sollicite principalement les muscles du dos, des épaules, de la ceinture abdominale et des bras, ainsi que les tendons des doigts, des bras et des épaules. Des séances régulières de tir à l'arc peuvent conduire à un renforcement musculaire notable du haut du corps en moins d'un an.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
