<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between py-4 p-sm-4">
        <a href="/">
            <img src="assets/img/logo.svg" alt="">
        </a>
        <!-- ***** Newsletter Area Start ***** -->
        <div class="newsletter-area mb-5 mb-lg-0">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-7 mx-auto">
                        <div class="newsletter-content text-center">
                            <img class="pt-4" src="assets/img/newsletter_thumb.png" alt="">
                            <h2 class="pt-5 fw-4">Subscribe to get updates!</h2>
                            <p class="my-4">By subscribing you will get newsleter, promotions adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form d-flex align-items-center mx-auto">
                                <input type="email" class="form-control" placeholder="info@yourmail.com">
                                <button type="submit" class="btn btn-bordered">Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Newsletter Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>