
<head>
    <meta charset="UTF-8">
    <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
    <meta name=ProgId content=Word.Document>
    <meta name=Generator content="Microsoft Word 15">
    <meta name=Originator content="Microsoft Word 15">
    <link rel=colorSchemeMapping
          href="Conditions%20Générales%20d_fichiers/colorschememapping.xml">
    <style>
        <!--
        /* Font Definitions */
        @font-face
        {font-family:Wingdings;
            panose-1:5 0 0 0 0 0 0 0 0 0;
            mso-font-charset:2;
            mso-generic-font-family:auto;
            mso-font-pitch:variable;
            mso-font-signature:0 268435456 0 0 -2147483648 0;}
        @font-face
        {font-family:"MS Mincho";
            panose-1:2 2 6 9 4 2 5 8 3 4;
            mso-font-alt:"\FF2D\FF33 \660E\671D";
            mso-font-charset:128;
            mso-generic-font-family:modern;
            mso-font-pitch:fixed;
            mso-font-signature:-536870145 1791491579 134217746 0 131231 0;}
        @font-face
        {font-family:"Cambria Math";
            panose-1:2 4 5 3 5 4 6 3 2 4;
            mso-font-charset:0;
            mso-generic-font-family:roman;
            mso-font-pitch:variable;
            mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
        @font-face
        {font-family:Calibri;
            panose-1:2 15 5 2 2 2 4 3 2 4;
            mso-font-charset:0;
            mso-generic-font-family:swiss;
            mso-font-pitch:variable;
            mso-font-signature:-469750017 -1073732485 9 0 511 0;}
        @font-face
        {font-family:Times;
            panose-1:2 2 6 3 5 4 5 2 3 4;
            mso-font-charset:0;
            mso-generic-font-family:roman;
            mso-font-pitch:variable;
            mso-font-signature:-536858881 -1073711013 9 0 511 0;}
        @font-face
        {font-family:"PT Sans";
            mso-font-charset:0;
            mso-generic-font-family:swiss;
            mso-font-pitch:variable;
            mso-font-signature:-1610611985 1342185547 0 0 151 0;}
        @font-face
        {font-family:"Amasis MT Pro";
            mso-font-charset:0;
            mso-generic-font-family:roman;
            mso-font-pitch:variable;
            mso-font-signature:-1610612561 1073750107 0 0 147 0;}
        @font-face
        {font-family:Poppins;
            panose-1:0 0 5 0 0 0 0 0 0 0;
            mso-font-charset:0;
            mso-generic-font-family:auto;
            mso-font-pitch:variable;
            mso-font-signature:32775 0 0 0 147 0;}
        @font-face
        {font-family:"\@MS Mincho";
            panose-1:2 2 6 9 4 2 5 8 3 4;
            mso-font-charset:128;
            mso-generic-font-family:modern;
            mso-font-pitch:fixed;
            mso-font-signature:-536870145 1791491579 134217746 0 131231 0;}
        /* Style Definitions */
        p.MsoNormal, li.MsoNormal, div.MsoNormal
        {mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-parent:"";
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:0cm;
            line-height:115%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
        {mso-style-noshow:yes;
            mso-style-priority:99;
            mso-style-link:"Commentaire Car";
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            font-size:12.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        p.MsoFooter, li.MsoFooter, div.MsoFooter
        {mso-style-priority:99;
            mso-style-link:"Pied de page Car";
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:0cm;
            line-height:115%;
            mso-pagination:widow-orphan;
            tab-stops:center 8.0cm right 16.0cm;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        span.MsoCommentReference
        {mso-style-noshow:yes;
            mso-style-priority:99;
            mso-ansi-font-size:9.0pt;
            mso-bidi-font-size:9.0pt;}
        a:link, span.MsoHyperlink
        {mso-style-priority:99;
            color:#0563C1;
            mso-themecolor:hyperlink;
            text-decoration:underline;
            text-underline:single;}
        a:visited, span.MsoHyperlinkFollowed
        {mso-style-noshow:yes;
            mso-style-priority:99;
            color:#954F72;
            mso-themecolor:followedhyperlink;
            text-decoration:underline;
            text-underline:single;}
        p
        {mso-style-priority:99;
            mso-margin-top-alt:auto;
            margin-right:0cm;
            mso-margin-bottom-alt:auto;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            font-size:12.0pt;
            font-family:"Times New Roman",serif;
            mso-fareast-font-family:"Times New Roman";}
        p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
        {mso-style-noshow:yes;
            mso-style-priority:99;
            mso-style-parent:Commentaire;
            mso-style-link:"Objet du commentaire Car";
            mso-style-next:Commentaire;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;
            font-weight:bold;}
        p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
        {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:115%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
        {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-type:export-only;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:0cm;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:115%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
        {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-type:export-only;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:0cm;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:115%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
        {mso-style-priority:34;
            mso-style-unhide:no;
            mso-style-qformat:yes;
            mso-style-type:export-only;
            margin-top:0cm;
            margin-right:0cm;
            margin-bottom:10.0pt;
            margin-left:36.0pt;
            mso-add-space:auto;
            line-height:115%;
            mso-pagination:widow-orphan;
            font-size:11.0pt;
            font-family:"Calibri",sans-serif;
            mso-fareast-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-fareast-language:EN-US;}
        p.msonormal0, li.msonormal0, div.msonormal0
        {mso-style-name:msonormal;
            mso-style-priority:99;
            mso-style-unhide:no;
            mso-margin-top-alt:auto;
            margin-right:0cm;
            mso-margin-bottom-alt:auto;
            margin-left:0cm;
            mso-pagination:widow-orphan;
            font-size:12.0pt;
            font-family:"Times New Roman",serif;
            mso-fareast-font-family:"Times New Roman";}
        span.CommentaireCar
        {mso-style-name:"Commentaire Car";
            mso-style-noshow:yes;
            mso-style-priority:99;
            mso-style-unhide:no;
            mso-style-locked:yes;
            mso-style-link:Commentaire;
            mso-ansi-font-size:12.0pt;
            mso-bidi-font-size:12.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-fareast-font-family:Calibri;
            mso-hansi-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-font-kerning:0pt;
            mso-ligatures:none;}
        span.PieddepageCar
        {mso-style-name:"Pied de page Car";
            mso-style-priority:99;
            mso-style-unhide:no;
            mso-style-locked:yes;
            mso-style-link:"Pied de page";
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-fareast-font-family:Calibri;
            mso-hansi-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-font-kerning:0pt;
            mso-ligatures:none;}
        span.ObjetducommentaireCar
        {mso-style-name:"Objet du commentaire Car";
            mso-style-noshow:yes;
            mso-style-priority:99;
            mso-style-unhide:no;
            mso-style-locked:yes;
            mso-style-parent:"Commentaire Car";
            mso-style-link:"Objet du commentaire";
            mso-ansi-font-size:12.0pt;
            mso-bidi-font-size:12.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-fareast-font-family:Calibri;
            mso-hansi-font-family:Calibri;
            mso-bidi-font-family:"Times New Roman";
            mso-font-kerning:0pt;
            mso-ligatures:none;
            font-weight:bold;}
        span.SpellE
        {mso-style-name:"";
            mso-spl-e:yes;}
        span.GramE
        {mso-style-name:"";
            mso-gram-e:yes;}
        .MsoChpDefault
        {mso-style-type:export-only;
            mso-default-props:yes;
            font-size:10.0pt;
            mso-ansi-font-size:10.0pt;
            mso-bidi-font-size:10.0pt;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-fareast-font-family:Calibri;
            mso-fareast-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-font-kerning:0pt;
            mso-ligatures:none;
            mso-fareast-language:EN-US;}
    </style>
    <title>CGU CapTarget France</title>
    <!--[if gte mso 10]>
    <style>
        /* Style Definitions */
        table.MsoNormalTable
        {mso-style-name:"Tableau Normal";
            mso-tstyle-rowband-size:0;
            mso-tstyle-colband-size:0;
            mso-style-noshow:yes;
            mso-style-priority:99;
            mso-style-parent:"";
            mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
            mso-para-margin:0cm;
            mso-pagination:widow-orphan;
            font-size:10.0pt;
            font-family:"Calibri",sans-serif;
            mso-ascii-font-family:Calibri;
            mso-ascii-theme-font:minor-latin;
            mso-hansi-font-family:Calibri;
            mso-hansi-theme-font:minor-latin;
            mso-bidi-font-family:"Times New Roman";
            mso-bidi-theme-font:minor-bidi;
            mso-fareast-language:EN-US;}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
    <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
    <o:shapelayout v:ext="edit">
        <o:idmap v:ext="edit" data="1"/>
    </o:shapelayout></xml><![endif]-->
</head>

<div class="container">
    <div lang=FR link="#0563C1" vlink="#954F72" style='tab-interval:35.4pt;
word-wrap:break-word'>

    <div class=WordSection1>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Conditions Générales d’Utilisation et d’Abonnement</span></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>CapTarget Archery est une
application web et mobile (l<span class=GramE>’«</span>&nbsp;<b
                    style='mso-bidi-font-weight:normal'>Application</b>&nbsp;») exploitée par Appli
<span class=SpellE>Magine</span>, société par actions simplifiée au capital de 100
euros, dont le siège social est situé 5, jardin Jean de Ockeghem à Tours
(37000), immatriculée au registre du commerce et des sociétés de Tours sous le
numéro d’identification 948 141 593 et dont le numéro de TVA intracommunautaire
est FR89948141593 (la «&nbsp;<b style='mso-bidi-font-weight:normal'>Société&nbsp;</b>»).
Ladite Application est dédiée, entre autres, au suivi de la performance de ses
utilisateurs dans leur pratique au tir à l’arc.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’objet des présentes
Conditions Générales d’Utilisation (les «&nbsp;<b style='mso-bidi-font-weight:
normal'>Conditions </b>» ou prises dans leur ensemble, le «&nbsp;<b
                    style='mso-bidi-font-weight:normal'>Contrat</b>&nbsp;») est de définir les
termes et les conditions régissant les relations entre les Utilisateurs et la
Société. Ces Conditions constituent un accord contractuel pour une durée
indéterminée à compter de l’acceptation par l’Utilisateur du présent Contrat. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Utilisateur s’engage lors
de chacune de ses visites de l’Application à respecter l’ensemble des présentes
Conditions sans aucune réserve. En conséquence, l’Utilisateur reconnait avoir
pris connaissance des Conditions et accepte d’être lié par les présentes
dispositions. Si l’Utilisateur accède à l’Application pour le compte d’une
entreprise ou de toute autre entité juridique, il est néanmoins personnellement
lié par le présent Contrat. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 1 - Définitions et règles d’interprétation</span></b></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:10.0pt;mso-margin-top-alt:0cm;mso-add-space:auto;
text-align:justify'><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>1.1
Définition des principaux termes</span></i></b></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Sauf
s’ils sont définis dans d’autres sections du présent Contrat, les termes et
expressions commençant par <span class=GramE>une majuscule utilisés</span>
ci-après ont la signification suivante&nbsp;:</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«<b style='mso-bidi-font-weight:normal'>&nbsp;Abonnement&nbsp;</b>»
a le sens qui lui est attribué à l’article 3 ;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«<b style='mso-bidi-font-weight:normal'>&nbsp;Application&nbsp;</b>»
a le sens qui lui est attribué dans l’exposé préalable&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Archer&nbsp;</b>»
désigne tout Utilisateur titulaire d’un compte «&nbsp;Archer&nbsp;» lequel est
réservé aux personnes physiques pratiquant le tir à l’arc&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Coach</b>&nbsp;»
désigne tout Utilisateur titulaire d’un compte «&nbsp;Coach&nbsp;» lequel est
réservé aux entraineurs de tir à l’arc ayant souscrit à un Abonnement Coach ;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Contrat</b>&nbsp;»
a le sens qui lui est attribué dans l’exposé préalable&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Groupe&nbsp;</b>»
désigne un groupe d’Utilisateurs créé sur l’Application par un
Utilisateur&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l1 level1 lfo2'><span style='font-size:
9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Service(s)&nbsp;</b>»
désigne le ou les services proposés sur l’Application tels que visés à
l’article 3&nbsp;;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;mso-add-space:auto;text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Société</b>&nbsp;»
a le sens qui lui est attribué dans l’exposé préalable&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:36.0pt;mso-add-space:auto;text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>«&nbsp;<b style='mso-bidi-font-weight:normal'>Utilisateur&nbsp;</b>»
désigne toute personne physique ou morale utilisant l’Application. </span></p>

        <p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:10.0pt;mso-margin-top-alt:0cm;mso-add-space:auto;
text-align:justify'><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>1.2
Règles d’interprétation</span></i></b></p>

        <p class=MsoNormal><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>Les règles exposées ci-après s’appliquent à
l’interprétation du présent Contrat&nbsp;:</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>les</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
titres des articles et des annexes sont inclus par commodité et n’affectent en
aucun cas l’interprétation de l’une quelconque des stipulations du présent
Contrat&nbsp;;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>l’usage</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> des
expressions «&nbsp;y compris&nbsp;», «&nbsp;en particulier&nbsp;», ou
«&nbsp;notamment&nbsp;» implique que l’énumération qui les suit n’est pas
limitative ou exhaustive&nbsp;;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>le</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
terme «&nbsp;ou&nbsp;» n’est pas exclusif&nbsp;;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
définition attribuée à un terme singulier s’applique également à ce terme
lorsqu’il est employé au pluriel et vice versa. Il en est de même concernant
l’utilisation du genre masculin ou féminin&nbsp;;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>le</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
décompte des délais exprimés en jours, en mois ou en années doit être fait
conformément aux dispositions des articles 640 à 642 du code de procédure
civile&nbsp;;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>toute</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
référence à une partie inclut une référence à ses héritiers, successeurs et
ayants droit&nbsp;; et</span></p>

        <p class=MsoNormal style='margin-bottom:0cm'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:64.35pt;text-indent:-18.0pt;mso-list:l0 level1 lfo6'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Amasis MT Pro";mso-bidi-font-family:"Amasis MT Pro"'><span
                style='mso-list:Ignore'>(g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>toute</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
référence à un document s’entend de ce document tel qu’il pourrait être modifié
ou remplacé (autrement qu’en violation des stipulations du présent Contrat).</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></i></b></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 2 - Eligibilité aux Services</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>2.1 Eligibilité au Service<span
                style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Pour être éligible au
Service, l’Utilisateur doit être une personne physique, ayant atteint l’âge de
18 ans et disposant de sa pleine </span><span style='font-size:9.5pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>capacité juridique.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
mso-fareast-language:FR'>La situation médicale et la constitution physique de
l’Utilisateur devront lui permettent, en outre, de pratiquer le sport et
notamment d’utiliser les Services.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
mso-fareast-language:FR'>Dans l’hypothèse où sa situation médicale ou sa
constitution physique ne lui permettraient plus de pratiquer le sport et/ou
d’utiliser les Services, l’Utilisateur devra se conformer strictement à
l’ensemble des recommandations médicales qui lui auront été adressées le cas
échéant et prendre toute mesure afin de ne pas nuire à sa santé ainsi qu’à
celle des tiers.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-theme-font:minor-fareast;
mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>2.2
</span></i></b><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Création
d’un compte</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La création d’un compte
membre sur l’Application est un préalable obligatoire à l’utilisation des
Services. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La création d’un compte
membre suppose de compléter le formulaire d’inscription, disponible sur
l’Application, en fournissant des informations exactes, à jour et complètes.
Ces informations devront faire, par la suite, l’objet de mises à jour
régulières, par l’Utilisateur afin de préserver leur exactitude.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Utilisateur devra choisir
un nom d’utilisateur constitué d’un mail valide et un mot de passe et fournir
obligatoirement les informations suivantes&nbsp;:</span><span style='font-family:
"Amasis MT Pro",serif'></span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>Nom et prénom&nbsp;;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>Catégorie d’âge&nbsp;et de sexe&nbsp;;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>Arme et discipline.</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify'><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les codes d’accès au compte
d’Utilisateur sont strictement confidentiels. En cas d’utilisation non
autorisée de son compte ou de toute atteinte à la confidentialité et à la
sécurité de ses moyens d’identification, l’Utilisateur devra, sans délais,
informer la Société.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Chaque Utilisateur s’engage
à ne créer qu’un seul compte correspondant à son profil. </span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>2.3 Typologie de comptes membres</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Dans l’hypothèse où un compte membre «&nbsp;Archer » serait créé par un
Utilisateur, les règles suivantes sont appliquées&nbsp;:</span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-left:2.0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>les</span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>
informations de l’Archer relatives à son nom, prénom, club, pays, catégorie
d'âge de sexe et arme principale et avatar sont visibles par tout Utilisateur.</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>En outre,
l’Utilisateur peut choisir de rendre visibles ses statistiques principales de <span
                    class=GramE>compétitions ,</span> d'entraînements et d'utilisation des
exercices de l'Application.</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>En cas
d’ajout d’ami, les informations suivantes pourront être visibles par ce
dernier&nbsp;: le volume de la semaine, les scores réalisés et les statistiques
de tir.</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:2.0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:2.0cm;mso-add-space:auto;text-align:justify;
text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>les</span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>
informations de l’Archer relatives à l’ensemble des statistiques, des séances,
des exercices, des bilans, des objectifs et des classements sont visibles par
les Coachs dudit Utilisateur. </span></p>

        <p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:2.0cm;mso-add-space:auto;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;mso-themecolor:text1'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Dans l’hypothèse où un compte membre «&nbsp;Coach » serait créé par un
Utilisateur, les règles suivantes sont appliquées&nbsp;:</span></p>

        <p class=MsoListParagraph style='margin-left:2.0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>les</span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'> informations
du Coach relatives à son nom, prénom et avatar sont visibles par tout
Utilisateur qui lui sont ou non rattachés.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les informations
communiquées par l’Utilisateur sur l’Application relèvent de sa seule
responsabilité, il appartient donc à ce dernier d’en vérifier l’exactitude. Il
s’engage, en tout état de cause, à fournir des informations exactes et
actualisées. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société se réserve, en
tout état de cause, le droit de refuser ou supprimer discrétionnairement toutes
informations qui lui paraitrait inappropriée ou contraire à l’esprit de
l’Application.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 3 - Les Services</span></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Sur l’Application,
plusieurs Services sont proposés aux Utilisateurs. L’accès à certains Services
est soumis à la souscription à un abonnement dont les conditions sont posées à
l’article 4 (<span class=GramE>l’ «</span>&nbsp;<b style='mso-bidi-font-weight:
normal'>Abonnement</b>&nbsp;»).</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>3.1 Services Archers hors
Abonnement<span style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Application permet aux
Archers d’accéder gratuitement au service de suivi de leur performance au tir à
l’arc comprenant notamment&nbsp;:</span></p>

        <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
saisie des données d’entrainement ;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
réalisation d’exercices suggérés par l’Application&nbsp;; </span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
gestion des séances d’entrainement et de compétition&nbsp;;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
gestion du matériel de tirs&nbsp;;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> g</span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-fareast-language:FR'>estion des objectifs&nbsp;</span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
FR'>;</span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'>l’accès</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-fareast-language:FR'> aux classements nationaux, au calendrier national et
aux statistiques issues de sa licence (uniquement pour les français)&nbsp;</span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
FR'>;</span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-fareast-language:FR'> création de groupes et de conversations entre
Archers&nbsp;;</span><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'>l’établissement</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-fareast-language:FR'> d’un carnet d'entrainement&nbsp;</span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
FR'>;</span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'>l’accès</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";color:black;background:white;
mso-fareast-language:FR'> aux données d'entraînements et compétitions de ses
amis</span><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
mso-list:l5 level1 lfo4'><span style='font-size:9.0pt;
line-height:115%;font-family:"Times",serif;mso-fareast-font-family:Times'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>la</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
création de Groupes&nbsp;; et</span></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>l’accès</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> à
un récapitulatif des performances et statistiques de l’Archer via les données
saisies par ce dernier sur l’Application.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>3.2. Services Archers
nécessitant l’Abonnement Archer<span style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>L’Abonnement
permet à ses souscripteurs d’accéder aux services suivants&nbsp;:</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>l’accès</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> à
des exercices supplémentaires&nbsp;;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>l’accès</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> à
des statistiques plus détaillés&nbsp;; </span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;font-family:"Times",serif;mso-fareast-font-family:Times;
mso-fareast-language:FR'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-fareast-language:FR'>la</span></span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'>
réalisation des exercices à plusieurs avec un classement en temps réel&nbsp;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;mso-fareast-language:FR'>;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:FR'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;line-height:normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;font-family:"Times",serif;mso-fareast-font-family:Times;
mso-fareast-language:FR'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-fareast-language:FR'>l’accès</span></span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'> à la
version web avancée permettant de percevoir plus de statistiques&nbsp;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;mso-fareast-language:FR'>;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:FR'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;line-height:normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;font-family:"Times",serif;mso-fareast-font-family:Times;
mso-fareast-language:FR'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-fareast-language:FR'>l’établissement</span></span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'> d’un
bilan de compétition et d'entraînement avancé&nbsp;;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:FR'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;line-height:normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;font-family:"Times",serif;mso-fareast-font-family:Times;
mso-fareast-language:FR'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-fareast-language:FR'>la</span></span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'> possibilité
de passer les publicités sans délai d’attente&nbsp;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;mso-fareast-language:FR'>;</span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";mso-fareast-language:FR'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;line-height:normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;font-family:"Times",serif;mso-fareast-font-family:Times;
mso-fareast-language:FR'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;background:white;mso-fareast-language:FR'>l’élaboration</span></span><span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:
"Times New Roman";color:black;background:white;mso-fareast-language:FR'> de
matchs par équipe&nbsp;;</span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;line-height:normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;font-family:"Times",serif;mso-fareast-font-family:Times;
mso-fareast-language:FR'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:FR'>le</span></span><span style='font-size:
9.0pt;font-family:"Amasis MT Pro",serif;mso-fareast-font-family:"Times New Roman";
color:black;mso-fareast-language:FR'> t<span style='background:white'>ri de
flèches&nbsp;</span>;</span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'></span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;line-height:normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"Times New Roman";mso-fareast-language:FR'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l5 level1 lfo4'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times'><span style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>l’invitation</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> d’amis
ou d’Utilisateurs à partager les exercices. L’Utilisateur renseigne alors le
formulaire de création prévu à cet effet sur l’Application. Une fois invités,
lesdits tiers doivent créer un compte Archer sur l’Application.</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify'><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>3.3. Services Coachs<span
                style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Application permet aux
Coachs d’accéder à divers services sous réserve de souscrire à un
«&nbsp;Abonnement Coach&nbsp;».</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Abonnement Coach permet
de suivre les performances d’Archers sur l’Application. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Pour ce faire, le Coach a
la possibilité d’inviter toute personne physique à rejoindre l’Application en
qualité d’Archer ou tout Archer déjà Utilisateur via leur nom et prénom ou
adresse mail. En cas d’acceptation de cette invitation, le Coach aura accès à
l’ensemble des informations suivantes de l’Archer&nbsp;: l’ensemble des
statistiques, des séances, des exercices, des bilans, des objectifs et des
classements </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Le Coach est informé que
tout Archer peut cesser, sur sa propre initiative ou sur l’initiative du Coach,
d’être affilié au Coach. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Le Coach ne saurait
monnayer l’accès à l’Application sans être autorisée préalablement et
expressément par la <span class=GramE>Société..</span></span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>3.3 Echange de
messages&nbsp;- Création de Groupes<span style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Sur l’Application, un
service de messagerie instantanée est mis à disposition des Utilisateurs afin
de leur permettre d’échanger sur leurs performances à l’exclusion de toute
autre finalité. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les Utilisateurs sont
informés que la Société n’a pas accès au contenu de cette messagerie et n’opère
donc aucun contrôle des messages échangés. Afin d’assurer un minimum de
cordialité des échanges, un module anti-insultes supprime automatiquement les
éventuelles insultes répertoriées comme telles par le module. La Société ne
garantit pas, pour autant, l’efficacité dudit module.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Sur l’Application, les
Utilisateurs ont la possibilité de créer des Groupes avec des Utilisateurs
amis. Pour ce faire, l’Utilisateur renseigne alors le formulaire de création
prévu à cet effet sur l’Application. Tout participant du Groupe doit avoir accepter
la demande d’ajout de l’Utilisateur ayant créé le Groupe. L’objet et la
finalité du Groupe doivent impérativement être conformes à l’esprit de
l’Application, à savoir l’amélioration des performances au tir à l’arc à
l’exclusion de toute autre finalité. L’Utilisateur s’interdit de constituer des
Groupes de manière intempestive.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>La
Société ne saurait être responsable au titre des échanges sur l’Application.
Chacun des Utilisateurs </span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>s’engage à ne pas saisir un contenu
susceptible de porter atteinte à l’ordre public ou aux bonnes mœurs, de
provoquer des protestations de tiers, ou encore de contrevenir aux dispositions
légales en vigueur. <span style='mso-bidi-font-weight:bold'>La Société se
réserve le droit de procéder à la fermeture sans information préalable de tout
Groupe. </span></span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 4 - Conditions de souscription à l’Abonnement
Archer - Coach</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>4.1 Durée de l’Abonnement</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Sur l’Application,
plusieurs formules d’abonnement sont proposées (le ou les «&nbsp;<b
                    style='mso-bidi-font-weight:normal'>Abonnement(s)&nbsp;</b>»)&nbsp;:</span></p>

        <p class=MsoNormal style='text-align:justify'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>(i)
Abonnement mensuel sans durée d’engagement résiliable à tout moment&nbsp;</span></i></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>En souscrivant à cette
formule d’abonnement, l’Abonné ne s’engage pas sur la durée de sa souscription
laquelle ne pouvant être toutefois inférieure à une durée d’un mois. Il dispose
de la faculté de résilier à tout moment et discrétionnairement l’Abonnement
sous réserve de notifier son intention de résilier à la Société conformément à
la procédure prévue sur l’Application. Une fois la procédure de résiliation
suivie, l’Abonnement sera résilié automatiquement au terme de la période
d’Abonnement déjà payé par l’Abonné.</span></p>

        <p class=MsoNormal style='text-align:justify'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>(ii)</span></i><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'> <i
                style='mso-bidi-font-style:normal'>Abonnement pour une durée de 12 mois</i></span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>En souscrivant à cette
formule d’abonnement, l’Abonné s’engage sur une durée de souscription de douze
mois courant à compter de la date de souscription à l’Abonnement. Cet
Abonnement est renouvelable tacitement pour la même durée (1 an) sans
limitation à moins que l’une des deux Parties notifie son intention via la
procédure prévue à cet effet sur l’Application, dans un délai <span
                    class=GramE>de un</span> (1) mois avant la fin de l’Abonnement, son intention
de ne pas voir l’Abonnement renouvelé tacitement.</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"MS Mincho";mso-bidi-font-family:Arial;mso-fareast-language:
FR'>Le choix et la souscription à un Abonnement relevant de la seule
responsabilité de l’Utilisateur, il appartient à ce dernier de vérifier
l’exactitude de sa souscription et de signaler immédiatement toute erreur. </span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"MS Mincho";mso-bidi-font-family:Arial;mso-fareast-language:
FR'>La souscription ne sera considérée comme définitive qu’après l’envoi à
l’Utilisateur de la confirmation de l’acceptation de sa souscription par la
Société par courrier électronique. </span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify;line-height:
normal'><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-fareast-font-family:"MS Mincho";mso-bidi-font-family:Arial;mso-fareast-language:
FR'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>4.2 Tarifs</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Abonnement
est proposé au tarif en vigueur figurant sur l’Application lors de la
souscription. Les prix sont exprimés en euros et toutes taxes comprises.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Les
tarifs tiennent compte d’éventuelles réductions qui seraient consenties par la
Société sur l’Application. Ils sont fermes et non révisables pendant leur
période de validité telle qu’indiquée sur l’Application, la Société se
réservant le droit, <span class=GramE>hors</span> cette période de validité, de
modifier discrétionnairement les tarifs à tout moment. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Toute
sous-utilisation ou non-utilisation de l’Abonnement ne saurait justifier une
quelconque demande de remboursement.</span></p>

        <p class=MsoNormal style='text-align:justify'><b><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.3
Modalités de paiement</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>En
cas de souscription à un Abonnement mensuel, le montant mensuel de l’Abonnement
doit être versé par l’Utilisateur mensuellement par prélèvement bancaire
automatique sur le compte bancaire qu’il aura renseigné lors de la
souscription. La première mensualité sera payée et débitée immédiatement lors
de la souscription.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>En
cas de souscription à un Abonnement annuel, le montant annuel de l’Abonnement
doit être payé immédiatement lors de la souscription.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Les
paiements effectués par l’Utilisateur ne seront considérés comme définitifs
qu’après encaissement effectif par la Société des sommes dues. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Une
facture est établie par la Société et disponible sur l’application.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Sans
préjudice d’éventuels dommages et intérêts, le défaut de paiement par
l’Utilisateur d’une seule échéance à la date prévue entraîne de plein droit
l’application d’un intérêt de retard égal à trois fois le taux d’intérêt légal
et à compter du premier jour de retard auquel s’ajoutent les frais bancaires et
de gestion supplémentaires. L’Utilisateur cessera en outre d’accéder aux
services de l’Abonnement.</span></p>

        <p class=MsoNormal style='text-align:justify'><b><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Utilisateur est informé
qu’en cas de souscription à l’Abonnement via&nbsp;:</span></b></p>

        <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt;
mso-list:l2 level1 lfo8'><span style='font-size:9.0pt;
line-height:115%;font-family:"PT Sans",sans-serif;mso-fareast-font-family:"PT Sans";
mso-bidi-font-family:"PT Sans";mso-bidi-font-weight:bold'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><b><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>le</span></b></span><b><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
module de paiement de Stripe, l’Abonnement est accessible via la plateforme web
et mobile avec les fonctions premium&nbsp;; </span></b></p>

        <p class=MsoListParagraphCxSpMiddle style='text-align:justify'><b><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></b></p>

        <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt;
mso-list:l2 level1 lfo8'><span style='font-size:9.0pt;
line-height:115%;font-family:"PT Sans",sans-serif;mso-fareast-font-family:"PT Sans";
mso-bidi-font-family:"PT Sans";mso-bidi-font-weight:bold'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><b><span style='font-size:
9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>le</span></b></span><b><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
module de paiement des Stores (Android ou <span class=SpellE>iOs</span>),
l’Abonnement est accessible (i) via la plateforme mobile&nbsp;avec les
fonctions premium et (ii) via la plateforme web sans accès aux fonctions
premium. L’Utilisateur ne saurait alors réclamer toute diminution du prix de
l’Abonnement.</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.4
Garanties</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Utilisateur
est informé, qu’en application de l’article 15 de la loi 2004-575 du 21 juin 2004,
la Société est responsable de plein droit à son égard de la bonne exécution des
obligations résultant des présentes, que ces obligations soient à exécuter par
lui-même ou par d'autres prestataires de services, sans préjudice de son droit
de recours contre ceux-ci.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Toutefois,
la Société peut s'exonérer de tout ou partie de sa responsabilité en apportant
la preuve que l'inexécution ou la mauvaise exécution des présentes est
imputable, soit à l’Utilisateur, soit au fait, imprévisible et insurmontable,
d'un tiers étranger à la fourniture des prestations prévues par les présentes,
soit à un cas de force majeure.</span></p>

        <p class=MsoNormal style='text-align:justify'><b><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.5
Droit de rétractation</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Conformément aux
dispositions légales en vigueur, lorsque l’Utilisateur est un consommateur au
sens du code de la consommation, ce dernier dispose d’un délai de rétractation
de 14 jours à compter de la souscription à un Abonnement. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Toutefois, en acceptant les
présentes Conditions, l’Utilisateur demande expressément à la Société
l´exécution immédiate des dispositions prévues par les présentes avant
l´expiration du délai de rétractation et renonce expressément à son droit de
rétractation. En conséquence, en application de l’article L. 221-28 13°du code
de la consommation, l’Abonné ne pourra rétracter son engagement souscrit sur
l’Application. </span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>4.6 Information
précontractuelle - Acceptation de l’Utilisateur</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Utilisateur reconnait
avoir eu communication, préalablement à la souscription, d'une manière lisible
et compréhensible, des présentes Conditions et de toutes les informations et
renseignements visés aux articles L111-1 à L111-8 du code de la consommation,
et en particulier :</span></p>

        <p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-indent:
-7.1pt;tab-stops:35.45pt 42.55pt'><span style='font-size:9.0pt;line-height:
115%;font-family:"Amasis MT Pro",serif'>-<span style='mso-tab-count:1'>  </span>les
caractéristiques essentielles de l’Abonnement souscrit, compte tenu du support
de communication utilisé et des services concernés ;</span></p>

        <p class=MsoNormal style='margin-left:1.0cm;text-align:justify;tab-stops:35.45pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-<span
                style='mso-tab-count:1'>  </span>le prix de l’Abonnement et des frais annexes ;
</span></p>

        <p class=MsoNormal style='margin-left:35.45pt;text-align:justify;text-indent:
-7.1pt;tab-stops:35.45pt'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>-<span style='mso-tab-count:1'>  </span>les
informations relatives à l'identité de la Société, à ses coordonnées postales,
téléphoniques et électroniques, et à ses activités, si elles ne ressortent pas
du contexte ;</span></p>

        <p class=MsoNormal style='margin-left:1.0cm;text-align:justify;tab-stops:35.45pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-<span
                style='mso-tab-count:1'>  </span>les informations relatives aux garanties
légales et contractuelles et à leurs modalités de mise en œuvre ; </span></p>

        <p class=MsoNormal style='margin-left:1.0cm;text-align:justify;tab-stops:35.45pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-<span
                style='mso-tab-count:1'>  </span>les fonctionnalités du contenu numérique et,
le cas échéant, à son interopérabilité ; </span></p>

        <p class=MsoNormal style='margin-left:1.0cm;text-align:justify;tab-stops:35.45pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-<span
                style='mso-tab-count:1'>  </span>la possibilité de recourir à une médiation
conventionnelle en cas de litige ; </span></p>

        <p class=MsoNormal style='margin-left:1.0cm;text-align:justify;tab-stops:35.45pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>-<span
                style='mso-tab-count:1'>  </span>les informations relatives au droit de
rétractation.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Le fait de souscrire sur
l’Application emporte adhésion et acceptation pleine et entière des présentes
Conditions, ce qui est expressément reconnu par l’Utilisateur, qui renonce,
notamment, à se prévaloir de tout document contradictoire, qui serait inopposable
à la Société.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 5 - Utilisation des services de l’Application</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>5.1
</span></i></b><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Droit
d’accès à l’Application<span style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société, selon les
présentes Conditions, accorde aux Utilisateurs un droit d’accès limité
révocable, non exclusif, non cessible aux services applicatifs à titre
strictement personnel. Toute utilisation contraire de l’Application à sa
finalité est strictement interdite et constitue un manquement aux présentes
dispositions. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’utilisation de
l’Application requiert une connexion internet. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Afin de garantir un bon
fonctionnement de l’Application, il est précisé celle-ci est optimisée
pour&nbsp;:</span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-left:38.35pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l3 level1 lfo10'><span
                style='font-size:9.0pt;line-height:115%;font-family:"PT Sans",sans-serif;
mso-fareast-font-family:"PT Sans";mso-bidi-font-family:"PT Sans"'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>les</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
dernières versions des navigateurs Chrome et Firefox&nbsp;; et</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:38.35pt;mso-add-space:
auto;text-align:justify'><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-left:38.35pt;mso-add-space:
auto;text-align:justify;text-indent:-18.0pt;mso-list:l3 level1 lfo10'><span
                style='font-size:9.0pt;line-height:115%;font-family:"PT Sans",sans-serif;
mso-fareast-font-family:"PT Sans";mso-bidi-font-family:"PT Sans"'><span
                style='mso-list:Ignore'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>les</span></span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>
dernières versions de Android ou iOS mobile.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Tous matériels et logiciels
nécessaires à l’accès à l’Application et à l’utilisation du Service restent
exclusivement à la charge de l’Utilisateur. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société se réserve le
droit de suspendre, modifier, remplacer, refuser un accès ou supprimer
discrétionnairement des comptes d’Utilisateurs. </span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>5.2
</span></i></b><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Obligations
des Utilisateurs </span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les Utilisateurs
s’interdisent&nbsp;:</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>1.
<span style='mso-tab-count:1'>    </span>de transmettre, publier, distribuer,
enregistrer ou détruire tout matériel, en particulier les contenus de
l’Application, en violation des lois ou règlementations en vigueur concernant
la collecte, le traitement ou le transfert d'informations personnelles ;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>2. <span
                style='mso-tab-count:1'>    </span>de créer des profils fictifs&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>3.
<span style='mso-tab-count:1'>    </span>de fournir des informations inexactes
dans le formulaire ou de ne pas les mettre à jour régulièrement&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>4.
<span style='mso-tab-count:1'>    </span>de diffuser des données, informations,
ou contenus à caractère diffamatoire, injurieux, obscène, offensant, violent ou
incitant à la violence, ou à caractère politique, raciste ou xénophobe et de
manière générale tout contenu qui serait contraire aux lois et règlements en
vigueur ou aux bonnes mœurs&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>5.
<span style='mso-tab-count:1'>    </span>de référencer ou créer des liens vers
tout contenu ou information disponible depuis l’Application, sauf accord
exprès, écrit et préalable de la Société ;</span></p>

        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>6.<span
                style='mso-tab-count:1'>     </span> d’obtenir d’autres Utilisateurs des mots
de passe ou des données personnelles d’identification&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>7.
<span style='mso-tab-count:1'>    </span>d’utiliser des informations, contenus
ou toutes données présentes sur l’Application afin de proposer un service
considéré, à l’entière discrétion de la Société comme concurrentiel à
l’Application&nbsp;; </span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>8.
<span style='mso-tab-count:1'>    </span>de vendre, échanger ou monnayer des
informations, contenus ou toutes données présentes sur l’Application ou service
proposé sur l’Application, sans l’accord expresse et écrit de la Société&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>9.
<span style='mso-tab-count:1'>    </span>de pratiquer de l’ingénierie inversée
(Reverse Engineering), décompiler, désassembler, déchiffrer ou autrement tenter
d’obtenir le code source en relation avec toute propriété intellectuelle
sous-jacente utilisée pour fournir tout ou partie des services applicatifs&nbsp;;
</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>10.
<span style='mso-tab-count:1'>  </span>d’utiliser des logiciels ou appareils
manuels ou automates, robots de codage ou autres moyens pour accéder, explorer,
extraire ou indexer toute page de l’Application&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>11.
<span style='mso-tab-count:1'>  </span>de mettre en danger ou essayer de mettre
en danger la sécurité d'un site web ou une application de la Société. Cela
comprend les tentatives de contrôler, scanner ou tester la vulnérabilité d’un
système ou réseau ou de violer des mesures de sécurité ou d’authentification
sans une autorisation préalable expresse&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>12.
<span style='mso-tab-count:1'>  </span>de contrefaire ou d’utiliser les
produits, les logos, les marques ou tout autre élément protégé par les droits
de propriété intellectuelle de la Société&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>13.
<span style='mso-tab-count:1'>  </span>de simuler l’apparence ou le fonctionnement
des sites ou des applications de la Société, en procédant par exemple à un
effet miroir&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>14.
<span style='mso-tab-count:1'>  </span>de perturber ou troubler, directement ou
indirectement, l’Application ou les services applicatifs, ou imposer une charge
disproportionnée sur l’infrastructure de l’Application ou tenter de transmettre
ou d’activer des virus informatiques via ou sur le l’Application.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Il est rappelé que les
violations de la sécurité du système ou du réseau peuvent conduire à des
poursuites civiles et pénales. La Société vérifie l’absence de telle violation
et peut faire appel aux autorités judiciaires pour poursuivre, le cas échéant, des
Utilisateurs ayant participé à de telles violations.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les Utilisateurs s’engagent
à utiliser l’Application de manière loyale, conformément à sa finalité
professionnelle et aux dispositions légales, règlementaires, aux présentes
Conditions et aux usages en vigueur. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Il est précisé que la
Société se réserve le droit de publier ou de ne pas publier ou de supprimer en
tout ou partie un avis ou un commentaire d’un Utilisateur sur l’Application. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 6 - Utilisation de l’Application</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’ensemble du contenu de
l’Application, notamment les designs, textes, graphiques, images, vidéos,
informations, logos, icônes-boutons, logiciels, <span class=GramE>fichiers
audio</span> et autres appartient à la Société, laquelle est seule titulaire de
l’intégralité des droits de propriété intellectuelle y afférents. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Toute représentation et/ou
reproduction et/ou exploitation partielle ou totale des contenus et services
proposés par la Société, par quelque procédé que ce soit, sans l'autorisation
préalable et écrite de la Société, est strictement interdite et serait susceptible
de donner lieu à des poursuites judiciaires.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société concède à
l’Utilisateur un droit personnel, non exclusif, non cessible et non
transférable d’utilisation de l’Application et des services applicatif, pendant
toute la durée de leur relation contractuelle.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les présentes ne confèrent
aucun droit de propriété sur l’Application et ses services y compris en cas de
développement spécifique réalisé à la demande d’un Utilisateur. La mise à
disposition temporaire de l’Application et des services, ne saurait être analysée
comme la cession d’un quelconque droit de propriété intellectuelle à un
Utilisateur.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Le droit d’utilisation de
l’Application n’est concédé que dans le seul et unique but de permettre à
l’Utilisateur l’utilisation de l’Application et ses services, à l’exclusion de
toute autre finalité, conformément à leur destination.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 7 - Données à caractère personnel</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>7.1 Données collectées</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Afin de permettre à tout Utilisateur de profiter pleinement des Services
et fonctionnalités de l’Application, la Société collecte différentes données.
Il est rappelé qu’en s’inscrivant sur l’Application, l’Utilisateur accepte
expressément que ces données soient collectées et cela <span class=SpellE>quelque</span>
soit le pays à partir duquel il se connecte. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Ces données sont collectées à différents moments et selon diverses
méthodes&nbsp;:</span></p>

        <p class=MsoListParagraph style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l4 level1 lfo12;tab-stops:list 36.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>Lors de
toute inscription sur l’Application : e-mail, langue, pays, numéro de licence
(pour la France), nom et prénom, catégorie de sexe d'âge et d'arme, disciplines
réalisés, adresse <span class=GramE>IP;</span></span></p>

        <p class=MsoNormal style='margin-bottom:0cm;text-align:justify'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
color:black;mso-themecolor:text1'>&nbsp;</span></p>

        <p class=MsoListParagraph style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l4 level1 lfo12;tab-stops:list 36.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>Fichiers
journaux et adresse de protocole internet (IP)&nbsp;: La Société reçoit à
chaque connexion sur le site web de l’Application, le lien du site depuis
lequel l’Utilisateur et arrivé et celui vers lequel il se dirige lorsqu’il
quitte le site. La Société reçoit également l’adresse de protocole internet
(IP) de l’Utilisateur ou encore certaines informations relatives au système
d’exploitation de son ordinateur ou son navigateur internet&nbsp;(numéro et nom
d'identification des <span class=SpellE>devices</span>)&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>&nbsp;</span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify;text-indent:-18.0pt;mso-list:l4 level1 lfo12;tab-stops:list 36.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>Cookies&nbsp;:
La Société utilise des fichiers cookies qui peuvent être définis comme des
fichiers textes susceptibles d’être enregistrés dans un terminal lors de la
consultation d’un service en ligne avec un logiciel de navigation. Un fichier
cookie permet à son émetteur, pendant sa durée de validité n’excédant pas 13
mois, de reconnaître le terminal concerné à chaque fois que ce terminal accède
à un contenu numérique comportant des cookies du même émetteur. Il n’est pas
possible de désactiver l’utilisation des cookies.</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-align:justify'><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif;color:black;mso-themecolor:text1'>&nbsp;</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>7.2 Conservation des données collectées</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Les données à caractère personnel sont stockées par la Société sur ses
serveurs, en vue de leur traitement dans le cadre de l’utilisation des
Services. Elles sont conservées aussi longtemps que nécessaire pour l’apport
des Services et fonctions offerts par l’Application. En conséquence, tant qu’un
Utilisateur disposera d’un compte membre, les données collectées seront
conservées. En cas de désinscription de l’Application, les données collectées
seront effacées par la Société et uniquement conservées à titre d’archive aux
fins d’établissement de la preuve d’un droit ou d’un contrat qui peuvent être
archivées conformément aux dispositions du code de commerce relatives à la
durée de conservation des livres et documents créés à l’occasion des activités
commerciales. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>L’Utilisateur reste toujours propriétaire des informations le concernant
qu’il transmet à la Société. Il dispose, conformément à la loi n° 78-17 du 6
janvier 1978 modifiée par la loi n° 2004-801 du 6 août 2004, d’un droit
d’accès, de rectification et de suppression des données à caractère personnel
le concernant, ainsi que du droit de s’opposer à la communication de ces
données à des tiers pour de justes motifs. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>L’Utilisateur pourra exercer ses droits en écrivant à l’adresse
électronique suivante&nbsp;: support@captarget-archery.com ou à l’adresse
postale suivante&nbsp;: Appli <span class=SpellE>Magine</span> - </span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>5,
jardin Jean de Ockeghem à Tours (37000)<span style='color:black;mso-themecolor:
text1'>.</span></span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Une réponse à la requête de l’Utilisateur lui sera adressée dans un
délai de 30 jours.</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>7.3 Finalités de la collecte des données collectées</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Les données personnelles sont collectées auprès <span class=GramE>des
Utilisateur</span> afin (i) de permettre à l’Utilisateur de profiter pleinement
des Services et des fonctions proposés par l’Application, (ii) de prévenir
toute fraude et (iii) à des fins statistiques.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Ces données peuvent être communiquées par la Société à tout tiers
chargés de l’exécution, du traitement et de la gestion des Services. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>Toutefois, dans différentes hypothèses, la Société sera susceptible de
divulguer ou partager les données personnelles d’un Utilisateur à tous autres
tiers, parmi lesquelles&nbsp;:</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l6 level1 lfo14;tab-stops:list 36.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>avec</span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'> le
consentement de l’Utilisateur&nbsp;;</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l6 level1 lfo14;tab-stops:list 36.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>afin</span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'> de se
conformer à la loi, la règlementation en vigueur, à toute procédure judiciaire,
aux décisions de justice ou à tout autre cas de divulgation obligatoire&nbsp;;
ou</span></p>

        <p class=MsoNormal style='margin-left:36.0pt;text-align:justify;text-indent:
-18.0pt;mso-list:l6 level1 lfo14;tab-stops:list 36.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Times",serif;mso-fareast-font-family:
Times;color:black;mso-themecolor:text1'><span style='mso-list:Ignore'>-<span
                style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><span class=GramE><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:
text1'>pour</span></span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif;color:black;mso-themecolor:text1'> protéger
les droits, la propriété ou la sécurité de l’Application, de ses membres ou du
public.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 8 - Responsabilité</span></b></p>

        <p class=MsoNormal style='text-align:justify;mso-outline-level:1'><b><i
                style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;line-height:
115%;font-family:"Amasis MT Pro",serif'>8.1 L’utilisation de l’Application</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Le
rôle de la Société se limite à la mise à disposition des services applicatifs
aux Utilisateurs. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Utilisateur
est seul responsable des conséquences de l’utilisation de l’Application. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Pour l’ensemble des
Services proposés sur l’Application, la Société ne saurait en aucun cas avoir
la qualité de coach sportif des Utilisateurs, le rôle de la Société étant
limité à la facilitation technique du suivi de performances. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société ne saurait être
tenue par une quelconque obligation de résultat en termes d’amélioration des
performances des Utilisateurs.<span style='mso-bidi-font-weight:bold'></span></span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Utilisateur
est tenu de s’assurer que l’utilisation qu’il fait de l’Application est
conforme aux dispositions légales, règlementaires ainsi qu’aux conditions
générales d’utilisation de l’Application. La Société ne donne aucune garantie à
l’Utilisateur quant à la conformité de l’utilisation de l’Application, qu’il
fait ou qu’il projette de faire, aux dispositions légales et règlementaires
nationales ou internationales.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Utilisateur
est averti des aléas techniques et des interruptions d’accès pouvant survenir
sur l’Application. En conséquence, la Société ne pourra être tenue responsable
des indisponibilités ou ralentissements des services applicatifs. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;color:black;mso-color-alt:
windowtext;background:white'>L’ensemble des informations, les conseils et
guides et toute autre donnée affichée sur l’Application n'ont pas vocation à
constituer des conseils sur la foi desquels une décision pourrait être prise
par l'Utilisateur. La Société ne saurait donc être responsable des conséquences
de l’utilisation des données et informations affichées sur l’Application. </span><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
background:white'></span></p>

        <p class=MsoNormal style='text-align:justify;mso-outline-level:1'><b><i
                style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;line-height:
115%;font-family:"Amasis MT Pro",serif'>8.2 Relations entre Utilisateurs</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société n’assure qu’un
rôle technique dans la relation entres les Utilisateurs. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>En
conséquence, les Utilisateurs sont tenus d’organiser entre eux les conditions
de leurs relations dont ils seront les seuls responsables. Ils devront alors
s’assurer du respect des conditions légales et règlementaires. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>En
outre, la Société</span><span style='font-size:9.0pt;line-height:115%;
font-family:"Amasis MT Pro",serif'> ne saurait en aucun cas être tenue
responsable&nbsp;notamment :</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>1. des conséquences des rencontres
virtuelles ou réelles entre Utilisateurs suite à l’utilisation de l’Application
;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>2. des conditions de la
réalisation des exercices sur l’Application ;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>4. de la violation par un
Utilisateur de ses obligations légales ou règlementaires&nbsp;;</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>5. des dommages de toutes
natures subis par un Utilisateur, y compris corporels, lors de tout exercice ;
et</span></p>

        <p class=MsoNormal style='text-align:justify;tab-stops:-9.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>6.
de tout préjudice consécutif à une faute de l’Utilisateur ou à un évènement
imputable à un tiers ou à un cas de force majeure.</span></p>

        <p class=MsoNormal style='text-align:justify;mso-outline-level:1'><b><i
                style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;line-height:
115%;font-family:"Amasis MT Pro",serif'>8.3 Les données </span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société n’a pas
d’obligation générale de surveillance des données et du contenu importés par
les Utilisateurs, ni d’obligation de suppression d’un contenu qui ne paraitrait
pas manifestement illicite, nonobstant son signalement. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Utilisateur
s’engage à ne pas saisir un contenu susceptible de porter atteinte à l’ordre
public ou aux bonnes mœurs, de provoquer des protestations de tiers, ou encore
de contrevenir aux dispositions légales en vigueur.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>En
conséquence, il est expressément convenu qu’au cas où la Société serait mise en
cause, à quelque titre que ce soit, dans quelque pays que ce soit, par un tiers
sur le fondement notamment d’un droit de la propriété industrielle et/ou
intellectuelle relatif à un élément fournis par un Utilisateur, cet Utilisateur
s’engage à garantir entièrement la Société des conséquences économiques et
financières directes et/ou indirectes (y compris les frais de procédure et de
défense) qui découleraient de ces revendications.</span></p>

        <p class=MsoNormal style='text-align:justify;tab-stops:-9.0pt'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Il
est rappelé que les données publiées par les Utilisateurs et les informations
partagées par ces derniers peuvent être captées et exploitées par d’autres
Utilisateurs ou des tiers. En ce sens, la Société ne garantit pas le respect de
la propriété de ces données, il incombe à l’Utilisateur de prendre l’ensemble
des dispositions nécessaires afin que soit préservée la propriété de ses
données. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>L’Utilisateur
devra s’assurer de l’envoi des données à l’Application et ne pourra reprocher à
la Société sur un quelque fondement et à quelque titre que ce soit la non
réception ou la perte des données transmises. L’Utilisateur veillera alors à
conserver une sauvegarde des données transmises. </span></p>

        <p class=MsoNormal style='text-align:justify;mso-outline-level:1'><b><i
                style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;line-height:
115%;font-family:"Amasis MT Pro",serif'>8.4 Analyse de performance -
Statistiques</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les analyses et données de
performances fournies sur l’Application n’ont qu’une valeur indicative. La
Société ne s’engage nullement sur leur exactitude. En conséquence,
l’Utilisateur est seul responsable des suites de l’utilisation de ces analyses
et données. Il ne saurait rechercher la responsabilité de la Société sur un
quelque fondement et à quelque titre que ce soit en raison notamment des
conséquences liées directement ou indirectement à ces analyses données. </span></p>

        <p class=MsoNormal style='text-align:justify;mso-outline-level:1'><b><i
                style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;line-height:
115%;font-family:"Amasis MT Pro",serif'>8.5 Dispositions générales</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>En
tout état de cause, la Société ne saurait en aucune circonstance être
responsable au titre des pertes ou dommages indirects ou imprévisibles des
Utilisateurs ou des tiers, ce qui inclut notamment tout gain manqué,
inexactitude ou corruption de fichiers ou données ou perte de chance lié à
quelque titre et sur quelque fondement que ce soit au présent Contrat.<span
                    style='mso-tab-count:1'> </span></span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>La
Société ne saurait être responsable du retard ou de l’inexécution du présent
Contrat justifié par un cas de force majeure, telle qu’elle est définie par la
jurisprudence des cours et tribunaux français.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 9 - Résiliation </span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Utilisateur peut résilier
à tout moment le présent Contrat, en se rendant sur l’Application et en y
suivant la procédure prévue à cet effet. Il est rappelé<span style='mso-bidi-font-weight:
bold'> qu’une fois le compte membre d’un Utilisateur clôturé, les données
transmises ainsi que l’ensemble des données générées pourront être
éventuellement détruites par la Société sans information préalable de
l’Utilisateur.</span></span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>La
résiliation du présent Contrat ne saurait engendrer une quelconque obligation
de remboursement de toute somme encaissée par la Société.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;mso-bidi-font-weight:bold'>Une
fois le compte membre de l’Utilisateur fermé pour une quelque raison que ce
soit (désinscription volontaire ou non), l’ensemble de ses données sont
supprimées par la Société sans information préalable de l’Utilisateur.
L’Utilisateur veillera alors, pendant toute la durée du Contrat, à conserver, à
sa seule charge et sous propre initiative, une sauvegarde des données
transmises et/ou stockées sur l’Application. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société se réserve le
droit de restreindre, suspendre ou supprimer, sans préavis, ni remboursement,
le compte de tout Utilisateur qui utilise de façon abusive ou inappropriée les
services applicatifs. L’appréciation du comportement de l’Utilisateur est
réservée à l’entière discrétion de la Société. </span></p>

        <p class=MsoNormal><span style='font-size:9.0pt;line-height:115%;font-family:
"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 10 - Convention de preuve</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Les systèmes et fichiers
informatiques font foi dans les rapports entre la Société et l’Utilisateur. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Ainsi, la Société pourra
valablement produire dans le cadre de toute procédure, aux fins de preuve les
données, fichiers, programmes, enregistrements ou autres éléments, reçus, émis
ou conservés au moyen des systèmes informatiques exploités par la Société, sur
tous supports numériques ou analogiques, et s’en prévaloir sauf erreur
manifeste. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 11- Dispositions diverses</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>11.1
</span></i></b><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Indivisibilité
du Contrat</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Le fait que l’une
quelconque des dispositions du Contrat soit ou devienne illégale ou
inapplicable n’affectera en aucune façon la validité ou l’applicabilité des
autres stipulations du Contrat.</span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>11.2
</span></i></b><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Modification
du Contrat<span style='text-transform:uppercase'></span></span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La Société se réserve le
droit de modifier, compléter ou remplacer les présentes Conditions. En cas de
refus de l’Utilisateur des nouvelles dispositions, il est rappelé que
l’Utilisateur pourra à tout moment, conformément à l’article 9 (Résiliation),
résilier le présent Contrat.<span style='mso-spacerun:yes'>  </span></span></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><i style='mso-bidi-font-style:normal'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif;text-transform:uppercase'>11.3
</span></i></b><b style='mso-bidi-font-weight:normal'><i style='mso-bidi-font-style:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>Langue</span></i></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Dans l’hypothèse où les
présentes seraient traduits dans une autre langue que la langue française, il
est expressément convenu que seule la langue française fera foi entre les
parties.</span></p>

        <p class=MsoNormal align=center style='text-align:center'><b style='mso-bidi-font-weight:
normal'><span style='font-size:10.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>Article 12 - Règlement des différends</span></b></p>

        <p class=MsoNormal style='text-align:justify'><b style='mso-bidi-font-weight:
normal'><span style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif;
text-transform:uppercase'>&nbsp;</span></b></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>La conclusion,
l’interprétation et la validité du présent Contrat sont régis par la loi
française, quel que soit le pays d’origine de l’Utilisateur ou le pays depuis
lequel l’Utilisateur accède à la Société et nonobstant les principes de
conflits de lois. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>Dans l’hypothèse où un
différend portant sur la validité, l’exécution ou l’interprétation du présent
Contrat et serait porté devant les juridictions civiles, il sera soumis à la
compétence exclusive des tribunaux français auquel il est fait expressément attribution
de compétence, même en cas de référé ou de pluralité de défendeurs. </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>L’Utilisateur est informé
qu’il peut en tout état de cause recourir à une médiation conventionnelle ou à
tout mode alternatif de règlement des différends (conciliation par exemple) en
cas de contestation.</span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'>En application de l’article
14.1 du règlement (UE) n°524/2013 du parlement européen et du conseil du 21 mai
2013, il est précisé à l’Utilisateur, qu’il peut consulter la page suivante
pour avoir plus d’informations sur ses démarches en cas de contestation : </span></p>

        <p class=MsoNormal style='text-align:justify'><span style='font-size:9.0pt;
line-height:115%;font-family:"Amasis MT Pro",serif'><a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=FR">
            https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&amp;lng=FR</a>.</span></p>

        <p style='line-height:18.0pt;background:white'><span style='font-size:9.0pt;
font-family:"Amasis MT Pro",serif;mso-bidi-font-family:Poppins;color:black;
mso-color-alt:windowtext'>Les parties conviennent de rechercher une solution
amiable avant toute action en justice. En cas de réclamation ne pouvant être
résolue à l'amiable entre les parties, l'Utilisateur peut avoir recours, à
titre gratuit, à une médiation de la consommation. Il est proposé de recourir
au médiateur suivant : </span><span style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;
mso-bidi-font-family:Arial;color:black;mso-color-alt:windowtext;background:
white'>Sas Médiation Solution</span>&nbsp;:
            <a href="https://www.sasmediationsolution-conso.fr">https://www.sasmediationsolution-conso.fr</a>
            <span
                style='font-size:9.0pt;font-family:"Amasis MT Pro",serif;mso-bidi-font-family:
Poppins;color:black;mso-color-alt:windowtext'>.</span><span style='font-size:
9.0pt;font-family:"Amasis MT Pro",serif;mso-bidi-font-family:Poppins'></span></p>

        <p class=MsoNormal align=center style='text-align:center'><span
                style='font-size:9.0pt;line-height:115%;font-family:"Amasis MT Pro",serif'>***</span></p>

        <p class=MsoNormal><span style='font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal><span style='font-family:"Amasis MT Pro",serif'>&nbsp;</span></p>

        <p class=MsoNormal>&nbsp;</p>

    </div>
    </div>
</div>
