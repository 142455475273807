<section class="section video-coach-area bg-overlay ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- Video Content -->
                <div class="video-content text-center">
                    <h2 class="text-white mb-2">{{ 'TRANSLATE.coach_video_title_1' | translate }}</h2>
                    <h5 class="text-white">{{ 'TRANSLATE.coach_video_subtitle_2' | translate }}</h5>
                    <!-- Video Icon -->
                    <div class="video-icon mt-4">
                        <!-- Play Button -->
                        <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=Hg1uPZstY1s&ab_channel=CapTargetArchery">
                            <div class="btn-circle play-animation"></div>
                            <div class="btn-circle play-animation animation-short"></div>
                            <!-- Play Icon -->
                            <div class="play-icon">
                                <i class="fas fa-play"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
