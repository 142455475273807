import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
