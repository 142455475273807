<section id="features" class="section features-area ptb_100">
    <div class="shapes-container">
        <div class="shape-1"></div>
    </div>
    <div class="container">
        <div class="row d-none d-md-block">
            <div class="col-12">
                <div class="row d-flex justify-content-center features-content border-bottom pb-5">
                    <div class="col-12 col-md-4">
                        <!-- Media Box -->
                        <div class="media-box media rounded-sm p-1 p-lg-4">
                            <!-- Features Icon -->
                            <div class="features-icon pr-3">
                                <span>
                                    <i class="icofont-cube"></i>
                                </span>
                            </div>
                            <!-- Features Text -->
                            <div class="media-body">
                                <h3 class="mb-2">Easy Management</h3>
                                <p class="d-none d-lg-block">Manage your dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <!-- Media Box -->
                        <div class="media-box media rounded-sm p-1 p-lg-4">
                            <!-- Features Icon -->
                            <div class="features-icon pr-3">
                                <span>
                                    <i class="icofont-envato"></i>
                                </span>
                            </div>
                            <!-- Features Text -->
                            <div class="media-body">
                                <h3 class="mb-2">Super Customizable</h3>
                                <p class="d-none d-lg-block">Customizable code dolor sit amet, consectetur adipisicing.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <!-- Media Box -->
                        <div class="media-box media rounded-sm p-1 p-lg-4">
                            <!-- Features Icon -->
                            <div class="features-icon pr-3">
                                <span>
                                    <i class="icofont-pie-chart"></i>
                                </span>
                            </div>
                            <!-- Features Text -->
                            <div class="media-body">
                                <h3 class="mb-2">Track Visually</h3>
                                <p class="d-none d-lg-block">Visual tracking dolor sit amet, consectetur adipisicing elit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row features-slider owl-carousel pt-md-5">
            <div class="col-12 py-md-3">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Text -->
                        <div class="about-text">
                            <!-- Headings -->
                            <div class="headings d-flex align-items-center mb-4">
                                <h2 class="text-capitalize">Easy To Manage All Your Data By Appia</h2>
                            </div>
                            <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus. Dolores, dolorum?</p>
                            <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p>
                            <!-- Store Buttons -->
                            <div class="button-group store-buttons d-flex">
                                <a href="#">
                                    <img src="assets/img/google_play.svg" alt="">
                                </a>
                                <a href="#">
                                    <img src="assets/img/app_store.svg" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 pt-sm-5 pt-lg-0 d-none d-md-block">
                        <!-- Features Thumb -->
                        <div class="features-thumb text-center">
                            <img src="assets/img/features_thumb_1.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 py-md-3">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Text -->
                        <div class="about-text">
                            <!-- Headings -->
                            <div class="headings d-flex align-items-center mb-4">
                                <h2 class="text-capitalize">Customize Things As You Want</h2>
                            </div>
                            <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus. Dolores, dolorum?</p>
                            <!-- Video Icon -->
                            <div class="video-icon d-grid">
                                <strong class="text-uppercase mb-3">Check out demo</strong>
                                <!-- Play Button -->
                                <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=mhxc89IjlFI">
                                    <div class="btn-circle play-animation"></div>
                                    <div class="btn-circle play-animation animation-short"></div>
                                    <!-- Play Icon -->
                                    <div class="play-icon">
                                        <i class="fas fa-play"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 pt-sm-5 pt-lg-0 d-none d-md-block">
                        <!-- Features Thumb -->
                        <div class="features-thumb text-center">
                            <img src="assets/img/features_thumb_2.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 py-md-3">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Text -->
                        <div class="about-text">
                            <!-- Headings -->
                            <div class="headings d-flex align-items-center mb-4">
                                <h2 class="text-capitalize">Visually Track Your Product Marketing Chart</h2>
                            </div>
                            <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus. Dolores, dolorum?</p>
                            <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p>
                            <a href="#" class="btn btn-bordered">Learn More</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 pt-sm-5 pt-lg-0 d-none d-md-block">
                        <!-- Features Thumb -->
                        <div class="features-thumb text-center">
                            <img src="assets/img/features_thumb_3.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
