<div class="row">
    <div class="col-12">
        <!-- FAQ Content -->
        <div class="faq-content">
            <span class="d-block text-center mt-5">Not sure what to choose? <a href="#">Contact Us</a></span>
            <!-- Appia Accordion -->
            <div class="accordion pt-5" id="appia-accordion">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <!-- Single Accordion Item -->
                        <div class="card my-2">
                            <!-- Card Header -->
                            <div class="card-header">
                                <h2 class="mb-0">
                                    <button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
                                        How to install Appia?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" class="collapse show" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card my-2">
                            <!-- Card Header -->
                            <div class="card-header">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                        Can I get support from the Author?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card my-2">
                            <!-- Card Header -->
                            <div class="card-header">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                                        Contact form isn't working?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- Single Accordion Item -->
                        <div class="card my-2">
                            <!-- Card Header -->
                            <div class="card-header">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
                                        What about the events?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card my-2">
                            <!-- Card Header -->
                            <div class="card-header">
                                <h2 class="mb-0">
                                    <button class="btn" type="button" data-toggle="collapse" data-target="#collapseFive">
                                        How can I get product update?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFive" class="collapse show" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card my-2">
                            <!-- Card Header -->
                            <div class="card-header">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
                                        Is this template support rtl?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSix" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body">
                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>