import { Component, OnInit } from '@angular/core';
import {LanguageService} from '../../../Services/language.service';

@Component({
  selector: 'app-footer-five',
  templateUrl: './footer-five.component.html',
  styleUrls: ['./footer-five.component.css']
})
export class FooterFiveComponent implements OnInit {

  constructor(public languageService: LanguageService) { }

  ngOnInit(): void {
  }

  sendMail(): void {
    const email = 'support@captarget-archery.com';
    const subject = 'Contact depuis le site de CapTarget';
    const emailBody = 'Bonjour CapTarget ! 🏹';
    window.open(`mailto:${email}?subject=${subject}&body=${emailBody}`, '_system');
    alert(email);
  }
}
