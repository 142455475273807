import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zh-conf',
  templateUrl: './zh-conf.component.html',
  styleUrls: ['./zh-conf.component.css']
})
export class ZhConfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
