import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confidenciality',
  templateUrl: './confidenciality.component.html',
  styleUrls: ['./confidenciality.component.css']
})
export class ConfidencialityComponent implements OnInit {
  selectedLanguage = 'Français';
  languages = [
    'Français',
    'English',
    'Español',
    'Italiano',
    'Dansk',
    'Português',
    '한국어',
    '中文',
    'Türkçe',
    '日本語',
    'Eesti',
    'Nederlands',
    'Deutsch'
  ];


  constructor() { }

  ngOnInit(): void {
  }

  // Function to handle language selection
  // tslint:disable-next-line:typedef
  selectLanguage(language: string) {
    this.selectedLanguage = language;
  }

}
