<div class="container">
    <h4>Definiciones</h4>
    <p> </p>
    <p>Los siguientes términos, cuya primera letra está en mayúscula en los Términos y Condiciones de Uso (CGU), tienen el significado que se les atribuye en este artículo.</p>
    <p> </p>
    <p>Abono: se refiere a cualquier suscripción, ya sea gratuita o de pago, a los servicios de la plataforma.</p>
    <p> </p>
    <p>Arquero: Usuario que practica el tiro con arco y utiliza o no los servicios de la aplicación.</p>
    <p> </p>
    <p>Entrenador (Coach): Entrenador de tiro con arco que se ha suscrito a una suscripción gratuita o de pago de tipo "Coach" y accede a los servicios asociados.</p>
    <p> </p>
    <p>Aplicación: se refiere a la aplicación móvil "CapTarget Archery" disponible en Android e iOS.</p>
    <p> </p>
    <p>Sitio: se refiere al sitio web de CapTarget Archery.</p>
    <p> </p>
    <p>Sitio del coach: se refiere al sitio con acceso reservado para los entrenadores (Coaches).</p>
    <p> </p>
    <p>Condiciones de uso: se refiere a los presentes términos y condiciones generales de uso de la Plataforma.</p>
    <p> </p>
    <p>Cuenta: se refiere a la cuenta asignada a un Usuario que proporciona acceso a la Plataforma y a varios servicios, y que está asociada con un identificador personal y una contraseña.</p>
    <p> </p>
    <p>Grupo: se refiere a un grupo creado en la aplicación CapTarget Archery que agrupa a Usuarios y Entrenadores.</p>
    <p> </p>
    <p>Colaborador: se refiere a una persona física o jurídica que ha celebrado una colaboración con CapTarget Archery que le permite ser presentada a los Usuarios a través de la Plataforma.</p>
    <p> </p>
    <p>Plataforma: se refiere a todos los servicios accesibles en los Sitios y/o la Aplicación.</p>
    <p> </p>
    <p>Usuario: se refiere a cualquier persona física o jurídica que tenga acceso a la Plataforma y la utilice, ya sea a través de una Cuenta o de otro modo.</p>
    <p></p>
    <p>Gemas: se refiere a una moneda virtual utilizada en CapTarget Archery.</p>
    <p> </p>
    <h4>Objeto</h4>
    <p> </p>
    <p>Los Términos y Condiciones de Uso (CGU) tienen como objeto definir los términos y condiciones aplicables a todos los Usuarios de la Plataforma.</p>
    <p> </p>
    <p>Toda inscripción, acceso y/o uso de la Plataforma implica la aceptación incondicional y el cumplimiento por parte del Usuario de la totalidad de los CGU.</p>
    <p> </p>
    <p>Cualquier Usuario menor de edad declara y reconoce haber obtenido la autorización de sus padres o del o de los titulares de la autoridad parental para registrarse en la Plataforma.</p>
    <p> </p>
    <p>Cualquier solicitud de registro generada automáticamente por un robot o por cualquier otro método será rechazada.</p>
    <p> </p>
    <h4>Presentación de la aplicación</h4>
    <p> </p>
    <p>La Aplicación está principalmente dedicada a los practicantes del tiro con arco o a los entrenadores y permite, en particular, gestionar y organizar actividades relacionadas con el deporte, comunicar información entre los miembros de un grupo o sobre las noticias del sector.</p>
    <p> </p>
    <p>A través de la Aplicación, los Usuarios disfrutan de las siguientes características:</p>
    <p> </p>
    <p>Gestión de sesiones de entrenamiento y competición</p>
    <p>Gestión de tiros contados y partidos</p>
    <p>Gestión de equipo de tiro</p>
    <p>Uso de datos para proporcionar estadísticas</p>
    <p>Interacciones sociales y lúdicas, disponibilidad de datos para los Entrenadores del Usuario</p>
    <p>Interacciones sociales y lúdicas entre los miembros de un Grupo</p>
    <p>Compra de elementos de personalización de la experiencia a través de la moneda virtual comúnmente llamada "Gema".</p>
    <p>Compra de "Gemas" a través del sistema de pago móvil (ANDROID o APPLE)</p>
    <p>Mensajería</p>
    <p> </p>
    <h4>Inscripción de un Usuario</h4>
    <p> </p>
    <p>Cada Usuario tiene la posibilidad de crear una Cuenta (personal o en nombre de un menor cuando el Usuario tiene la autoridad parental) que le permitirá acceder a la aplicación y sus servicios.</p>
    <p> </p>
    <p>La inscripción se realiza en la Plataforma por parte del Usuario, a través de la aplicación. Para ello, deberá completar los diferentes campos del formulario de inscripción, incluyendo una dirección de correo electrónico válida, su nombre y apellido, así como la información relacionada con su práctica del tiro con arco. Deberá tomar conocimiento y aceptar los CGU y la Política de privacidad de los datos.</p>
    <p> </p>
    <p>Al completar el formulario de inscripción, el Usuario se compromete a proporcionar únicamente información precisa, actual y completa.</p>
    <p> </p>
    <p>El Usuario se compromete a mantener confidencial el código de acceso de su Cuenta. En caso de uso fraudulento del código de acceso, el Usuario informará inmediatamente a CapTarget Archery.</p>
    <p> </p>
    <p>El Usuario es el único responsable del uso de su Cuenta. Cualquier conexión o transmisión de datos a través de la Plataforma se considerará que ha sido realizada por el Usuario y bajo su responsabilidad exclusiva.</p>
    <p> </p>
    <p>CapTarget Archery no será responsable si los datos de registro del Usuario no llegan a CapTarget Archery por motivos que no le sean imputables (por ejemplo, problemas de conexión a Internet del Usuario, fallos temporales en sus servidores, etc.), o si los datos llegan a CapTarget Archery en un formato no procesable (por ejemplo, si el Usuario tiene hardware o software inadecuados).</p>
    <p>Cualquier suscripción a una suscripción de pago se realiza por un período de tiempo indefinido, a menos que el usuario solicite explícitamente lo contrario. Le recomendamos que consulte los Términos y Condiciones de Venta (CGV) para obtener información sobre los procedimientos de cancelación.</p>
    <p> </p>

    <h4>Compartir Datos</h4>
    <p>Para acceder a las funciones de compartir datos a través de CapTarget Archery, nuestra aplicación ofrece un sistema de seguidores y seguimiento. Usted, como usuario, tiene la opción de aceptar invitaciones o invitar a otros usuarios a unirse a su red.</p>
    <p>En caso de un perfil público, no se requiere aprobación para que otros usuarios lo sigan. Una vez que un usuario lo sigue, algunos de sus datos pueden compartirse automáticamente o manualmente, incluyendo:</p>
    <p>- Resultados de sus competiciones o sesiones de entrenamiento.</p>
    <p>- Estadísticas deportivas diversas, como la duración de su licencia, su mejor resultado o su clasificación.</p>
    <p>- Datos relacionados con su uso de la aplicación, como sus juegos y ejercicios favoritos.</p>
    <p>- Información sobre su club, idioma, país, así como otros datos como su nombre, apellido, tipo de arco utilizado o categoría de tiro.</p>
    <p>Tiene la opción de optar por no compartir algunos de estos datos modificando su configuración personal o la configuración de cada situación.</p>
    <p>Desde la creación de su cuenta, acepta ser visible en la plataforma CapTarget. Otros usuarios pueden buscarlo utilizando su nombre y apellido. Su perfil también puede sugerirse automáticamente a otros usuarios con el fin de crear desafíos deportivos o conexiones de seguimiento.</p>
    <p>Tenga en cuenta que puede estar expuesto a información compartida en CapTarget Archery por CapTarget, sus socios, anunciantes u otros usuarios. Le recomendamos consultar nuestra política de privacidad para obtener más detalles sobre la gestión de sus datos personales.</p>
    <p>Queremos destacar que todas estas cláusulas son conformes a las leyes y regulaciones vigentes sobre protección de datos personales y privacidad. Si tiene alguna preocupación o pregunta adicional, no dude en ponerse en contacto con nosotros.</p>

    <h4>Advertencia</h4>
    <p>El acceso a las versiones para entrenadores de las plataformas de CapTarget Archery requiere el uso de herramientas informáticas, ya sea una computadora, tableta o teléfono para la versión web, y un teléfono o tableta con sistema Android o iOS para la versión móvil. Se requiere una conexión a Internet para descargar los datos necesarios. Como usuario, declara estar al tanto de esta necesidad para acceder a los servicios ofrecidos.</p>
    <p>Los ejercicios y situaciones propuestos en los servicios suponen que los usuarios están en buenas condiciones físicas y aptos para realizarlos. El suscriptor se compromete a tomar todas las precauciones necesarias para practicar la actividad preservando su salud, seguridad y la de otras personas.</p>

    <h4>Condiciones Generales de Venta</h4>
    <p>Al suscribirse a una suscripción o comprar uno de los servicios digitales o productos digitales ofrecidos por CapTarget Archery, acepta los términos y condiciones de venta que se describen a continuación. Si no acepta estas condiciones, le rogamos que no compre nada en nuestra aplicación o en nuestro sitio web.</p>

    <h6>Suscripciones</h6>
    <p>Cuando compra contenido de CapTarget, CapTarget le otorga una licencia limitada, revocable, no exclusiva y no transferible que le permite acceder a ciertas funciones de pago en nuestra aplicación o sitio web. Nuestra aplicación ofrece suscripciones mensuales y anuales para acceder a servicios digitales con renovación automática. Las tarifas de suscripción se deducirán automáticamente del método de pago que haya elegido en la fecha de aniversario de la suscripción. Las suscripciones pagadas con gemas (la moneda virtual de CapTarget Archery) no se renuevan automáticamente.</p>
    <p>Si no desea renovar su suscripción, debe desactivarla al menos 24 horas antes de la fecha de vencimiento de la suscripción accediendo a la configuración de su cuenta.</p>

    <h6>Tarifas</h6>
    <p>Nuestras tarifas de suscripción se publican en nuestra aplicación y pueden cambiar en cualquier momento sin previo aviso. Las tarifas de suscripción incluyen todos los impuestos aplicables. Usted es responsable de todos los cargos asociados con el uso de nuestra aplicación, incluidos los cargos de acceso a Internet y datos móviles. A pesar de todos nuestros esfuerzos, algunos artículos de nuestro catálogo pueden tener precios incorrectos. Las tarifas pueden cambiar incluso para los suscriptores actuales. Dependiendo del sistema que haya elegido (Apple, Google, Stripe u otro), puede recibir notificaciones en caso de aumento de tarifas en su suscripción en curso y puede cancelar la suscripción antes de su renovación.</p>

    <h6>Riesgo de Pérdida</h6>
    <p>Una vez que el contenido de CapTarget esté a su disposición, asume el riesgo de pérdida del contenido de CapTarget después de la compra, incluida cualquier pérdida debido a la corrupción del archivo o falla del dispositivo o disco duro. El contenido de CapTarget comprado generalmente seguirá estando disponible en su cuenta de CapTarget, pero puede dejar de estar disponible por diversas razones. En tales casos, CapTarget no será responsable ante usted por la falta de disponibilidad posterior del contenido de CapTarget y no tendrá derecho a un reembolso, total o parcial, de los cargos ya pagados.</p>

    <h6>Entrega</h6>
    <p>El acceso al contenido de CapTarget se proporciona al comprar el producto o la suscripción. El contenido está disponible en su cuenta de CapTarget. Es posible que se requiera reiniciar la aplicación, así como un tiempo razonable para que los servicios estén disponibles (generalmente unos minutos como máximo).</p>

    <h6>Pago</h6>
    <p>Debe tener un método de pago válido (como una tarjeta de crédito u otro método de pago autorizado) en su cuenta de CapTarget si desea utilizar funciones de pago. El pago de su suscripción se deducirá automáticamente de su cuenta en función del método de pago que haya ingresado en su cuenta de Apple o Google Play, o en Stripe u otro si ha comprado la suscripción en nuestro sitio web. Acepta proporcionar información de pago precisa y actualizada. Si el pago no se realiza por cualquier motivo, nos reservamos el derecho de suspender o cancelar su suscripción.</p>

    <h6>Uso de la Aplicación</h6>
    <p>Se le permite utilizar nuestra aplicación para fines personales y no comerciales. No puede modificar, distribuir, vender ni transferir ningún contenido de nuestra aplicación sin nuestro previo permiso por escrito. No puede utilizar nuestra aplicación de manera que infrinja la ley aplicable, viole los derechos de terceros o cause perjuicio.</p>
    <h6>Reembolsos</h6>
    <p>Nuestras suscripciones pueden ser probadas con anticipación durante un período de 3 semanas y, por lo tanto, no son reembolsables. Por favor, verifique las características de cada suscripción antes de suscribirse a una u otra. Los productos digitales puestos a la venta son explícitos y no son reembolsables.</p>
    <h6>Rescisión</h6>
    <p>Nos reservamos el derecho de rescindir su acceso a nuestra aplicación si incumple estos términos y condiciones de venta o uso, o si tenemos motivos para creer que está utilizando nuestra aplicación de cualquier manera. Al comprar una suscripción a nuestra aplicación, acepta estos términos y condiciones de venta.</p>

    <h6> Contáctenos </h6>
    <p>Si tiene preguntas o inquietudes, contáctenos en la dirección: support@captarget-archery.com</p>
    <p>Haremos todo lo posible para resolver su problema de manera amigable y asegurarnos de que todas las partes queden satisfechas.</p>
    <p></p>
    <p></p>
    <h4>Costos de uso de la Plataforma</h4>
    <p> </p>
    <p>La creación de una Cuenta y un Grupo es gratuita para un Usuario. Sin embargo, los gastos de conexión y comunicación relacionados con el uso de la Plataforma corren exclusivamente a cargo del Usuario.</p>
    <p>El acceso a las versiones de entrenadores es de pago, excepto las versiones de prueba y la versión beta. Los precios están disponibles a partir del lanzamiento de la versión en el sitio web.</p>
    <h4>Compras a través de las Plataformas</h4>
    <p> </p>
    <p>Es posible comprar una licencia limitada, personal, no transferible, no sublicenciable y revocable con dinero "real", que le permite usar (a) una "moneda virtual", incluyendo efectivo o diamantes virtuales, todo destinado a ser utilizado en los juegos de CapTarget Archery. También es posible obtener esta moneda en eventos, concursos, juegos, viendo publicidades o como recompensa al alcanzar objetivos en la plataforma, o distribuidos por CapTarget Archery.</p>
    <p>Esta moneda permite comprar diferentes productos vendidos por la aplicación.</p>
    <p> </p>
    <p>La transferencia de Artículos virtuales está prohibida, excepto cuando esté expresamente autorizada en el Servicio. Salvo lo expresamente autorizado en el Servicio, debe abstenerse de vender, comprar, convertir o transferir de cualquier otra manera Artículos virtuales en beneficio de cualquier persona o entidad, o de intentar cualquiera de las acciones mencionadas anteriormente, en particular en beneficio de CapTarget Archery, otro usuario o un tercero.</p>
    <p>CapTarget Archery puede gestionar, regular, controlar, modificar o eliminar los Artículos virtuales en cualquier momento, con o sin previo aviso. CapTarget Archery no será responsable ante usted ni ante terceros por el ejercicio de estos derechos.</p>
    <p> </p>
    <p>Todas las compras y conversiones de artículos virtuales de terceros realizadas a través del servicio son definitivas y no reembolsables.</p>
    <p>Usted se compromete a pagar todos los cargos y impuestos aplicables que usted o cualquier persona que use una cuenta registrada en su nombre deba pagar. CapTarget Archery puede cambiar el precio de los artículos virtuales ofrecidos a través del servicio en cualquier momento. Usted reconoce que CapTarget Archery no está obligado a proporcionarle un reembolso por ninguna razón y que no recibirá ninguna suma u otra compensación por artículos virtuales no utilizados al cerrar una cuenta, ya sea voluntaria o involuntariamente.</p>
    <h4>Publicidad y Socios</h4>
    <p> </p>
    <p>CapTarget Archery puede proporcionar enlaces a otros sitios publicitarios y/o socios en los diferentes servicios ofrecidos.</p>
    <p>En la medida en que CapTarget Archery no pueda controlarlos, CapTarget Archery no asume ningún compromiso con respecto a la disponibilidad de estos sitios y fuentes externas y no puede ser responsable del contenido, la publicidad, los productos, los servicios o cualquier otro material disponible en ellos.</p>
    <p>Asimismo, CapTarget Archery no será responsable de ningún daño o pérdida real o presunta derivada del uso de los contenidos o servicios disponibles en estos sitios publicitarios, socios o fuentes externas.</p>
    <h4>Cierre de la cuenta</h4>
    <p> </p>
    <p>Un Usuario puede cerrar su Cuenta en cualquier momento. El cierre de una Cuenta resultará en la eliminación de los datos personales relacionados, de acuerdo con nuestra Política de privacidad de datos.</p>
    <p>Sin perjuicio de los daños y perjuicios que CapTarget Archery pueda solicitar, CapTarget Archery se reserva el derecho de cerrar, sin previo aviso ni indemnización, de manera temporal o definitiva, la Cuenta de un Usuario en caso, en particular, de incumplimiento de estas Condiciones de uso, suministro de información falsa durante el registro o acciones contrarias a los intereses comerciales de CapTarget Archery.</p>
    <p>El cierre de una cuenta por iniciativa de CapTarget Archery debido al incumplimiento de estas CGU o de los Términos y Condiciones de Venta no da lugar a ninguna compensación o reembolso, incluso en el caso de suscripciones de pago. Todas las sumas pagadas por la Suscripción seguirán siendo propiedad de CapTarget Archery.</p>
    <p>El cierre de un acceso coach puede llevarse a cabo de acuerdo con los Términos y Condiciones de Venta aplicables. Le invitamos a consultar estos últimos para obtener información sobre el cierre de un acceso coach de pago. Una versión de prueba se pausa automáticamente al final del período y puede reabrirse mediante la suscripción a una suscripción. La versión beta es renovable a discreción de CapTarget Archery. Para cerrar una versión de prueba o una versión beta, envíe un correo electrónico a la dirección support@captarget-archery.com.</p>
    <h4>Eliminación de un Grupo</h4>
    <p> </p>
    <p>Un Administrador puede eliminar un Grupo en cualquier momento. La eliminación del Espacio de una Organización resultará en la eliminación de todos los datos y beneficios relacionados.</p>
    <p>La eliminación del Espacio de una Organización por iniciativa de CapTarget Archery</p>
    <p> </p>
    <p>Sin perjuicio de los daños y perjuicios que CapTarget Archery pueda solicitar, CapTarget Archery se reserva el derecho de eliminar, sin previo aviso ni indemnización, de manera temporal o definitiva, el Espacio de una Organización en caso de incumplimiento de los Términos y Condiciones de Venta, suministro de información falsa durante el registro o acciones contrarias a los intereses comerciales de CapTarget Archery.</p>
    <p>La eliminación del Espacio de una Organización por iniciativa de CapTarget Archery no dará lugar a ninguna compensación o reembolso, incluso en el caso de una suscripción de pago. Todas las sumas pagadas por la Suscripción seguirán siendo propiedad de CapTarget Archery y la eliminación del Espacio de una Organización no eximirá a la Organización/Administrador de la obligación de pagar la Suscripción hasta su término.</p>
    <h4>Información y contenido de las Plataformas</h4>
    <p> </p>
    <p>El Usuario se compromete a ejercer discernimiento al usar la Plataforma, especialmente al evaluar la información y el contenido en un Grupo del que sea miembro.</p>
    <p>En este sentido, el Usuario reconoce que la información y el contenido disponibles en la Plataforma no son exhaustivos y tienen como objetivo principal ayudar al Administrador en la gestión de la Organización.</p>
    <p>El Usuario se prohíbe vender, revender o explotar de cualquier manera y con cualquier propósito todo o parte del contenido disponible en la Plataforma.</p>
    <p>El Usuario es el único responsable de las comunicaciones, información, archivos, videos, fotos, documentos u otro contenido que publique y transmita a través de la Plataforma.</p>
    <p>Las comunicaciones, información, archivos, videos, fotos, documentos u otro contenido publicado por el Usuario en la Plataforma representan las opiniones y puntos de vista del Usuario y en ningún caso los de CapTarget Archery o sus empleados y agentes.</p>
    <p>En el marco de la Plataforma, el Usuario se compromete a proporcionar solo información objetiva, completa y veraz, de acuerdo con las reglas de decencia, cortesía y respeto hacia los demás.</p>

    <p>CapTarget Archery anima al Usuario a ejercer moderación y prudencia al expresar comunicaciones, información, archivos, videos, fotos, documentos u otros contenidos que desee publicar en la Plataforma. En este sentido, el Usuario se compromete a no publicar en la Plataforma contenidos contrarios a las leyes y regulaciones vigentes, en particular:</p>
    <p> </p>
    <p>difundir información contraria al orden público o a las buenas costumbres;</p>
    <p>desviar el propósito de la Plataforma hacia la propaganda o el proselitismo, el acoso o la solicitud; publicar información con fines comerciales o publicitarios o que constituya una propaganda a favor del tabaco, el alcohol o cualquier otra sustancia, producto o servicio regulado;</p>
    <p>difundir contenidos que infrinjan los derechos de personalidad de terceros o que sean difamatorios, insultantes, obscenos, pornográficos, ofensivos, violentos o que inciten a la discriminación, la violencia política, el racismo, la xenofobia, el sexismo o la homofobia;</p>
    <p>publicar información que viole la legislación de protección de datos personales permitiendo la identificación de personas físicas sin su consentimiento, en particular su apellido, dirección postal y/o electrónica, número de teléfono, fotografía, grabación de sonido o audiovisual;</p>
    <p>vulnerar los derechos de propiedad intelectual de terceros, en particular eliminando o borrando cualquier mención de derechos de autor o marca, así como cualquier leyenda restrictiva.</p>
    <p> </p>
    <p>El Usuario también se compromete a respetar los derechos de los demás, en particular</p>
    <p> </p>
    <p>los derechos de personalidad (como el derecho a la imagen, el derecho a la vida privada)</p>
    <p>los derechos de marcas</p>
    <p>los derechos de autor (en particular sobre software, sonidos, imágenes, fotografías, textos, imágenes animadas) y los derechos conexos (intérpretes, productores de fonogramas y videogramas, y los derechos sui generis de los productores de bases de datos);</p>
    <p>en general, los derechos de las personas y los bienes.</p>
    <p> </p>
    <p>El Usuario también se prohíbe, salvo autorización previa y por escrito de CapTarget Archery, lo siguiente (esta lista no es exhaustiva):</p>
    <p> </p>
    <p>utilizar la Plataforma con fines promocionales y, en general, ofrecer productos y servicios que le generen remuneración directa o indirecta;</p>
    <p>crear archivos de archivo a partir del contenido de la Plataforma;</p>
    <p>reproducir, representar, utilizar, hacer referencia (incluyendo en las palabras clave de los motores de búsqueda), todo o parte del contenido, marcas, logotipos, signos distintivos que aparecen en la Plataforma.</p>
    <p> </p>
    <p>El incumplimiento de estos Términos y Condiciones de Uso puede resultar en la eliminación inmediata y automática del contenido incriminado, una advertencia y/o el cierre de la Cuenta del Usuario, sin perjuicio de cualquier otra acción legal y/o daños y perjuicios que CapTarget Archery pueda emprender o reclamar.</p>
    <p> </p>
    <p>CapTarget Archery no es responsable de la información, archivos, videos, fotos, documentos u otro contenido de la Plataforma, incluido, pero sin limitarse a, cualquier contenido ilegal, errores u omisiones, o cualquier pérdida o daño resultante del uso de este material por parte de cualquier Usuario.</p>
    <p> </p>
    <p>Se prohíbe estrictamente cualquier enlace de hipertexto a páginas secundarias del Sitio, salvo autorización expresa de CapTarget Archery otorgada al Usuario. Además, cualquier enlace debe ser eliminado por el Usuario a solicitud de CapTarget Archery.</p>
    <p> </p>
    <h4>Papel del Administrador en la Moderación</h4>
    <p> </p>
    <p>El Administrador, en su calidad de coordinador y supervisor del Grupo, se compromete a hacer cumplir a los Miembros los términos de los Términos y Condiciones de Uso. Tan pronto como tenga conocimiento de ello, se compromete a informar o eliminar el contenido (si es posible) que sea contrario a la ley y/o al orden público y/o a estos Términos y Condiciones, en particular cualquier contenido violento, pornográfico, racista, antisemita, xenófobo, que atente contra el respeto a la persona humana o su dignidad, que viole la privacidad, que infrinja los derechos de autor, derechos conexos, derechos de marca, derechos de la personalidad de terceros.</p>
    <p> </p>
    <p>En caso de incumplimiento de estas reglas y prácticas, CapTarget Archery se reserva el derecho de excluir al Administrador y/o al Miembro infractor y de emprender cualquier acción legal, contra el o los autores, que pueda poner fin al trastorno manifiestamente ilícito resultante del uso incorrecto de la Plataforma.</p>
    <p> </p>
    <h4>Envío de correos electrónicos, notificaciones push y SMS</h4>
    <p> </p>
    <p>En el marco de los servicios ofrecidos, CapTarget Archery propone comunicaciones por correo electrónico, notificaciones push en la Aplicación y SMS para comunicarse entre los Miembros de una Organización, con el exclusivo propósito de intercambiar información inherente a la Organización, utilizando los números de teléfono móvil y las direcciones de correo electrónico proporcionadas por los Miembros al crear su Cuenta.</p>
    <p> </p>
    <p>El Usuario puede decidir en cualquier momento dejar de recibir correos electrónicos, notificaciones push o SMS de CapTarget Archery modificando la configuración de sus dispositivos o solicitándolo al soporte técnico.</p>
    <p> </p>
    <h4>Acceso a la Plataforma - Responsabilidad y compromisos</h4>
    <p> </p>
    <p>CapTarget Archery hace todo lo posible para mantener la Plataforma disponible las 24 horas del día, los 7 días de la semana, excepto por las operaciones de mantenimiento programadas y sujeto a las disposiciones de este artículo.</p>
    <p> </p>
    <p>CapTarget Archery se reserva el derecho de realizar en la Plataforma todas las modificaciones y mejoras que considere necesarias en función de la evolución de las técnicas. Por lo tanto, CapTarget Archery se reserva el derecho, sin previo aviso ni indemnización, de suspender temporalmente el acceso a la Plataforma para llevar a cabo operaciones de mantenimiento evolutivo o correctivo. El Usuario libera a CapTarget Archery de cualquier responsabilidad en este sentido y renuncia a cualquier reclamo al respecto.</p>
    <p> </p>
    <p>CapTarget Archery puede exigir que acepte actualizaciones del Servicio y juegos de CapTarget Archery que haya instalado en su dispositivo o computadora. Reconoce y acepta que CapTarget Archery puede actualizar el Servicio y los juegos de CapTarget Archery, ya sea notificándoselo o no. Es posible que deba actualizar software de terceros para recibir el Servicio y jugar a los Juegos de CapTarget Archery.</p>
    <p> </p>
    <p>CapTarget Archery no ofrece ninguna garantía, expresa o implícita, incluyendo, pero no limitándose a, las garantías de calidad y adecuación de la Plataforma para un propósito particular.</p>
    <p> </p>
    <p>CapTarget Archery no garantiza que el funcionamiento ininterrumpido y/o la continuidad de la Plataforma se mantendrán en caso de fuerza mayor o circunstancias imprevistas, como se definen en el artículo 1218 del Código Civil francés, en cuyo caso no se responsabilizará a CapTarget Archery.</p>
    <p> </p>
    <p>En caso de que la responsabilidad de CapTarget Archery sea comprometida por el Usuario debido al incumplimiento de una de sus obligaciones bajo estos Términos y Condiciones de Uso, la reparación se limitará a los daños directos, personales, ciertos y previsibles.</p>
    <p> </p>
    <p>CapTarget Archery no garantiza la relevancia y exactitud de la información publicada en la Plataforma por terceros.</p>
    <p> </p>
    <p>CapTarget Archery no se hace responsable de las disputas, acciones o reclamaciones de terceros que puedan tener derechos, incluidos los derechos de propiedad, sobre cualquier contenido no creado por CapTarget Archery.</p>
    <p> </p>
    <p>Los términos en los que CapTarget Archery recopila y procesa los datos se detallan en la política de privacidad de datos.</p>
    <p> </p>
    <p>CapTarget Archery no será responsable ante usted por ningún daño indirecto, incidental, consecuente u otro daño similar y, en particular, por ninguna pérdida de ingresos, lucro cesante, pérdida de datos o pérdida de operaciones u otra pérdida no material (cualquiera sea la calificación dada a estas pérdidas), relacionada de alguna manera con estos términos de servicio o el propio servicio, y no estará obligada a pagarle daños especiales o punitivos, ya sea en base contractual, extracontractual o de otro tipo, y ya sea que CapTarget Archery haya sido informada o no de la posibilidad de dicho daño. La responsabilidad de CapTarget Archery hacia usted no excederá la cantidad que haya pagado a CapTarget Archery de acuerdo con estos términos de servicio en los seis (6) meses anteriores a la fecha en que presente una reclamación por primera vez. Usted reconoce y acepta que cuando no haya pagado nada a CapTarget Archery durante tal período, su único recurso (y la única responsabilidad de CapTarget Archery) en relación con cualquier disputa con CapTarget Archery será dejar de usar el servicio y cerrar su cuenta.</p>
    <p>En particular, ninguna de las disposiciones de estos términos de servicio afectará los derechos legales de cualquier consumidor ni excluirá ni limitará ninguna responsabilidad por muerte o lesiones personales resultantes de negligencia o fraude por parte de CapTarget Archery.</p>
    <p> </p>
    <p>Usted se compromete a indemnizar, defender y eximir de responsabilidad a CapTarget Archery (así como a sus desarrolladores, administradores y creadores de contenido) por cualquier reclamación, demanda, daño u otras pérdidas, incluidos los honorarios razonables de abogados, presentados por un tercero y resultantes de su uso del Servicio o de cualquier incumplimiento por su parte de estos Términos de Servicio. Sin embargo, lo anterior no se aplicará en caso de violación de derechos no atribuible a su comportamiento intencional o negligente.</p>
    <p> </p>
    <p>CapTarget Archery no será responsable por ningún retraso o falta de cumplimiento que resulte de causas legítimamente fuera de su control, incluyendo, pero no limitándose a, cualquier falta de cumplimiento de estos términos debido a circunstancias imprevisibles o causas fuera del control de CapTarget Archery, como desastres naturales, guerra, actos de terrorismo, disturbios, embargos, medidas de autoridades civiles o militares, incendios, inundaciones, accidentes, huelgas o escasez de transporte, combustible, energía, mano de obra o materiales.</p>
    <p> </p>
    <p>Los consejos sobre la práctica de actividades tal como se describen en la aplicación son indicativos, es responsabilidad del Usuario adaptar los consejos a su salud o buscar consejo médico. La responsabilidad de la práctica deportiva recae en el Usuario, CapTarget Archery no es de ninguna manera responsable de lesiones físicas o morales derivadas de la práctica de una actividad con la ayuda de la aplicación.</p>
    <p> </p>
    <p>El Usuario se compromete a informar cualquier vulnerabilidad de seguridad y a no utilizarlas en su beneficio o en el de otra persona/entidad. El Usuario se compromete a no realizar acciones que puedan perjudicar el buen funcionamiento de los servicios ofrecidos por CapTarget Archery y a no aprovechar posibles errores.</p>
    <p> </p>
    <h4>Propiedad Intelectual</h4>
    <p> </p>
    <p>Los servicios y su contenido (incluidos los datos, bases de datos, software, fotografías, información, ilustraciones, logotipos, marcas, etc.) que aparecen o están disponibles en la Plataforma están protegidos por todos los derechos de propiedad intelectual existentes, derechos de los productores de bases de datos y/u otros derechos reconocidos por la legislación vigente.</p>
    <p> </p>
    <p>Cualquier copia, reproducción, representación, adaptación, alteración, modificación o difusión no autorizada, total o parcial, de los servicios y/o contenidos de la Plataforma, ya sea que estos contenidos pertenezcan a CapTarget Archery, a un Usuario, a una Organización o a un tercero, es ilícita y puede incurrir en la responsabilidad penal y civil de su autor.</p>
    <p> </p>
    <p>CapTarget Archery otorga al Usuario el derecho de utilizar la Plataforma para sus propias necesidades, excluyendo cualquier uso con fines lucrativos.</p>
    <p> </p>
    <p>Cuando el Usuario carga contenido en la Plataforma, automáticamente otorga a CapTarget Archery una licencia personal y no exclusiva para usar dicho contenido de manera gratuita.</p>
    <p> </p>
    <p>Esta licencia se otorga en todo el mundo, para todas las aplicaciones en todos los medios y por todos los métodos de distribución conocidos o desconocidos hasta la fecha, y durante toda la duración de la protección legal de los derechos de propiedad intelectual (incluidas renovaciones y extensiones):</p>
    <p> </p>
    <p>el derecho de reproducción: el derecho de realizar o hacer que se realice un número ilimitado de copias del contenido, en cualquier forma o medio, conocido o desconocido hasta la fecha, y en particular en cualquier medio físico, magnético, digital u otro (por ejemplo, cualquier folleto, documento de prensa, póster, página web, informes y cualquier medio de grabación como CD, DVD, discos flexibles, discos duros internos o externos u otro medio de grabación), mediante cualquier proceso técnico conocido o desconocido hasta la fecha;</p>
    <p>el derecho de representación: el derecho de comunicar, difundir, comunicar o difundir contenidos a los miembros de la Organización del Usuario por cualquier medio, conocido o desconocido hasta la fecha, y en particular mediante cualquier red de telecomunicaciones o cualquier medio de lectura, intercambio de información y comunicación (Internet, red terrestre, cable, satélite, descarga, transmisión, etc.);</p>
    <p>el derecho de traducción de contenidos a cualquier idioma (incluyendo todos los lenguajes informáticos).</p>
    <p>El Usuario garantiza a CapTarget Archery que el contenido cargado en la Plataforma no infringe ningún derecho de propiedad intelectual u otro derecho de un tercero.</p>
    <p> </p>
    <p>En consecuencia, el Usuario acepta defender a CapTarget Archery y asumir la responsabilidad y/o indemnizar a CapTarget Archery por cualquier daño que pueda resultar de cualquier acción o reclamación de un tercero con respecto al contenido publicado por el usuario.</p>
    <p> </p>
    <h4>Documentos contractuales</h4>
    <p> </p>
    <p>Estos Términos y Condiciones de Uso rigen todas las relaciones comerciales contractuales o precontractuales entre CapTarget Archery, el Usuario y/o la Organización, en relación con el uso de la Plataforma y la suscripción a las Suscripciones ofrecidas por CapTarget Archery.</p>
    <p> </p>
    <p>Estos Términos y Condiciones prevalecen sobre cualquier acuerdo o disposición en contrario entre las partes. Anulan y reemplazan todos los Términos y Condiciones Generales anteriores que puedan haber regido las relaciones entre las partes.</p>
    <p> </p>
    <p>Estos Términos y Condiciones constituyen la totalidad de los documentos contractuales entre las partes.</p>
    <p> </p>
    <p>CapTarget Archery se reserva el derecho de modificar en cualquier momento el contenido de estos Términos y Condiciones. Estas modificaciones darán lugar a una nueva versión que se aplicará automáticamente a todos los servicios proporcionados posteriormente.</p>
    <p> </p>
    <h4>Ley aplicable, Mediación y Jurisdicción</h4>
    <p> </p>
    <p>Estos Términos y Condiciones están sujetos a la ley francesa.</p>
    <p> </p>
    <p>Las partes acuerdan buscar una solución amistosa antes de cualquier acción legal. En caso de reclamación que no pueda resolverse de manera amistosa entre las partes, el Usuario puede recurrir, de forma gratuita, a la mediación de consumo. Se propone recurrir al siguiente mediador: Centre de Médiation et d'Arbitrage de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p> </p>
    <p>El Usuario también puede utilizar la plataforma de resolución de disputas en línea de la Comisión Europea en la siguiente dirección: www.ec.europa.eu/consumers/odr/.</p>
</div>
