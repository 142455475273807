import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-promo-one',
    templateUrl: './promo-one.component.html',
    styleUrls: ['./promo-one.component.css']
})
export class PromoOneComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    goTo(com: string): void {
      window.open(com, '_system');
    }
}
