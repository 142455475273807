import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions-archery',
  templateUrl: './questions-archery.component.html',
  styleUrls: ['./questions-archery.component.css']
})
export class QuestionsArcheryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
