import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    currentLanguage = 'fr';

    constructor(public translateService: TranslateService) {
        this.currentLanguage = navigator.language ? navigator.language.split('-')[0]
                ?? (this.translateService.getBrowserLang() ?? 'fr') : (this.translateService.getBrowserLang() ?? 'fr');
        this.setCurrentLanguage(this.currentLanguage);
        // this.setCurrentLanguage('en');
    }

    setCurrentLanguage(language: string): void {
        this.currentLanguage = language ?? 'fr';
        this.translateService.use(language);
        this.translateService.setDefaultLang(language);
    }
}
