<div class="container">
    <h4>정의</h4>
    <p></p>
    <p>다음 용어들은 이용 약관에서 첫 글자가 대문자로 작성되어 있는 경우, 본 조항에서 명시된 의미를 갖습니다.</p>
    <p></p>
    <p>가입: 플랫폼의 서비스에 무료 또는 유료로 가입하는 것을 의미합니다.</p>
    <p></p>
    <p>궁수: 활을 쏘는 사용자로서 앱의 서비스를 사용하거나 사용하지 않는 사용자를 의미합니다.</p>
    <p></p>
    <p>코치: 활 쏘기 트레이너로서 "Coach" 유형의 무료 또는 유료 가입을 완료하고 관련 서비스에 액세스하는 사용자를 의미합니다.</p>
    <p></p>
    <p>앱: Android 및 iOS에서 이용 가능한 "CapTarget Archery" 모바일 애플리케이션을 의미합니다.</p>
    <p></p>
    <p>사이트: CapTarget Archery의 쇼케이스 사이트를 의미합니다.</p>
    <p></p>
    <p>코치 사이트: 코치에게 제한된 액세스가 있는 사이트를 의미합니다.</p>
    <p></p>
    <p>이용 약관: 플랫폼의 이용 약관을 의미합니다.</p>
    <p></p>
    <p>계정: 플랫폼과 관련된 다양한 서비스에 액세스 할 수 있는 사용자에게 할당된 계정을 의미하며 개인 식별자와 액세스 코드가 연결되어 있습니다.</p>
    <p></p>
    <p>그룹: "CapTarget Archery" 애플리케이션에서 생성되고 사용자 및 코치를 그룹화하는 그룹을 의미합니다.</p>
    <p></p>
    <p>파트너: CapTarget Archery와 협력 관계를 맺은 개인 또는 법인으로서 플랫폼을 통해 사용자에게 소개되는 사람을 의미합니다.</p>
    <p></p>
    <p>플랫폼: 사이트 및/또는 애플리케이션에서 액세스 가능한 모든 서비스를 의미합니다.</p>
    <p></p>
    <p>사용자: 플랫폼에 액세스하고 사용하는 개인 또는 법인을 의미하며 계정을 통해 액세스할 수 있든 안 하든 상관 없습니다.</p>
    <p></p>
    <p>젬: "CapTarget Archery"에서 사용되는 가상 화폐를 의미합니다.</p>
    <p></p>
    <h4>목적</h4>
    <p></p>
    <p>이용 약관은 플랫폼의 모든 사용자에게 적용되는 조건과 약관을 정의하는 것을 목적으로 합니다.</p>
    <p></p>
    <p>플랫폼의 가입, 액세스 및/또는 이용은 사용자가 이용 약관 전체를 무조건적으로 수락하고 준수한다는 것을 의미합니다.</p>
    <p></p>
    <p>미성년자 사용자는 부모 또는 부모의 권한을 가진 자의 허락을 받았다고 진술하며 플랫폼에 가입하는 것을 인정합니다.</p>
    <p></p>
    <p>로봇이나 다른 방법으로 자동으로 생성된 가입 요청은 거부될 것입니다.</p>
    <p></p>
    <h4>애플리케이션 소개</h4>
    <p></p>
    <p>이 애플리케이션은 주로 활을 쏘는 사람들이나 코치를 위해 개발되었으며, 스포츠와 관련된 활동을 관리하고 조직하며 회원 간 정보를 교류하거나 관련 분야의 소식을 전달하는 데 사용됩니다.</p>
    <p></p>
    <p>이 애플리케이션을 통해 사용자는 다음과 같은 기능을 활용할 수 있습니다:</p>
    <p></p>
    <p>연습 및 대회 세션 관리</p>
    <p>계수 및 경기 관리</p>
    <p>사격 장비 관리</p>
    <p>데이터 활용을 통한 통계 제공</p>
    <p>소셜 및 놀이 상호작용, 사용자의 코치에게 데이터 제공</p>
    <p>그룹 내 회원 간 소셜 및 놀이 상호작용</p>
    <p>가상 화폐 "젬"을 사용한 경험 개인화 아이템 구매</p>
    <p>모바일 결제 시스템(ANDROID 또는 APPLE)을 통한 "젬" 구매</p>
    <p>메시징</p>
    <p></p>
    <h4>사용자 등록</h4>
    <p></p>
    <p>각 사용자는 애플리케이션과 관련된 서비스에 액세스하기 위해 계정(개인 또는 만 18세 미만의 회원의 경우 법정 대리인 자격이 있는 경우 회원의 계정)을 생성할 수 있습니다.</p>
    <p></p>
    <p>등록은 사용자에 의해 애플리케이션에서 수행됩니다. 사용자는 등록 양식의 다양한 필드를 작성해야 하며, 유효한 이메일 주소, 이름 및 성, 활을 쏘는 실천과 관련된 다양한 정보를 입력해야 합니다. 또한 이용 약관과 데이터 개인 정보 정책을 확인하고 수락해야 합니다.</p>
    <p></p>
    <p>등록 양식을 작성함으로써 사용자는 정확하고 최신 및 완전한 정보만 제공할 것에 동의합니다.</p>
    <p></p>
    <p>사용자는 계정의 액세스 코드를 비밀로 유지할 것을 동의합니다. 부정 사용이 의심될 경우 사용자는 즉시 CapTarget Archery에 알려야 합니다.</p>
    <p></p>
    <p>사용자는 자신의 계정 사용에 대한 책임을 집니다. 플랫폼을 통한 연결이나 데이터 전송은 사용자에 의해 수행되었으며 그 책임은 전적으로 사용자에게 있습니다.</p>
    <p></p>
    <p>CapTarget Archery는 사용자의 등록 데이터가 CapTarget Archery에 도달하지 않는 경우(예: 사용자의 인터넷 연결 문제, 서버 일시적인 장애 등 사용자의 원인이 아닌 경우) 또는 데이터가 CapTarget Archery에 처리 불가능한 형태로 도달하는 경우(예: 사용자가 부적절한 하드웨어나 소프트웨어를 사용하는 경우)에 대해 책임을지지 않습니다.</p>
    <p></p>
    <p>등록은 무기한으로 이루어지며 사용자 계정은 해지될 때까지 활성 상태를 유지합니다.</p>
    <p></p>
    <p></p>
    <h4>그룹 등록</h4>
    <p></p>
    <p>사용자는 그룹의 기능을 활용하기 위해 그룹을 생성하거나 가입할 수 있습니다.</p>
    <p></p>
    <p>등록 양식을 작성함으로써 관리자는 정확하고 최신 및 완전한 정보만 제공할 것에 동의합니다. 그리고 관리자는 관련 이메일 주소와 전화번호를 CapTarget Archery에 제공하기 전에 해당 멤버의 사전 동의를 확보했음을 보장합니다. 이 정보는 언제든지 CapTarget Archery에 삭제를 요청할 수 있는 자유로운 권리가 있습니다(개인 정보 보호 정책을 참조하세요).</p>
    <p></p>
    <p>관리자는 그룹의 사용에 대한 책임을 집니다.</p>
    <p>또한 관리자는 자기 자신 및 어떠한 제3자로 인해 그룹 공간을 사용하는 것에 대해 전적으로 책임을 집니다.</p>
    <p></p>
    <p>CapTarget Archery는 그룹 등록에 대한 데이터가 CapTarget Archery에 도달하지 않는 경우(예: 관리자의 인터넷 연결 문제, 서버 일시적인 장애 등 관리자의 원인이 아닌 경우) 또는 데이터가 CapTarget Archery에 처리 불가능한 형태로 도달하는 경우(예: 관리자가 부적절한 하드웨어나 소프트웨어를 사용하는 경우)에 대해 책임을지지 않습니다.</p>
    <p></p>
    <p>등록은 무기한으로 이루어지며 관리자 중 한 명이 그룹을 종료할 때까지 그룹은 활성 상태를 유지합니다.</p>
    <p></p>
    <p></p>
    <h4>코치 등록</h4>
    <p>코치의 기능을 활용하기 위해 사용자는 CapTarget Archery의 코치 서비스에 액세스 할 수 있는 구독을 신청해야 합니다. 이 구독은 웹 사이트 및/또는 모바일 애플리케이션을 통해 제공되며 구독한 제안에 따라 사용됩니다.</p>
    <p>다른 사람이 대신 구독한 것에 따라 사용자에게 액세스 라이선스를 제공할 수 있습니다. 액세스 라이선스는 해당 라이선스로 등록한 사용자에게만 엄격하게 제공되며 계정은 다른 사람과 공유할 수 없습니다.</p>
    <p>다양한 제안의 혜택은 변경될 수 있습니다. 현재 구독이 진행 중인 경우 이러한 변경은 적용되지 않거나 사용자의 이익으로 간주될 수 있습니다.</p>
    <p>등록은 사용자에 의해 웹 사이트에서 수행되며 유효한 이메일 주소, 이름 및 성, 활을 쏘는 실천과 관련된 다양한 정보를 입력해야 합니다. 또한 이용 약관, 판매 약관 및 데이터 개인 정보 정책을 확인하고 수락해야 합니다.</p>
    <p>등록 양식을 작성함으로써 코치 및 라이선스를 신청한 소비자는 정확하고 최신 및 완전한 정보만 제공할 것에 동의합니다. 코치 라이선스를 사용하는 사용자는 계정의 사용에 대한 책임을 집니다. 플랫폼을 통한 연결이나 데이터 전송은 사용자에 의해 수행되었으며 그 책임은 전적으로 사용자에게 있습니다.</p>
    <p>CapTarget Archery는 사용자의 등록 데이터가 CapTarget Archery에 도달하지 않는 경우(예: 사용자의 인터넷 연결 문제, 서버 일시적인 장애 등 사용자의 원인이 아닌 경우) 또는 데이터가 CapTarget Archery에 처리 불가능한 형태로 도달하는 경우(예: 사용자가 부적절한 하드웨어나 소프트웨어를 사용하는 경우)에 대해 책임을지지 않습니다. 유료 구독은 무기한으로 이루어지며 사용자의 명시적인 요청이 없는 한 최소한의 계약 기간 후 자동으로 갱신됩니다. 탈퇴 방법에 대한 자세한 내용은 판매 약관을 참조하세요.</p>
    <p></p>

    <h4>데이터 공유</h4>
    <p>CapTarget Archery를 통한 데이터 공유 기능에 액세스하기 위해 우리의 애플리케이션은 팬 및 팔로우 시스템을 제공합니다. 사용자로서 다른 사용자를 팔로우하거나 초대할 수 있는 기회가 주어집니다.</p>
    <p>공개 프로필인 경우 다른 사용자가 당신을 팔로우하기 위해 승인이 필요하지 않습니다. 사용자가 팔로우하면 일부 데이터가 자동적으로 또는 수동적으로 공유될 수 있습니다. 이 데이터에는 다음과 같은 정보가 포함될 수 있습니다:</p>
    <p>- 대회 또는 훈련 세션 결과</p>
    <p>- 라이선스 기간, 최고 점수, 랭킹과 같은 다양한 스포츠 통계</p>
    <p>- 애플리케이션 사용과 관련된 데이터, 좋아하는 게임 및 연습</p>
    <p>- 클럽, 언어, 국가 정보 및 이름, 성, 사용하는 활 유형 또는 사격 카테고리와 같은 다른 데이터</p>
    <p>이러한 데이터 중 일부를 공유하지 않도록 개인 설정이나 각 상황 설정을 수정하여 선택할 수 있습니다.</p>
    <p>계정을 생성하는 순간, 당신은 CapTarget 플랫폼에서 가시성을 가지게 된다는 것에 동의합니다. 다른 사용자는 당신의 이름과 성을 사용하여 당신을 검색할 수 있습니다. 또한 당신의 프로필은 스포츠적인 도전이나 팔로우 연결을 만들기 위해 다른 사용자에게 자동으로 제안될 수 있습니다.</p>
    <p>CapTarget, 파트너, 광고주 또는 다른 사용자에 의해 CapTarget Archery에서 공유되는 정보에 노출될 수 있다는 점을 참고해 주세요. 개인 데이터 관리에 대한 자세한 내용은 개인 정보 보호 정책을 확인하시기 바랍니다.</p>
    <p>이 모든 조항들이 개인 정보 보호 및 개인 정보 관련 법률 및 규정을 준수합니다. 추가적인 문의나 질문이 있으시면 언제든지 연락주시기 바랍니다.</p>
    <p></p>
    <p></p>
    <p></p>
    <h4>경고</h4>
    <p>CapTarget Archery 플랫폼의 코치 버전에 액세스하려면 웹 버전에는 컴퓨터, 태블릿 또는 휴대폰이 필요하며 모바일 버전에는 안드로이드 또는 iOS 휴대폰 또는 태블릿이 필요합니다. 데이터를 다운로드하려면 인터넷 연결이 필요합니다. 이 서비스에 액세스하기 위해 이러한 요구 사항을 인지했다고 가정합니다.</p>
    <p>서비스에서 제공되는 연습 및 상황은 사용자가 좋은 신체 상태에 있으며 이를 수행할 준비가 되어 있다고 가정합니다. 구독자는 자신의 건강, 안전 및 다른 사람의 건강과 안전을 보호하기 위해 필요한 모든 조치를 취하기로 동의합니다.</p>
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <h4>판매 약관</h4>
    <p>CapTarget Archery가 제공하는 디지털 서비스나 제품을 구독하거나 구매할 경우 아래에 설명된 판매 조건에 동의하는 것으로 간주됩니다. 이러한 조건에 동의하지 않는 경우 애플리케이션이나 웹 사이트에서 아무 것도 구매하지 마십시오.</p>
    <h6>구독</h6>
    <p>CapTarget이 제공하는 콘텐츠를 구매하면 CapTarget은 애플리케이션 또는 웹 사이트에서 일부 유료 기능에 액세스할 수 있는 제한적이고 철회 가능하며 배타적이고 양도 불가능한 라이선스를 부여합니다. 우리의 애플리케이션에서는 자동 갱신되는 월별 및 연간 구독 옵션이 제공됩니다. 구독 비용은 구독기간의 연중 기념일에 선택한 결제 방법에서 자동으로 청구됩니다. CapTarget Archery의 가상 통화인 젬(gem)을 사용하여 지불하는 구독은 자동 갱신되지 않습니다.</p>
    <p>구독을 갱신하지 않으려면 구독 기간 종료 24시간 이전에 계정 설정으로 이동하여 비활성화해야 합니다.</p>
    <h6>가격</h6>
    <p>우리의 구독 가격은 애플리케이션에서 게시되며 예고 없이 언제든지 변경될 수 있습니다. 구독 가격에는 적용 가능한 모든 세금이 포함됩니다. 인터넷 액세스 및 모바일 데이터 사용에 따른 모든 비용은 사용자가 부담하셔야 합니다. 노력에도 불구하고 카탈로그의 일부 항목은 잘못 평가될 수 있습니다. 가격은 현재 구독자에게도 변경될 수 있습니다. 선택한 시스템(애플, 구글, 스트라이프 또는 기타)에 따라 현재 구독의 가격이 인상될 경우 알림을 받을 수 있으며 갱신 전에 구독을 취소할 수 있습니다.</p>
    <h6>손실 위험</h6>
    <p>CapTarget 콘텐츠가 제공된 후에 CapTarget 콘텐츠의 손실 위험은 구매한 후에도 당신에게 있습니다. 파일 손상이나 장치 또는 하드 드라이브 장치의 고장으로 인한 손실을 포함합니다. 구매한 CapTarget 콘텐츠는 일반적으로 계정에서 사용 가능하며 다양한 이유로 사용할 수 없게 될 수 있습니다. 이 경우 CapTarget은 나중의 CapTarget 콘텐츠의 가용성에 대해 책임지지 않으며 이미 지불한 비용에 대해 전액 또는 일부 환불을 받을 권리가 없습니다.</p>
    <h6>배송</h6>
    <p>CapTarget 콘텐츠에 액세스하는 것은 제품이나 구독을 구매할 때 제공됩니다. 콘텐츠는 당신의 CapTarget 계정에서 사용 가능합니다. 애플리케이션을 다시 시작하거나 서비스가 사용 가능하게 되는 데는 합리적인 시간이 소요될 수 있습니다. (일반적으로 몇 분 이내)</p>
    <h6>결제</h6>
    <p>결제 기능을 사용하려면 CapTarget 계정에 유효한 결제 방법(신용 카드 또는 다른 승인된 결제 방법)이 있어야 합니다. 구독 비용은 Apple 또는 Google Play 계정 또는 당사 웹 사이트에서 구독을 구매했을 경우 해당 계정의 결제 방법을 기반으로 자동으로 청구됩니다. 정확하고 최신의 결제 정보를 제공하는 것에 동의하셔야 합니다. 어떤 이유로 결제가 이루어지지 않으면 우리는 귀하의 구독을 중단하거나 취소할 권리를 보유합니다.</p>
    <h6>애플리케이션 사용</h6>
    <p>당신은 우리의 애플리케이션을 개인적이고 상업적이지 않은 목적으로 사용할 수 있습니다. 우리의 사전 서면 승인 없이 우리의 애플리케이션 콘텐츠를 수정, 배포, 판매 또는 양도할 수 없습니다. 당신은 해당 법률을 위반하거나 타사의 권리를 침해하거나 그들에게 해가 가는 방식으로 우리의 애플리케이션을 사용할 수 없습니다.</p>
    <h6>환불</h6>
    <p>우리의 구독은 3주간 무료로 테스트할 수 있으며 환불이 불가능합니다. 각 구독의 기능을 확인한 후 구독을 선택하기 전에 주의하십시오. 판매되는 디지털 제품은 명시적이며 환불이 불가능합니다.</p>
    <h6>해지</h6>
    <p>귀하는 판매 조건이나 이용 약관을 위반하거나 어떤 방식으로든 우리의 애플리케이션을 사용하는 것으로 판단되는 경우 액세스 권한을 해지할 권리를 보유합니다. 우리의 애플리케이션 구독을 구매함으로써 판매 조건에 동의하게 됩니다.</p>

    <h6>문의하기</h6>
    <p>질문이나 관심사가 있으시면 다음 주소로 문의해주세요: support@captarget-archery.com</p>
    <p>문제를 해결하기 위해 최선을 다하겠으며 모든 당사자가 상호 협력하여 해결책을 찾을 수 있도록 노력하겠습니다.</p>
    <p></p>
    <p></p>
    <h4>플랫폼 사용 비용</h4>
    <p></p>
    <p>사용자의 계정 및 그룹 생성은 무료입니다. 그러나 플랫폼 사용에 따른 연결 및 통신 비용은 사용자의 책임하에 발생합니다.</p>
    <p>코치 버전에 액세스하는 데는 특정 요금이 부과됩니다. 테스트 버전 및 베타 버전은 제외하고 유료입니다. 가격은 버전이 출시되면 웹 사이트에서 확인하실 수 있습니다.</p>
    <h4>플랫폼을 통한 구매</h4>
    <p></p>
    <p>실제 돈으로 제한된, 개인용, 양도 및 하위라이선스 불가능한 제한된 라이선스를 구매하여 사용할 수 있습니다. "가상 화폐" 중 하나인 가상 화폐 및 특히 가상 현금 또는 다이아몬드를 사용하여 CapTarget Archery 게임에서 사용할 수 있습니다. 또한 행사, 대회, 게임을 통해 이 가상 화폐를 획득하거나 광고 시청 또는 플랫폼에서 목표 달성 시 보상으로 받을 수도 있습니다.</p>
    <p></p>
    <p>이 가상 화폐를 사용하여 애플리케이션에서 판매되는 다양한 제품을 구매할 수 있습니다.</p>
    <p></p>
    <p>허용되는 경우를 제외하고 가상 아이템의 이전은 금지됩니다. CapTarget Archery는 이러한 권한이 명시적으로 허용되는 경우를 제외하고는 가상 아이템을 판매, 구매, 교환하거나 어떤 방식으로든 전환할 수 없습니다.</p>
    <p></p>
    <p></p>
    <p>CapTarget Archery는 언제든지 권한을 행사하여 가상 아이템을 관리, 조절, 수정 또는 삭제할 수 있습니다. 이러한 권한을 행사하는 경우 CapTarget Archery는 당신이나 제3자에게 어떠한 책임도 지지 않습니다.</p>
    <p></p>
    <p>제3자의 가상 아이템을 통한 모든 구매 및 전환이 최종적이며 환불되지 않습니다.</p>
    <p></p>
    <p>당신 또는 당신의 이름으로 등록된 계정을 사용하는 사람이 지불해야 하는 모든 적용 가능한 요금 및 세금을 지불하는 것에 동의합니다. CapTarget Archery는 서비스를 통해 제공되는 가상 아이템의 가격을 언제든지 변경할 수 있습니다. 당신은 CapTarget Archery에 어떤 이유에서든 환불을 요청할 수 없으며 계정을 닫을 때 사용하지 않은 가상 아이템에 대한 어떠한 금액 또는 보상도 받지 못할 것입니다.</p>
    <p></p>
    <h4>광고 및 파트너</h4>
    <p></p>
    <p>CapTarget Archery는 다양한 서비스에서 광고 및/또는 파트너 사이트로의 링크를 제공할 수 있습니다.</p>
    <p></p>
    <p>CapTarget Archery는 이러한 사이트 및 외부 소스의 가용성에 대한 어떠한 책임도 지지 않으며 해당 사이트에서 제공되는 콘텐츠, 광고, 제품, 서비스 또는 다른 모든 자료에 대해 책임을 지지 않습니다.</p>
    <p></p>
    <p>마찬가지로, CapTarget Archery는 광고 사이트, 파트너 사이트 또는 외부 소스에서 제공되는 콘텐츠 또는 서비스의 사용으로 발생하는 실제 또는 추정된 손실이나 손해에 대한 어떠한 책임도 지지 않습니다.</p>
    <p></p>
    <h4>계정 해지</h4>
    <p></p>
    <p>사용자는 언제든지 계정을 해지할 수 있습니다. 계정을 해지하면 관련된 개인 데이터가 삭제됩니다.</p>
    <p></p>
    <p>CapTarget Archery는 어떤 피해 배상을 청구할 권리를 보유하며, 사전 통지나 보상 없이 사용자의 계정을 일시적 또는 영구적으로 종료할 수 있는 권한을 보유합니다.</p>
    <p>이 조항은 본 약관을 위반한 경우, 가입 시 잘못된 정보 제공, CapTarget Archery의 상업적 이익에 반하는 조치 등에 대해 적용됩니다.</p>
    <p></p>
    <p>CapTarget Archery가 사용자가 본 약관이나 판매 조건을 위반하거나 부정행위를 한 경우 해당 계정을 해지할 권한을 보유합니다. 이 경우에도 결제한 금액을 환불하지 않으며, 유료 구독인 경우에도 해당 금액은 CapTarget Archery의 소유로 유지됩니다.</p>
    <p></p>
    <h4>그룹 삭제</h4>
    <p></p>
    <p>관리자는 언제든지 그룹을 삭제할 수 있습니다. 기관의 공간 삭제는 관련된 모든 데이터 및 혜택이 삭제됩니다.</p>
    <p></p>
    <p>CapTarget Archery의 판매 조건을 위반한 경우, 등록 시 잘못된 정보를 제공한 경우, CapTarget Archery의 상업적 이익에 반하는 조치를 취한 경우 등의 사유로, 사전 통지나 보상 없이 기관의 공간을 일시적 또는 영구적으로 삭제할 권한을 보유합니다.</p>
    <p></p>
    <p>CapTarget Archery에 의한 기관의 공간 삭제는 어떤 보상이나 환불을 요구할 권리를 발생시키지 않으며, 유료 구독인 경우에도 해당 금액은 CapTarget Archery의 소유로 유지됩니다. 기관의 공간 삭제는 기관 또는 관리자가 구독 종료 의무에서 해방되는 것이 아닙니다.</p>
    <p></p>
    <h4>플랫폼 정보 및 콘텐츠</h4>
    <p></p>
    <p>사용자는 플랫폼을 사용할 때 특히 그룹 내에 있는 정보 및 콘텐츠를 이용하는 데 있어서 판단력을 발휘할 것을 약속합니다.</p>
    <p></p>
    <p>이와 관련하여 사용자는 플랫폼에서 제공되는 정보 및 콘텐츠가 완전하지 않을 수 있으며, 주로 기관 관리에 도움을 제공하기 위한 목적으로 만들어진 것임을 인식해야 합니다.</p>
    <p></p>
    <p>사용자는 플랫폼에서 제공되는 콘텐츠를 전체 또는 일부 판매, 재판매 또는 어떤 목적으로든 이용하는 것을 금지합니다.</p>
    <p></p>
    <p>사용자는 플랫폼을 통해 게시하고 전송하는 의사 소통, 정보, 파일, 비디오, 사진, 문서 또는 기타 콘텐츠에 대한 책임을 집니다.</p>
    <p></p>
    <p>사용자가 플랫폼에 게시한 의사 소통, 정보, 파일, 비디오, 사진, 문서 또는 기타 콘텐츠는 사용자 본인의 의견을 나타내는 것이며 반드시 CapTarget Archery나 해당 회사의 직원 또는 대리인의 의견을 반영하는 것은 아닙니다.</p>
    <p></p>
    <p>플랫폼을 통해 사용자가 게시하려는 의사 소통, 정보, 파일, 비디오, 사진, 문서 또는 기타 콘텐츠에 대해 삼가분별과 신중함을 기울이도록 CapTarget Archery는 권고합니다. 사용자는 플랫폼에 반드시 다음과 같은 내용을 게시하지 않아야 합니다.</p>
    <p></p>
    <p></p>
    <p>공공 질서나 사회 도덕에 위배되는 정보를 유포하는 것</p>
    <p>포판이나 홍보, 선전을 목적으로 플랫폼의 목적을 왜곡하는 것</p>
    <p>상업적이거나 광고적 정보 또는 금연, 금주 등 규제되는 제품, 서비스를 홍보하는 것</p>
    <p>타인의 인격권을 침해하거나 명예를 훼손하는 내용 또는 명예훼손, 욕설, 음란, 포르노, 공격적이거나 차별적인 내용을 유포하는 것</p>
    <p>개인 정보 보호 법령에 위반되는 내용으로, 사람들의 개인 정보(성명, 주소, 전화번호, 사진 등)를 동의 없이 게시하는 것</p>
    <p>타인의 지적 재산권을 침해하는 내용으로, 저작권, 상표권 등의 표시를 제거하거나 삭제하거나 상세한 설명을 생략하는 것</p>
    <p></p>
    <p>또한 사용자는 다른 사람의 권리를 존중해야 하며 특히 다음과 같은 권리를 존중해야 합니다.</p>
    <p></p>
    <p>사진, 이미지, 텍스트, 움직이는 이미지 등의 저작권</p>
    <p>상표권</p>
    <p>저작권(소프트웨어, 소리, 이미지, 사진, 텍스트, 애니메이션 이미지 등) 및 이에 상응하는 저작권 법에서 정한 권리(공연자, 음향 기록물 및 영상 기록물 제작자, 데이터베이스 제작자에 관한 법률에서 정한 권리)를 존중하는 것</p>
    <p>일반적으로 개인과 재산에 대한 권리</p>
    <p></p>

    <p>사용자는 또한 CapTarget Archery의 사전 서면 허가 없이 다음을 포함한 제한 없는 경우에는 다음을 금지합니다 :</p>
    <p> </p>
    <p>직접 또는 간접적으로 보상받는 제품 및 서비스를 홍보하거나 일반적으로 홍보 목적으로 플랫폼을 사용하기</p>
    <p>플랫폼의 콘텐츠로부터 아카이브 파일을 생성하기</p>
    <p>플랫폼에 표시된 콘텐츠, 상표, 로고, 상징 등 일부 또는 전체를 복제, 표시, 사용, 참조(특히 검색 엔진 메타 태그에)하는 것</p>
    <p> </p>
    <p>본 CGU를 준수하지 않을 경우, 해당 콘텐츠의 즉시 자동 삭제, 경고 및/또는 사용자 계정 폐쇄 등이 발생할 수 있으며, CapTarget Archery가 소송을 제기하거나 손해배상을 청구할 권리에 영향을 미치지 않습니다.</p>
    <p> </p>
    <p>CapTarget Archery는 플랫폼의 정보, 파일, 비디오, 사진, 문서 또는 기타 콘텐츠에 대한 책임을 지지 않으며, 이는 불법 콘텐츠, 오류, 생략 또는 어떤 사용자에 의한 이러한 자료의 사용으로 인한 손실이나 손해를 포함하지만 이에 국한되지 않습니다.</p>
    <p> </p>
    <p>사이트의 하위 페이지로의 하이퍼링크는 CapTarget Archery의 명시적 허가를 받은 경우를 제외하고 엄격히 금지되며, 어떠한 하이퍼링크도 CapTarget Archery의 요청에 따라 사용자에 의해 제거되어야 합니다.</p>
    <p> </p>
    <h4>관리자의 모더레이터 역할</h4>
    <p> </p>
    <p>조정자 및 그룹의 감독자로서, 관리자는 회원들이 CGU 조항을 준수하도록 보장하는 역할을 맡으며, 그가 인지하는 즉시, 관련 법령 및/또는 본 CGU에 위반하는 콘텐츠(가능한 경우)를 신고하거나 제거하는 데 동참합니다. 이로 인해 발생하는 피해, 손실 또는 어떤 법적 조치에 대한 책임을 지지 않습니다.</p>
    <p> </p>
    <p>이 규칙과 관습을 어기면, CapTarget Archery는 관리자 및/또는 위반하는 회원을 제외하고, 플랫폼의 오용으로 인한 불법적인 피해를 중단시키기 위한 조치로 적절한 적법 조치를 취할 권리를 보유하며, 이로 인한 모든 책임을 지지 않습니다.</p>
    <p> </p>
    <h4>이메일, 푸시 알림 및 SMS 전송</h4>
    <p> </p>
    <p>CapTarget Archery는 제공되는 서비스의 일환으로 회원들 간에 조직과 관련된 정보를 교환하기 위해 전자 메일, 애플리케이션의 푸시 알림 및 SMS를 활용하는 통신을 제공합니다. 이 통신은 회원들이 계정 생성 시 제공한 전화번호와 이메일 주소를 통해 이루어집니다.</p>
    <p> </p>
    <p>사용자는 언제든지 자신의 장치 설정을 수정하거나 지원팀에 요청함으로써 CapTarget Archery로부터 이메일, 푸시 알림 또는 SMS를 더 이상 받지 않을 수 있습니다.</p>
    <p> </p>
    <h4>플랫폼 접근 - 책임과 약정</h4>
    <p> </p>
    <p>CapTarget Archery는 기술 발전에 따라 필요한 경우 플랫폼을 24시간 7일, 정기적인 유지 보수 작업 및 본 조항의 규정에 따라 제외하고 제공하기 위해 노력합니다.</p>
    <p> </p>
    <p>CapTarget Archery는 기술 발전에 따라 필요한 경우 플랫폼에 변경 및 개선을 가할 수 있습니다. 따라서 CapTarget Archery는 예고 없이 혹은 보상 없이 진화적 또는 정정적인 유지보수 작업을 수행하기 위해 플랫폼의 임시적인 접근 차단을 할 수 있습니다. 사용자는 이와 관련하여 CapTarget Archery에 대한 책임을 면제하고 이와 관련된 어떠한 청구도 포기합니다.</p>
    <p> </p>
    <p>CapTarget Archery는 사용자의 장치나 컴퓨터에 설치된 CapTarget Archery의 서비스와 게임을 업데이트하는 것을 요구할 수 있습니다. 사용자는 CapTarget Archery의 서비스와 게임을 업데이트할 수 있으며, 이에 대한 알림을 받거나 받지 않게 됩니다. 사용자는 CapTarget Archery의 서비스와 게임을 사용하기 위해 타사 소프트웨어를 업데이트해야 할 수도 있습니다.</p>
    <p> </p>
    <p> </p>
    <p>CapTarget Archery는 명시적 또는 암시적 보증을 포함하여 특정 용도에 대한 품질 및 적합성과 관련된 보증을 포함하여 어떠한 보증도 제공하지 않습니다.</p>
    <p> </p>
    <p>CapTarget Archery는 불가항력 또는 프랑스 민법 제1218조에서 정의한 사고와 같은 불가항력적인 사유로 인해 플랫폼의 무단 접근 및 지속적인 운영을 보장하지 않습니다.</p>
    <p> </p>
    <p>본 CGU에 따른 의무 위반으로 사용자가 CapTarget Archery에 대해 책임을 지게 될 경우, 보상은 직접적이고 개인적이며 특정하며 예측 가능한 피해로 한정될 것입니다.</p>
    <p> </p>
    <p>CapTarget Archery는 제3자가 플랫폼에 게시한 정보의 타당성과 정확성을 보증하지 않습니다.</p>
    <p> </p>
    <p>CapTarget Archery는 제3자가 작성하지 않은 콘텐츠에 대한 권리, 특히 소유권권리에 대한 분쟁, 조치 또는 요구사항에 대한 책임을 지지 않습니다.</p>
    <p> </p>
    <p>CapTarget Archery가 데이터를 수집하고 처리하는 조건은 데이터의 개인 정보 보호 정책에서 자세히 설명되어 있습니다.</p>
    <p> </p>
    <p>CapTarget Archery는 간접적, 부수적, 결과적 또는 유사한 다른 손해로부터 비롯되는 어떤 특수 손해나 처벌적 손해에 대해 책임을지지 않으며, 특히 수익 손실, 이익 손실, 데이터 손실 또는 영업 중단 손실과 같은 비물적 손해에 대한 책임을지지 않습니다(이러한 손해에 대한 명칭을 부여하는 것에 관계없이), 이러한 서비스 약관 또는 서비스 자체와 관련하여 계약 상의, 불법 행위 상의 또는 기타 근거에 따라 CapTarget Archery가 특별하거나 처벌적인 손해 및 손해배상금을 지급하지 않아도 됩니다. CapTarget Archery의 책임은 사용자가 첫 번째로 클레임을 제기한 날로부터 직전 6개월 동안 서비스 약관에 따라 CapTarget Archery에 지불한 금액을 초과하지 않을 것입니다. 사용자는 이러한 기간 동안 CapTarget Archery에 아무런 지불을 하지 않은 경우, 어떤 청구를 하더라도 사용자의 유일한 구제 조치(및 CapTarget Archery의 유일한 책임)가 서비스의 사용 중단 및 계정 닫기임을 인정하고 동의합니다.</p>
    <p>특히, 본 서비스 약관의 어떠한 조항도 CapTarget Archery의 고의나 과실로 인한 사망이나 신체상해로 인한 책임을 제한하거나 배제하거나 제한하지 않을 것입니다.</p>
    <p> </p>
    <p>사용자는 본 서비스 약관의 위반에 따른 요구, 손해, 기타 손실, 합리적인 변호사 비용을 포함하되 이에 국한되지 않는 소송비용을 CapTarget Archery(및 그 개발자, 관리자 및 콘텐츠 작성자)로부터 보호하고 변호하며 면제할 것에 동의합니다. 단, 의도적 또는 과실적인 행동에 기인하지 않은 권리 침해의 경우 이에 해당하지 않습니다.</p>
    <p> </p>
    <p>CapTarget Archery는 합법적으로 제어할 수 없는 원인으로 인한 지연 또는 미이행에 대한 책임을 지지 않습니다. 특히 자연 재해, 전쟁, 테러 행위, 폭동, 수출입 금지, 공무원 또는 군사 당국의 조치, 화재, 홍수, 사고, 파업 또는 교통, 연료, 에너지, 노동력 또는 재료의 부족과 같은 예측 불가능한 사유로 인한 이러한 미이행에 대한 책임을 지지 않습니다.</p>
    <p> </p>
    <p>응용 프로그램에서 설명한 활동에 대한 조언은 일반적인 정보이며 사용자는 해당 조언을 건강에 맞게 조정하거나 의사의 조언을 받을 책임이 있습니다. 운동 실천에 대한 책임은 사용자에게 있으며, CapTarget Archery는 애플리케이션의 도움을 받아 활동을 실천함으로써 발생하는 신체적 또는 정신적인 부상에 대한 책임을 지지 않습니다.</p>
    <p> </p>
    <p>사용자는 보안 취약점을 보고하고 이를 자신이나 다른 사람/단체에게 이용하지 않을 것을 동의합니다. 사용자는 CapTarget Archery의 서비스의 원활한 작동에 해를 끼치는 행동을 하지 않을 것에 동의합니다.</p>
    <p> </p>


    <h4>지적 재산권</h4>
    <p> </p>
    <p>플랫폼에 나타나거나 사용 가능한 서비스 및 그 내용(데이터, 데이터베이스, 소프트웨어, 사진, 정보, 일러스트레이션, 로고, 상표 등)은 현재 법령에 의해 인정된 모든 지적 재산권, 데이터베이스 생산자의 권리 및/또는 기타 어떠한 권리로도 보호받고 있습니다.</p>
    <p> </p>
    <p>서비스 및/또는 플랫폼의 내용, 이러한 내용이 CapTarget Archery, 사용자, 조직 또는 제3자에게 속하더라도 무단 복사, 복제, 표시, 적응, 변경, 수정 또는 전파(전체 또는 일부)는 불법이며, 이러한 행위의 저작자는 형사 및 민사 책임을 지게 될 수 있습니다.</p>
    <p> </p>
    <p>CapTarget Archery는 사용자에게 이익을 추구하지 않는 용도로 플랫폼을 사용하는 권리를 부여합니다.</p>
    <p> </p>
    <p>사용자가 플랫폼에 콘텐츠를 업로드할 때 해당 콘텐츠의 무상 사용에 대한 개인적이고 배타적이지 않은 라이선스를 CapTarget Archery에 자동으로 부여합니다.</p>
    <p> </p>
    <p>이 라이선스는 전 세계적으로, 현재 알려진 또는 알려지지 않은 모든 매체 및 방법을 통한 모든 사용에 대해 부여됩니다. 이 라이선스는 현재 법적 지적 재산권 보호 기간(갱신 및 연장 포함) 동안 유효하며 다음을 포함합니다:</p>
    <p> </p>
    <p>복제권: 콘텐츠의 무제한 복사, 형태나 매체와 상관없이, 알려진 또는 알려지지 않은 모든 매체(예: 모든 브로셔, 보도 자료, 포스터, 웹 페이지, 보고서 등)에 대한 권리, 알려진 또는 알려지지 않은 모든 기술적 방법(예: 모든 기술적 방법, CD, DVD, 플로피 디스크, 내부 또는 외부 하드 디스크 또는 기타 레코딩 매체),</p>
    <p>표시권: 알려진 또는 알려지지 않은 모든 매체를 통한 콘텐츠의 회원에게 전달, 전송 또는 전달하거나 전달하거나 전송하는 권리, 알려진 또는 알려지지 않은 모든 통신 네트워크 또는 정보 교환 및 통신 매체(인터넷, 육상 네트워크, 케이블, 위성, 다운로드, 전송 등)를 통한 권리,</p>
    <p>번역권: 모든 언어로 콘텐츠 번역(모든 컴퓨터 언어 포함).</p>
    <p>사용자는 플랫폼에 업로드되는 콘텐츠가 제3자의 지적 재산권 또는 기타 권리에 침해하지 않는다고 보증합니다.</p>
    <p> </p>
    <p>따라서 사용자는 사용자가 게시한 내용과 관련하여 제3자의 조치 또는 청구로 인해 발생할 수 있는 모든 손해에 대한 CapTarget Archery를 방어하고 책임지고 보상할 것에 동의합니다.</p>
    <p> </p>
    <h4>계약 문서</h4>
    <p> </p>
    <p>본 사용 조건은 CapTarget Archery, 사용자 및/또는 조직 간의 상업 계약 관계 또는 계약 전 관계를 통제합니다. 이는 플랫폼의 사용 및 CapTarget Archery에서 제공하는 구독과 관련하여 파생되는 모든 관계에 적용됩니다.</p>
    <p> </p>
    <p>본 사용 조건은 당사자 간의 모든 상충하는 합의 또는 조항을 우선시합니다. 이는 당사자 간의 관계를 규정해온 이전의 모든 이전 CGV를 취소하고 대체합니다.</p>
    <p> </p>
    <p>본 사용 조건은 당사자 간의 모든 계약 문서입니다.</p>
    <p> </p>
    <p>CapTarget Archery는 언제든지 CGU 내용을 수정하는 권리를 보유합니다. 이러한 수정은 새로운 버전으로 나타나며 그 이후에 제공되는 모든 서비스에 자동으로 적용됩니다.</p>
    <p> </p>
    <h4>적용 법률, 중재 및 관할</h4>
    <p> </p>
    <p>본 사용 조건은 프랑스 법령에 의해 규정됩니다.</p>
    <p> </p>
    <p>당사자는 소송 제기 전에 합의된 해결책을 찾기로 동의합니다. 당사자 간에 원만한 해결이 불가능한 클레임의 경우, 사용자는 소비자 중재를 무료로 신청할 수 있습니다. 다음 중재기관을 통해 중재할 것을 제안합니다: Centre de Médiation et d'Arbitrage de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p> </p>
    <p>또한 사용자는 유럽 위원회가 온라인 분쟁 해결 플랫폼을 제공하는 주소인 www.ec.europa.eu/consumers/odr/을 사용할 수 있습니다.</p>


</div>
