import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nl',
  templateUrl: './nl.component.html',
  styleUrls: ['./nl.component.css']
})
export class NlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
