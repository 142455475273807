<div class="coming-soon bg-overlay">
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
        <a href="/">
            <img src="assets/img/logo_white.svg" alt="Application de tir à l'arc">
        </a>
        <!-- ***** Coming Soon Area Start ***** -->
        <div class="coming-soon-area">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 text-center mb-5 mx-auto text-center">
                        <div class="countdown-content">
                            <span class="text-white pb-1 fw-5">We are</span>
                            <h1 class="text-white text-center text-uppercase mb-5">Coming Soon</h1>
                            <p class="text-white">Our website is under construction. We'll be here soon with our new awesome site, subscribe to be notified.</p>
                            <div class="countdown-times pt-4">
                                <div class='countdown d-flex justify-content-center' data-date="2021-09-09"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Coming Soon Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>