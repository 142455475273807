import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-six',
  templateUrl: './footer-six.component.html',
  styleUrls: ['./footer-six.component.css']
})
export class FooterSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
