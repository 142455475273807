import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fr-conf',
  templateUrl: './fr-conf.component.html',
  styleUrls: ['./fr-conf.component.css']
})
export class FrConfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
