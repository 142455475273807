<div class="homepage-6">
    <!--    Créé par Anatole Couasnon en 2021. Vive CapTarget Archery ! :p-->
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <app-hero-six></app-hero-six>
        <app-video></app-video>
        <app-promo-three></app-promo-three>
        <app-screenshot-one></app-screenshot-one>
        <app-promo-four></app-promo-four>
        <app-screenshot-two></app-screenshot-two>
        <app-video-coach></app-video-coach>
        <app-content-one></app-content-one>
<!--        <app-content-five></app-content-five>-->
        <!--        <app-content-six></app-content-six>-->
        <!--        <app-counter-two></app-counter-two>-->
        <!--        <app-pricing-two></app-pricing-two>-->
        <app-faq-one></app-faq-one>
        <app-team-one></app-team-one>
        <app-review></app-review>
        <app-promo-one></app-promo-one>
        <!--        <app-cta-two></app-cta-two>-->
        <!--        <app-contact></app-contact>-->


        <app-footer-five></app-footer-five>
    </div>
</div>
