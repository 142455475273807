<div class="container">
    <h4>Definitioner</h4>
    <p> </p>
    <p>Følgende termer, hvor den første bogstav er stort i Vilkårene, har den betydning, der er tillagt dem i denne artikel.</p>
    <p> </p>
    <p>Abonnement: Betegner ethvert abonnement, gratis eller betalt, på platformens tjenester.</p>
    <p> </p>
    <p>Archer: Bruger, der praktiserer bueskydning og bruger eller ikke bruger appens tjenester.</p>
    <p> </p>
    <p>Træner: Bueskydningsinstruktør, der har tegnet et gratis eller betalt "Træner" abonnement og dermed får adgang til tilknyttede tjenester.</p>
    <p> </p>
    <p>App: Betegner den mobile applikation "CapTarget Archery", der er tilgængelig på Android og iOS.</p>
    <p> </p>
    <p>Side: Betegner CapTarget Archerys showcase-side.</p>
    <p> </p>
    <p>Trænerside: Betegner siden med adgang for trænere.</p>
    <p> </p>
    <p>Vilkår for brug: Betegner de nuværende generelle vilkår for brug af platformen.</p>
    <p> </p>
    <p>Konto: Betegner konto tildelt en bruger, der giver adgang til platformen og forskellige tjenester, hvor der er tilknyttet en personlig identifikator og adgangskode.</p>
    <p> </p>
    <p>Gruppe: Betegner en gruppe oprettet på CapTarget Archery-applikationen og samler brugere og trænere.</p>
    <p> </p>
    <p>Partner: Betegner en fysisk eller juridisk person, der har indgået et partnerskab med CapTarget Archery, der tillader at blive præsenteret for brugere via platformen.</p>
    <p> </p>
    <p>Platform: Betegner samlingen af tjenester, der er tilgængelige på siderne og/eller appen.</p>
    <p> </p>
    <p>Bruger: Betegner enhver fysisk eller juridisk person, der har adgang til og bruger platformen, uanset om det er via en konto eller ej.</p>
    <p></p>
    <p>Gems: Betegner en virtuel valuta, der anvendes på CapTarget Archery.</p>
    <p> </p>
    <h4>Formål</h4>
    <p> </p>
    <p>De generelle vilkår for brug har til formål at definere de betingelser og vilkår, der gælder for alle brugere af platformen.</p>
    <p> </p>
    <p>Enhver registrering, adgang og/eller brug af platformen indebærer brugerens ubetingede accept og overholdelse af alle generelle vilkår for brug.</p>
    <p> </p>
    <p>Enhver mindreårig bruger erklærer og anerkender, at de har opnået tilladelse fra deres forældre eller indehaverne af forældreansvaret for at registrere sig på platformen.</p>
    <p> </p>
    <p>Enhver automatisk genereret tilmeldingsanmodning fra en robot eller enhver anden metode vil blive afvist.</p>
    <p> </p>
    <h4>Præsentation af applikationen</h4>
    <p> </p>
    <p>Applikationen er primært dedikeret til bueskydningsudøvere eller trænere og giver blandt andet mulighed for at administrere og organisere aktiviteter relateret til sporten, kommunikere information mellem gruppe medlemmer eller om emner inden for området.</p>
    <p> </p>
    <p>Brugere får adgang til følgende funktioner via applikationen:</p>
    <p> </p>
    <p>Administration af trænings- og konkurrencesessioner</p>
    <p>Administration af registrerede skud og kampe</p>
    <p>Administration af bueudstyr</p>
    <p>Brug af data til at levere statistikker</p>
    <p>Socialt og legende interaktion, deling af data med brugerens trænere</p>
    <p>Socialt og legende samspil mellem gruppe medlemmer</p>
    <p>Køb af tilpasningsgenstande til oplevelsen via den virtuelle valuta kaldet "Gems".</p>
    <p>Køb af "Gems" via mobilbetalingsystemet (ANDROID eller APPLE)</p>
    <p>Beskeder</p>
    <p> </p>
    <h4>Registrering af en bruger</h4>
    <p> </p>
    <p>Hver bruger har mulighed for at oprette en konto (personlig eller for en mindreårig medlem, når brugeren har forældreansvaret), der giver adgang til applikationen og dens tjenester.</p>
    <p> </p>
    <p>Registreringen foretages på platformen af brugeren via applikationen. For dette skal brugeren udfylde de forskellige felter i registreringsformularen, herunder en gyldig e-mailadresse, deres navn og efternavn samt forskellige oplysninger om deres bueskydningspraksis. De skal læse og acceptere de generelle vilkår for brug og databeskyttelsespolitikken.</p>
    <p> </p>
    <p>Ved at udfylde registreringsformularen forpligter brugeren sig til kun at give korrekte, opdaterede og komplette oplysninger.</p>
    <p> </p>
    <p>Brugeren forpligter sig til at holde adgangskoden til deres konto fortrolig. Ved brug af adgangskoden i ond tro skal brugeren straks informere CapTarget Archery.</p>
    <p> </p>
    <p>Brugeren er alene ansvarlig for brugen af deres konto. Enhver forbindelse eller overførsel af data via platformen anses for at være udført af brugeren og under deres udelte ansvar.</p>
    <p> </p>
    <p>CapTarget Archery er ikke ansvarlig, hvis brugerens registreringsdata ikke når frem til CapTarget Archery af årsager, som ikke kan tilskrives dem (f.eks. brugerens internetforbindelsesproblem, midlertidig svigt af deres servere osv.), eller hvis dataene når frem til CapTarget Archery i en uhåndterbar form (f.eks. hvis brugeren har utilstrækkelig hardware eller software).</p>
    <p> </p>
    <p>Enhver registrering gælder for en ubestemt periode, og brugerens konto forbliver aktiv, indtil den lukkes.</p>
    <p> </p>
    <p> </p>
    <h4>Registrering af en gruppe</h4>
    <p> </p>
    <p>For at få adgang til gruppefunktionerne skal en bruger oprette eller deltage i en gruppe.</p>
    <p> </p>
    <p>Ved at udfylde registreringsformularen forpligter administratoren sig til kun at give korrekte, opdaterede og komplette oplysninger. De garanterer, at CapTarget Archery har opnået forudgående samtykke fra medlemmerne, hvis e-mailadresser og telefonnumre kommunikeres, og de kan til enhver tid anmode CapTarget Archery om at fjerne disse (se vores databeskyttelsespolitik).</p>
    <p> </p>
    <p>Administratorer er alene ansvarlige for brugen af gruppen.</p>
    <p>Det skal også bemærkes, at administratoren er fuldt og udelukkende ansvarlig for brugen af Organisationens område af sig selv og af enhver tredjepart, uanset hvem de er.</p>
    <p> </p>
    <p>CapTarget Archery er ikke ansvarlig, hvis dataene vedrørende en organisations registrering ikke når frem til CapTarget Archery af årsager, der ikke kan tilskrives dem (f.eks. internetforbindelsesproblem forårsaget af en eller anden grund fra administratorens side, midlertidig svigt af deres servere osv.), eller hvis dataene når frem til CapTarget Archery i en uhåndterbar form (f.eks. hvis administratoren har utilstrækkelig computerhardware eller registreringssoftware).</p>
    <p> </p>
    <p>Enhver registrering gælder for en ubestemt periode, og gruppen forbliver aktiv, indtil den lukkes af en af administratorerne.</p>
    <p> </p>
    <p> </p>
    <h4>Registrering af en træner</h4>
    <p>For at få adgang til trænerfunktionerne skal en bruger abonnere på et abonnement, der giver adgang til en trænerlicens til CapTarget Archerys tjenester via websitet og/eller mobilappen i henhold til det tegnede tilbud.</p>
    <p>En adgangslicens kan leveres af en tredjepart, der har abonneret på vegne af brugeren. En adgangslicens er strengt eksklusiv for den bruger, der har registreret sig med den pågældende licens. Kontoen kan ikke deles med andre personer.</p>
    <p>Fordele ved forskellige tilbud kan ændres. Hvis et abonnement er aktivt, vil ændringer ikke træde i kraft, eller de skal betragtes som til brugerens fordel.</p>
    <p>Registreringen foretages på websitet af brugeren. For dette skal de udfylde de forskellige felter i registreringsformularen, herunder en gyldig e-mailadresse, deres navn og efternavn samt forskellige oplysninger om deres bueskydningspraksis. De skal læse og acceptere de generelle vilkår for brug, handelsbetingelserne og databeskyttelsespolitikken.</p>
    <p> </p>
    <p>Ved at udfylde registreringsformularen forpligter træneren og forbrugeren, der abonnerer på licensen, sig til kun at give korrekte, opdaterede og komplette oplysninger.
        Brugeren, der udnytter deres licens, er alene ansvarlig for brugen af deres konto. Enhver forbindelse eller overførsel af data via platformen anses for at være udført af brugeren og under deres udelte ansvar.
    </p>
    <p>CapTarget Archery er ikke ansvarlig, hvis brugerens registreringsdata ikke når frem til dem af årsager, som ikke kan tilskrives dem (f.eks. brugerens internetforbindelsesproblem, midlertidig svigt af deres servere osv.), eller hvis dataene når frem til dem i en uhåndterbar form (f.eks. hvis brugeren har utilstrækkelig hardware eller software).</p>
    <p>Enhver tilmelding til et betalt abonnement gælder for en ubestemt periode, medmindre brugeren eksplicit anmoder om det, vil kontrakten automatisk blive fornyet efter den minimumsforpligtelsesperiode. Vi henviser til handelsbetingelserne for oplysninger om afmeldingsprocedurerne.</p>
    <p> </p>

    <h4>Data Deling</h4>
    <p>For at få adgang til dataudvekslingsfunktionerne via CapTarget Archery tilbyder vores app et fans- og følgesystem. Du som bruger har mulighed for at acceptere invitationer eller invitere andre brugere til at deltage i dit netværk.</p>
    <p>Ved offentlig profil kræves ingen godkendelse for at blive fulgt af andre brugere. Når en bruger følger dig, kan nogle af dine data deles automatisk eller manuelt, herunder:</p>
    <p>- Resultater fra dine konkurrencer eller træningssessioner.</p>
    <p>- Forskellige sportsstatistikker såsom din licensvarighed, din bedste resultat eller din placering.</p>
    <p>- Data relateret til din brug af appen, såsom dine foretrukne spil og øvelser.</p>
    <p>- Oplysninger om din klub, dit sprog, dit land samt andre data som dit navn, efternavn, type bue anvendt eller skydekategori.</p>
    <p>Du har mulighed for at vælge at ikke dele visse af disse data ved at ændre dine personlige indstillinger eller indstillingerne for hver situation.</p>
    <p>Ved oprettelsen af din konto accepterer du at være synlig på CapTarget-platformen. Andre brugere kan søge efter dig ved at bruge dit for- og efternavn. Dit profil kan også automatisk blive foreslået til andre brugere med det formål at skabe sportsudfordringer eller følgeforbindelser.</p>
    <p>Bemærk, at du kan blive udsat for oplysninger, der deles på CapTarget Archery af CapTarget, dets partnere, annoncører eller andre brugere. Vi opfordrer dig til at læse vores privatlivspolitik for flere detaljer om håndteringen af dine personlige data.</p>
    <p>Vi ønsker at understrege, at alle disse betingelser overholder gældende love og regulativer vedrørende beskyttelse af personlige data og privatliv. Hvis du har bekymringer eller yderligere spørgsmål, er du velkommen til at kontakte os.</p>
    <p></p>
    <p></p>

    <p></p>
    <h4>Advarsel</h4>
    <p>Adgang til coachversionerne af CapTarget Archery-platformene kræver brug af it-værktøjer som computer, tablet eller telefon til webversionen og telefon eller tablet med ANDROID eller IOS til mobilversionen. Internetforbindelse er påkrævet for at downloade nødvendige data. Brugeren bekræfter at være opmærksom på denne nødvendighed for at få adgang til de solgte tjenester.</p>
    <p>Øvelser og forskellige situationer, der tilbydes i tjenesterne, antager, at brugerne er i god fysisk stand og i stand til at udføre dem. Abonnenten forpligter sig til at tage alle nødvendige forholdsregler for at udøve aktiviteten og beskytte sin egen sundhed, sikkerhed og andres.</p>
    <p></p>
    <p></p>

    <p></p>
    <p></p>

    <h4>Salgsbetingelser</h4>
    <p>Ved at tegne et abonnement eller købe en af de digitale tjenester eller digitale produkter, der stilles til rådighed af CapTarget Archery, accepterer du nedenstående salgsbetingelser. Hvis du ikke accepterer disse betingelser, bedes du ikke købe noget på vores app eller vores hjemmeside.</p>
    <h6>Abonnementer</h6>
    <p>Når du køber indhold fra CapTarget, giver CapTarget dig en begrænset, tilbagekaldelig, ikke-eksklusiv og ikke-overdragelig licens til at få adgang til visse betalte funktioner på vores app eller vores websted. Vores app tilbyder månedlige og årlige abonnementer med automatisk fornyelse for at få adgang til digitale tjenester. Abonnementsafgifter trækkes automatisk fra den betalingsmetode, du har valgt, på abonnementets årsdag. Abonnementer betalt med gems (CapTarget Archery's virtuelle valuta) fornyes ikke automatisk.</p>
    <p>Hvis du ikke ønsker at forny dit abonnement, skal du deaktivere det mindst 24 timer før abonnementsperiodens udløb ved at få adgang til dine kontoindstillinger.</p>
    <h6>Priser</h6>
    <p>Vores abonnementspriser er offentliggjort i vores app og kan ændres når som helst uden varsel. Abonnementspriser inkluderer alle relevante skatter. Du er ansvarlig for alle omkostninger i forbindelse med brugen af vores app, herunder internetadgang og mobile dataafgifter. På trods af vores bestræbelser kan et begrænset antal elementer i vores katalog have forkerte priser. Priser kan ændres selv for nuværende abonnenter. Afhængigt af det system, du har valgt (Apple, Google, Stripe eller andet), kan du blive underrettet om prisstigninger på din igangværende abonnement og annullere abonnementet, hvis du ønsker det, inden fornyelse.</p>
    <h6>Risiko for tab</h6>
    <p>Når CapTarget-indholdet er gjort tilgængeligt for dig, påtager du dig risikoen for tab af CapTarget-indholdet efter købet, herunder ethvert tab på grund af filkorruption eller fejl i enhed eller harddisk. Købt CapTarget-indhold vil normalt forblive tilgængeligt på din CapTarget-konto, men kan stoppe med at være tilgængeligt af forskellige årsager. I sådanne tilfælde vil CapTarget ikke være ansvarlig over for dig for manglende tilgængelighed af senere CapTarget-indhold, og du vil ikke have ret til at modtage en refusion, helt eller delvist, for allerede betalte gebyrer.</p>
    <h6>Levering</h6>
    <p>Adgang til CapTarget-indhold leveres ved køb af produktet eller abonnementet. Indholdet er tilgængeligt på din CapTarget-konto. En genstart af appen kan være nødvendig, såvel som en rimelig ventetid for, at tjenesterne bliver tilgængelige. (Normalt inden for få minutter)</p>
    <h6>Betaling</h6>
    <p>Du skal have en gyldig betalingsmetode (såsom et kreditkort eller en anden autoriseret betalingsmetode) i din CapTarget-konto for at kunne drage fordel af betalingsfunktionerne. Betalingen for dit abonnement trækkes automatisk fra din konto baseret på den betalingsmetode, der er angivet i din Apple- eller Google Play-konto eller på Stripe eller en anden metode, hvis du har købt abonnementet på vores hjemmeside. Du accepterer at give korrekte og opdaterede betalingsoplysninger. Hvis betalingen ikke gennemføres af en eller anden grund, forbeholder vi os retten til at suspendere eller annullere dit abonnement.</p>
    <h6>App-brug</h6>
    <p>Du har tilladelse til at bruge vores app til personlige og ikke-kommercielle formål. Du må ikke ændre, distribuere, sælge eller overføre noget indhold fra vores app uden vores forudgående skriftlige tilladelse. Du må ikke bruge vores app på en måde, der overtræder gældende lov, krænker tredjeparts rettigheder eller påfører dem skade.</p>
    <h6>Refusioner</h6>
    <p>Vores abonnementer kan prøves på forhånd i en periode på 3 uger og er derfor ikke refunderbare. Du bedes tjekke funktionerne i hvert abonnement, før du tilmelder dig en eller flere af dem. De digitale produkter, der sælges, er eksplicitte og kan ikke refunderes.</p>
    <h6>Opsigelse</h6>
    <p>Vi forbeholder os ret til at opsige din adgang til vores app, hvis du overtræder disse salgsbetingelser eller brugsbetingelser eller hvis vi har grund til at tro, at du bruger vores app på nogen måde.</p>
    <p>Ved at købe et abonnement på vores app accepterer du disse salgsbetingelser.</p>

    <h6>Kontakt os</h6>
    <p>Hvis du har spørgsmål eller bekymringer, bedes du kontakte os på følgende adresse: support@captarget-archery.com</p>
    <p>Vi vil gøre vores bedste for at løse din problemstilling på en retfærdig måde, så begge parter er tilfredse.</p>
    <p></p>
    <p></p>
    <h4>Brugsomkostninger for platformen</h4>
    <p></p>
    <p>Oprettelsen af en konto og en gruppe er gratis for en bruger. Dog påhviler omkostningerne ved forbindelse og kommunikation i forbindelse med brugen af platformen udelukkende brugeren.</p>
    <p>Adgangen til trænerversioner er betalingspligtig, med undtagelse af testversioner og beta-versionen. Priserne er tilgængelige efter versionens udgivelse på hjemmesiden.</p>
    <h4>Køb via platformen</h4>
    <p></p>
    <p>Det er muligt at købe en begrænset, personlig, ikke-overdragelig og tilbagekaldelig licens med "rigtige" penge, der giver dig adgang til brug af "virtuel valuta", herunder virtuelle mønter eller diamanter, som er beregnet til brug i CapTarget Archery-spil. Det er også muligt at opnå denne valuta gennem begivenheder, konkurrencer, spil, ved at se reklamer eller som belønning for at nå mål på platformen eller som uddeling fra CapTarget Archery.</p>
    <p>Denne valuta kan bruges til at købe forskellige produkter, der sælges via applikationen.</p>
    <p>Overførsel af virtuelle varer er forbudt, medmindre det udtrykkeligt er tilladt i tjenesten. Med forbehold af det, der udtrykkeligt er tilladt i tjenesten, skal du afstå fra at sælge, købe, konvertere eller på anden måde overføre virtuelle varer til fordel for nogen person eller enhed, herunder især CapTarget Archery, en anden bruger eller en tredjepart.</p>
    <p>CapTarget Archery kan til enhver tid administrere, regulere, kontrollere, ændre eller slette virtuelle varer med eller uden forudgående varsel. CapTarget Archery kan ikke holdes ansvarlig over for dig eller tredjeparter for udøvelse af disse rettigheder.</p>
    <p>Alle køb og konverteringer af tredjeparts virtuelle varer, der udføres via tjenesten, er endelige og kan ikke refunderes.</p>
    <p>Du forpligter dig til at betale alle gældende gebyrer og afgifter, der skyldes dig eller enhver person, der bruger en konto, der er registreret i dit navn. CapTarget Archery kan ændre prisen på virtuelle varer, der tilbydes via tjenesten, når som helst. Du erkender, at CapTarget Archery ikke er forpligtet til at give dig en refusion af nogen art, og at du ikke vil modtage nogen form for kompensation eller godtgørelse for ubrugte virtuelle varer ved lukning af en konto, uanset om lukningen er frivillig eller ufrivillig.</p>
    <h4>Reklamer og partnere</h4>
    <p></p>
    <p>CapTarget Archery kan levere links til andre reklamesider og/eller partnere på de forskellige tjenester, der tilbydes.</p>
    <p>Da CapTarget Archery ikke kan kontrollere dem, påtager CapTarget Archery sig intet ansvar for tilgængeligheden af disse eksterne websteder og kilder og kan ikke holdes ansvarlig for indholdet, annoncerne, produkterne, tjenesterne eller andet materiale, der er tilgængeligt på dem.</p>
    <p>På samme måde kan CapTarget Archery ikke holdes ansvarlig for enhver faktisk eller påstået skade eller tab som følge af eller i forbindelse med brugen af indholdet eller tjenesterne, der er tilgængelige på disse reklamesider, partnere eller eksterne kilder.</p>
    <h4>Kontosletning</h4>
    <p></p>
    <p>En bruger kan til enhver tid lukke sin konto eller den konto, de administrerer på vegne af en mindreårig. I overensstemmelse med vores privatlivspolitik vil lukningen af en konto medføre sletning af tilknyttede personlige data.</p>
    <p>Uden at det påvirker skadeserstatning, som CapTarget Archery måtte kræve, forbeholder CapTarget Archery sig retten til at lukke en brugers konto midlertidigt eller permanent uden varsel eller kompensation i tilfælde af, blandt andet:</p>
    <p>overtrædelse af disse brugsvilkår</p>
    <p>levering af falske oplysninger ved registreringen;</p>
    <p>handlinger, der strider mod CapTarget Archerys kommercielle interesser.</p>
    <p>Lukning af en konto initieret af CapTarget Archery på grund af overtrædelse af disse brugsvilkår eller salgsvilkår berettiger ikke til kompensation eller tilbagebetaling, inklusive i tilfælde af betalte abonnementer. Alle betalinger for abonnementet forbliver CapTarget Archerys ejendom.</p>
    <p>Lukning af en adgang til trænerversionen kan udføres i overensstemmelse med gældende salgsvilkår. Vi opfordrer brugeren til at henvise til disse vilkår for lukning af en betalt træneradgang. En testversion sættes automatisk på pause ved afslutningen af perioden og kan genaktiveres ved at tegne et abonnement. Betaversionen kan forlænges efter CapTarget Archerys skøn. For at afslutte en testversion eller en betaversion skal du sende en e-mail til support@captarget-archery.com</p>
    <h4>Sletning af en gruppe</h4>
    <p></p>
    <p>En administrator kan til enhver tid slette en gruppe. Sletning af en organisationsrum medfører sletning af alle tilknyttede data og fordele.</p>
    <p>Sletning af et organisationsrum på initiativ af CapTarget Archery</p>
    <p></p>
    <p>Uden at det påvirker skadeserstatning, som CapTarget Archery måtte kræve, forbeholder CapTarget Archery sig retten til midlertidigt eller permanent at slette et organisationsrum uden varsel eller kompensation i tilfælde af, blandt andet:</p>
    <p>overtrædelse af salgsvilkårene;</p>
    <p>levering af falske oplysninger ved registrering af organisationen;</p>
    <p>handlinger, der strider mod CapTarget Archerys kommercielle interesser.</p>
    <p>Sletning af et organisationsrum på initiativ af CapTarget Archery berettiger ikke til kompensation eller tilbagebetaling, inklusive i tilfælde af et betalt abonnement. Alle betalinger for abonnementet forbliver CapTarget Archerys ejendom, og sletning af et organisationsrum fritager ikke organisationen/administratoren for forpligtelsen til at betale abonnementet indtil udløbet.</p>
    <h4>Information og indhold på platformen</h4>
    <p></p>
    <p>Brugeren forpligter sig til at udvise omtanke ved brug af platformen, især ved vurdering af informationer og indhold i en gruppe, som brugeren er medlem af.</p>
    <p>I denne forbindelse erkender brugeren især, at de informationer og det indhold, der er tilgængelige på platformen, ikke er udtømmende og primært har til formål at hjælpe administratorer med at administrere organisationen.</p>
    <p>Brugeren må ikke sælge, videresælge eller på nogen måde udnytte hele eller dele af det indhold, der er tilgængeligt på platformen.</p>
    <p>Brugeren er eneansvarlig for kommunikation, informationer, filer, videoer, fotos, dokumenter eller andet indhold, som brugeren offentliggør og overfører via platformen.</p>
    <p>Kommunikation, informationer, filer, videoer, fotos, dokumenter eller andet indhold, som brugeren offentliggør på platformen, repræsenterer brugerens egne synspunkter og meninger og er på ingen måde udtryk for CapTarget Archerys eller dets medarbejderes synspunkter og meninger.</p>
    <p>I forbindelse med platformen forpligter brugeren sig til kun at levere objektive, fuldstændige og sandfærdige informationer i overensstemmelse med reglerne om anstændighed, høflighed og respekt for andre.</p>
    <p>CapTarget Archery opfordrer brugeren til at udvise tilbageholdenhed og forsigtighed ved udtryk for kommunikation, informationer, filer, videoer, fotos, dokumenter eller andet indhold, som brugeren ønsker at offentliggøre på platformen. I denne henseende må brugeren ikke offentliggøre indhold, der strider imod gældende love og bestemmelser, især:</p>
    <p>formidle informationer, der strider mod den offentlige orden eller god moral;</p>
    <p>ændre formålet med platformen til propaganda, påvirkning, reklame eller opfordring;</p>
    <p>offentliggøre kommercielle eller reklameinformationer eller gøre reklame for tobak, alkohol eller anden reguleret substans, produkt eller service;</p>
    <p>offentliggøre indhold, der krænker tredjeparts personlighedsrettigheder eller er injurierende, ærekrænkende, obskønt, pornografisk, stødende, voldeligt eller opfordrer til diskrimination, politisk vold, racisme, fremmedhad, sexisme eller homofobi;</p>
    <p>offentliggøre informationer, der overtræder lovgivningen om beskyttelse af personlige data og identificering af fysiske personer uden deres samtykke, herunder deres efternavn, postadresse og/eller e-mailadresse, telefonnummer, fotografier, lydoptagelser eller audiovisuelle optagelser;</p>
    <p>krænke tredjeparts immaterielle rettigheder, især ved at fjerne eller slette ophavsret- eller varemærkemærkater samt enhver begrænsende betegnelse.</p>
    <p>Brugeren forpligter sig også til at respektere andres rettigheder, især:</p>
    <p>personlighedsrettigheder (som retten til billede og retten til privatliv);</p>
    <p>varemærkerettigheder;</p>
    <p>ophavsrettigheder (især på software, lyd, billeder, fotografier, tekster, animerede billeder) og beslægtede rettigheder (udøvende kunstnere, producenter af fonogrammer og videogrammer samt sui generis-rettigheder for producenter af databaser);</p>
    <p>generelt andres rettigheder til personer og ejendom.</p>
    <p></p>

    <p>Brugeren forpligter sig også til ikke at gøre følgende uden forudgående skriftlig tilladelse fra CapTarget Archery, uden at denne liste er udtømmende:</p>
    <p> </p>
    <p>anvende Platformen til reklameformål og generelt tilbyde produkter og tjenester, der direkte eller indirekte giver brugeren indtægter;</p>
    <p>oprette arkivfiler fra indholdet på Platformen;</p>
    <p>reproducere, fremstille, bruge, henvise (herunder i søgeordsmeta-tags), helt eller delvist af indhold, varemærker, logoer, karakteristiske tegn på Platformen.</p>
    <p> </p>
    <p>Overtrædelse af disse brugsbetingelser kan medføre omgående og automatisk sletning af det inkriminerede indhold, en advarsel og/eller lukning af brugerens konto, uanset enhver anden retlig handling og/eller skadeserstatning, som CapTarget Archery har ret til at indlede eller kræve.</p>
    <p> </p>
    <p>CapTarget Archery påtager sig intet ansvar for information, filer, videoer, fotos, dokumenter eller andet indhold på Platformen, herunder men ikke begrænset til ulovligt indhold, fejl eller udeladelser, eller tab eller skade som følge af brug af dette materiale af enhver bruger.</p>
    <p> </p>
    <p>Enhver hypertextlink til undersider på webstedet er strengt forbudt uden udtrykkelig tilladelse fra CapTarget Archery, der er modtaget af brugeren. Derudover skal ethvert link fjernes af brugeren på en simpel anmodning fra CapTarget Archery.</p>
    <p> </p>
    <h4>Administratorrollen for moderation</h4>
    <p> </p>
    <p>Administrator, som koordinator og supervisor af gruppen, forpligter sig til at håndhæve brugsbetingelserne blandt medlemmerne. Så snart administrator får kendskab til det, forpligter han sig til at rapportere eller fjerne indhold (hvis det er muligt), der er i strid med loven og/eller den offentlige orden og/eller disse brugsbetingelser, især voldeligt, pornografisk, racistisk, antisemitisk, xenofobisk, krænkende over for den menneskelige værdighed, krænkelse af privatlivet, krænkelse af ophavsrettigheder, nærtstående rettigheder, tredjeparts personlighedsrettigheder.</p>
    <p> </p>
    <p>I tilfælde af overtrædelse af disse regler og praksis forbeholder CapTarget Archery sig retten til at ekskludere den skyldige administrator og/eller medlemmet og tage retlige skridt mod en eller flere forfattere, der kan afslutte åbenbar ulovlig uro som følge af fejlagtig brug af Platformen.</p>
    <p> </p>
    <h4>Send e-mails, push-meddelelser og SMS'er</h4>
    <p> </p>
    <p>Som en del af de tilbudte tjenester tilbyder CapTarget Archery kommunikation via e-mail, push-meddelelser på applikationen og SMS'er for at muliggøre kommunikation mellem medlemmer af en organisation med det eksklusive formål at udveksle oplysninger om organisationen ved hjælp af mobiltelefonnumre og e-mailadresser, der leveres af medlemmerne ved oprettelsen af deres konto.</p>
    <p> </p>
    <p>Brugeren kan til enhver tid beslutte at stoppe med at modtage e-mails, push-meddelelser eller SMS'er fra CapTarget Archery ved at ændre indstillingerne på sine enheder eller ved at anmode om det via supporten.</p>
    <p> </p>
    <h4>Adgang til platformen - Ansvar og forpligtelser</h4>
    <p> </p>
    <p>CapTarget Archery gør sit bedste for at gøre Platformen tilgængelig 24 timer i døgnet, 7 dage om ugen, med undtagelse af planlagt vedligeholdelse og i overensstemmelse med bestemmelserne i denne artikel.</p>
    <p> </p>
    <p>CapTarget Archery forbeholder sig ret til at foretage alle nødvendige ændringer og forbedringer på Platformen i overensstemmelse med udviklingen af teknologierne. Således forbeholder CapTarget Archery sig ret til uden varsel eller kompensation midlertidigt at suspendere adgangen til Platformen for at udføre udviklingsmæssige eller korrektive vedligeholdelsesoperationer. Brugeren frasiger sig ethvert ansvar over for CapTarget Archery i denne henseende og afstår fra ethvert krav i den forbindelse.</p>
    <p> </p>
    <p>CapTarget Archery kan kræve, at du accepterer opdateringer af tjenesten og CapTarget Archery-spil, som du har installeret på din enhed eller computer. Du anerkender og accepterer, at CapTarget Archery kan opdatere tjenesten og CapTarget Archery-spil uden meddelelse eller med meddelelse. Du skal muligvis opdatere tredjepartssoftware for at kunne modtage tjenesten og spille CapTarget Archery-spil.</p>
    <p> </p>
    <p> </p>
    <p>CapTarget Archery giver ingen udtrykkelig eller implicit garanti, herunder men ikke begrænset til garantier vedrørende kvalitet og egnethed til et bestemt formål, med hensyn til Platformen.</p>
    <p> </p>
    <p>CapTarget Archery garanterer ikke, at uafbrudt drift og/eller kontinuitet i Platformen vil blive opretholdt i tilfælde af force majeure eller tilfældige omstændigheder som defineret i artikel 1218 i den franske civillov, i hvilket tilfælde CapTarget Archery's ansvar ikke vil være påkrævet.</p>
    <p> </p>
    <p>I tilfælde af, at CapTarget Archery's ansvar er involveret på grund af brugerens manglende opfyldelse af en af dens forpligtelser i henhold til disse brugsbetingelser, vil erstatningen være begrænset til direkte, personlige, specifikke og forudsigelige skader.</p>
    <p> </p>
    <p>CapTarget Archery garanterer ikke relevansen og nøjagtigheden af information, der er offentliggjort på Platformen af tredjeparter.</p>
    <p> </p>
    <p>CapTarget Archery er ikke ansvarlig for tvister, handlinger eller krav fra tredjeparter, der måtte have rettigheder, herunder ejendomsrettigheder, over ethvert indhold, der ikke er skabt af CapTarget Archery.</p>
    <p> </p>
    <p>Vilkårene, under hvilke CapTarget Archery indsamler og behandler data, er detaljeret beskrevet i privatlivspolitikken for data.</p>
    <p> </p>
    <p>CapTarget Archery vil ikke være ansvarlig over for dig for nogen indirekte, tilfældige, følgeskader eller lignende skader og især ingen indtægtstab, ingen tab af fortjeneste, ingen tab af data eller driftstab eller andet immaterielt tab (uanset betegnelsen for sådanne tab), der relaterer sig på nogen måde til disse servicevilkår eller tjenesten selv, og vil ikke være forpligtet til at betale særlig eller straffende erstatning, uanset om det er på en kontraktlig, uagtsom eller anden basis, og uanset om CapTarget Archery er blevet informeret om muligheden for sådan skade. CapTarget Archery's ansvar over for dig må ikke overstige det beløb, du har betalt til CapTarget Archery i overensstemmelse med disse servicevilkår i de forudgående seks (6) måneder før datoen for den første klage, du fremsætter. Du anerkender og accepterer, at hvis du ikke har betalt noget til CapTarget Archery i en sådan periode, vil dit eneste retsmiddel (og den eneste ansvar for CapTarget Archery) med hensyn til enhver tvist med CapTarget Archery være at stoppe med at bruge tjenesten og lukke din konto.</p>
    <p>Især kan ingen bestemmelser i disse servicevilkår påvirke nogen forbrugers lovmæssige rettigheder eller udelukke eller begrænse nogen ansvar i tilfælde af død eller personskade som følge af CapTarget Archery's uagtsomhed eller bedrageri.</p>
    <p> </p>
    <p>Du forpligter dig til at beskytte, forsvare og fritage CapTarget Archery (samt dens udviklere, administratorer og indholdsproducenter) for enhver ansvar i forbindelse med krav, krav, skader eller andre tab, herunder rimelige advokatomkostninger, der fremføres af en tredjepart og som følge af din brug af tjenesten eller enhver overtrædelse af disse servicevilkår. Ikke desto mindre finder det foregående ikke anvendelse i tilfælde af overtrædelse af rettigheder, der ikke kan tilskrives din hensigtsmæssige eller forsømmelige adfærd.</p>
    <p> </p>
    <p>CapTarget Archery skal ikke holdes ansvarlig for forsinkelser eller manglende opfyldelse som følge af årsager, der rimeligt set ligger uden for dens kontrol, herunder men ikke begrænset til enhver manglende opfyldelse af disse vilkår på grund af uforudsigelige omstændigheder eller omstændigheder uden for CapTarget Archery's kontrol, såsom naturkatastrofer, krig, terrorisme, optøjer, embargo, civil eller militær myndighedsforanstaltninger, brand, oversvømmelser, ulykker, strejker eller mangel på transportmidler, brændstof, energi, arbejdskraft eller materialer.</p>
    <p> </p>
    <p>De praksisråd, der beskrives i applikationen, er vejledende; det er brugerens ansvar at tilpasse rådene til sin sundhed eller at søge råd fra en læge. Ansvaret for sportsudøvelsen ligger hos brugeren; CapTarget Archery er på ingen måde ansvarlig for fysisk eller følelsesmæssig skade som følge af brug af applikationen til sportsaktiviteter.</p>
    <p> </p>
    <p>Brugeren forpligter sig til at rapportere enhver sikkerhedsbrist og ikke udnytte dem til egen eller andres fordel. Brugeren forpligter sig til ikke at udføre handlinger, der kan skade driften af de tjenester, der tilbydes af CapTarget Archery, og ikke udnytte eventuelle fejl.</p>
    <p> </p>
    <h4>Ophavsret</h4>
    <p> </p>
    <p>De tjenester og indholdet (herunder data, databaser, software, fotografier, oplysninger, illustrationer, logoer, varemærker osv.), der vises eller er tilgængelige på Platformen, er beskyttet af alle eksisterende immaterielle rettigheder, rettigheder for producenter af databaser og/eller enhver anden ret anerkendt ved lovgivningen.</p>
    <p> </p>
    <p>Enhver kopiering, reproduktion, repræsentation, tilpasning, ændring eller uautoriseret distribution, fuldstændig eller delvis, af tjenesterne og/eller indholdet på Platformen, uanset om dette indhold tilhører CapTarget Archery, en bruger, en organisation eller en tredjepart, er ulovlig og kan udløse strafferetlige og civile ansvar for forfatteren.</p>
    <p> </p>
    <p>CapTarget Archery giver brugeren ret til at bruge Platformen til egne behov, med undtagelse af enhver brug med kommercielt formål.</p>
    <p> </p>
    <p>Når brugeren uploader indhold på Platformen, giver brugeren automatisk CapTarget Archery en personlig og ikke-eksklusiv licens til brug af indholdet gratis.</p>
    <p> </p>
    <p>Denne licens gives på verdensplan, til alle brug af alle medier og gennem alle kendte eller ukendte udsendelsesmetoder i dag, og for hele varigheden af den lovbeskyttelse for immaterielle rettigheder (herunder fornyelser og forlængelser):</p>
    <p> </p>
    <p>retten til at reproducere: retten til at lave eller få lavet et ubegrænset antal kopier af indholdet i enhver form eller på ethvert medie, kendt eller ukendt i dag, og især på ethvert papir, magnetisk, digitalt eller andet medie (f.eks. alle brochurer, pressemeddelelser, plakater, websider, rapporter og enhver form for optagemedie såsom CD, DVD, disketter, interne eller eksterne harddiske eller enhver anden optagemedie), ved hjælp af enhver kendt eller ukendt teknisk metode i dag;</p>
    <p>retten til at repræsentere: retten til at kommunikere, distribuere, informere eller informere medlemmer af brugerens organisation på enhver måde, kendt eller ukendt i dag, og især via ethvert kommunikationsnetværk eller informations- og kommunikationsmiddel (internet, jordbaseret netværk, kabel, satellit, download, fjernoverførsel osv.);</p>
    <p>rettigheden til at oversætte indholdet til ethvert sprog (herunder alle computersprog).</p>
    <p>Brugeren garanterer over for CapTarget Archery, at det indhold, der uploades til Platformen, ikke krænker nogen immaterielle rettigheder eller andre rettigheder, der tilhører tredjeparter.</p>
    <p> </p>
    <p>Som følge heraf accepterer brugeren at forsvare CapTarget Archery og påtage sig ansvaret og/eller betale erstatning til CapTarget Archery for enhver skade, der måtte opstå som følge af handling eller krav fra en tredjepart vedrørende det indhold, brugeren har indsendt.</p>
    <p> </p>
    <h4>Kontraktlige dokumenter</h4>
    <p> </p>
    <p>Disse brugsbetingelser regulerer alle kommercielle kontraktmæssige eller forudkontraktmæssige forhold mellem CapTarget Archery, brugeren og/eller organisationen vedrørende brugen af Platformen og abonnementsindgåelse, der tilbydes af CapTarget Archery.</p>
    <p> </p>
    <p>Disse brugsbetingelser har forrang over enhver anden aftale eller bestemmelse, der er i modstrid mellem parterne. De ophæver og erstatter alle tidligere generelle salgsbetingelser, der måtte have reguleret forholdet mellem parterne.</p>
    <p> </p>
    <p>Disse brugsbetingelser udgør hele det kontraktlige dokument mellem parterne.</p>
    <p> </p>
    <p>CapTarget Archery forbeholder sig ret til at ændre indholdet af brugsbetingelserne til enhver tid. Disse ændringer vil medføre en ny version, der automatisk gælder for alle tjenester, der tilbydes herefter.</p>
    <p> </p>
    <h4>Gældende lov, mediation og jurisdiktion</h4>
    <p> </p>
    <p>Disse brugsbetingelser er underlagt fransk ret.</p>
    <p> </p>
    <p>Parterne er enige om at søge en mæglingsløsning, inden der anlægges retssag. I tilfælde af en klage, der ikke kan løses i mindelighed mellem parterne, kan brugeren gratis søge forbrugermediering. Det anbefales at anvende følgende mægler: Centre de Médiation et d'Arbitrage de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p> </p>
    <p>Brugeren kan også bruge den online tvistbilæggelsesplatform, der er oprettet af den europæiske kommission, og som kan findes på følgende adresse: https://ec.europa.eu/consumers/odr/.</p>
</div>
