<div class="homepage-5">
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <app-hero-five></app-hero-five>
        <app-counter-one></app-counter-one>
        <app-about-two></app-about-two>
        <app-promo-four></app-promo-four>
        <app-content-five></app-content-five>
        <app-content-six></app-content-six>
        <app-pricing-two></app-pricing-two>
        <app-review></app-review>
        <app-cta-two></app-cta-two>
        <app-contact></app-contact>
        <app-footer-five></app-footer-five>
    </div>
</div>