<section class="section testimonial-area ptb_100" style="padding-top: 20px" id="reviews">
    <div class="container text-center">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.testimonial_title_1' | translate }}</h2>
                </div>
            </div>
        </div>
        <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-8">
                <div class="testimonials owl-carousel">
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/man017.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Florian3902</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Montpellier</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_1' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/man024.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Ridjex</h3>
                        <!-- Client Address -->
<!--                        <h5 class="client-address fw-4">Montpellier</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_2' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/woman026.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Cévenne </h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_3' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/woman018.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Novalight007  </h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_4' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/woman019.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Petuniageant  </h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_5' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/man026.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Henri</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_6' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/coachman005.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Gwareger29 </h3>
                        <!-- Client Address -->
<!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_7' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/woman017.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">F.D</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_8' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Single Testimonial -->
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/man027.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">R.D</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_9' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/woman007.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Valentin Gautier</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_10' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/man025.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">RDoanGiaHuy</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_11' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/dog003.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">989pasglop </h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_12' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/man013.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">Q. P</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="far fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_13' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/dog002.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">E.L</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_14' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="single-testimonial p-3 p-md-5">
                        <img src="assets/img/avatars_reviews/woman007.svg" class="mx-auto d-block" alt="">
                        <!-- Client Name -->
                        <h3 class="client-name mt-4 mb-2">M.S</h3>
                        <!-- Client Address -->
                        <!--                        <h5 class="client-address fw-4">Los Angeles, California</h5>-->
                        <!-- Client Rating -->
                        <div class="client-rating mt-2 mb-3">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                        </div>
                        <!-- Client Description -->
                        <div class="client-description">
                            <!-- Client Text -->
                            <div class="client-text">
                                <p>{{ 'TRANSLATE.testimonial_text_15' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
