import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-screenshot-two',
    templateUrl: './screenshot-two.component.html',
    styleUrls: ['./screenshot-two.component.css']
})
export class ScreenshotTwoComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    openPDF(): void {
        window.open('https://bit.ly/3WGNx6k', '_system');
    }
}
