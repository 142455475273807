<div class="container">
    <p> </p>
    <p>以下の用語は、利用規約内で最初の文字が大文字で示されるものであり、本条項で定義される意味を持ちます。</p>
    <p> </p>
    <p>サブスクリプション：プラットフォームのサービスへの無料または有料の購読を指します。</p>
    <p> </p>
    <p>アーチャー：弓矢の練習をするユーザーで、アプリケーションのサービスを使用するかどうかに関わらず該当します。</p>
    <p> </p>
    <p>コーチ：弓矢のコーチで、「コーチ」という無料または有料の購読型に申し込み、関連するサービスにアクセスするユーザーです。</p>
    <p> </p>
    <p>アプリケーション：AndroidおよびiOSで利用可能なモバイルアプリケーション「CapTarget Archery」を指します。</p>
    <p> </p>
    <p>サイト：CapTarget Archeryのウェブサイトを指します。</p>
    <p> </p>
    <p>コーチサイト：コーチ専用のサイトを指します。</p>
    <p> </p>
    <p>利用条件：プラットフォームの利用に関する利用規約を指します。</p>
    <p> </p>
    <p>アカウント：ユーザーに割り当てられるアカウントで、個人識別子とアクセスコードが関連付けられています。</p>
    <p> </p>
    <p>グループ：CapTarget Archeryアプリケーションで作成されたユーザーとコーチのグループを指します。</p>
    <p> </p>
    <p>パートナー：CapTarget Archeryと提携し、プラットフォームを介してユーザーに紹介される個人または法人を指します。</p>
    <p> </p>
    <p>プラットフォーム：サイトと/またはアプリケーションでアクセス可能なすべてのサービスを指します。</p>
    <p> </p>
    <p>ユーザー：個人または法人で、アカウントを持つかどうかに関わらずプラットフォームにアクセスし利用する者を指します。</p>
    <p></p>
    <p>ジェム：CapTarget Archeryで使用される仮想通貨を指します。</p>
    <p> </p>
    <h4>目的</h4>
    <p> </p>
    <p>利用規約の目的は、プラットフォームのすべてのユーザーに適用される条件と用語を定義することです。</p>
    <p> </p>
    <p>プラットフォームへの登録、アクセス、および/または利用は、ユーザーが利用規約のすべてを無条件に受け入れ、遵守することを意味します。</p>
    <p> </p>
    <p>未成年のユーザーは、登録のために親の許可または親権者の許可を取得したことを宣言し、認めるものとします。</p>
    <p> </p>
    <p>自動的にロボットや他の方法で生成される登録要求は拒否されます。</p>
    <p> </p>
    <h4>アプリケーションの紹介</h4>
    <p> </p>
    <p>アプリケーションは主にアーチェリーのプラクティショナーやコーチ向けであり、スポーツに関連する活動の管理と組織、メンバー間での情報のやり取り、業界の最新情報の伝達などを可能にします。</p>
    <p> </p>
    <p>アプリケーションを通じて、ユーザーは次の機能を享受できます：</p>
    <p> </p>
    <p>トレーニングや競技のセッションの管理</p>
    <p>スコアカウントやマッチの管理</p>
    <p>アーチェリー用具の管理</p>
    <p>データを使用した統計情報の提供</p>
    <p>ソーシャルで楽しいインタラクション、ユーザーのコーチにデータを提供</p>
    <p>グループメンバー間のソーシャルで楽しいインタラクション</p>
    <p>仮想通貨「ジェム」を使用した体験のカスタマイズアイテムの購入</p>
    <p>モバイル支払いシステム（ANDROIDまたはAPPLE）を使用した「ジェム」の購入</p>
    <p>メッセージング</p>
    <p> </p>
    <h4>ユーザーの登録</h4>
    <p> </p>
    <p>各ユーザーは、アプリケーションへのアクセスを可能にするために、アカウント（個人用または未成年のメンバーのためのアカウント）を作成することができます。</p>
    <p> </p>
    <p>登録は、ユーザーによってプラットフォーム上で行われます。ユーザーは、有効なメールアドレス、氏名、名前、および弓矢の実践に関する異なる情報など、登録フォームのさまざまな項目を入力する必要があります。ユーザーは利用規約とデータのプライバシーポリシーに関する情報を確認し、受け入れる必要があります。</p>
    <p> </p>
    <p>登録フォームに情報を記入することで、ユーザーは正確で最新かつ完全な情報のみを提供することに同意します。</p>
    <p> </p>
    <p>ユーザーはアカウントのアクセスコードを機密に保つことに同意します。アクセスコードの不正使用がある場合、ユーザーはすぐにCapTarget Archeryに通知します。</p>
    <p> </p>
    <p>ユーザーはアカウントの使用に対して単独で責任を持ちます。プラットフォームを介した接続やデータの送信は、ユーザーによって行われ、ユーザーの独占的な責任に基づいて行われたものとみなされます。</p>
    <p> </p>
    <p>CapTarget Archeryは、ユーザーの登録データがCapTarget Archeryに到達しない場合（ユーザーのインターネット接続の問題、一時的なサーバーの障害など、CapTarget Archeryに帰することのできない理由による場合）、またはデータが処理できない形式でCapTarget Archeryに到達した場合（ユーザーの不適切なハードウェアやソフトウェアを使用している場合など）に関しては責任を負いません。</p>
    <p> </p>
    <p>すべての登録は無期限であり、ユーザーアカウントは閉鎖されるまで有効です。</p>
    <p> </p>
    <p> </p>
    <h4>グループの登録</h4>
    <p> </p>
    <p>ユーザーがグループ機能にアクセスするためには、グループを作成または参加する必要があります。</p>
    <p> </p>
    <p>登録フォームに情報を記入することで、管理者は正確で最新かつ完全な情報のみを提供することに同意します。CapTarget Archeryがメンバーの電子メールアドレスと電話番号にアクセスする許可を事前に得ていることを保証し、メンバーはいつでもそれらの情報の削除を要求する自由があります（プライバシーポリシーをご覧ください）。</p>
    <p> </p>
    <p>管理者はグループの使用に対して単独で責任を持ちます。</p>
    <p>また、管理者は、自身および他のいかなる第三者が組織スペースを使用する際に、完全かつ排他的な責任を負うことを明示します。</p>
    <p> </p>
    <p>CapTarget Archeryは、組織の登録データがCapTarget Archeryに到達しない場合（管理者のインターネット接続の問題など、CapTarget Archeryに帰することのできない理由による場合）、またはデータが処理できない形式でCapTarget Archeryに到達した場合（管理者の不適切なハードウェアやソフトウェアを使用している場合など）に関しては責任を負いません。</p>
    <p> </p>
    <p>すべての登録は無期限であり、グループは管理者の一人によって閉鎖されるまで有効です。</p>
    <p> </p>
    <p> </p>
    <h4>コーチの登録</h4>
    <p>コーチの機能にアクセスするためには、ユーザーはCapTarget Archeryのコーチサービスへのアクセスライセンスを取得するために、ウェブサイトと/またはモバイルアプリケーションを介して、無料または有料の購読を行う必要があります。</p>
    <p>ライセンスは、ライセンスを取得したユーザー専用であり、そのユーザーがライセンスを使用して登録した場合にのみ適用されます。アカウントは他の個人と共有することはできません。</p>
    <p>異なる提供条件の利点は変更される可能性があります。購読が進行中の場合、これらの変更は有効にならないか、ユーザーに有利な変更として扱われます。</p>
    <p>登録は、ユーザーによってウェブサイト上で行われます。ユーザーは、有効なメールアドレス、氏名、名前、および弓矢の実践に関する異なる情報など、登録フォームのさまざまな項目を入力する必要があります。ユーザーは利用規約、販売条件、およびデータのプライバシーポリシーに関する情報を確認し、受け入れる必要があります。</p>
    <p>登録フォームに情報を記入することで、コーチおよびライセンスを購読する消費者は正確で最新かつ完全な情報のみを提供することに同意します。ライセンスを利用するユーザーは、アカウントの使用に対して単独で責任を持ちます。プラットフォームを介した接続やデータの送信は、ユーザーによって行われ、ユーザーの独占的な責任に基づいて行われたものとみなされます。</p>
    <p>CapTarget Archeryは、ユーザーの登録データがCapTarget Archeryに到達しない場合（ユーザーのインターネット接続の問題など、CapTarget Archeryに帰することのできない理由による場合）、またはデータが処理できない形式でCapTarget Archeryに到達した場合（ユーザーが不適切なハードウェアやソフトウェアを使用している場合など）に関しては責任を負いません。</p>
    <p>有料の購読は無期限で行われ、ユーザーの明示的な要求がない限り、最低契約期間後に契約は自動的に更新されます。解約の手続きに関する詳細については、販売条件をご参照ください。</p>
    <p> </p>

    <h4>データ共有</h4>
    <p>CapTarget Archeryを介してデータ共有機能にアクセスするために、私たちのアプリケーションではファンとフォローシステムを提供しています。あなたはユーザーとして、招待を受け入れたり、他のユーザーをあなたのネットワークに招待したりする機能を持っています。</p>
    <p>パブリックプロフィールの場合、他のユーザーがあなたをフォローするためには承認は必要ありません。ユーザーがあなたをフォローすると、あなたのいくつかのデータが自動的にまたは手動で共有される可能性があります。これには次のようなデータが含まれます：</p>
    <p>-競技結果やトレーニングセッションの結果。</p>
    <p>-ライセンスの期間、最高の成績、ランキングなどのスポーツ統計情報。</p>
    <p>-アプリの使用に関連するデータ、お気に入りのゲームやエクササイズなど。</p>
    <p>-クラブ、言語、国に関する情報、および名前、姓、使用している弓のタイプ、射撃カテゴリなどのその他のデータ。</p>
    <p>これらのデータの一部を共有しないように選択するオプションがあり、個人設定や各状況の設定を変更することで可能です。</p>
    <p>アカウントを作成する際に、CapTargetプラットフォームで表示されることに同意することになります。他のユーザーはあなたの名前と姓を使用してあなたを検索することができます。また、あなたのプロフィールは、スポーツ的なチャレンジやフォローコネクションの作成を目的として、自動的に他のユーザーに提案されることがあります。</p>
    <p>CapTarget Archery、そのパートナー、広告主、または他のユーザーによって共有される情報にさらされる可能性があることに注意してください。個人データの管理に関する詳細については、プライバシーポリシーをご覧ください。</p>
    <p>これらの条件は、個人データの保護およびプライバシーに関する現行の法律と規制に準拠しています。ご質問や懸念がある場合は、お気軽にお問い合わせください。</p>
    <p> </p>
    <p> </p>

    <p> </p>
    <h4>注意事項</h4>
    <p>CapTarget Archeryのコーチバージョンへのアクセスには、Webバージョンではコンピュータ、タブレット、または電話、モバイルバージョンではANDROIDまたはIOSのタブレットまたは電話を使用するためのコンピュータツールが必要です。データをダウンロードするためにはインターネット接続が必要です。サービスにアクセスするためのこの必要性を理解していることをユーザーは証明します。</p>
    <p>サービスで提供されるエクササイズやシチュエーションは、ユーザーが健康な状態で実行できることを前提としています。加入者は、自分の健康と他の人々の安全を守るために必要なすべての注意を払うことに同意します。
    </p>
    <p> </p>
    <p> </p>

    <p></p>
    <p></p>

    <h4>販売条件</h4>
    <p>CapTarget Archeryが提供するデジタルサービスやデジタル製品を購入するか、購読するかにかかわらず、以下に記載された販売条件を承諾するものとします。これらの条件に同意しない場合は、アプリケーションまたはウェブサイトでの購入をお控えください。</p>
    <h6>購読</h6>
    <p>CapTargetからコンテンツを購入する際、CapTargetは限定的で取り消し可能な非独占的かつ譲渡不可のライセンスを付与します。ライセンスにより、当社のアプリケーションやウェブサイト上で一部の有料機能にアクセスできるようになります。アプリケーションでは自動更新される月額および年間の購読が提供されています。購読料金は購読契約の記念日に自動的に選択した支払方法から引き落とされます。CapTarget Archeryの仮想通貨「gemmes」で支払われる購読は自動的に更新されません。</p>
    <p>購読を更新しない場合は、購読期間終了の少なくとも24時間前にアカウント設定にアクセスして無効にする必要があります。</p>
    <h6>料金</h6>
    <p>購読料金はアプリケーション上で公開されており、予告なしにいつでも変更される可能性があります。購読料にはすべての適用税が含まれています。インターネットアクセスやモバイルデータの使用料など、当社のアプリケーションの利用に関連するすべての料金は、ユーザーの責任です。カタログの一部の商品が誤って評価される可能性があります。料金は現在の購読者にも変更される可能性があります。選択したシステム（Apple、Google、Stripeなど）によっては、現在の購読料金の変更がある場合に通知され、更新前に購読をキャンセルすることができる場合があります。</p>
    <h6>損失のリスク</h6>
    <p>CapTargetコンテンツが提供された後、購入後のCapTargetコンテンツの損失リスクはあなたに移行します。これにはファイルの破損、デバイスやハードディスクの故障による損失が含まれます。購入したCapTargetコンテンツは通常、CapTargetアカウントで利用できますが、さまざまな理由で利用できなくなることがあります。そのような場合、CapTargetは後続のCapTargetコンテンツの利用不可能性に対して責任を負いません。すでに支払った料金の全額または一部に対して払い戻しを受ける権利はありません。</p>
    <h6>配信</h6>
    <p>CapTargetコンテンツへのアクセスは、製品または購読の購入時に提供されます。コンテンツはCapTargetアカウントで利用可能です。アプリケーションの再起動が必要な場合があります。サービスが利用可能になるまでには合理的な時間がかかる場合があります。（通常、数分以内）</p>
    <h6>支払い</h6>
    <p>支払い機能を利用するには、有効な支払い方法（クレジットカードなどの承認された他の支払い方法）がCapTargetアカウントに設定されている必要があります。購読料金は、AppleやGoogle Playのアカウント、または当社のウェブサイトで購読を購入した場合に、指定した支払い方法から自動的に引き落とされます。正確で最新の支払い情報を提供することに同意します。支払いがなされない場合、当社は支払いを停止またはキャンセルする権利を保持します。</p>
    <h6>アプリケーションの使用</h6>
    <p>アプリケーションは個人的および商業目的で使用することが許可されています。私たちの事前の書面による許可なしに、アプリケーションの内容を変更、配布、販売、または転送することはできません。適用される法律に違反し、第三者の権利を侵害し、または第三者に害を与える方法でアプリケーションを使用することはできません。</p>
    <h6>払い戻し</h6>
    <p>私たちの購読は3週間の試用期間があり、したがって払い戻しはできません。各購読を購読する前に、各購読の機能をよく確認してください。販売されるデジタル製品は明示されており、払い戻しはできません。</p>
    <h6>解約</h6>
    <p>購読者が購読条件または使用条件に違反した場合、または私たちのアプリケーションを何らかの方法で使用していると我々が信じる理由がある場合、私たちはアプリケーションへのアクセスを終了する権利を保持します。アプリケーションの購読を購入することで、購読条件に同意するものとします。</p>

    <h6>お問い合わせ</h6>
    <p>質問や懸念がある場合は、次のアドレスにお問い合わせください：support@captarget-archery.com</p>
    <p>問題を解決し、すべての関係者が満足する方法を見つけるために最善の努力をいたします。</p>
    <p></p>
    <p></p>
    <h4>プラットフォームの使用料金</h4>
    <p> </p>
    <p>ユーザーのアカウントとグループの作成は無料です。ただし、プラットフォームの利用に関連する接続および通信料金はユーザーの負担です。</p>
    <p>コーチバージョンへのアクセスには料金がかかり、テストバージョンやベータバージョンを除いて有料です。料金はバージョンのリリース後、ウェブサイトで提供されます。
    </p>
    <h4>プラットフォームを介した購入</h4>
    <p> </p>
    <p>実際のお金で限定的で取り消し可能な個人用ライセンスを購入することができ、これには仮想通貨「ゲム」を使用しての購入も含まれます。特にCapTarget Archeryのゲーム内で使用される仮想通貨やダイヤモンドを購入できます。これらの通貨はイベント、コンテスト、ゲーム、広告の閲覧、プラットフォームでの目標達成時の報酬などで獲得することも可能です。</p>
    <p> </p>
    <p>これらの通貨はアプリ内で販売されているさまざまなアイテムの購入に使用できます。</p>
    <p> </p>
    <p>アイテムの転送は禁止されており、サービス内で明示的に許可されている場合を除いて、第三者への売買、取引、転送は行えません。</p>
    <p> </p>
    <p> </p>
    <p>CapTarget Archeryは、いつでも通知なしで仮想アイテムを管理、規制、制御、変更、または削除することができます。これにより、CapTarget Archeryはいつでもこれらの権利を行使することができるため、これによって発生した損害または損失について、あなたまたは第三者に対する責任はありません。</p>
    <p> </p>
    <p>サービスを介しての他社の仮想アイテムの購入や変換は、最終的で返金不可です。</p>
    <p> </p>
    <p>あなたまたはあなたの名前で登録したアカウントを使用する人物が支払う必要があるすべての適用可能な料金と税金を支払うことに同意します。CapTarget Archeryは、サービスを通じて提供される仮想アイテムの価格をいつでも変更できる権利を保持します。CapTarget Archeryは、どの理由においても返金の義務を負わないことを認識し、アカウントの閉鎖時に未使用の仮想アイテムに対して金額や補償金は受け取らないことに同意します。</p>
    <p> </p>
    <h4>広告とパートナー</h4>
    <p> </p>
    <p>CapTarget Archeryは、さまざまな提供サービスで広告やパートナーへのリンクを提供することがあります。</p>
    <p> </p>
    <p>CapTarget Archeryはこれらを制御できないため、これらのサイトや外部ソースの可用性についての保証を提供することはできず、これらのサイトで提供される内容、広告、製品、サービス、またはその他の素材に対する責任を負うことはありません。</p>
    <p> </p>
    <p>同様に、CapTarget Archeryは、これらの広告、パートナー、または外部ソースで提供されるコンテンツやサービスの使用に関連する実際または推定の損害や損失に対して一切の責任を負いません。</p>
    <p> </p>
    <h4>アカウントの閉鎖</h4>
    <p> </p>
    <p>ユーザーはいつでも自身のアカウントまたは未成年者のために管理しているアカウントを閉鎖できます。データのプライバシーポリシーに従い、アカウントの閉鎖によって関連する個人データが削除されます。</p>
    <p> </p>
    <p>CapTarget Archeryが損害賠償を請求する権利を損なうことなく、CapTarget Archeryは以下の場合、通知なしまたは補償なしでユーザーのアカウントを一時的または永久に閉鎖する権利を保持します。</p>
    <p> </p>
    <p>利用規約の違反</p>
    <p>登録時の虚偽の情報提供；</p>
    <p>CapTarget Archeryのビジネス利益に反する行動。</p>
    <p> </p>
    <p>CapTarget Archeryによるアカウントの閉鎖により、利用規約や販売条件の違反が発生した場合でも、賠償金や返金は行われません。アカウントに支払われたすべての金額はCapTarget Archeryの所有となります。</p>
    <p> </p>
    <p>有料アクセスの場合、該当する販売条件に従ってアクセスを閉鎖できます。有料アクセスの閉鎖については、詳細については販売条件をご覧いただくか、support@captarget-archery.comまでメールでお問い合わせください。</p>
    <p> </p>
    <h4>グループの削除</h4>
    <p> </p>
    <p>管理者はいつでもグループを削除できます。組織のスペースを削除すると、関連するすべてのデータと利益も削除されます。</p>
    <p> </p>
    <p>CapTarget Archeryによる組織スペースの削除</p>
    <p> </p>
    <p>CapTarget Archeryは損害賠償を請求する権利を損なうことなく、CapTarget Archeryは以下の場合、通知なしまたは補償なしで組織スペースを一時的または永久に削除する権利を保持します。</p>
    <p> </p>
    <p>販売条件の違反</p>
    <p>登録時の虚偽の情報提供；</p>
    <p>CapTarget Archeryのビジネス利益に反する行動。</p>
    <p> </p>
    <p>CapTarget Archeryによる組織スペースの削除により、アカウントが有料アクセスの場合でも賠償金や返金は行われません。アカウントに支払われたすべての金額はCapTarget Archeryの所有となり、組織または管理者は契約の期限までアクセス料金を支払う義務を負います。</p>
    <p> </p>
    <h4>情報とプラットフォームのコンテンツ</h4>
    <p> </p>
    <p>ユーザーは、特にメンバーであるグループの情報やコンテンツを評価する際に、プラットフォームの使用において慎重に判断することに同意します。</p>
    <p> </p>
    <p>この点に関して、ユーザーは特に、プラットフォームで利用可能な情報やコンテンツが完全ではないこと、およびそれらが主に組織の管理を支援するためのものであることを認識しています。</p>
    <p> </p>
    <p>ユーザーはプラットフォームで利用可能なコンテンツの一部または全体を販売、転売、または何らかの方法で利用してはなりません。</p>
    <p> </p>
    <p>ユーザーは、プラットフォームを通じて公開または送信する通信、情報、ファイル、動画、写真、文書、その他のコンテンツについて一切の責任を負います。</p>
    <p> </p>
    <p>ユーザーがプラットフォームに投稿する通信、情報、ファイル、動画、写真、文書、その他のコンテンツは、ユーザー自身の見解と意見を表すものであり、CapTarget Archeryやその従業員や代理人の見解や意見ではないことに同意します。</p>
    <p> </p>
    <p>プラットフォームを通じてユーザーが投稿する際には、公平さ、礼儀正しさ、他人への尊重の原則に従い、客観的かつ正確な情報を提供することに同意します。</p>
    <p> </p>
    <p>CapTarget Archeryは、ユーザーがプラットフォームに投稿する通信、情報、ファイル、動画、写真、文書、その他のコンテンツに対して節度と注意を持つようユーザーに奨励します。この点に関して、ユーザーはプラットフォームに投稿するコンテンツを次のように制限します。</p>
    <p> </p>
    <p>公序良俗や善良の風俗に反する情報の拡散；</p>
    <p>宣伝活動や勧誘、営業活動、営業の誘導を目的とする情報の転用；タバコ、アルコール、その他の規制対象の物質、製品、サービスを支持する宣伝、またはその他の広告活動；</p>
    <p>他人の人格権に対する侵害や中傷、侮辱、わいせつ、ポルノ、攻撃的、暴力的、または差別、政治的暴力、人種差別、排外主義、性差別、同性愛嫌悪を扇動する情報の拡散；</p>
    <p>個人データ保護法に違反する情報の拡散、特に個人の氏名、住所、電子メール、電話番号、写真、音声、映像の録音、録画など、個人を特定できるデータの提供；</p>
    <p>知的財産権に対する侵害、特に著作権、商標権に関する、著作権表示や商標表示を削除すること、または制限の表示を削除すること。</p>
    <p> </p>
    <p>また、ユーザーは以下の権利を尊重することに同意します。</p>
    <p> </p>
    <p>個人の権利（肖像権、プライバシー権）</p>
    <p>商標権</p>
    <p>著作権（ソフトウェア、音声、映像、写真、テキスト、アニメーション画像など）および関連する権利（演者、音楽や映像の製作者、データベース製作者など）；</p>
    <p>一般的には、個人と財産の権利。</p>
    <p> </p>

    <p>ユーザーは、CapTarget Archeryの事前かつ書面による許可なしに、次に示すことを含むがこれに限定されない制約を受けます：</p>
    <p> </p>
    <p>直接または間接的に報酬を受ける商品やサービスを提供するなど、プロモーション目的でプラットフォームを使用すること；</p>
    <p>プラットフォームのコンテンツからアーカイブファイルを作成すること；</p>
    <p>プラットフォームに表示されるコンテンツ、商標、ロゴ、識別子の一部または全体を複製、表示、使用、参照（特に検索エンジンのメタタグ内など）、または利用すること。</p>
    <p> </p>
    <p>本規約の違反は、当該違反の内容が即座に削除されること、警告および/またはユーザーアカウントの閉鎖、およびCapTarget Archeryが提起または請求する他の法的手続や損害賠償を含む、他の法的措置に関わらず、引き起こす可能性があります。</p>
    <p> </p>
    <p>CapTarget Archeryは、プラットフォームの情報、ファイル、ビデオ、写真、ドキュメント、または他のコンテンツ（以下、「プラットフォームのコンテンツ」といいます）について、一切の責任を負いません。これには、違法なコンテンツ、誤り、または省略、またはプラットフォームのコンテンツの使用によって生じるいかなる利用者の損失や損害も含まれます。</p>
    <p> </p>
    <p>ウェブサイトのセカンダリページへのハイパーリンクは、ユーザーがCapTarget Archeryから明示的な許可を得た場合を除き、厳格に禁止されています。また、CapTarget Archeryの要請により、ユーザーはリンクを簡単に削除する必要があります。</p>
    <p> </p>
    <h4>管理者のモデレーションの役割</h4>
    <p> </p>
    <p>管理者は、グループのコーディネーターおよび監督者として、利用者による本規約の遵守を確保する責任を負います。彼が違反内容に気付いた場合、法律や公共秩序、および/または本規約に違反するコンテンツ（可能な場合）を報告または削除することに同意します。具体的には、暴力的、ポルノグラフィック、人種差別的、反ユダヤ主義的、排外主義的、人格や尊厳を侵害するコンテンツ、プライバシーの侵害、著作権、関連する権利、商標権、第三者の人格権を侵害するコンテンツを含みます。</p>
    <p> </p>
    <p>これらの規則や慣習を守らない場合、CapTarget Archeryは、問題のある管理者および/またはメンバーを除外し、プラットフォームの不適切な使用による明白な違法行為を終了させる可能性のある措置を講じる権利を留保します。</p>
    <p> </p>
    <h4>電子メール、プッシュ通知、およびSMSの送信</h4>
    <p> </p>
    <p>提供されるサービスの一環として、CapTarget Archeryは電子メール、アプリのプッシュ通知、およびSMSの通信を提供しており、これによりユーザー間で組織内の情報を交換できます。これは、ユーザーがアカウント作成時に提供した携帯電話番号とメールアドレスを使用して行われます。</p>
    <p> </p>
    <p>ユーザーはいつでも、デバイスの設定を変更するか、サポートに要求することで、CapTarget Archeryからの電子メール、プッシュ通知、またはSMSを受け取らないようにすることができます。</p>
    <p> </p>
    <h4>プラットフォームへのアクセス - 責任と契約</h4>
    <p> </p>
    <p>CapTarget Archeryは、技術の進化に応じて必要な変更や改善をプラットフォームに加えるための最善の努力をしており、予定されたメンテナンス操作を除いて、プラットフォームを24時間365日利用可能にする努力をしています。</p>
    <p> </p>
    <p>CapTarget Archeryは、進化的または修正的なメンテナンス操作を実施するために、プラットフォームへのアクセスを一時的に停止する権利を留保します。ユーザーは、このようなメンテナンスに関連してCapTarget Archeryに対する一切の責任を免除し、これに関するいかなる請求も放棄することに同意します。</p>
    <p> </p>
    <p>CapTarget Archeryは、ユーザーがデバイスまたはコンピュータにインストールしたCapTarget Archeryのサービスやゲームの更新を要求する場合があります。ユーザーは、CapTarget Archeryが通知するかしないかに関係なく、CapTarget Archeryのサービスやゲームを更新できることを認識し、同意します。ユーザーは、CapTarget Archeryのサービスとゲームを受信するためにサードパーティのソフトウェアを更新する必要があるかもしれないことに注意してください。</p>
    <p> </p>
    <p>CapTarget Archeryは、特定の使用目的に対するプラットフォームの品質と適合性に関する保証を含む、明示的または黙示の保証を提供しません。</p>
    <p> </p>
    <p>CapTarget Archeryは、プラットフォームの無停止および/または連続性の確保について、法的な強制力を持つ状況やフランス民法第1218条で定義される不可抗力または偶発的な事態など、いかなる責任も負わないことを保証しません。</p>
    <p> </p>
    <p>ユーザーが本規約の義務に違反した結果、CapTarget Archeryの責任が発生した場合、修復は直接的、個人的、特定的で予測可能な損害に限定されます。</p>
    <p> </p>
    <p>CapTarget Archeryは、サードパーティによってプラットフォームに投稿される情報の適合性と正確性を保証しません。</p>
    <p> </p>
    <p>CapTarget Archeryは、CapTarget Archeryが作成しないプラットフォームのコンテンツに対して、第三者が所有権を有する権利や紛争、訴訟、クレームに対して責任を負いません。</p>
    <p> </p>
    <p>CapTarget Archeryがデータの収集と処理を行う方法についての詳細は、データのプライバシーポリシーに記載されています。</p>
    <p> </p>
    <p>CapTarget Archeryは、特に、これらのサービス規約やサービス自体に関連するいかなる賠償義務も負わないため、あなたに対して間接的、付随的、連続的、または同様の損害に対して責任を負わないことを認識し、同意します。特に、契約的、不法行為的、その他の基準に関わらず、特別または懲罰的な損害賠償を支払う義務はありません。CapTarget Archeryがこのような損害の可能性を知っているかどうかにかかわらず、です。CapTarget Archeryに対するあなたの損害賠償請求の最大額は、サービス規約に従い過去6ヶ月間に支払った金額に限られることに同意します。したがって、その期間に何も支払っていない場合、CapTarget Archeryとのあらゆる紛争に対するあなたの唯一の救済措置（およびCapTarget Archeryの専属の責任）は、サービスの使用を停止し、アカウントを閉じることです。</p>
    <p>特に、これらのサービス規約のいずれの条項も、CapTarget Archeryの過失または詐欺に起因する死亡または身体的損傷の場合の法的権利を損なうものではありません。</p>
    <p> </p>
    <p>ユーザーは、サービスの使用に関する違反またはその他の損害、包括的な弁護士費用を含む、第三者が主張する要求、損害、その他の損失に関するCapTarget Archery（およびその開発者、管理者、コンテンツクリエイター）の責任を免れ、防衛し、解放することに同意します。ただし、前述は、あなた自身の故意または過失の行動に帰すことができない権利の侵害の場合には適用されません。</p>
    <p> </p>
    <p>CapTarget Archeryは、合理的な額の弁護士費用を含む、その制御を逸脱した要因による遅延または履行不履行のために一切の責任を負いません。これには、予測できない事態やCapTarget Archeryの制御を逸脱する要因、例えば自然災害、戦争、テロ行為、暴動、輸出入停止措置、市民および軍事当局の措置、火災、洪水、事故、ストライキ、交通機関、燃料、エネルギー、労働力、材料の不足などによる、本規約の不履行が含まれます。</p>
    <p> </p>
    <p>アプリケーションで説明されている活動の実践に関するアドバイスは参考情報です。ユーザーは自身の健康にアドバイスを適応させるか、医師に相談する責任があります。スポーツの実践に関する責任はユーザーにあり、CapTarget Archeryはアプリケーションのサポートを利用して行う活動に起因する身体的または精神的な怪我に対して一切の責任を負いません。</p>
    <p> </p>
    <p>ユーザーは、セキュリティの脆弱性を報告し、それを自身または他の個人/組織の利益のために使用しないことに同意します。ユーザーは、CapTarget Archeryが提供するサービスの正常な動作に支障をきたす行動を取らず、バグを悪用しないことに同意します。</p>
    <p> </p>
    <h4>知的財産権</h4>
    <p> </p>
    <p>プラットフォームに表示されるサービスおよびその内容（データ、データベース、ソフトウェア、写真、情報、イラスト、ロゴ、商標など）は、現行の知的財産権、データベースプロデューサーの権利、または法律により認められたその他の権利によって保護されています。</p>
    <p> </p>
    <p>サービスおよび/またはプラットフォームの内容を、CapTarget Archery、ユーザー、組織、または第三者に帰属するかにかかわらず、無断でコピー、複製、表示、適応、変更、修正、または配布することは違法であり、その著者の刑事および民事責任を引き起こす可能性があります。</p>
    <p> </p>
    <p>CapTarget Archeryは、ユーザーに対し、利益を得る目的を除外し、自身のニーズに応じてプラットフォームを使用する権利を付与します。</p>
    <p> </p>
    <p>ユーザーがコンテンツをプラットフォームにアップロードする際、自動的にそのコンテンツをCapTarget Archeryが無料で利用できる個人的で非独占的なライセンスを付与します。</p>
    <p> </p>
    <p>このライセンスは、全世界、すべての使用、すべてのメディア、現在知られているまたは知られていないすべての伝送手段、および知られているまたは知られていないすべての伝送手段による使用、またはすべての知られているまたは知られていない伝送手段での使用を対象とし、知的財産権の法的保護（更新および延長を含む）の法的保護期間全体にわたります：</p>
    <p> </p>
    <p>複製権：コンテンツの無制限の複製を作成または作成する権利、どの形式またはどのメディアにも適用されます。</p>
    <p> </p>
    <p>表現権：コンテンツを組織のメンバーにすべての手段を使用して伝達、配信、伝達、または配信する権利、すべての通信ネットワークや情報交換および通信手段（インターネット、地上ネットワーク、ケーブル、衛星、ダウンロード、テレビ送信など）を含む。</p>
    <p> </p>
    <p>翻訳権：コンテンツをすべての言語に翻訳する権利（すべてのコンピュータ言語を含む）。</p>
    <p>ユーザーは、プラットフォームにアップロードされるコンテンツが第三者の知的財産権またはその他の権利を侵害していないことをCapTarget Archeryに保証します。</p>
    <p> </p>
    <p>したがって、ユーザーは、ユーザーが投稿したコンテンツに関する第三者の行動または請求によって生じる損害についてCapTarget Archeryを防衛し、その責任を負うことに同意します。</p>
    <p> </p>
    <h4>契約書類</h4>
    <p> </p>
    <p>これらの利用規約は、CapTarget Archery、ユーザー、および/または組織との間のすべての契約関係を規制し、またはプラットフォームの使用およびCapTarget Archeryが提供するサブスクリプションに関連する商業契約または事前契約関係を規制します。</p>
    <p> </p>
    <p>これらの利用規約は、当事者間のどの合意または不一致する規定よりも優先されます。これらの規約は、当事者間の関係を規制してきた以前の一切の利用規約を無効にし、置き換えます。</p>
    <p> </p>
    <p>これらの利用規約は、当事者間のすべての契約書類を構成します。</p>
    <p> </p>
    <p>CapTarget Archeryは、いつでも利用規約の内容を変更する権利を留保します。これらの変更は、その後提供されるすべてのサービスに自動的に適用される新しいバージョンにつながります。</p>
    <p> </p>
    <h4>適用法、仲裁および裁判所</h4>
    <p> </p>
    <p>これらの利用規約は、フランス法に従います。</p>
    <p> </p>
    <p>当事者は、司法行動を起こす前に和解を探求することに合意します。当事者間で解決できないクレームがある場合、ユーザーは消費者の調停を無料で利用することができます。以下の調停機関を利用することを検討してください：パリ調停および仲裁センター（CMAP）、www.mediateur-conso.cmap.fr。</p>
    <p> </p>
    <p>ユーザーは、欧州委員会が公開している紛争解決プラットフォームも利用できます。詳細は以下のウェブサイトをご覧ください：www.ec.europa.eu/consumers/odr/。</p>

</div>
