import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-it-conf',
  templateUrl: './it-conf.component.html',
  styleUrls: ['./it-conf.component.css']
})
export class ItConfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
