<div class="homepage-1">
    <app-scrollup></app-scrollup>
    <div class="main">
<!--        <app-header-one></app-header-one>-->
<!--        <app-hero-one></app-hero-one>-->
<!--        <app-promo-one></app-promo-one>-->
        <app-feature-one></app-feature-one>
        <app-screenshot-one></app-screenshot-one>
        <app-content-one></app-content-one>
        <app-content-two></app-content-two>
        <app-pricing-one></app-pricing-one>
        <app-review></app-review>
        <app-team-one></app-team-one>
        <app-cta-one></app-cta-one>
        <app-download-one></app-download-one>
        <app-faq-one></app-faq-one>
        <app-contact></app-contact>
        <app-footer-one></app-footer-one>
    </div>
</div>