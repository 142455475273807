<section class="start-free-area">
    <div class="container">
        <div class="col-12">
            <!-- Start Free Content -->
            <div class="start-free-content d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between shadow-lg" data-aos="fade-up" data-aos-duration="800">
                <!-- Start Free Content -->
                <div class="start-free-text">
                    <strong>Not sure what's better for you?</strong>
                    <h2 class="my-2">Start your 14 days free trial</h2>
                    <span>No Credit card required</span>
                </div>
                <!-- Start Free Button -->
                <div class="start-free-btn mt-4 mt-lg-0">
                    <a href="#" class="btn btn-bordered"><span>Get Started</span></a>
                </div>
            </div>
        </div>
    </div>
</section>