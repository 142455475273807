import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-team-one',
    templateUrl: './team-one.component.html',
    styleUrls: ['./team-one.component.css']
})
export class TeamOneComponent implements OnInit {

    members: {
        name: string,
        photo: string,
        title: string,
        facebook: string,
        instagram: string,
        website: string,
        linkedin: string
    }[] = [
        {
            title: 'Fondateur',
            name: 'Anatole',
            photo: 'anatole.jpg',
            facebook: 'https://www.facebook.com/anatole.couasnon',
            instagram: 'https://www.instagram.com/anatole_csn/?hl=fr',
            linkedin: 'https://www.linkedin.com/in/anatole-couasnon-65b700159/',
            website: ''
        },
        {
            title: 'Community Manager',
            name: 'Zoé',
            photo: 'zoé.jfif',
            facebook: 'https://www.facebook.com/zoe.machado.14',
            instagram: 'https://www.instagram.com/zoe.machadoformiga/',
            linkedin: 'https://www.linkedin.com/search/results/all/?keywords=zo%C3%A9%20machado%20formiga&origin=RICH_QUERY_SUGGESTION&position=0&searchId=80ce040c-a1e8-491c-951e-002772872d8e&sid=gR~',
            website: ''
        },
        {
            title: 'Graphiste',
            name: 'Aymeric',
            photo: 'aymeric.jpg',
            facebook: 'https://www.facebook.com/aymeric.bernard.9081',
            instagram: 'https://www.instagram.com/aymeric_art_/',
            linkedin: 'https://www.linkedin.com/in/aymeric-bernard-8b5170200/',
            website: ''
        },
        {
            title: 'Graphiste',
            name: 'Hugo',
            photo: 'hugo.jpg',
            facebook: '',
            instagram: 'https://www.instagram.com/hugo.fontface/',
            linkedin: '',
            website: ''
        },
        {
            title: 'Développeur',
            name: 'Yann',
            photo: 'yann.jpg',
            facebook: '',
            instagram: 'https://www.instagram.com/yanngeny_photos/?hl=en',
            linkedin: 'https://fr.linkedin.com/in/yann-geny-53490717a',
            website: ''
        },
        {
            title: 'Développeur',
            name: 'Louis',
            photo: 'louis.jpg',
            facebook: 'https://www.facebook.com/lili.guimar',
            instagram: 'https://www.instagram.com/louis_mjlt/',
            linkedin: 'https://www.linkedin.com/in/louis-marjolet-4a231b159/',
            website: ''
        }
    ];

    constructor() {
    }

    ngOnInit(): void {
    }

}
