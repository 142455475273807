import {Component, OnInit} from '@angular/core';
import {LanguageService} from '../../../Services/language.service';

@Component({
    selector: 'app-promo-three',
    templateUrl: './promo-three.component.html',
    styleUrls: ['./promo-three.component.css']
})
export class PromoThreeComponent implements OnInit {

    constructor(public languageService: LanguageService) {
    }

    ngOnInit(): void {
    }

    openForm(): void {
        window.open('https://docs.google.com/forms/d/1IJ0J-uUcZ4FXCNftjpbOUIQTXhski1IpUn4bXAHAC1Y/edit#responses', '_system');
    }

    openFacebook(): void  {
        window.open('https://www.facebook.com/captarget.archery', '_system');
    }

    openInstagram(): void  {
        if (this.languageService.currentLanguage === 'fr') {
            window.open('https://www.instagram.com/captarget.france/', '_system');
        } else {
            window.open('https://www.instagram.com/captarget.archery/', '_system');
        }
    }

    openAndroid(): void  {
        window.open('https://play.google.com/store/apps/details?id=captarget.archery', '_system');
    }

    openIos(): void  {
        window.open('https://apps.apple.com/us/app/captarget-archery/id1584542210', '_system');
    }
}
