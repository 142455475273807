<section id="benifits" class="section benifits-area style-three ptb_100">
    <div class="container">
        <div class="row">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item p-4">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <span class="icon-lightbulb"></span>
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Free Trail</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item p-4">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <span class="icon-puzzle"></span>
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Easy to use</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4">
                <div class="benifits-item p-4">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <span class="icon-pricetags"></span>
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Customizable Code</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci, ipsum.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>