import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../Services/language.service';

@Component({
    selector: 'app-header-two',
    templateUrl: './header-two.component.html',
    styleUrls: ['./header-two.component.css']
})
export class HeaderTwoComponent implements OnInit {
    displayFullLogo = true;
    languages: any[] = [
        {text: 'Français', value: 'fr'},
        {text: 'English', value: 'en'},
        {text: 'Español', value: 'es'},
        {text: 'Italiano', value: 'it'},
        {text: 'Deutsch (German)', value: 'de'},
        {text: 'Português', value: 'pt'},
        {text: '한국어 (Coréen)', value: 'ko'},
        {text: '中文 (Chinois)', value: 'zh'},
        {text: 'Türkçe (Turc)', value: 'tr'},
        {text: '日本語 (Japonais)', value: 'ja'},
        {text: 'Eesti keel (Estonien)', value: 'et'},
        {text: 'Dutch', value: 'nl'},
        {text: 'Dansk (Danois)', value: 'da'},
    ];

    constructor(public translateService: TranslateService,
                public languageService: LanguageService) {
        this.onResize(undefined);
    }

    onResize($event): any {
        this.displayFullLogo = window.outerWidth > 500;
    }

    ngOnInit(): void {
    }

    onSelect(event: any): void {
        this.languageService.currentLanguage = event.target.value;
        console.log('Selected Language:', this.languageService.currentLanguage);
        this.languageService.setCurrentLanguage(this.languageService.currentLanguage);
    }
}
