<section id="pricing" class="section price-plan-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Affordable Pricing Plans</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <!-- Price Plan Wrapper -->
            <div class="price-plan-wrapper">
                <!-- Single Price Plan -->
                <div class="single-price-plan">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <img class="avatar-lg" src="assets/img/startup.png" alt="">
                    </div>
                    <!-- Price Header -->
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                            Starter
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$19</span>
                            <span class="plan-type text-uppercase">/month</span>
                        </div>
                    </div>
                    <!-- Plan Features -->
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>5GB Linux Web Space</li>
                        <li><i class="icofont-check"></i>5 MySQL Databases</li>
                        <li><i class="icofont-close"></i>24/7 Tech Support</li>
                        <li><i class="icofont-close"></i>Daily Backups</li>
                    </ul>
                    <!-- Plan Select -->
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                    </div>
                </div>
                <div class="single-price-plan">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <img class="avatar-lg" src="assets/img/basic.png" alt="">
                    </div>
                    <!-- Price Header -->
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                            Basic
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$29</span>
                            <span class="plan-type text-uppercase">/month</span>
                        </div>
                    </div>
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>10GB Linux Web Space</li>
                        <li><i class="icofont-check"></i>10 MySQL Databases</li>
                        <li><i class="icofont-check"></i>Unlimited Email</li>
                        <li><i class="icofont-close"></i>Daily Backups</li>
                    </ul>
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                    </div>
                </div>
                <div class="single-price-plan featured">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <img class="avatar-lg" src="assets/img/pro.png" alt="">
                    </div>
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                            Pro
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$49</span>
                            <span class="plan-type text-uppercase">/month</span>
                        </div>
                    </div>
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>25GB Linux Web Space</li>
                        <li><i class="icofont-check"></i>25 MySQL Databases</li>
                        <li><i class="icofont-check"></i>24/7 Tech Support</li>
                        <li><i class="icofont-check"></i>Daily Backups</li>
                    </ul>
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                    </div>
                </div>
                <div class="single-price-plan">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <img class="avatar-lg" src="assets/img/ultra.png" alt="">
                    </div>
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                            Ultra
                        </h4>
                        <div class="plan-cost text-uppercase">
                            <span class="plan-price">$99</span>
                            <span class="plan-type">/month</span>
                        </div>
                    </div>
                    <ul class="plan-features pb-3">
                        <li><i class="icofont-check"></i>100GB Linux Web Space</li>
                        <li><i class="icofont-check"></i>Unlimited Databases</li>
                        <li><i class="icofont-check"></i>24/7 Tech Support</li>
                        <li><i class="icofont-check"></i>Daily Backups</li>
                    </ul>
                    <div class="plan-select">
                        <a class="btn btn-bordered text-uppercase" href="#">Select Plan</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>