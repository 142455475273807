<div class="container">
    <h4>Definities</h4>
    <p> </p>
    <p>De volgende termen, waarvan de eerste letter in hoofdletters is in de AV, hebben de betekenis die aan hen is toegekend in dit artikel.</p>
    <p> </p>
    <p>Abonnement: verwijst naar elk abonnement, gratis of tegen betaling, op de diensten van het platform.</p>
    <p> </p>
    <p>Boogschutter: Gebruiker die boogschieten beoefent en al dan niet gebruik maakt van de diensten van de applicatie.</p>
    <p> </p>
    <p>Coach: Boogschiettrainer die zich heeft geabonneerd op een gratis of betaald "Coach" abonnement en daardoor toegang heeft tot de bijbehorende diensten.</p>
    <p> </p>
    <p>Applicatie: verwijst naar de mobiele applicatie "CapTarget Archery" beschikbaar op Android en iOS.</p>
    <p> </p>
    <p>Site: verwijst naar de vitrine van CapTarget Archery.</p>
    <p> </p>
    <p>Coach site: verwijst naar de site met gereserveerde toegang voor Coaches.</p>
    <p> </p>
    <p>Gebruiksvoorwaarden: verwijst naar deze algemene gebruiksvoorwaarden van het platform.</p>
    <p> </p>
    <p>Account: verwijst naar het account dat aan een Gebruiker is toegewezen om toegang te krijgen tot het platform en diverse diensten, waaraan een persoonlijke identificatiecode en toegangscode zijn gekoppeld.</p>
    <p> </p>
    <p>Groep: Verwijst naar een groep die is gemaakt in de CapTarget Archery-applicatie en die Gebruikers en Coaches samenbrengt.</p>
    <p> </p>
    <p>Partner: verwijst naar een persoon of rechtspersoon die een partnerschap is aangegaan met CapTarget Archery en daardoor aan Gebruikers wordt gepresenteerd via het platform.</p>
    <p> </p>
    <p>Platform: verwijst naar alle diensten die toegankelijk zijn op de Sites en/of de Applicatie.</p>
    <p> </p>
    <p>Gebruiker: verwijst naar elke natuurlijke of rechtspersoon die toegang heeft tot het platform en het gebruikt, al dan niet via een Account.</p>
    <p></p>
    <p>Edelstenen: verwijst naar een virtuele valuta gebruikt op CapTarget Archery.</p>
    <p> </p>
    <h4>Doel</h4>
    <p> </p>
    <p>De AV hebben tot doel de termen en voorwaarden vast te stellen die van toepassing zijn op alle Gebruikers van het Platform.</p>
    <p> </p>
    <p>Elke inschrijving, toegang en/of gebruik van het Platform impliceert de onvoorwaardelijke aanvaarding en naleving door de Gebruiker van alle AV.</p>
    <p> </p>
    <p>Elke minderjarige Gebruiker verklaart en erkent toestemming te hebben gekregen van zijn ouders of wettelijke vertegenwoordigers om zich op het Platform te registreren.</p>
    <p> </p>
    <p>Elk verzoek om registratie dat automatisch wordt gegenereerd door een robot, of op enige andere manier, zal worden geweigerd.</p>
    <p> </p>
    <h4>Presentatie van de applicatie</h4>
    <p> </p>
    <p>De Applicatie is voornamelijk gericht op boogschutters of trainers en stelt hen in staat om activiteiten met betrekking tot de sport te beheren en te organiseren, informatie te communiceren tussen groepsleden of over de actualiteit in het veld.</p>
    <p> </p>
    <p>Gebruikers hebben via de Applicatie onder andere de volgende functionaliteiten:</p>
    <p> </p>
    <p>Beheer van trainingssessies en wedstrijden</p>
    <p>Beheer van getelde schoten en wedstrijden</p>
    <p>Beheer van boogschietspullen</p>
    <p>Gebruik van gegevens voor het verstrekken van statistieken</p>
    <p>Sociale en speelse interacties, gegevens beschikbaar stellen aan de Coaches van de gebruiker</p>
    <p>Sociale en speelse interacties tussen groepsleden</p>
    <p>Aankoop van personalisatie-items voor de ervaring via de virtuele valuta, algemeen bekend als "Edelstenen".</p>
    <p>Aankoop van "Edelstenen" via het mobiele betalingssysteem (ANDROID of APPLE)</p>
    <p>Berichtenservice</p>
    <p> </p>
    <h4>Registratie van een Gebruiker</h4>
    <p> </p>
    <p>Elke Gebruiker heeft de mogelijkheid om een Account aan te maken (persoonlijk of voor een minderjarig lid wanneer de Gebruiker ouderlijke macht heeft) waarmee hij toegang krijgt tot de applicatie en de bijbehorende diensten.</p>
    <p> </p>
    <p>De registratie wordt door de Gebruiker op het Platform gedaan, via de applicatie. Hiervoor moet hij de verschillende velden van het registratieformulier invullen, waaronder een geldig e-mailadres, zijn naam en voornaam, evenals verschillende informatie over zijn boogschietpraktijk. Hij moet kennisnemen van en akkoord gaan met de AV en het Privacybeleid.</p>
    <p> </p>
    <p>Door het registratieformulier in te vullen, verbindt de Gebruiker zich ertoe alleen accurate, actuele en complete informatie te verstrekken.</p>
    <p> </p>
    <p>De Gebruiker verbindt zich ertoe de toegangscode van zijn Account vertrouwelijk te houden. In geval van frauduleus gebruik van de toegangscode zal de Gebruiker CapTarget Archery onmiddellijk op de hoogte stellen.</p>
    <p> </p>
    <p>De Gebruiker is als enige verantwoordelijk voor het gebruik van zijn Account. Elke verbinding of gegevensoverdracht via het Platform wordt geacht te zijn uitgevoerd door de Gebruiker en onder zijn exclusieve verantwoordelijkheid.</p>
    <p> </p>
    <p>CapTarget Archery is niet verantwoordelijk als de registratiegegevens van de Gebruiker om welke reden dan ook niet bij CapTarget Archery aankomen (bijvoorbeeld een internetverbindingsprobleem van de Gebruiker, tijdelijke uitval van zijn servers, enz.), of als de gegevens bij CapTarget Archery aankomen in een niet-verwerkbaar formaat (bijvoorbeeld als de Gebruiker beschikt over ongeschikte hardware of software).</p>
    <p> </p>
    <p>Elke inschrijving wordt voor onbepaalde tijd gedaan en het Account van de Gebruiker blijft actief totdat het wordt gesloten.</p>
    <p> </p>
    <p> </p>
    <h4>Registratie van een groep</h4>
    <p> </p>
    <p>Om toegang te krijgen tot de groepsfuncties moet een Gebruiker een Groep aanmaken of zich bij een Groep aansluiten.</p>
    <p> </p>
    <p>Door het registratieformulier in te vullen, verbindt de beheerder zich ertoe alleen accurate, actuele en complete informatie te verstrekken. Hij garandeert dat CapTarget Archery voorafgaande toestemming heeft gekregen van de Leden waarvan hij e-mailadressen en telefoonnummers doorgeeft, die te allen tijde vrij zijn om CapTarget Archery te verzoeken deze te verwijderen (zie ons Privacybeleid).</p>
    <p> </p>
    <p>Beheerders zijn als enige verantwoordelijk voor het gebruik van de Groep.</p>
    <p>Het wordt ook benadrukt dat de Beheerder volledig en uitsluitend verantwoordelijk is voor het gebruik van de Organisatieruimte door hemzelf en door welke derde dan ook.</p>
    <p> </p>
    <p>CapTarget Archery is niet verantwoordelijk als gegevens met betrekking tot de registratie van een Organisatie om welke reden dan ook niet bij CapTarget Archery aankomen (bijvoorbeeld een internetverbindingsprobleem van de Beheerder, tijdelijke uitval van zijn servers, etc.), of als de gegevens bij CapTarget Archery aankomen in een niet-verwerkbaar formaat (bijvoorbeeld als de Beheerder beschikt over ongeschikte hardware of software).</p>
    <p> </p>
    <p>Elke inschrijving wordt voor onbepaalde tijd gedaan en de Groep blijft actief totdat deze wordt gesloten door een van de beheerders.</p>
    <p> </p>
    <p> </p>
    <h4>Registratie van een coach</h4>
    <p>Om toegang te krijgen tot de coachfuncties moet een Gebruiker zich abonneren op een abonnement waarmee hij een licentie krijgt voor toegang tot de coachdiensten van CapTarget Archery via de website en/of de mobiele applicatie, afhankelijk van het gekozen aanbod.</p>
    <p>Een toegangslicentie kan door een derde aan hem zijn verstrekt die het abonnement heeft afgesloten. Een toegangslicentie is strikt exclusief voor de gebruiker die zich heeft ingeschreven met de betreffende licentie. Het account kan niet met anderen worden gedeeld.</p>
    <p>De voordelen van verschillende aanbiedingen kunnen worden gewijzigd. Als er een abonnement loopt, zullen deze wijzigingen niet van kracht zijn of moeten ze als gunstig voor de gebruiker worden beschouwd.</p>
    <p>De inschrijving wordt door de Gebruiker op de website gedaan. Hiervoor moet hij de verschillende velden van het registratieformulier invullen, waaronder een geldig e-mailadres, zijn naam en voornaam, evenals verschillende informatie over zijn boogschietpraktijk. Hij moet kennisnemen van en akkoord gaan met de AV, de Algemene Verkoopvoorwaarden en het Privacybeleid.</p>
    <p>Door het registratieformulier in te vullen, verbinden de coach en de consument die zich inschrijft voor de licentie zich ertoe alleen accurate, actuele en complete informatie te verstrekken.
        De gebruiker die zijn licentie exploiteert, is als enige verantwoordelijk voor het gebruik van zijn Account. Elke verbinding of gegevensoverdracht via het Platform wordt geacht te zijn uitgevoerd door de Gebruiker en onder zijn exclusieve verantwoordelijkheid.
    </p>
    <p>CapTarget Archery is niet verantwoordelijk als de registratiegegevens van de Gebruiker om welke reden dan ook niet bij CapTarget Archery aankomen (bijvoorbeeld een internetverbindingsprobleem van de Gebruiker, tijdelijke uitval van zijn servers, etc.), of als de gegevens bij CapTarget Archery aankomen in een niet-verwerkbaar formaat (bijvoorbeeld als de Gebruiker beschikt over ongeschikte hardware of software).</p>
    <p>Elke inschrijving voor een betaald abonnement wordt voor onbepaalde tijd gedaan, tenzij de gebruiker expliciet verzoekt om op te zeggen, zal het contract automatisch worden verlengd na de minimale contractperiode. We verwijzen u naar de Algemene Verkoopvoorwaarden voor informatie over de voorwaarden voor uitschrijving.</p>
    <p> </p>

    <h4>Delen van Gegevens</h4>
    <p>Om toegang te krijgen tot de gegevensdelingsfuncties via CapTarget Archery, biedt onze applicatie een fans en volgsysteem aan. Als gebruiker hebt u de mogelijkheid om uitnodigingen te accepteren of andere gebruikers uit te nodigen om lid te worden van uw netwerk.</p>
    <p>In geval van een openbaar profiel is geen goedkeuring vereist voor andere gebruikers om u te volgen. Zodra een gebruiker u volgt, kunnen bepaalde gegevens automatisch of handmatig worden gedeeld, waaronder:</p>
    <p>- Uw wedstrijdresultaten of trainingsessies.</p>
    <p>- Diverse sportstatistieken zoals de duur van uw licentie, uw beste resultaat of uw rangschikking.</p>
    <p>- Gegevens met betrekking tot uw gebruik van de applicatie, zoals uw favoriete games en oefeningen.</p>
    <p>- Informatie over uw club, taal, land, evenals andere gegevens zoals uw naam, voornaam, type boog gebruikt of schietcategorie.</p>
    <p>U hebt de mogelijkheid om ervoor te kiezen bepaalde van deze gegevens niet te delen door uw persoonlijke instellingen of instellingen voor elke situatie aan te passen.</p>
    <p>Vanaf het moment dat u een account aanmaakt, stemt u ermee in om zichtbaar te zijn op het CapTarget-platform. Andere gebruikers kunnen u zoeken op basis van uw naam en voornaam. Uw profiel kan ook automatisch worden voorgesteld aan andere gebruikers om sportieve uitdagingen of volgverbindingen te creëren.</p>
    <p>Houd er rekening mee dat u mogelijk wordt blootgesteld aan gedeelde informatie op CapTarget Archery door CapTarget, zijn partners, adverteerders of andere gebruikers. Wij raden u aan ons privacybeleid te raadplegen voor meer details over het beheer van uw persoonlijke gegevens.</p>
    <p>We willen benadrukken dat al deze clausules in overeenstemming zijn met de geldende wetten en voorschriften met betrekking tot de bescherming van persoonlijke gegevens en privacy. Als u zorgen of aanvullende vragen heeft, neem dan gerust contact met ons op.</p>

    <h4>Waarschuwing</h4>
    <p>Toegang tot de coachversies van de CapTarget Archery-platforms vereist het gebruik van computer-, tablet- of telefooncomputertools voor de webversie en telefoon of tablet ANDROID of IOS voor de mobiele versie. Een internetverbinding is vereist om de benodigde gegevens te downloaden. De gebruiker bevestigt op de hoogte te zijn van deze vereiste om toegang te krijgen tot de aangeboden diensten.</p>
    <p>De oefeningen en verschillende situaties die worden aangeboden in de diensten, gaan ervan uit dat gebruikers in goede fysieke conditie zijn en in staat zijn om ze uit te voeren. De Abonnee verbindt zich ertoe alle nodige voorzorgsmaatregelen te nemen om de activiteit te beoefenen met behoud van zijn gezondheid, veiligheid en die van andere personen.</p>

    <h4> </h4>

    <h4>Algemene verkoopvoorwaarden</h4>
    <p>Door een abonnement af te sluiten of een van de digitale services of digitale producten te kopen die beschikbaar zijn gesteld door CapTarget Archery, accepteert u de hieronder beschreven verkoopsvoorwaarden. Als u deze voorwaarden niet accepteert, koop dan niets op onze applicatie of website.</p>
    <h6>Abonnementen</h6>
    <p>Wanneer u inhoud van CapTarget koopt, verleent CapTarget u een beperkte, herroepbare, niet-exclusieve en niet-overdraagbare licentie om toegang te krijgen tot bepaalde betaalde functies op onze app of website. Onze applicatie biedt maandelijkse en jaarlijkse abonnementen aan om toegang te krijgen tot digitale services met automatische verlenging. Abonnementskosten worden automatisch afgetrokken van de betaalmethode die u hebt gekozen op de verjaardagsdatum van het abonnement. Abonnementen die zijn betaald met edelstenen (de virtuele valuta van CapTarget Archery) worden niet automatisch vernieuwd.</p>
    <p>Als u uw abonnement niet wilt verlengen, moet u het minstens 24 uur voor het einde van de abonnementsperiode uitschakelen via de instellingen van uw account.</p>
    <h6>Tarieven</h6>
    <p>Onze abonnementstarieven worden vermeld op onze applicatie en kunnen op elk moment zonder voorafgaande kennisgeving worden gewijzigd. Abonnementskosten zijn inclusief alle toepasselijke belastingen. U bent verantwoordelijk voor alle kosten in verband met het gebruik van onze applicatie, inclusief kosten voor internettoegang en mobiele gegevens. Ondanks al onze inspanningen kan een beperkt aantal artikelen in onze catalogus verkeerd worden geprijsd. Tarieven kunnen zelfs voor huidige abonnees veranderen. Afhankelijk van het gekozen systeem (Apple, Google, Stripe of andere) kunt u op de hoogte worden gesteld van tariefsverhogingen voor uw lopende abonnement en kunt u het abonnement annuleren als u dat wilt voordat het wordt vernieuwd.</p>
    <h6>Risico op verlies</h6>
    <p>Zodra de CapTarget-content aan u is verstrekt, draagt u het risico van verlies van de CapTarget-content na aankoop, inclusief elk verlies als gevolg van bestandsbeschadiging of storing van het apparaat of de harde schijf. Gekochte CapTarget-content blijft meestal beschikbaar op uw CapTarget-account, maar kan om verschillende redenen niet langer beschikbaar zijn. In dergelijke gevallen is CapTarget niet aansprakelijk jegens u voor latere onbeschikbaarheid van CapTarget-content en heeft u geen recht op gehele of gedeeltelijke terugbetaling van reeds betaalde kosten.</p>
    <h6>Levering</h6>
    <p>Toegang tot CapTarget-content wordt verstrekt bij aankoop van het product of abonnement. De inhoud is beschikbaar in uw CapTarget-account. Een herstart van de applicatie kan nodig zijn, evenals een redelijke wachttijd voordat de services beschikbaar worden. (Meestal enkele minuten maximaal)</p>
    <h6>Betaling</h6>
    <p>U moet een geldige betaalmethode hebben (zoals een creditcard of andere geautoriseerde betaalmethode) in uw CapTarget-account als u gebruik wilt maken van de betaalfuncties. De betaling voor uw abonnement wordt automatisch afgetrokken van uw account op basis van de betaalmethode die u hebt ingevoerd in uw Apple- of Google Play-account of op Stripe of een andere betaalmethode als u het abonnement hebt gekocht op onze website. U stemt ermee in om nauwkeurige en actuele betaalinformatie te verstrekken. Als om welke reden dan ook de betaling niet wordt uitgevoerd, behouden wij ons het recht voor om uw abonnement op te schorten of te annuleren.</p>
    <h6>Gebruik van de applicatie</h6>
    <p>U bent gemachtigd om onze applicatie voor persoonlijke en niet-commerciële doeleinden te gebruiken. U mag geen inhoud van onze applicatie wijzigen, distribueren, verkopen of overdragen zonder onze voorafgaande schriftelijke toestemming. U mag onze applicatie niet gebruiken op een manier die in strijd is met de toepasselijke wetgeving, die de rechten van derden schendt of deze schade berokkent.</p>
    <h6>Terugbetalingen</h6>
    <p>Onze abonnementen kunnen van tevoren worden getest voor een periode van 3 weken en zijn daarom niet restitueerbaar. Controleer de functies van elk abonnement voordat u zich op een van beiden abonneert. Digitale producten die te koop worden aangeboden, zijn expliciet en kunnen niet worden terugbetaald.</p>
    <h6>Beëindiging</h6>
    <p>Wij behouden ons het recht voor om uw toegang tot onze applicatie te beëindigen als u deze verkoop- of gebruiksvoorwaarden schendt, of als we redenen hebben om aan te nemen dat u onze applicatie op enige manier gebruikt. Door een abonnement op onze applicatie te kopen, accepteert u deze verkoopvoorwaarden.</p>

    <h6>Neem contact met ons op</h6>
    <p>Als u vragen of zorgen heeft, neem dan contact met ons op via het volgende adres: support@captarget-archery.com</p>
    <p>We zullen ons best doen om uw zorgen op een vriendelijke manier op te lossen, zodat beide partijen tevreden zijn.</p>
    <p></p>
    <p></p>
    <h4>Kosten voor het gebruik van het platform</h4>
    <p> </p>
    <p>Het aanmaken van een account en een groep is gratis voor een gebruiker. Niettemin blijven de kosten voor verbinding en communicatie voor het gebruik van het platform volledig voor rekening van de gebruiker.</p>
    <p>Toegang tot coachversies is tegen betaling, met uitzondering van testversies en bètaversies. De tarieven zijn beschikbaar vanaf de lancering van de versie op de website.</p>
    <h4>Aankopen via het platform</h4>
    <p> </p>
    <p>Het is mogelijk om met "echt" geld een beperkte, persoonlijke, niet-overdraagbare, niet-sub-licentieerbare en herroepbare licentie te kopen waarmee u gebruik kunt maken van (a) "virtueel geld" en met name virtueel geld of diamanten, die allemaal bedoeld zijn voor gebruik in de spellen van CapTarget Archery. Het is ook mogelijk om deze valuta te verkrijgen tijdens evenementen, wedstrijden, spellen, door het bekijken van advertenties of als beloning bij het bereiken van doelen op het platform, of uitgereikt door CapTarget Archery.</p>
    <p> </p>
    <p>Deze valuta kan worden gebruikt om verschillende producten die door de applicatie worden verkocht, aan te schaffen.</p>
    <p> </p>
    <p>De overdracht van virtuele artikelen is verboden, tenzij uitdrukkelijk toegestaan in de service. Behoudens wat uitdrukkelijk is toegestaan in de service, dient u zich te onthouden van het verkopen, kopen, converteren of op enige andere manier overdragen van virtuele artikelen ten gunste van een persoon, entiteit of een van de bovengenoemde acties te ondernemen, met name ten gunste van CapTarget Archery, een andere gebruiker of een derde partij.</p>
    <p> </p>
    <p> </p>
    <p>CapTarget Archery kan op elk moment, met of zonder voorafgaande kennisgeving, virtuele artikelen beheren, reguleren, controleren, wijzigen of verwijderen. CapTarget Archery kan niet aansprakelijk worden gesteld jegens u of een derde partij voor de uitoefening van deze rechten.</p>
    <p> </p>
    <p>Alle aankopen en conversies van virtuele artikelen van derden via de service zijn definitief en niet-restitueerbaar.</p>
    <p> </p>
    <p>U stemt ermee in om alle toepasselijke kosten en belastingen te betalen die door uzelf of door iemand anders die een account op uw naam gebruikt, verschuldigd zijn. CapTarget Archery kan de prijs van virtuele artikelen die via de service worden aangeboden, op elk moment wijzigen. U erkent dat CapTarget Archery niet verplicht is om om welke reden dan ook een terugbetaling te verstrekken, en dat u geen bedrag of andere vergoeding zult ontvangen voor ongebruikte virtuele artikelen bij de sluiting van een account, ongeacht of deze sluiting vrijwillig of onvrijwillig is.</p>
    <p> </p>
    <h4>Advertenties en partners</h4>
    <p> </p>
    <p>CapTarget Archery kan links naar andere advertentie- en/of partnerwebsites bieden op de verschillende aangeboden diensten.</p>
    <p> </p>
    <p>Aangezien CapTarget Archery deze niet kan controleren, kan CapTarget Archery geen enkele garantie geven over de beschikbaarheid van deze externe sites en bronnen en kan niet verantwoordelijk worden gehouden voor de inhoud, advertenties, producten, diensten of enig ander materiaal dat op deze sites beschikbaar is.</p>
    <p> </p>
    <p>Evenzo kan CapTarget Archery niet aansprakelijk worden gesteld voor enige schade of verlies die daadwerkelijk is geleden of beweerdelijk voortvloeit uit of in verband staat met het gebruik van de inhoud of diensten die beschikbaar zijn op deze advertentie- of partnerwebsites of externe bronnen.</p>
    <p> </p>
    <h4>Account sluiten</h4>
    <p> </p>
    <p>Een gebruiker kan op elk moment zijn account of het account dat hij namens een minderjarige beheert, sluiten. Overeenkomstig ons privacybeleid leidt het sluiten van een account tot het verwijderen van de daaraan gekoppelde persoonlijke gegevens.</p>
    <p> </p>
    <p>Onverminderd de schadevergoeding die CapTarget Archery zou kunnen eisen, behoudt CapTarget Archery zich het recht voor om het account van een gebruiker te sluiten, zonder voorafgaande kennisgeving of vergoeding, tijdelijk of definitief, in geval van</p>
    <p>niet-naleving van deze gebruiksvoorwaarden</p>
    <p>het verstrekken van valse informatie bij registratie;</p>
    <p>handelingen die in strijd zijn met de commerciële belangen van CapTarget Archery.</p>
    <p> </p>
    <p>Het sluiten van een account op initiatief van CapTarget Archery in geval van niet-naleving van deze gebruiksvoorwaarden of algemene voorwaarden geeft geen recht op compensatie of terugbetaling, inclusief in het geval van betaalde abonnementen. Alle bedragen die voor het abonnement zijn betaald, blijven eigendom van CapTarget Archery.</p>
    <p> </p>
    <p>Het sluiten van een toegang tot een coach kan worden uitgevoerd in overeenstemming met de toepasselijke algemene voorwaarden. Wij nodigen de gebruiker uit om hierin te verwijzen naar de sluiting van een betaalde coachtoegang. Een testversie wordt automatisch onderbroken aan het einde van de periode en kan worden heropend door een abonnement af te sluiten. De bètaversie kan naar goeddunken van CapTarget Archery worden verlengd. Voor het sluiten van een testversie of een bètaversie kunt u een e-mail sturen naar support@captarget-archery.com</p>
    <p> </p>
    <h4>Verwijdering van een groep</h4>
    <p> </p>
    <p>Een beheerder kan op elk moment een groep verwijderen. Het verwijderen van de ruimte van een organisatie leidt tot het verwijderen van alle gekoppelde gegevens en voordelen.</p>
    <p> </p>
    <p>Verwijdering van de ruimte van een organisatie op initiatief van CapTarget Archery</p>
    <p> </p>
    <p>Onverminderd de schadevergoeding die CapTarget Archery zou kunnen eisen, behoudt CapTarget Archery zich het recht voor om de ruimte van een organisatie, zonder voorafgaande kennisgeving of vergoeding, tijdelijk of definitief te verwijderen in geval van</p>
    <p> </p>
    <p>niet-naleving van de algemene voorwaarden;</p>
    <p>het verstrekken van valse informatie bij de registratie van de organisatie;</p>
    <p>handelingen die in strijd zijn met de commerciële belangen van CapTarget Archery.</p>
    <p> </p>
    <p>Het verwijderen van de ruimte van een organisatie op initiatief van CapTarget Archery geeft geen recht op compensatie of terugbetaling, inclusief in het geval van een betaald abonnement. Alle bedragen die voor het abonnement zijn betaald, blijven eigendom van CapTarget Archery, en het verwijderen van de ruimte van een organisatie ontslaat de organisatie/beheerder niet van de verplichting om het abonnement te betalen tot de vervaldatum.</p>
    <p> </p>
    <h4>Informatie en inhoud van de platformen</h4>
    <p> </p>
    <p>De gebruiker verbindt zich ertoe om oordeelkundig te handelen bij het gebruik van het platform, met name bij het beoordelen van de informatie en inhoud in een groep waarvan hij lid is.</p>
    <p> </p>
    <p>In dit verband erkent de gebruiker met name dat de informatie en inhoud die beschikbaar zijn op het platform niet volledig zijn en voornamelijk bedoeld zijn om de beheerder te helpen bij het beheer van de organisatie.</p>
    <p> </p>
    <p>De gebruiker verbiedt zich om op welke manier dan ook en met welk doel dan ook de inhoud die beschikbaar is op het platform geheel of gedeeltelijk te verkopen, door te verkopen of te exploiteren.</p>
    <p> </p>
    <p>De gebruiker is als enige verantwoordelijk voor de communicatie, informatie, bestanden, video's, foto's, documenten of andere inhoud die hij via het platform publiceert en verzendt.</p>
    <p> </p>
    <p>De communicatie, informatie, bestanden, video's, foto's, documenten of andere inhoud die door de gebruiker op het platform worden geplaatst, vertegenwoordigen de eigen standpunten en meningen van de gebruiker en in geen geval die van CapTarget Archery of zijn medewerkers en agenten.</p>
    <p> </p>
    <p>In het kader van het platform verbindt de gebruiker zich ertoe om alleen objectieve, volledige en waarheidsgetrouwe informatie te verstrekken, in overeenstemming met de regels van fatsoen, hoffelijkheid en respect voor anderen.</p>
    <p> </p>
    <p>CapTarget Archery moedigt de gebruiker aan om terughoudendheid en voorzichtigheid te betrachten bij het uiten van communicatie, informatie, bestanden, video's, foto's, documenten of andere inhoud die hij op het platform wil plaatsen. In dit verband verbiedt de gebruiker het plaatsen van inhoud op het platform die in strijd is met de geldende wet- en regelgeving, en met name:</p>
    <p> </p>
    <p>het verspreiden van informatie die in strijd is met de openbare orde of goede zeden;</p>
    <p>het afleiden van het doel van het platform voor propaganda of proselitisme, werving of verzoek; het plaatsen van informatie van commerciële of reclame-aard, of het voeren van propaganda voor tabak, alcohol of andere gereglementeerde stoffen, producten of diensten;</p>
    <p>het verspreiden van inhoud die inbreuk maakt op de persoonlijkheidsrechten van derden of lasterlijk, beledigend, obsceen, pornografisch, beledigend, gewelddadig is, of aanzet tot discriminatie, politiek geweld, racisme, xenofobie, seksisme of homofobie;</p>
    <p>het publiceren van informatie die in strijd is met de wetgeving inzake de bescherming van persoonsgegevens die de identificatie van natuurlijke personen mogelijk maakt zonder hun toestemming, zoals hun achternaam, postadres en/of e-mailadres, telefoonnummer, foto, geluidsopname of audiovisuele opname;</p>
    <p>inbreuk maken op de intellectuele eigendomsrechten van derden, met name door enige vermelding van auteursrecht of merk en elke beperkende vermelding te verwijderen of te wissen.</p>
    <p> </p>
    <p>De gebruiker verbindt zich er ook toe de rechten van anderen te respecteren, met name:</p>
    <p> </p>
    <p>de persoonlijkheidsrechten (zoals het recht op afbeelding, het recht op privacy);</p>
    <p>de merkrechten;</p>
    <p>het auteursrecht (met name op software, geluiden, afbeeldingen, foto's, teksten, bewegende beelden) en naburige rechten (uitvoerende kunstenaars, producenten van fonogrammen en videogrammen en sui generis rechten van producenten van databases);</p>
    <p>in het algemeen de rechten van personen en eigendommen.</p>
    <p> </p>

    <p>De Gebruiker verbiedt zich ook om, tenzij met voorafgaande schriftelijke toestemming van CapTarget Archery, zonder dat deze lijst uitputtend is:</p>
    <p> </p>
    <p>de Platform te gebruiken voor promotionele doeleinden en in het algemeen producten en diensten aan te bieden die hem direct of indirect vergoeden;</p>
    <p>archieffiles te maken van de inhoud van het Platform;</p>
    <p>reproductie, voorstelling, gebruik, referentie (met name in de meta-tags van zoekmachines), geheel of gedeeltelijk van de inhoud, merken, logo's, onderscheidende tekens op het Platform.</p>
    <p> </p>
    <p>Niet-naleving van deze AV kan leiden tot onmiddellijke en automatische verwijdering van het betreffende inhoud, een waarschuwing en/of de sluiting van het Account van de Gebruiker, onverminderd enige andere gerechtelijke stappen en/of schadevergoeding die CapTarget Archery gerechtigd zou zijn te vorderen of te eisen.</p>
    <p> </p>
    <p>CapTarget Archery is niet verantwoordelijk voor informatie, bestanden, video's, foto's, documenten of andere inhoud op het Platform, inclusief maar niet beperkt tot illegale inhoud, fouten of omissies, of enig verlies of schade als gevolg van het gebruik van dit materiaal door een Gebruiker.</p>
    <p> </p>
    <p>Elke hyperlink naar subpagina's van de Site is strikt verboden, tenzij uitdrukkelijke toestemming van CapTarget Archery is verkregen door de Gebruiker. Bovendien moet elke link op verzoek van CapTarget Archery door de Gebruiker worden verwijderd.</p>
    <p> </p>
    <h4>Moderatierol van de Beheerder</h4>
    <p> </p>
    <p>De Beheerder, als coördinator en toezichthouder van de Groep, verbindt zich ertoe de Leden de voorwaarden van de AV te laten naleven. Zodra hij ervan op de hoogte is, verbindt hij zich ertoe de inhoud te melden of te verwijderen (indien mogelijk) die in strijd zou zijn met de wet en/of de openbare orde en/of deze AV, met name elke inhoud die gewelddadig, pornografisch, racistisch, antisemitisch, xenofoob is, inbreuk maakt op de eerbiediging van de menselijke persoon of zijn waardigheid, inbreuk maakt op de privacy, inbreuk maakt op auteursrechten, naburige rechten, merkrechten, persoonlijkheidsrechten van derden.</p>
    <p> </p>
    <p>In geval van niet-naleving van deze regels en gebruiken behoudt CapTarget Archery zich het recht voor om de Beheerder en/of de schuldige Lid uit te sluiten en elke gerechtelijke actie te ondernemen tegen de auteurs die het schijnbaar onwettige verstoring van het onjuiste gebruik van het Platform kunnen beëindigen.</p>
    <p> </p>
    <h4>Verzending van e-mails, pushmeldingen en sms-berichten</h4>
    <p> </p>
    <p>In het kader van de aangeboden diensten biedt CapTarget Archery communicatie per e-mail, pushmeldingen op de Applicatie en sms-berichten aan om communicatie mogelijk te maken tussen Leden van een Organisatie, met als exclusief doel informatie uit te wisselen met betrekking tot de Organisatie, met behulp van de mobiele telefoonnummers en e-mailadressen verstrekt door de Leden bij het aanmaken van hun Account.</p>
    <p> </p>
    <p>De Gebruiker kan te allen tijde besluiten om geen e-mails, pushmeldingen of sms-berichten meer te ontvangen van CapTarget Archery door de instellingen van zijn apparaten aan te passen of door een verzoek in te dienen bij de klantenservice.</p>
    <p> </p>
    <h4>Toegang tot het Platform - Verantwoordelijkheid en verplichtingen</h4>
    <p> </p>
    <p>CapTarget Archery doet haar best om het Platform 24 uur per dag, 7 dagen per week beschikbaar te maken, met uitzondering van geplande onderhoudswerkzaamheden en onder voorbehoud van de bepalingen van dit artikel.</p>
    <p> </p>
    <p>CapTarget Archery behoudt zich het recht voor om naar eigen goeddunken wijzigingen en verbeteringen aan te brengen in het Platform op basis van technologische ontwikkelingen. Aldus behoudt CapTarget Archery zich het recht voor om zonder voorafgaande kennisgeving of compensatie tijdelijk de toegang tot het Platform op te schorten voor het uitvoeren van evolutieve of correctieve onderhoudswerkzaamheden. De Gebruiker vrijwaart CapTarget Archery van alle aansprakelijkheid dienaangaande en doet afstand van enige claim in dit verband.</p>
    <p> </p>
    <p>CapTarget Archery kan eisen dat u updates van de Service en spellen van CapTarget Archery installeert die op uw apparaat of computer zijn geïnstalleerd. U erkent en stemt ermee in dat CapTarget Archery de Service en de spellen van CapTarget Archery kan bijwerken, met of zonder kennisgeving aan u. Het kan nodig zijn dat u software van derden moet bijwerken om de Service te kunnen ontvangen en de spellen van CapTarget Archery te kunnen spelen.</p>
    <p> </p>
    <p> </p>
    <p>CapTarget Archery geeft geen enkele garantie, expliciet of impliciet, inclusief maar niet beperkt tot garanties met betrekking tot de kwaliteit en geschiktheid van het Platform voor een bepaald doel.</p>
    <p> </p>
    <p>CapTarget Archery garandeert niet dat de ononderbroken werking en/of continuïteit van het Platform zal worden gehandhaafd in geval van overmacht of toeval, zoals gedefinieerd in artikel 1218 van het Franse Burgerlijk Wetboek, in welk geval de aansprakelijkheid van CapTarget Archery niet zal worden ingeroepen.</p>
    <p> </p>
    <p>In het geval dat de aansprakelijkheid van CapTarget Archery zou worden ingeroepen door de Gebruiker vanwege een schending van een van zijn verplichtingen onder deze AV, zal de vergoeding beperkt zijn tot directe, persoonlijke, zekere en voorzienbare schade.</p>
    <p> </p>
    <p>CapTarget Archery garandeert niet de relevantie en nauwkeurigheid van de informatie die door derden op het Platform is gepubliceerd.</p>
    <p> </p>
    <p>CapTarget Archery is niet verantwoordelijk voor geschillen, acties of claims van derden die mogelijk rechten, met name eigendomsrechten, hebben op inhoud die niet door CapTarget Archery is gemaakt.</p>
    <p> </p>
    <p>De voorwaarden waarin CapTarget Archery gegevens verzamelt en verwerkt, worden beschreven in het gegevensprivacybeleid.</p>
    <p> </p>
    <p>CapTarget Archery zal niet aansprakelijk zijn jegens u voor enige indirecte, incidentele, gevolg- of soortgelijke schade en met name niet voor enig verlies van inkomsten, winstderving, gegevensverlies of verlies van exploitatie of ander niet-materieel verlies (ongeacht de kwalificatie die aan dergelijke verliezen wordt gegeven), die op enigerlei wijze verband houden met deze servicevoorwaarden of de service zelf, en zal niet verplicht zijn tot het betalen van speciale of straffende schadevergoeding, hetzij op contractuele, onrechtmatige daad of andere basis, en of CapTarget Archery al dan niet op de hoogte is gesteld van de mogelijkheid van dergelijke schade. De aansprakelijkheid van CapTarget Archery jegens u zal niet meer bedragen dan het bedrag dat u overeenkomstig deze servicevoorwaarden aan CapTarget Archery hebt betaald gedurende de zes (6) maanden onmiddellijk voorafgaand aan de datum waarop u voor het eerst een claim indient. U erkent en stemt ermee in dat wanneer u gedurende een dergelijke periode niets aan CapTarget Archery hebt betaald, uw enige remedie (en de exclusieve aansprakelijkheid van CapTarget Archery) met betrekking tot elk geschil met CapTarget Archery zal zijn om het gebruik van de service te staken en uw account te sluiten.</p>
    <p>In het bijzonder zal geen van de bepalingen van deze servicevoorwaarden afbreuk doen aan de wettelijke rechten van elke consument of enige aansprakelijkheid uitsluiten of beperken in geval van overlijden of lichamelijk letsel als gevolg van enige nalatigheid of fraude van CapTarget Archery.</p>
    <p> </p>

    <p>U stemt ermee in om CapTarget Archery (evenals zijn ontwikkelaars, beheerders en contentmakers) te vrijwaren, verdedigen en ontslaan van aansprakelijkheid voor claims, verzoeken, schade of andere verliezen, inclusief redelijke advocaatkosten, die door een derde partij worden ingediend en die voortvloeien uit uw gebruik van de Dienst of enige schending van deze Servicevoorwaarden door uzelf. Dit is echter niet van toepassing in geval van inbreuk op rechten die niet aan uw opzettelijk of nalatig gedrag kunnen worden toegeschreven.</p>
    <p> </p>
    <p>CapTarget Archery kan niet aansprakelijk worden gesteld voor enige vertraging of niet-uitvoering als gevolg van legitiem buiten zijn controle vallende oorzaken, in het bijzonder elke niet-uitvoering van deze Voorwaarden als gevolg van onvoorziene omstandigheden of een oorzaak die buiten de controle van CapTarget Archery valt, zoals natuurrampen, oorlog, terrorisme, rellen, embargo's, maatregelen van burgerlijke of militaire autoriteiten, brand, overstromingen, ongevallen, stakingen of tekorten aan vervoermiddelen, brandstof, energie, arbeidskrachten of materialen.</p>
    <p> </p>
    <p>De adviezen over activiteiten zoals beschreven in de app zijn indicatief; het is de verantwoordelijkheid van de Gebruiker om de adviezen aan te passen aan zijn gezondheid of advies te vragen aan een arts. De verantwoordelijkheid voor sportieve activiteiten ligt bij de Gebruiker; CapTarget Archery is op geen enkele wijze aansprakelijk voor fysieke of mentale verwondingen als gevolg van het beoefenen van een activiteit met behulp van de app.</p>
    <p> </p>
    <p>De Gebruiker verbindt zich ertoe eventuele beveiligingslekken te melden en deze niet te gebruiken voor eigen voordeel of dat van een andere persoon / entiteit. De Gebruiker verbindt zich ertoe geen acties te ondernemen die de goede werking van de diensten die door CapTarget Archery worden aangeboden, kunnen schaden, en niet te profiteren van eventuele bugs.</p>
    <p> </p>
    <h4>Intellectueel eigendom</h4>
    <p> </p>
    <p>De diensten en hun inhoud (inclusief gegevens, databases, software, foto's, informatie, illustraties, logo's, merken, etc.) die verschijnen of beschikbaar zijn op het Platform, zijn beschermd door alle bestaande intellectuele eigendomsrechten, rechten van producenten van databases en/of andere rechten die door de huidige wetgeving worden erkend.</p>
    <p> </p>
    <p>Elke ongeoorloofde, gehele of gedeeltelijke kopie, reproductie, voorstelling, aanpassing, wijziging of verspreiding van de diensten en/of inhoud van het Platform, of deze inhoud nu toebehoort aan CapTarget Archery, een Gebruiker, een Organisatie of een derde partij, is onwettig en kan de strafrechtelijke en civiele aansprakelijkheid van de auteur ervan inhouden.</p>
    <p> </p>
    <p>CapTarget Archery verleent aan de Gebruiker het recht om het Platform voor zijn eigen behoeften te gebruiken, met uitzondering van elk gebruik voor winst.</p>
    <p> </p>
    <p>Wanneer de Gebruiker inhoud op het Platform plaatst, verleent hij automatisch aan CapTarget Archery een persoonlijke, niet-exclusieve licentie om deze inhoud gratis te gebruiken.</p>
    <p> </p>
    <p>Deze licentie wordt verleend voor de hele wereld, voor alle gebruik op alle bekende of onbekende dragers en via alle bekende of onbekende distributiemethoden tot op heden, en gedurende de gehele duur van de wettelijke bescherming van intellectuele eigendomsrechten (inclusief vernieuwingen en verlengingen):</p>
    <p> </p>
    <p>het recht op reproductie: het recht om een onbeperkt aantal kopieën van de inhoud te maken of te laten maken, in elke vorm of op elk medium, bekend of onbekend tot op heden, met name op elk papier, magnetisch, digitaal of ander medium (bijvoorbeeld alle brochures, persdocumenten, affiches, webpagina's, rapporten en elk opname-medium zoals cd's, dvd's, diskettes, interne of externe harde schijven of elk ander opname-medium), via elke bekende of onbekende technische methode tot op heden;</p>
    <p>het recht op vertoning: het recht om inhoud te communiceren, te verspreiden, te laten communiceren of te laten verspreiden naar de leden van de Organisatie van de Gebruiker via elk middel, bekend of onbekend tot op heden, met name via elk telecommunicatienetwerk of enig lees-, informatie-uitwisselings- en communicatiemiddel (internet, grondnetwerk, kabel, satelliet, downloaden, teletransmissie, enzovoort);</p>
    <p>het recht om de inhoud in elke taal te vertalen (inclusief alle computertalen).</p>
    <p>De Gebruiker garandeert aan CapTarget Archery dat de inhoud die op het Platform wordt geplaatst, geen inbreuk maakt op enig intellectueel eigendomsrecht of enig ander recht van derden.</p>
    <p> </p>
    <p>Als gevolg daarvan stemt de Gebruiker ermee in om CapTarget Archery te verdedigen en de verantwoordelijkheid op zich te nemen en/of CapTarget Archery te vergoeden voor enige schade die kan voortvloeien uit enige actie of claim van een derde partij met betrekking tot de inhoud die door de gebruiker is geplaatst.</p>
    <p> </p>
    <h4>Contractdocumenten</h4>
    <p> </p>
    <p>Deze Gebruiksvoorwaarden regelen alle contractuele of precontractuele commerciële relaties tussen CapTarget Archery, de Gebruiker en/of de Organisatie met betrekking tot het gebruik van het Platform en het aangaan van de Abonnementen die door CapTarget Archery worden aangeboden.</p>
    <p> </p>
    <p>Deze Algemene Voorwaarden hebben voorrang boven enige tegengestelde overeenkomst of bepaling tussen de partijen. Ze annuleren en vervangen alle vorige Algemene Verkoopvoorwaarden die de relaties tussen de partijen hebben kunnen regeren.</p>
    <p> </p>
    <p>Deze Algemene Voorwaarden vormen de volledige contractuele documenten tussen de partijen.</p>
    <p> </p>
    <p>CapTarget Archery behoudt zich het recht voor om op elk moment de inhoud van de Algemene Voorwaarden te wijzigen. Deze wijzigingen leiden tot een nieuwe versie die automatisch van toepassing zal zijn op alle daaropvolgende geleverde diensten.</p>
    <p> </p>
    <h4>Toepasselijk recht, Bemiddeling en Rechtsgebied</h4>
    <p> </p>
    <p>De Algemene Voorwaarden zijn onderworpen aan het Franse recht.</p>
    <p> </p>
    <p>De partijen zijn overeengekomen om een minnelijke oplossing te zoeken voordat zij juridische stappen ondernemen. In geval van een geschil dat niet in der minne kan worden opgelost tussen de partijen, kan de Gebruiker gratis gebruik maken van consumentenbemiddeling. Het wordt aanbevolen om de volgende bemiddelaar te gebruiken: Centre de Médiation et d'Arbitrage de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p> </p>
    <p>De Gebruiker kan ook het online platform voor geschillenbeslechting gebruiken dat door de Europese Commissie is gelanceerd op het volgende adres: www.ec.europa.eu/consumers/odr/.</p>
</div>
