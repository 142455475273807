<div class="container">
    <h4>Definitionen</h4>
    <p> </p>
    <p>Die folgenden Begriffe, bei denen der erste Buchstabe in den AGB groß geschrieben ist, haben die Bedeutung, die ihnen in diesem Artikel zugewiesen wird.</p>
    <p> </p>
    <p>Abonnement: Bezeichnet jedes kostenlose oder kostenpflichtige Abonnement für die Dienste der Plattform.</p>
    <p> </p>
    <p>Bogenschütze: Ein Nutzer, der Bogenschießen betreibt und die Dienste der Anwendung nutzt oder nicht nutzt.</p>
    <p> </p>
    <p>Coach: Ein Bogenschießtrainer, der ein kostenloses oder kostenpflichtiges "Coach"-Abonnement abonniert hat und dadurch auf die zugehörigen Dienste zugreifen kann.</p>
    <p> </p>
    <p>Anwendung: Bezeichnet die mobile Anwendung "CapTarget Archery", die für Android und iOS verfügbar ist.</p>
    <p> </p>
    <p>Website: Bezeichnet die Informationswebsite von CapTarget Archery.</p>
    <p> </p>
    <p>Coach-Website: Bezeichnet die Website mit exklusivem Zugang für Trainer.</p>
    <p> </p>
    <p>Nutzungsbedingungen: Bezeichnet die vorliegenden allgemeinen Nutzungsbedingungen der Plattform.</p>
    <p> </p>
    <p>Konto: Bezeichnet das einem Nutzer zugeordnete Konto, das Zugang zur Plattform und verschiedenen Diensten ermöglicht und dem eine persönliche Kennung und ein Zugangscode zugeordnet sind.</p>
    <p> </p>
    <p>Gruppe: Bezeichnet eine auf der Anwendung CapTarget Archery erstellte Gruppe, die Nutzer und Trainer zusammenführt.</p>
    <p> </p>
    <p>Partner: Bezeichnet eine natürliche oder juristische Person, die eine Partnerschaft mit CapTarget Archery eingegangen ist und über die Plattform den Nutzern vorgestellt wird.</p>
    <p> </p>
    <p>Plattform: Bezeichnet alle auf den Websites und/oder der Anwendung zugänglichen Dienste.</p>
    <p> </p>
    <p>Nutzer: Bezeichnet jede natürliche oder juristische Person, die Zugang zur Plattform hat und diese nutzt, ob über ein Konto oder anderweitig.</p>
    <p> </p>
    <p>Edelsteine: Bezeichnet eine virtuelle Währung, die auf CapTarget Archery verwendet wird.</p>
    <p> </p>
    <h4>Zweck</h4>
    <p> </p>
    <p>Diese AGB sollen die Bedingungen und Konditionen festlegen, die für alle Nutzer der Plattform gelten.</p>
    <p> </p>
    <p>Jede Anmeldung, Zugriff und/oder Nutzung der Plattform impliziert die bedingungslose Akzeptanz und Einhaltung der gesamten AGB durch den Nutzer.</p>
    <p> </p>
    <p>Jeder minderjährige Nutzer erklärt und erkennt an, die Zustimmung seiner Eltern oder seines/ihrer Sorgeberechtigten für die Anmeldung auf der Plattform erhalten zu haben.</p>
    <p> </p>
    <p>Jede automatisch von einem Roboter oder auf andere Weise generierte Anmeldeanfrage wird abgelehnt.</p>
    <p> </p>
    <h4>Vorstellung der Anwendung</h4>
    <p> </p>
    <p>Die Anwendung ist hauptsächlich für Bogensportler oder Trainer konzipiert und ermöglicht insbesondere das Verwalten und Organisieren von Aktivitäten im Zusammenhang mit dem Sport, die Kommunikation von Informationen zwischen Gruppenmitgliedern oder Informationen aus dem Bereich.</p>
    <p> </p>
    <p>Durch die Anwendung erhalten Nutzer unter anderem folgende Funktionen:</p>
    <p> </p>
    <p>Verwaltung von Trainings- und Wettkampfsitzungen</p>
    <p>Verwaltung von Treffern und Matches</p>
    <p>Verwaltung des Bogensportmaterials</p>
    <p>Verwendung von Daten zur Bereitstellung von Statistiken</p>
    <p>Soziale und unterhaltsame Interaktionen, Bereitstellung von Daten an Nutzer-Coaches</p>
    <p>Soziale und unterhaltsame Interaktionen zwischen Gruppenmitgliedern</p>
    <p>Kauf von Personalisierungsgegenständen für die Erfahrung mit der virtuellen Währung, die gemeinhin als "Edelstein" bezeichnet wird.</p>
    <p>Kauf von "Edelsteinen" über das mobile Zahlungssystem (ANDROID oder APPLE)</p>
    <p>Nachrichten</p>
    <p> </p>
    <h4>Registrierung eines Nutzers</h4>
    <p> </p>
    <p>Jeder Nutzer hat die Möglichkeit, ein Konto (persönlich oder im Namen eines minderjährigen Mitglieds, wenn der Nutzer sorgeberechtigt ist) zu erstellen, um Zugang zur Anwendung und ihren Diensten zu erhalten.</p>
    <p> </p>
    <p>Die Anmeldung erfolgt auf der Plattform durch den Nutzer über die Anwendung. Hierfür muss er die verschiedenen Felder des Anmeldeformulars ausfüllen, insbesondere eine gültige E-Mail-Adresse, seinen Namen und Vornamen sowie verschiedene Informationen über seine Bogensportpraxis. Er muss die AGB und die Datenschutzrichtlinie zur Kenntnis nehmen und akzeptieren.</p>
    <p> </p>
    <p>Durch das Ausfüllen des Anmeldeformulars verpflichtet sich der Nutzer, nur genaue, aktuelle und vollständige Informationen bereitzustellen.</p>
    <p> </p>
    <p>Der Nutzer verpflichtet sich, den Zugangscode seines Kontos vertraulich zu behandeln. Im Falle eines betrügerischen Gebrauchs des Zugangscodes wird der Nutzer CapTarget Archery unverzüglich informieren.</p>
    <p> </p>
    <p>Der Nutzer ist allein verantwortlich für die Nutzung seines Kontos. Jede Verbindung oder Datenübertragung über die Plattform gilt als vom Nutzer durchgeführt und unterliegt seiner alleinigen Verantwortung.</p>
    <p> </p>
    <p>CapTarget Archery haftet nicht, wenn die Anmeldedaten des Nutzers aus Gründen, die nicht auf CapTarget Archery zurückzuführen sind (z. B. ein Internetverbindungsproblem des Nutzers, vorübergehende Ausfälle seiner Server usw.), nicht bei CapTarget Archery eingehen, oder wenn die Daten in einer nicht verarbeitbaren Form bei CapTarget Archery eintreffen (z. B. wenn der Nutzer über ungeeignete Hardware oder Software verfügt).</p>
    <p> </p>
    <p>Jede Anmeldung erfolgt auf unbestimmte Zeit, und das Konto des Nutzers bleibt aktiv, bis es geschlossen wird.</p>
    <p> </p>
    <p> </p>
    <h4>Registrierung einer Gruppe</h4>
    <p> </p>
    <p>Um auf Gruppenfunktionen zugreifen zu können, muss ein Nutzer eine Gruppe erstellen oder ihr beitreten.</p>
    <p> </p>
    <p>Durch das Ausfüllen des Anmeldeformulars verpflichtet sich der Administrator, nur genaue, aktuelle und vollständige Informationen bereitzustellen. Er garantiert, dass CapTarget Archery die vorherige Zustimmung der Mitglieder erhalten hat, deren E-Mail-Adressen und Telefonnummern er kommuniziert, und die jederzeit CapTarget Archery bitten können, sie zu löschen (siehe unsere Datenschutzrichtlinie).</p>
    <p> </p>
    <p>Die Administratoren sind allein verantwortlich für die Nutzung der Gruppe.</p>
    <p>Es wird auch darauf hingewiesen, dass der Administrator allein verantwortlich ist für die Nutzung des Organisationsbereichs, sei es von ihm selbst oder von Dritten.</p>
    <p> </p>
    <p>CapTarget Archery haftet nicht, wenn Daten im Zusammenhang mit der Anmeldung einer Organisation aus Gründen, die nicht auf CapTarget Archery zurückzuführen sind (z. B. ein Internetverbindungsproblem aufgrund einer Handlung des Administrators, vorübergehende Ausfälle seiner Server usw.), nicht bei CapTarget Archery eingehen, oder wenn die Daten in einer nicht verarbeitbaren Form bei CapTarget Archery eintreffen (z. B. wenn der Administrator über ungeeignete Hardware oder Software zur Anmeldung verfügt).</p>
    <p> </p>
    <p>Jede Anmeldung erfolgt auf unbestimmte Zeit, und die Gruppe bleibt aktiv, bis sie von einem der Administratoren geschlossen wird.</p>
    <p> </p>
    <p> </p>
    <h4>Registrierung eines Trainers</h4>
    <p>Um auf die Funktionen für Trainer zugreifen zu können, muss ein Nutzer ein Abonnement abschließen, das ihm den Zugang zu einer Lizenz für Coach-Dienste von CapTarget Archery über die Website und/oder die mobile Anwendung gemäß dem abonnierten Angebot ermöglicht.</p>
    <p>Eine Zugangslizenz kann ihm von einem Dritten zur Verfügung gestellt werden, der das Abonnement stellvertretend abonniert hat. Eine Zugangslizenz ist streng auf den Nutzer beschränkt, der sich mit der betreffenden Lizenz angemeldet hat. Das Konto darf nicht mit anderen Personen geteilt werden.</p>
    <p>Die Vorteile der verschiedenen Angebote können sich ändern. Wenn ein Abonnement aktiv ist, werden diese Änderungen nicht wirksam oder sollten zum Vorteil des Nutzers betrachtet werden.</p>
    <p>Die Anmeldung erfolgt auf der Website durch den Nutzer. Hierfür muss er die verschiedenen Felder des Anmeldeformulars ausfüllen, insbesondere eine gültige E-Mail-Adresse, seinen Namen und Vornamen sowie verschiedene Informationen über seine Bogensportpraxis. Er muss die AGB, die Verkaufsbedingungen und die Datenschutzrichtlinie zur Kenntnis nehmen und akzeptieren.</p>
    <p> </p>
    <p>Durch das Ausfüllen des Anmeldeformulars verpflichtet sich der Coach und der Nutzer, der die Lizenz abonniert, nur genaue, aktuelle und vollständige Informationen bereitzustellen.
        Der Nutzer, der seine Lizenz nutzt, ist allein verantwortlich für die Nutzung seines Kontos. Jede Verbindung oder Datenübertragung über die Plattform gilt als vom Nutzer durchgeführt und unterliegt seiner alleinigen Verantwortung.
    </p>
    <p>CapTarget Archery haftet nicht, wenn die Anmeldedaten des Nutzers aus Gründen, die nicht auf CapTarget Archery zurückzuführen sind (z. B. ein Internetverbindungsproblem des Nutzers, vorübergehende Ausfälle seiner Server usw.), nicht bei CapTarget Archery eingehen, oder wenn die Daten in einer nicht verarbeitbaren Form bei CapTarget Archery eintreffen (z. B. wenn der Nutzer über ungeeignete Hardware oder Software verfügt).</p>
    <p>Das Abonnement für kostenpflichtige Dienste wird auf unbestimmte Zeit abgeschlossen. Falls der Nutzer keine ausdrückliche Kündigung beantragt, wird der Vertrag nach Ablauf der Mindestvertragslaufzeit automatisch verlängert. Wir empfehlen Ihnen, die Verkaufsbedingungen für Informationen zur Abmeldung zu konsultieren.</p>
    <p> </p>

    <h4>Datenfreigabe</h4>
    <p>Um auf die Datenfreigabefunktionen von CapTarget Archery zugreifen zu können, bietet unsere Anwendung ein Fansystem und Tracking an. Als Benutzer haben Sie die Möglichkeit, Einladungen anzunehmen oder andere Benutzer einzuladen, Ihrem Netzwerk beizutreten.</p>
    <p>Bei einem öffentlichen Profil ist keine Zustimmung erforderlich, damit andere Benutzer Ihnen folgen können. Sobald ein Benutzer Ihnen folgt, können einige Ihrer Daten automatisch oder manuell geteilt werden, einschließlich:</p>
    <p>- Die Ergebnisse Ihrer Wettkämpfe oder Trainingseinheiten.</p>
    <p>- Verschiedene Sportstatistiken wie die Dauer Ihrer Lizenz, Ihr bestes Ergebnis oder Ihre Platzierung.</p>
    <p>- Daten im Zusammenhang mit Ihrer Anwendungsnutzung, wie Ihre bevorzugten Spiele und Übungen.</p>
    <p>- Informationen über Ihren Verein, Ihre Sprache, Ihr Land sowie andere Daten wie Ihr Vorname, Nachname, verwendeter Bogen oder Schießkategorie.</p>
    <p>Sie haben die Möglichkeit, bestimmte dieser Daten nicht zu teilen, indem Sie Ihre persönlichen Einstellungen oder die Einstellungen für jede Situation ändern.</p>
    <p>Ab dem Zeitpunkt der Kontoerstellung stimmen Sie zu, auf der CapTarget-Plattform sichtbar zu sein. Andere Benutzer können Sie mithilfe Ihres Vor- und Nachnamens suchen. Ihr Profil kann auch automatisch anderen Benutzern vorgeschlagen werden, um sportliche Herausforderungen oder Verbindungen zu erstellen.</p>
    <p>Bitte beachten Sie, dass Sie möglicherweise Informationen sehen, die von CapTarget, seinen Partnern, Werbetreibenden oder anderen Benutzern auf CapTarget Archery geteilt wurden. Wir empfehlen Ihnen, unsere Datenschutzrichtlinie für weitere Details zur Verwaltung Ihrer persönlichen Daten zu konsultieren.</p>
    <p>Wir möchten darauf hinweisen, dass alle diese Klauseln im Einklang mit geltenden Gesetzen und Vorschriften zum Schutz personenbezogener Daten und der Privatsphäre stehen. Wenn Sie Bedenken oder weitere Fragen haben, zögern Sie nicht, uns zu kontaktieren.</p>
    <p> </p>
    <p> </p>

    <p> </p>
    <h4>Warnung</h4>
    <p>Der Zugriff auf die Trainer-Versionen der CapTarget Archery-Plattformen erfordert die Verwendung von Computertools wie Computer, Tablet oder Telefon für die Webversion und Telefon oder Tablet mit ANDROID oder IOS für die mobile Version. Eine Internetverbindung ist erforderlich, um die benötigten Daten herunterzuladen. Der Benutzer bestätigt, diese Notwendigkeit zur Nutzung der verkauften Dienstleistungen zu kennen.</p>
    <p>Die in den Diensten angebotenen Übungen und verschiedenen Situationen setzen voraus, dass die Benutzer sich in guter körperlicher Verfassung befinden und in der Lage sind, sie durchzuführen. Der Abonnent verpflichtet sich, alle notwendigen Vorsichtsmaßnahmen zu treffen, um die Aktivität unter Wahrung seiner Gesundheit, Sicherheit und der Gesundheit anderer Personen auszuüben.</p>
    <p> </p>
    <p> </p>

    <p></p>
    <p></p>

    <h4>Allgemeine Geschäftsbedingungen</h4>
    <p>Durch den Abschluss eines Abonnements oder den Kauf einer der von CapTarget Archery zur Verfügung gestellten digitalen Dienstleistungen oder digitalen Produkte akzeptieren Sie die nachstehend beschriebenen Verkaufsbedingungen. Wenn Sie diese Bedingungen nicht akzeptieren, kaufen Sie bitte nichts auf unserer Anwendung oder unserer Website.</p>
    <h6>Abonnements</h6>
    <p>Beim Kauf von Inhalten von CapTarget gewährt Ihnen CapTarget eine begrenzte, widerrufliche, nicht exklusive und nicht übertragbare Lizenz, um auf bestimmte kostenpflichtige Funktionen auf unserer App oder unserer Website zuzugreifen. Unsere Anwendung bietet monatliche und jährliche Abonnements an, um auf kostenpflichtige digitale Dienstleistungen mit automatischer Verlängerung zuzugreifen. Die Abonnementgebühren werden automatisch von der von Ihnen gewählten Zahlungsmethode am Jahrestag des Abonnements abgebucht. Mit Edelsteinen bezahlte Abonnements (die virtuelle Währung von CapTarget Archery) werden nicht automatisch verlängert.</p>
    <p>Wenn Sie Ihr Abonnement nicht verlängern möchten, müssen Sie es mindestens 24 Stunden vor Ablauf des Abonnementzeitraums in den Kontoeinstellungen deaktivieren.</p>
    <h6>Preise</h6>
    <p>Unsere Abonnementpreise sind auf unserer Anwendung veröffentlicht und können jederzeit ohne Vorankündigung geändert werden. Die Abonnementpreise enthalten alle anfallenden Steuern. Sie sind für alle Gebühren im Zusammenhang mit der Nutzung unserer Anwendung verantwortlich, einschließlich Internet- und Mobilfunkdatengebühren. Trotz aller Bemühungen können einige Artikel unseres Katalogs falsch bewertet werden. Die Preise können sich auch für bestehende Abonnenten ändern. Je nach gewähltem System (Apple, Google, Stripe oder andere) können Sie im Falle einer Preiserhöhung für Ihr aktuelles Abonnement benachrichtigt werden und das Abonnement vor der Verlängerung stornieren.</p>
    <h6>Verlustrisiko</h6>
    <p>Nachdem der CapTarget-Inhalt zur Verfügung gestellt wurde, tragen Sie das Risiko eines Verlusts des CapTarget-Inhalts nach dem Kauf, einschließlich Verlust aufgrund von Dateibeschädigung oder Geräte- oder Festplattenausfall. Der gekaufte CapTarget-Inhalt bleibt in der Regel in Ihrem CapTarget-Konto verfügbar, kann jedoch aus verschiedenen Gründen nicht mehr verfügbar sein. In solchen Fällen haftet CapTarget nicht für die spätere Nichtverfügbarkeit des CapTarget-Inhalts, und Sie haben kein Recht auf eine teilweise oder vollständige Rückerstattung der bereits gezahlten Gebühren.</p>
    <h6>Lieferung</h6>
    <p>Der Zugriff auf den CapTarget-Inhalt erfolgt beim Kauf des Produkts oder des Abonnements. Der Inhalt ist in Ihrem CapTarget-Konto verfügbar. Ein Neustart der Anwendung kann erforderlich sein, sowie eine angemessene Wartezeit, bis die Dienste verfügbar sind. (Normalerweise innerhalb von wenigen Minuten)</p>
    <h6>Zahlung</h6>
    <p>Sie müssen über eine gültige Zahlungsmethode (wie eine Kreditkarte oder eine andere autorisierte Zahlungsmethode) in Ihrem CapTarget-Konto verfügen, um Zahlungsfunktionen nutzen zu können. Die Zahlung Ihres Abonnements wird automatisch von Ihrem Konto abgebucht, basierend auf der in Ihrem Apple- oder Google-Play-Konto oder auf Stripe oder einer anderen Methode eingegebenen Zahlungsmethode, wenn Sie das Abonnement auf unserer Website erworben haben. Sie stimmen zu, genaue und aktuelle Zahlungsinformationen bereitzustellen. Wenn aus irgendeinem Grund keine Zahlung erfolgt, behalten wir uns das Recht vor, Ihr Abonnement zu sperren oder zu kündigen.</p>
    <h6>Anwendungsnutzung</h6>
    <p>Sie dürfen unsere Anwendung nur für persönliche und nicht kommerzielle Zwecke verwenden. Sie dürfen Inhalte unserer Anwendung nicht ändern, verteilen, verkaufen oder übertragen, ohne unsere vorherige schriftliche Genehmigung. Sie dürfen unsere Anwendung nicht in einer Weise verwenden, die gegen geltendes Recht verstößt, die Rechte Dritter verletzt oder ihnen schadet.</p>
    <h6>Rückerstattungen</h6>
    <p>Unsere Abonnements können vorab für einen Zeitraum von 3 Wochen getestet werden und sind daher nicht erstattungsfähig. Bitte überprüfen Sie die Funktionen jedes Abonnements, bevor Sie sich für eines entscheiden. Digitale Produkte, die zum Verkauf angeboten werden, sind ausdrücklich und nicht erstattungsfähig.</p>
    <h6>Kündigung</h6>
    <p>Wir behalten uns das Recht vor, Ihren Zugang zu unserer Anwendung zu kündigen, wenn Sie gegen diese Verkaufs- oder Nutzungsbedingungen verstoßen oder wenn wir Grund zur Annahme haben, dass Sie unsere Anwendung in irgendeiner Weise nutzen. Durch den Kauf eines Abonnements für unsere Anwendung akzeptieren Sie diese Verkaufsbedingungen.</p>
    <h6>Kontaktieren Sie uns</h6>
    <p>Wenn Sie Fragen oder Bedenken haben, kontaktieren Sie uns bitte unter der Adresse: support@captarget-archery.com</p>
    <p>Wir werden unser Bestes tun, um Ihre Anliegen auf faire Weise zu lösen und sicherzustellen, dass alle Parteien zufrieden sind.</p>
    <p></p>
    <p></p>
    <h4>Nutzungskosten der Plattform</h4>
    <p></p>
    <p>Die Erstellung eines Kontos und einer Gruppe ist für einen Benutzer kostenlos. Die mit der Nutzung der Plattform verbundenen Verbindungs- und Kommunikationskosten liegen jedoch ausschließlich beim Benutzer.</p>
    <p>Der Zugang zu Coach-Versionen ist kostenpflichtig, mit Ausnahme von Testversionen und Beta-Versionen. Die Preise werden ab Veröffentlichung der Version auf der Website verfügbar sein.</p>
    <h4>Käufe über die Plattformen</h4>
    <p></p>
    <p>Es ist möglich, mit "echtem" Geld eine begrenzte, persönliche, nicht übertragbare und widerrufbare Lizenz zu erwerben, die es Ihnen ermöglicht, eine "virtuelle Währung" zu verwenden, einschließlich virtueller Münzen oder Diamanten, die ausschließlich für Spiele von CapTarget Archery vorgesehen sind. Diese Währung kann auch bei Veranstaltungen, Wettbewerben, Spielen, Anzeigen oder als Belohnung für das Erreichen von Zielen auf der Plattform erlangt werden, oder sie wird von CapTarget Archery verteilt.</p>
    <p>Diese Währung ermöglicht den Kauf verschiedener Produkte, die von der Anwendung verkauft werden.</p>
    <p>Der Transfer von virtuellen Gegenständen ist untersagt, es sei denn, dies ist ausdrücklich im Service gestattet. Sie sollten sich, abgesehen von dem, was im Service ausdrücklich gestattet ist, davor hüten, virtuelle Gegenstände zu verkaufen, zu kaufen, umzuwandeln oder anderweitig zu übertragen, zum Nutzen einer Person oder Einrichtung oder zum Nutzen von CapTarget Archery, eines anderen Benutzers oder eines Dritten.</p>
    <p>CapTarget Archery kann virtuelle Gegenstände jederzeit mit oder ohne Vorankündigung verwalten, regulieren, kontrollieren, ändern oder löschen. CapTarget Archery übernimmt keine Verantwortung für die Ausübung dieser Rechte gegenüber Ihnen oder Dritten.</p>
    <p>Alle Käufe und Umwandlungen von virtuellen Drittanbieter-Gegenständen über den Service sind endgültig und nicht erstattungsfähig.</p>
    <p>Sie verpflichten sich, alle anfallenden Gebühren und Steuern zu zahlen, die von Ihnen oder von einer Person, die ein Konto unter Ihrem Namen nutzt, geschuldet werden. CapTarget Archery kann den Preis der über den Service angebotenen virtuellen Gegenstände jederzeit ändern. Sie erkennen an, dass CapTarget Archery nicht verpflichtet ist, Ihnen aus irgendeinem Grund eine Rückerstattung zu gewähren, und dass Ihnen keine Beträge oder Entschädigungen für ungenutzte virtuelle Gegenstände bei der Schließung eines Kontos gewährt werden, unabhängig davon, ob die Schließung freiwillig oder unfreiwillig erfolgt.</p>
    <h4>Werbung und Partner</h4>
    <p></p>
    <p>CapTarget Archery kann Links zu anderen Werbe- und/oder Partnerseiten auf den verschiedenen angebotenen Diensten bereitstellen.</p>
    <p>Da CapTarget Archery diese Seiten nicht kontrollieren kann, übernimmt CapTarget Archery keine Verpflichtung in Bezug auf die Verfügbarkeit dieser externen Websites und Quellen und kann nicht für den Inhalt, Werbung, Produkte, Dienstleistungen oder sonstige Materialien auf diesen haftbar gemacht werden.</p>
    <p>Ebenso haftet CapTarget Archery nicht für jegliche Schäden oder Verluste, die sich aus oder im Zusammenhang mit der Nutzung von Inhalten oder Diensten auf diesen Werbe- oder Partnerseiten oder externen Quellen ergeben.</p>
    <h4>Kontoschließung</h4>
    <p></p>
    <p>Ein Benutzer kann sein Konto oder das von ihm verwaltete Konto für einen Minderjährigen jederzeit schließen. Gemäß unserer Datenschutzrichtlinie führt die Schließung eines Kontos zur Löschung der damit verbundenen persönlichen Daten.</p>
    <p>Unbeschadet etwaiger Schadensersatzansprüche, die CapTarget Archery geltend machen könnte, behält sich CapTarget Archery das Recht vor, das Konto eines Benutzers ohne Vorankündigung oder Entschädigung vorübergehend oder dauerhaft zu schließen, insbesondere</p>
    <p>bei Verstoß gegen diese Nutzungsbedingungen</p>
    <p>bei Bereitstellung falscher Informationen bei der Registrierung;</p>
    <p>bei Handlungen, die den geschäftlichen Interessen von CapTarget Archery zuwiderlaufen.</p>
    <p>Die von CapTarget Archery initiierte Schließung eines Kontos aufgrund von Verstößen gegen diese Nutzungsbedingungen oder Allgemeinen Geschäftsbedingungen berechtigt nicht zu einer Entschädigung oder Rückerstattung, einschließlich kostenpflichtiger Abonnements. Alle für das Abonnement gezahlten Beträge verbleiben im Besitz von CapTarget Archery.</p>
    <p>Die Schließung eines Coach-Zugangs kann gemäß den geltenden Allgemeinen Geschäftsbedingungen erfolgen. Benutzer werden gebeten, sich für die Schließung eines kostenpflichtigen Coach-Zugangs auf diese zu beziehen. Eine Testversion wird automatisch am Ende des Zeitraums pausiert und kann durch ein Abonnement wieder geöffnet werden. Die Beta-Version kann nach Belieben von CapTarget Archery verlängert werden. Um eine Testversion oder eine Beta-Version zu schließen, senden Sie bitte eine E-Mail an die Adresse support@captarget-archery.com.</p>
    <h4>Löschen einer Gruppe</h4>
    <p></p>
    <p>Ein Administrator kann eine Gruppe jederzeit löschen. Das Löschen des Bereichs einer Organisation führt zur Löschung aller damit verbundenen Daten und Vorteile.</p>
    <p>Das Löschen des Bereichs einer Organisation auf Initiative von CapTarget Archery</p>
    <p></p>
    <p>Unbeschadet etwaiger Schadensersatzansprüche, die CapTarget Archery geltend machen könnte, behält sich CapTarget Archery das Recht vor, den Bereich einer Organisation ohne Vorankündigung oder Entschädigung vorübergehend oder dauerhaft zu löschen, insbesondere bei</p>
    <p>Verstoß gegen die Allgemeinen Geschäftsbedingungen;</p>
    <p>Bereitstellung falscher Informationen bei der Organisationseintragung;</p>
    <p>Handlungen, die den geschäftlichen Interessen von CapTarget Archery zuwiderlaufen.</p>
    <p>Die von CapTarget Archery initiierte Löschung des Bereichs einer Organisation berechtigt nicht zu einer Entschädigung oder Rückerstattung, einschließlich kostenpflichtiger Abonnements. Alle für das Abonnement gezahlten Beträge verbleiben im Besitz von CapTarget Archery, und das Löschen des Bereichs einer Organisation entbindet die Organisation/den Administrator nicht von der Verpflichtung, das Abonnement bis zum Ende zu bezahlen.</p>
    <h4>Informationen und Inhalte der Plattformen</h4>
    <p></p>
    <p>Der Benutzer verpflichtet sich, bei der Nutzung der Plattform besonnen vorzugehen, insbesondere bei der Bewertung von Informationen und Inhalten in einer Gruppe, deren Mitglied er ist.</p>
    <p>In diesem Zusammenhang erkennt der Benutzer insbesondere an, dass die auf der Plattform verfügbaren Informationen und Inhalte nicht umfassend sind und hauptsächlich dazu dienen, den Administrator bei der Verwaltung der Organisation zu unterstützen.</p>
    <p>Es ist dem Benutzer untersagt, Inhalte der Plattform ganz oder teilweise zu verkaufen, weiterzuverkaufen oder anderweitig zu nutzen.</p>
    <p>Der Benutzer ist allein verantwortlich für die Kommunikation, Informationen, Dateien, Videos, Fotos, Dokumente oder andere Inhalte, die er über die Plattform veröffentlicht und überträgt.</p>
    <p>Die von dem Benutzer auf der Plattform veröffentlichten Kommunikationen, Informationen, Dateien, Videos, Fotos, Dokumente oder andere Inhalte spiegeln die Ansichten und Meinungen des Benutzers wider und keineswegs die von CapTarget Archery oder seinen Mitarbeitern und Vertretern.</p>
    <p>Im Rahmen der Plattform verpflichtet sich der Benutzer, nur objektive, umfassende und wahre Informationen gemäß den Regeln der Höflichkeit und des Respekts gegenüber anderen bereitzustellen.</p>
    <p>CapTarget Archery ermutigt den Benutzer, Zurückhaltung und Vorsicht bei der Veröffentlichung von Kommunikationen, Informationen, Dateien, Videos, Fotos, Dokumenten oder anderen Inhalten auf der Plattform walten zu lassen. Der Benutzer verpflichtet sich, keine Inhalte auf der Plattform zu veröffentlichen, die gegen geltende Gesetze und Vorschriften verstoßen, insbesondere:</p>
    <p></p>
    <p>Verbreitung von Informationen, die dem öffentlichen Anstand oder den guten Sitten widersprechen;</p>
    <p>Verwendung der Plattform für Propaganda oder Agitation, Werbung oder Anfrage; Veröffentlichung von kommerziellen oder werblichen Informationen oder Propaganda für Tabak, Alkohol oder andere regulierte Substanzen, Produkte oder Dienstleistungen;</p>
    <p>Verbreitung von Inhalten, die die Persönlichkeitsrechte Dritter verletzen oder diffamierend, beleidigend, obszön, pornografisch, anstößig, gewalttätig oder diskriminierend sind, zur politischen Gewalt aufrufen, Rassismus, Fremdenfeindlichkeit, Sexismus oder Homophobie fördern;</p>
    <p>Veröffentlichung von Informationen, die gegen die Datenschutzgesetze verstoßen und die Identifizierung von natürlichen Personen ohne deren Zustimmung ermöglichen, einschließlich Vorname, Nachname, Post- oder E-Mail-Adresse, Telefonnummer, Fotografie oder audiovisuelle Aufnahme;</p>
    <p>Verletzung der geistigen Eigentumsrechte Dritter, insbesondere durch Entfernen oder Löschen von Urheberrechtsvermerken oder Marken sowie von Beschränkungen.</p>
    <p>Der Benutzer verpflichtet sich auch, die Rechte Dritter zu respektieren, insbesondere:</p>
    <p></p>
    <p>Persönlichkeitsrechte (wie das Recht am eigenen Bild und das Recht auf Privatsphäre);</p>
    <p>Markenrechte;</p>
    <p>Urheberrechte (insbesondere an Software, Klängen, Bildern, Fotos, Texten, bewegten Bildern) und verwandte Schutzrechte (Leistungsschutzrechte für ausübende Künstler, Hersteller von Ton- und Bildtonträgern sowie verwandte Schutzrechte für Datenbankhersteller);</p>
    <p>Allgemein die Rechte von Personen und Eigentum.</p>

    <p>Der Nutzer verpflichtet sich ebenfalls, ohne vorherige schriftliche Genehmigung von CapTarget Archery, folgende Handlungen zu unterlassen, ohne dass diese Liste abschließend ist:</p>
    <p> </p>
    <p>Die Plattform zu Werbezwecken zu verwenden und im Allgemeinen Produkte und Dienstleistungen anzubieten, die ihn direkt oder indirekt vergüten;</p>
    <p>Archive aus den Inhalten der Plattform zu erstellen;</p>
    <p>Die Inhalte, Marken, Logos und Zeichen, die auf der Plattform erscheinen, zu reproduzieren, darzustellen, zu verwenden oder zu referenzieren (insbesondere in den Metatags von Suchmaschinen).</p>
    <p> </p>
    <p>Die Nichtbeachtung dieser Nutzungsbedingungen kann zur sofortigen und automatischen Löschung des beanstandeten Inhalts, einer Warnung und/oder zur Sperrung des Benutzerkontos führen, unbeschadet sonstiger rechtlicher Schritte und/oder Schadensersatzansprüche, die CapTarget Archery geltend machen könnte.</p>
    <p> </p>
    <p>CapTarget Archery übernimmt keine Verantwortung für Informationen, Dateien, Videos, Fotos, Dokumente oder andere Inhalte auf der Plattform, einschließlich, aber nicht beschränkt auf illegale Inhalte, Fehler oder Auslassungen oder jeglichen Verlust oder Schaden, der sich aus der Nutzung dieses Materials durch jeden Benutzer ergibt.</p>
    <p> </p>
    <p>Jeglicher Hyperlink zu Unterseiten der Website ist strengstens untersagt, es sei denn, der Nutzer hat ausdrücklich die Genehmigung von CapTarget Archery erhalten. Darüber hinaus muss jeder Link auf einfache Anfrage von CapTarget Archery entfernt werden.</p>
    <p> </p>
    <h4>Moderationsrolle des Administrators</h4>
    <p> </p>
    <p>Der Administrator verpflichtet sich als Koordinator und Aufsichtsperson der Gruppe, die Mitglieder zur Einhaltung der Nutzungsbedingungen zu veranlassen. Sobald er davon Kenntnis hat, verpflichtet er sich, Inhalte zu melden oder zu entfernen (sofern möglich), die gegen das Gesetz und/oder die öffentliche Ordnung und/oder diese Nutzungsbedingungen verstoßen. Dazu gehören insbesondere gewalttätige, pornografische, rassistische, antisemitische, fremdenfeindliche Inhalte, die die Achtung der menschlichen Person oder ihrer Würde beeinträchtigen, die Privatsphäre verletzen, Urheberrechte, verwandte Schutzrechte, Markenrechte oder Persönlichkeitsrechte Dritter verletzen.</p>
    <p> </p>
    <p>Bei Verstoß gegen diese Regeln und Praktiken behält sich CapTarget Archery das Recht vor, den Administrator und/oder das fehlerhafte Mitglied auszuschließen und rechtliche Schritte gegen die oder den Verantwortlichen einzuleiten, um die offensichtlich rechtswidrige Störung durch fehlerhafte Nutzung der Plattform zu beenden.</p>
    <p> </p>
    <h4>Versand von E-Mails, Push-Benachrichtigungen und SMS</h4>
    <p> </p>
    <p>Im Rahmen der angebotenen Dienste bietet CapTarget Archery Kommunikation per E-Mail, Push-Benachrichtigungen in der Anwendung und SMS an, um Informationen zwischen den Mitgliedern einer Organisation auszutauschen, ausschließlich zum Zwecke des Austauschs von organisationsbezogenen Informationen, unter Verwendung der Telefonnummern und E-Mail-Adressen, die von den Mitgliedern bei der Erstellung ihres Kontos angegeben wurden.</p>
    <p> </p>
    <p>Der Nutzer kann jederzeit entscheiden, keine E-Mails, Push-Benachrichtigungen oder SMS von CapTarget Archery mehr zu erhalten, indem er die Einstellungen seiner Geräte ändert oder eine Anfrage an den Support stellt.</p>
    <p> </p>
    <h4>Zugang zur Plattform - Verantwortung und Verpflichtungen</h4>
    <p> </p>
    <p>CapTarget Archery bemüht sich, die Plattform rund um die Uhr, 7 Tage die Woche, verfügbar zu machen, mit Ausnahme von geplanten Wartungsarbeiten und vorbehaltlich der Bestimmungen dieses Artikels.</p>
    <p> </p>
    <p>CapTarget Archery behält sich das Recht vor, die Plattform entsprechend den technischen Entwicklungen jederzeit zu ändern und zu verbessern. Somit behält sich CapTarget Archery das Recht vor, den Zugang zur Plattform ohne Vorankündigung oder Entschädigung vorübergehend auszusetzen, um Weiterentwicklungs- oder Korrekturwartungsarbeiten durchzuführen. Der Nutzer entbindet CapTarget Archery von jeglicher Verantwortung in dieser Angelegenheit und verzichtet auf jegliche entsprechende Forderung.</p>
    <p> </p>
    <p>CapTarget Archery kann verlangen, dass Sie Aktualisierungen des Dienstes und der von CapTarget Archery entwickelten Spiele akzeptieren, die auf Ihrem Gerät oder Computer installiert sind. Sie erkennen an und stimmen zu, dass CapTarget Archery den Dienst und die Spiele von CapTarget Archery aktualisieren kann, mit oder ohne Benachrichtigung. Möglicherweise müssen Sie Drittanbietersoftware aktualisieren, um den Dienst zu erhalten und die Spiele von CapTarget Archery zu spielen.</p>
    <p> </p>
    <p>CapTarget Archery gibt keine ausdrücklichen oder stillschweigenden Garantien, einschließlich, aber nicht beschränkt auf Garantien hinsichtlich Qualität und Eignung der Plattform für einen bestimmten Zweck.</p>
    <p> </p>
    <p>CapTarget Archery garantiert nicht, dass der störungsfreie Betrieb und/oder die Kontinuität der Plattform im Falle höherer Gewalt oder unvorhersehbarer Ereignisse gemäß Artikel 1218 des französischen Bürgerlichen Gesetzbuches gewährleistet sind, in welchem Fall die Haftung von CapTarget Archery nicht gegeben ist.</p>
    <p> </p>
    <p>Falls die Haftung von CapTarget Archery aufgrund eines Verstoßes des Nutzers gegen seine Pflichten gemäß diesen Nutzungsbedingungen geltend gemacht wird, ist die Schadensersatzpflicht auf direkte, persönliche, bestimmte und vorhersehbare Schäden beschränkt.</p>
    <p> </p>
    <p>CapTarget Archery garantiert nicht die Relevanz und Richtigkeit von von Dritten auf der Plattform veröffentlichten Informationen.</p>
    <p> </p>
    <p>CapTarget Archery übernimmt keine Verantwortung für Streitigkeiten, Klagen oder Ansprüche Dritter, die Rechte, insbesondere Eigentumsrechte, an Inhalten geltend machen könnten, die nicht von CapTarget Archery erstellt wurden.</p>
    <p> </p>
    <p>Die Bedingungen, unter denen CapTarget Archery Daten erhebt und verarbeitet, sind in der Datenschutzrichtlinie detailliert beschrieben.</p>
    <p> </p>
    <p>CapTarget Archery haftet Ihnen gegenüber nicht für indirekte, zufällige, Folge- oder ähnliche Schäden, einschließlich, aber nicht beschränkt auf entgangene Einnahmen, entgangenen Gewinn, Datenverlust oder immateriellen Schaden (unabhängig von der Bezeichnung dieser Verluste), die in irgendeiner Weise im Zusammenhang mit diesen Nutzungsbedingungen oder dem Dienst stehen, und ist nicht verpflichtet, Ihnen besondere oder strafende Schadensersatzansprüche zu leisten, sei es auf vertraglicher, deliktischer oder anderer Grundlage, unabhängig davon, ob CapTarget Archery über die Möglichkeit eines solchen Schadens informiert wurde oder nicht. Die Haftung von CapTarget Archery Ihnen gegenüber kann den Betrag, den Sie gemäß diesen Nutzungsbedingungen in den letzten sechs (6) Monaten vor dem Zeitpunkt, an dem Sie erstmals Anspruch erheben, an CapTarget Archery gezahlt haben, nicht überschreiten. Sie erkennen an und stimmen zu, dass wenn Sie in einem solchen Zeitraum keinen Betrag an CapTarget Archery gezahlt haben, Ihr einziger Rechtsbehelf (und die alleinige Haftung von CapTarget Archery) im Falle einer Streitigkeit mit CapTarget Archery darin besteht, die Nutzung des Dienstes einzustellen und Ihr Konto zu schließen.</p>
    <p>Insbesondere beeinträchtigt keine Bestimmung dieser Nutzungsbedingungen die gesetzlichen Rechte eines Verbrauchers oder schließt eine Haftung im Falle von Tod oder Körperverletzung aufgrund von Fahrlässigkeit oder Betrug von CapTarget Archery aus.</p>
    <p> </p>
    <p>Sie verpflichten sich, CapTarget Archery (sowie seine Entwickler, Administratoren und Inhalteersteller) gegenüber Ansprüchen, Forderungen, Schäden oder anderen Verlusten zu verteidigen, zu schützen und von Haftung freizustellen, einschließlich angemessener Anwaltskosten, die ein Dritter geltend macht und die sich aus Ihrer Nutzung des Dienstes oder einer Verletzung dieser Nutzungsbedingungen durch Sie ergeben. Dies gilt jedoch nicht im Falle einer Rechtsverletzung, die nicht auf Ihr vorsätzliches oder fahrlässiges Verhalten zurückzuführen ist.</p>
    <p> </p>
    <p>CapTarget Archery haftet nicht für Verzögerungen oder Nichterfüllung, die auf Gründe zurückzuführen sind, die außerhalb ihrer Kontrolle liegen und insbesondere auf unvorhersehbare Umstände oder Umstände zurückzuführen sind, die nicht von CapTarget Archery kontrolliert werden, wie Naturkatastrophen, Krieg, Terrorismus, Unruhen, Embargos, Maßnahmen ziviler oder militärischer Behörden, Feuer, Überschwemmungen, Unfälle, Streiks oder Mangel an Transportmitteln, Treibstoff, Energie, Arbeitskräften oder Materialien.</p>
    <p> </p>
    <p>Die Anleitung zur Ausübung von Aktivitäten, wie in der Anwendung beschrieben, ist indikativ; es liegt in der Verantwortung des Nutzers, die Anleitung an seine Gesundheit anzupassen oder einen Arzt zu konsultieren. Die Verantwortung für die sportliche Betätigung liegt beim Nutzer. CapTarget Archery ist in keiner Weise für körperliche oder seelische Verletzungen verantwortlich, die durch die Nutzung der Anwendung bei der Ausübung einer Aktivität entstehen.</p>
    <p> </p>
    <p>Der Nutzer verpflichtet sich, Sicherheitslücken zu melden und diese nicht zu seinem eigenen oder dem Vorteil einer anderen Person oder Einrichtung zu nutzen. Der Nutzer verpflichtet sich, keine Maßnahmen zu ergreifen, die den ordnungsgemäßen Betrieb der von CapTarget Archery angebotenen Dienste beeinträchtigen könnten, und von möglichen Softwarefehlern keinen Nutzen zu ziehen.</p>
    <p> </p>
    <h4>Geistiges Eigentum</h4>
    <p> </p>
    <p>Die Dienste und ihr Inhalt (einschließlich Daten, Datenbanken, Software, Fotografien, Informationen, Illustrationen, Logos, Marken usw.), die auf der Plattform erscheinen oder verfügbar sind, sind durch sämtliche bestehenden Rechte des geistigen Eigentums, Rechte der Datenbankhersteller und/oder andere Rechte geschützt, die durch die geltende Gesetzgebung anerkannt werden.</p>
    <p> </p>
    <p>Jede unbefugte Kopie, Vervielfältigung, Darstellung, Anpassung, Veränderung oder Verbreitung, ganz oder teilweise, der Dienste und/oder Inhalte der Plattform, egal ob diese Inhalte CapTarget Archery, einem Nutzer, einer Organisation oder einem Dritten gehören, ist rechtswidrig und kann straf- und zivilrechtliche Haftung des Verfassers nach sich ziehen.</p>
    <p> </p>
    <p>CapTarget Archery gewährt dem Nutzer das Recht zur Nutzung der Plattform für seine eigenen Bedürfnisse, jedoch nicht zu kommerziellen Zwecken.</p>
    <p> </p>
    <p>Wenn der Nutzer Inhalte auf die Plattform hochlädt, erteilt er CapTarget Archery automatisch eine persönliche und nicht exklusive Lizenz zur kostenlosen Nutzung dieses Inhalts.</p>
    <p> </p>
    <p>Diese Lizenz wird weltweit für alle Verwendungszwecke auf allen Medien und auf alle bekannten oder unbekannten Verbreitungsverfahren gewährt und bleibt während der gesamten Dauer des gesetzlichen Schutzes des geistigen Eigentums (einschließlich Erneuerungen und Verlängerungen) gültig:</p>
    <p> </p>
    <p>das Recht zur Vervielfältigung: das Recht, eine unbegrenzte Anzahl von Kopien des Inhalts in jeglicher Form oder auf jedem bekannten oder unbekannten Medium herzustellen oder herstellen zu lassen, einschließlich aller Arten von gedruckten, magnetischen, digitalen oder anderen Medien (zum Beispiel Broschüren, Presseunterlagen, Plakate, Webseiten, Berichte und alle Arten von Aufnahmemedien wie CDs, DVDs, Disketten, interne oder externe Festplatten oder andere Aufnahmemedien), mittels bekannter oder unbekannter technischer Verfahren;</p>
    <p>das Recht zur Darstellung: das Recht, Inhalte an Mitglieder der Nutzerorganisation auf beliebige Weise zu kommunizieren, zu verbreiten, kommunizieren oder verbreiten zu lassen, sei es durch jedes Telekommunikationsnetzwerk oder jeden Kommunikationsmittel (Internet, terrestrisches Netzwerk, Kabel, Satellit, Download, Teletransmission usw.);</p>
    <p>das Recht zur Übersetzung der Inhalte in jede Sprache (einschließlich aller Informatiksprachen).</p>
    <p>Der Nutzer garantiert CapTarget Archery, dass die auf die Plattform hochgeladenen Inhalte keine Rechte des geistigen Eigentums oder sonstige Rechte Dritter verletzen.</p>
    <p> </p>
    <p>Folglich verpflichtet sich der Nutzer, CapTarget Archery zu verteidigen und CapTarget Archery von jeder Verantwortung freizustellen und/oder CapTarget Archery für jeglichen Schaden haftbar zu machen, der sich aus einer Handlung oder Forderung eines Dritten in Bezug auf den vom Nutzer veröffentlichten Inhalt ergeben könnte.</p>
    <p> </p>
    <h4>Vertragsdokumente</h4>
    <p> </p>
    <p>Diese Nutzungsbedingungen regeln sämtliche vertraglichen oder vorvertraglichen geschäftlichen Beziehungen zwischen CapTarget Archery, dem Nutzer und/oder der Organisation in Bezug auf die Nutzung der Plattform und den Abschluss von Abonnements, die von CapTarget Archery angeboten werden.</p>
    <p> </p>
    <p>Diese Nutzungsbedingungen haben Vorrang vor jeder abweichenden Vereinbarung oder Regelung zwischen den Parteien. Sie heben alle früheren Allgemeinen Geschäftsbedingungen auf, die die Beziehung zwischen den Parteien geregelt haben könnten.</p>
    <p> </p>
    <p>Diese Nutzungsbedingungen stellen das gesamte vertragliche Dokument zwischen den Parteien dar.</p>
    <p> </p>
    <p>CapTarget Archery behält sich das Recht vor, den Inhalt der Nutzungsbedingungen jederzeit zu ändern. Diese Änderungen führen zu einer neuen Version, die automatisch auf alle zukünftig erbrachten Dienste Anwendung findet.</p>
    <p> </p>
    <h4>Anwendbares Recht, Schlichtung und Gerichtsstand</h4>
    <p> </p>
    <p>Die Nutzungsbedingungen unterliegen französischem Recht.</p>
    <p> </p>
    <p>Die Parteien verpflichten sich, eine außergerichtliche Lösung vor rechtlichen Schritten anzustreben. Im Falle einer nicht einvernehmlichen Beschwerde zwischen den Parteien kann der Nutzer unentgeltlich eine Verbrauchermediation in Anspruch nehmen. Es wird empfohlen, sich an den folgenden Mediator zu wenden: Centre de Médiation et d'Arbitrage de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p> </p>
    <p>Der Nutzer kann auch die von der Europäischen Kommission eingerichtete Online-Streitbeilegungsplattform unter folgender Adresse nutzen: www.ec.europa.eu/consumers/odr/.</p>
</div>
