<section class="section download-area bg-img bg-overlay ptb_150">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Download Text -->
                <div class="download-text">
                    <h2 class="text-white">Download Appia</h2>
                    <p class="text-white my-3 d-none d-sm-block">Appia is available for all devices, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus.</p>
                    <p class="text-white my-3 d-block d-sm-none">Appia is available for all devices, consectetur adipisicing elit. Vel neque, cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi quisquam.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex">
                        <a href="#">
                            <img src="assets/img/google_play.svg" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app_store.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Top -->
    <div class="shape-top">
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
            c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
            c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
        </svg>
    </div>
    <!-- Download Thumb -->
    <div class="download-thumb pos-abs-top-right d-none d-md-block" data-aos="fade-up" data-aos-offset="300" data-aos-duration="1000">
        <img src="assets/img/download_thumb.png" alt="">
    </div>
</section>