<section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-9 col-lg-7">
                <div class="welcome-intro text-center">
                    <h1 class="text-white">{{ 'TRANSLATE.title_add' | translate }}</h1>
                    <p class="text-white my-4">{{ 'TRANSLATE.captarget_adds_message' | translate }}</p>
                    <!-- Video Icon -->
<!--                    <div class="video-icon mt-5">-->
<!--                        &lt;!&ndash; Play Button &ndash;&gt;-->
<!--                        <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=mhxc89IjlFI">-->
<!--                            <div class="btn-circle play-animation"></div>-->
<!--                            <div class="btn-circle play-animation animation-short"></div>-->
<!--                            &lt;!&ndash; Play Icon &ndash;&gt;-->
<!--                            <div class="play-icon">-->
<!--                                <i class="fas fa-play"></i>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape-bottom">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2200 114.5" style="enable-background:new 0 0 2200 114.5;" xml:space="preserve">
            <style type="text/css">
                .st0 {
                    fill: #FFFFFF;
                }
            </style>
            <path class="st0" d="M0,0L0,0c725.8,128.6,1460.1,129.4,2186.1,2.4L2200,0v114.5H0V0z" />
        </svg>
    </div>
</section>


<section class="section service-area overflow-hidden ptb_180">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">{{ 'TRANSLATE.add_specificity' | translate }}</h2>
                    <p style="font-weight: bold">Documents conditions:</p>
                    <ul>
                        <li style="margin-left: 35px;">- .png or .jpg or .svg images under 5MO</li>
                        <li style="margin-left: 35px;">- .mp4 videos under 15MO and under 60 seconds</li>
                        <li style="margin-left: 35px;">- Max 10 files</li>
                    </ul>
                    <p style="font-weight: bold; margin-top: 10px;">Some informations</p>
                    <p>- You can provide phrases (less than 80 characters long) that will appear below the images (optional).</p>
                    <p>- You can specify whether they should be attached to a particular document.</p>
                    <p>- You can specify bow type, sex, country and language you want to target.</p>
                    <p>- Recommend period: more than a week and less than 1 month.</p>
                    <p style="font-weight: bold; margin-top: 10px;">Process:</p>
                    <ul>
                        <li style="margin-left: 35px;">- You send this e-mail.</li>
                        <li style="margin-left: 35px;">- We send you a preview of your ads and a payment link with the maximum amount. We'll refund you if the budget hasn't been reached in full at the end.</li>
                        <li style="margin-left: 35px;">- We'll send you regular statistics.</li>
                    </ul>
                    <p style="font-weight: bold; margin-top: 10px;">Simulation:</p>
                    <p >Here's a simulation to give you an idea of the price, which depends on a number of factors and can therefore vary out of these limits:</p>
                    <ul>
                        <li style="margin-left: 35px;">- 5000 total views</li>
                        <li style="margin-left: 35px;">- 150 unique clicks</li>
                        <li style="margin-left: 35px;">- prize : 150€-200€</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section service-area overflow-hidden ptb_180">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">{{ 'TRANSLATE.title_add' | translate }}</h2>
                    <!-- Service List -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-camera"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step1' | translate }}</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-coins"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step2' | translate }}</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-mail-bulk"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step3' | translate }}</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><i class="fas fa-database"></i></span>
                            </div>
                            <div class="service-text media-body">
                                <p>{{ 'TRANSLATE.add_step4' | translate }}</p>
                            </div>
                        </li>
                    </ul>
                    <a (click)="openMail()" class="btn btn-bordered mt-4" style="cursor: pointer">{{ 'TRANSLATE.contact_us' | translate }}</a>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
                    <!--                    &lt;!&ndash; Thumb Top &ndash;&gt;-->
                    <!--                    <div class="thumb-top">-->
                    <!--                        <img src="assets/img/service_thumb_top.png" alt="">-->
                    <!--                    </div>-->
                    <!-- Thumb Certer -->
                    <div class="thumb-center">
                        <img src="assets/img/service_thumb.png" alt="CapTarget add">
                    </div>
                    <!-- Thumb Bottom -->
                    <!--                    <div class="thumb-bottom">-->
                    <!--                        <img src="assets/img/service_thumb_bottom.png" alt="">-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</section>



