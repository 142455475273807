<div class="container">
    <h4>Definizioni</h4>
    <p> </p>
    <p>I seguenti termini, di cui la prima lettera è maiuscola nei Termini e Condizioni Generali d'Uso, hanno il significato loro attribuito nell'articolo seguente.</p>
    <p> </p>
    <p>Abbonamento: indica qualsiasi sottoscrizione, gratuita o a pagamento, ai servizi della piattaforma.</p>
    <p> </p>
    <p>Arciere: Utente che pratica il tiro con l'arco e utilizza o meno i servizi dell'applicazione.</p>
    <p> </p>
    <p>Coach: Allenatore di tiro con l'arco che ha sottoscritto un abbonamento gratuito o a pagamento di tipo "Coach" e che ha quindi accesso ai servizi associati.</p>
    <p> </p>
    <p>Applicazione: indica l'applicazione mobile "CapTarget Archery" disponibile su Android e iOS.</p>
    <p> </p>
    <p>Sito: indica il sito vetrina di CapTarget Archery.</p>
    <p> </p>
    <p>Sito coach: indica il sito con accesso riservato ai Coach.</p>
    <p> </p>
    <p>Condizioni d'uso: indicano le presenti condizioni generali d'uso della Piattaforma.</p>
    <p> </p>
    <p>Account: indica l'account assegnato a un Utente che dà accesso alla Piattaforma e ai vari servizi, al quale sono associati un identificatore personale e un codice di accesso.</p>
    <p> </p>
    <p>Gruppo: Indica un gruppo creato sull'applicazione CapTarget Archery che raggruppa Utenti e Coach.</p>
    <p> </p>
    <p>Partner: indica una persona fisica o giuridica che ha stipulato un partenariato con CapTarget Archery, consentendole di essere presentata agli Utenti tramite la Piattaforma.</p>
    <p> </p>
    <p>Piattaforma: indica l'insieme dei servizi accessibili sui Siti e/o sull'Applicazione.</p>
    <p> </p>
    <p>Utente: indica qualsiasi persona fisica o giuridica che ha accesso alla Piattaforma e la utilizza, che sia tramite un Account o meno.</p>
    <p> </p>
    <p>Gemme: indica una valuta virtuale utilizzata su CapTarget Archery.</p>
    <p> </p>
    <h4>Oggetto</h4>
    <p> </p>
    <p>Le Condizioni Generali d'Uso hanno lo scopo di definire i termini e le condizioni applicabili a tutti gli Utenti della Piattaforma.</p>
    <p> </p>
    <p>Ogni iscrizione, accesso e/o utilizzo della Piattaforma implica l'accettazione incondizionata e il rispetto da parte dell'Utente di tutte le Condizioni Generali d'Uso.</p>
    <p> </p>
    <p>Ogni Utente minorenne dichiara e riconosce di aver ottenuto l'autorizzazione dei propri genitori o dei titolari dell'autorità genitoriale per iscriversi alla Piattaforma.</p>
    <p> </p>
    <p>Ogni richiesta di iscrizione generata automaticamente da un robot o con qualsiasi altra modalità sarà rifiutata.</p>
    <p> </p>
    <h4>Presentazione dell'applicazione</h4>
    <p> </p>
    <p>L'Applicazione è principalmente dedicata ai praticanti del tiro con l'arco o agli allenatori e consente in particolare di gestire e organizzare le attività legate allo sport, di comunicare informazioni tra i membri di un gruppo o riguardanti le novità del settore.</p>
    <p> </p>
    <p>Attraverso l'Applicazione, gli Utenti beneficiano in particolare delle seguenti funzionalità:</p>
    <p> </p>
    <p>Gestione delle sessioni di allenamento e delle competizioni</p>
    <p>Gestione dei tiri contati e delle partite</p>
    <p>Gestione dell'attrezzatura per il tiro</p>
    <p>Utilizzo dei dati per fornire statistiche</p>
    <p>Interazioni sociali e ludiche, condivisione dei dati con i Coach dell'Utente</p>
    <p>Interazioni sociali e ludiche tra i membri di un Gruppo</p>
    <p>Acquisto di oggetti per la personalizzazione dell'esperienza tramite la valuta virtuale comunemente chiamata "Gemme".</p>
    <p>Acquisto di "Gemme" tramite il sistema di pagamento mobile (ANDROID o APPLE)</p>
    <p>Messaggistica</p>
    <p> </p>
    <h4>Iscrizione di un Utente</h4>
    <p> </p>
    <p>Ogni Utente ha la possibilità di creare un Account (personale o per conto di un Minore quando l'Utente è titolare dell'autorità genitoriale), che gli consentirà di accedere all'applicazione e ai suoi servizi.</p>
    <p> </p>
    <p>L'iscrizione viene effettuata sulla Piattaforma dall'Utente, tramite l'applicazione. A tal fine, dovrà compilare i diversi campi del modulo di registrazione, in particolare un indirizzo e-mail valido, il suo nome e cognome e le diverse informazioni relative alla sua pratica del tiro con l'arco. Dovrà prendere visione ed accettare le Condizioni Generali d'Uso e la Politica sulla Privacy dei dati.</p>
    <p> </p>
    <p>Compilando il modulo di registrazione, l'Utente si impegna a fornire solo informazioni esatte, aggiornate e complete.</p>
    <p> </p>
    <p>L'Utente si impegna a mantenere riservato il codice di accesso al suo Account. In caso di utilizzo fraudolento del codice di accesso, l'Utente informerà immediatamente CapTarget Archery.</p>
    <p> </p>
    <p>L'Utente è l'unico responsabile dell'uso del suo Account. Ogni connessione o trasmissione di dati tramite la Piattaforma sarà considerata effettuata dall'Utente e sotto la sua responsabilità esclusiva.</p>
    <p> </p>
    <p>CapTarget Archery non sarà responsabile se i dati di registrazione dell'Utente non pervengono a CapTarget Archery per una ragione che non le sia imputabile (ad esempio, un problema di connessione Internet dell'Utente, un malfunzionamento temporaneo dei suoi server, ecc.), o se i dati pervengono a CapTarget Archery in una forma non elaborabile (ad esempio, se l'Utente dispone di hardware o software inadeguato).</p>
    <p> </p>
    <p>Ogni iscrizione è effettuata per una durata indeterminata e l'Account dell'Utente rimane attivo fino alla sua chiusura.</p>
    <p> </p>
    <p> </p>
    <h4>Iscrizione di un gruppo</h4>
    <p> </p>
    <p>Per accedere alle funzionalità di gruppo, un Utente deve creare o unirsi a un Gruppo.</p>
    <p> </p>
    <p>Compilando il modulo di registrazione, l'amministratore si impegna a fornire solo informazioni esatte, aggiornate e complete. Garantisce che CapTarget Archery abbia ottenuto il consenso preventivo dei Membri di cui comunica gli indirizzi e-mail e i numeri di telefono, che sono liberi di chiedere a CapTarget Archery di rimuoverli in qualsiasi momento (vedere la nostra Politica sulla Privacy).</p>
    <p> </p>
    <p>Gli amministratori sono unici responsabili dell'uso del Gruppo.</p>
    <p>Si precisa inoltre che l'Amministratore è pienamente ed esclusivamente responsabile dell'uso dello Spazio dell'Organizzazione da parte sua e da parte di qualsiasi terza parte.</p>
    <p> </p>
    <p>CapTarget Archery non sarà responsabile se i dati relativi all'iscrizione di un'Organizzazione non pervengono a CapTarget Archery per una ragione non imputabile a CapTarget Archery (ad esempio, un problema di connessione a Internet dovuto a qualsiasi motivo da parte dell'Amministratore, un malfunzionamento temporaneo dei suoi server, ecc.) o se i dati pervengono a CapTarget Archery in una forma non elaborabile (ad esempio, se l'Amministratore dispone di hardware informatico o di software di registrazione inadeguato).</p>
    <p> </p>
    <p>Ogni iscrizione è effettuata per una durata indeterminata, il Gruppo rimane attivo fino alla sua chiusura da parte di uno degli amministratori.</p>
    <p> </p>
    <p> </p>
    <h4>Iscrizione di un coach</h4>
    <p>Per accedere alle funzionalità dei coach, un Utente deve sottoscrivere un abbonamento che gli permetta di accedere a una licenza di accesso ai servizi coach di CapTarget Archery tramite il sito web e/o l'applicazione mobile secondo l'offerta sottoscritta.</p>
    <p>Una licenza di accesso può essere fornita da un terzo che ha sottoscritto l'abbonamento al suo posto. Una licenza di accesso è strettamente esclusiva dell'Utente che ha effettuato l'iscrizione con la licenza in questione. L'account non può essere condiviso con altre persone.</p>
    <p>Le vantaggi delle diverse offerte sono soggetti a modifiche. Se è in corso un abbonamento, queste modifiche non saranno efficaci o dovranno essere considerate a vantaggio dell'Utente.</p>
    <p>L'iscrizione viene effettuata sul sito web dall'Utente. A tal fine, dovrà compilare i diversi campi del modulo di registrazione, in particolare un indirizzo e-mail valido, il suo nome e cognome e le diverse informazioni relative alla sua pratica del tiro con l'arco. Dovrà prendere visione ed accettare le Condizioni Generali d'Uso, le Condizioni Generali di Vendita e la Politica sulla Privacy dei dati.</p>
    <p>Compilando il modulo di registrazione, il coach e il consumatore che sottoscrive la licenza si impegnano a fornire solo informazioni esatte, aggiornate e complete.
        L'utente che utilizza la sua licenza è l'unico responsabile dell'uso del suo Account. Ogni connessione o trasmissione di dati tramite la Piattaforma sarà considerata effettuata dall'Utente e sotto la sua responsabilità esclusiva.
    </p>
    <p>CapTarget Archery non sarà responsabile se i dati di registrazione dell'Utente non pervengono a CapTarget Archery per una ragione che non le sia imputabile (ad esempio, un problema di connessione Internet dell'Utente, un malfunzionamento temporaneo dei suoi server, ecc.), o se i dati pervengono a CapTarget Archery in una forma non elaborabile (ad esempio, se l'Utente dispone di hardware o software inadeguato).</p>
    <p>Ogni sottoscrizione di un abbonamento a pagamento è effettuata per una durata indeterminata, salvo richiesta esplicita da parte dell'Utente; il contratto sarà rinnovato automaticamente dopo il periodo di impegno minimo. Vi invitiamo a fare riferimento alle Condizioni Generali di Vendita per le modalità di disiscrizione.</p>
    <p> </p>
    Please note that the tr


    <h4>Condivisione dei Dati</h4>
    <p>Per accedere alle funzionalità di condivisione dei dati tramite CapTarget Archery, la nostra applicazione offre un sistema di fan e follow. Come utente, hai la possibilità di accettare inviti o invitare altri utenti a unirti alla tua rete.</p>
    <p>In caso di profilo pubblico, non è richiesta alcuna approvazione affinché altri utenti ti seguano. Una volta che un utente ti segue, alcune delle tue informazioni possono essere condivise automaticamente o manualmente, tra cui:</p>
    <p>- I risultati delle tue competizioni o delle tue sessioni di allenamento.</p>
    <p>- Diverse statistiche sportive come la durata della tua licenza, il tuo miglior risultato o la tua classifica.</p>
    <p>- Dati relativi al tuo utilizzo dell'applicazione, come i tuoi giochi e esercizi preferiti.</p>
    <p>- Informazioni sul tuo club, la tua lingua, il tuo paese, nonché altre informazioni come il tuo nome, cognome, tipo di arco utilizzato o categoria di tiro.</p>
    <p>Hai la possibilità di scegliere di non condividere alcune di queste informazioni modificando le tue impostazioni personali o le impostazioni per ogni situazione.</p>
    <p>Dalla creazione del tuo account, accetti di essere visibile sulla piattaforma CapTarget. Altri utenti possono cercarti utilizzando il tuo nome e cognome. Il tuo profilo potrebbe anche essere suggerito automaticamente ad altri utenti al fine di creare sfide sportive o connessioni di follow.</p>
    <p>Ti preghiamo di notare che potresti essere esposto a informazioni condivise su CapTarget Archery da parte di CapTarget, dei suoi partner, degli inserzionisti pubblicitari o di altri utenti. Ti incoraggiamo a consultare la nostra politica sulla privacy per ulteriori dettagli sulla gestione dei tuoi dati personali.</p>
    <p>Vogliamo sottolineare che tutte queste clausole sono conformi alle leggi e normative vigenti in materia di protezione dei dati personali e privacy. Se hai preoccupazioni o domande aggiuntive, non esitare a contattarci.</p>
    <p> </p>
    <p> </p>

    <h4>Avviso</h4>
    <p>L'accesso alle versioni per allenatori delle piattaforme CapTarget Archery richiede l'utilizzo di strumenti informatici come computer, tablet o telefono per la versione web e telefono o tablet ANDROID o IOS per la versione mobile. È necessaria una connessione internet per scaricare i dati necessari. L'utente attesta di essere consapevole di questa necessità per accedere ai servizi venduti.</p>
    <p>Gli esercizi e le diverse situazioni proposti nei servizi presuppongono che gli utenti siano in buone condizioni fisiche e in grado di eseguirli. L'Abbonato si impegna a prendere tutte le precauzioni necessarie per praticare l'attività preservando la propria salute, sicurezza e quella delle altre persone.</p>
    <p> </p>
    <p> </p>

    <h4>Condizioni Generali di Vendita</h4>
    <p>Sottoscrivendo un abbonamento o acquistando uno dei servizi digitali o prodotti digitali messi a disposizione da CapTarget Archery, accetti i termini e le condizioni di vendita descritte di seguito. Se non accetti queste condizioni, ti preghiamo di non acquistare nulla dalla nostra applicazione o dal nostro sito web.</p>
    <h6>Abbonamenti</h6>
    <p>Quando acquisti contenuti da CapTarget, ti viene concessa una licenza limitata, revocabile, non esclusiva e non trasferibile per accedere a determinate funzionalità a pagamento sulla nostra app o sul nostro sito. La nostra applicazione offre abbonamenti mensili e annuali per accedere a servizi digitali con rinnovo automatico. Le spese dell'abbonamento saranno addebitate automaticamente dalla modalità di pagamento che hai scelto alla data anniversario dell'abbonamento. Gli abbonamenti pagati con gemme (la valuta virtuale di CapTarget Archery) non vengono rinnovati automaticamente.</p>
    <p>Se non desideri rinnovare il tuo abbonamento, devi disattivarlo almeno 24 ore prima della scadenza dell'abbonamento accedendo alle impostazioni del tuo account.</p>
    <h6>Tariffe</h6>
    <p>Le nostre tariffe di abbonamento sono pubblicate sulla nostra applicazione e possono essere modificate in qualsiasi momento senza preavviso. Le tariffe di abbonamento includono tutte le tasse applicabili. Sei responsabile di tutte le spese associate all'utilizzo della nostra applicazione, comprese le spese di accesso a Internet e di dati mobili. Nonostante tutti i nostri sforzi, un numero limitato di articoli del nostro catalogo potrebbe essere valutato erroneamente. Le tariffe possono variare anche per gli abbonati attuali. In base al sistema che hai scelto (Apple, Google, Stripe o altro), potresti ricevere una notifica in caso di aumento delle tariffe dell'abbonamento in corso e potresti annullare l'abbonamento se lo desideri prima del suo rinnovo.</p>
    <h6>Rischio di Perdita</h6>
    <p>Dopo che i Contenuti CapTarget ti sono stati resi disponibili, assumi il rischio di perdita dei Contenuti CapTarget dopo l'acquisto, incluso qualsiasi perdita dovuta a corruzione del file o guasto del dispositivo o del disco rigido. I Contenuti CapTarget acquistati rimarranno generalmente disponibili sul tuo Account CapTarget, ma potrebbero cessare di essere disponibili per varie ragioni. In tali casi, CapTarget non sarà responsabile nei tuoi confronti per l'indisponibilità dei Contenuti CapTarget successivi e non avrai il diritto di ricevere un rimborso, in tutto o in parte, per le spese già pagate.</p>
    <h6>Consegna</h6>
    <p>L'accesso ai Contenuti CapTarget viene fornito al momento dell'acquisto del prodotto o dell'abbonamento. I contenuti sono disponibili sul tuo account CapTarget. Potrebbe essere necessario riavviare l'applicazione e attendere un periodo ragionevole affinché i servizi diventino disponibili. (Di solito pochi minuti al massimo)</p>
    <h6>Pagamento</h6>
    <p>Devi avere un metodo di pagamento valido (come una carta di credito o un altro metodo di pagamento accettato) nel tuo account CapTarget se desideri usufruire delle funzioni di pagamento. Il pagamento dell'abbonamento verrà addebitato automaticamente dal tuo account in base al metodo di pagamento inserito nel tuo account Apple o Google Play o su Stripe o altro se hai acquistato l'abbonamento sul nostro sito web. Accetti di fornire informazioni di pagamento accurate e aggiornate. Se il pagamento non viene effettuato per qualsiasi motivo, ci riserviamo il diritto di sospendere o annullare il tuo abbonamento.</p>
    <h6>Utilizzo dell'Applicazione</h6>
    <p>Sei autorizzato a utilizzare la nostra applicazione per scopi personali e non commerciali. Non puoi modificare, distribuire, vendere o trasferire alcun contenuto della nostra applicazione senza il nostro previo consenso scritto. Non puoi utilizzare la nostra applicazione in modo che violi la legge applicabile, che violi i diritti di terzi o che causi danni a terzi.</p>
    <h6>Rimborsi</h6>
    <p>I nostri abbonamenti possono essere testati in anticipo per un periodo di 3 settimane e quindi non sono rimborsabili. Ti preghiamo di verificare le caratteristiche di ciascun abbonamento prima di sottoscrivere uno qualsiasi. I prodotti digitali in vendita sono espliciti e non possono essere rimborsati.</p>
    <h6>Risoluzione</h6>
    <p>Ci riserviamo il diritto di risolvere il tuo accesso alla nostra applicazione se violi queste condizioni di vendita o di utilizzo, o se abbiamo motivo di ritenere che stai utilizzando la nostra applicazione in qualsiasi modo. Acquistando un abbonamento alla nostra applicazione, accetti queste condizioni di vendita.</p>

    <h6>Contattaci</h6>
    <p>Se hai domande o preoccupazioni, ti preghiamo di contattarci all'indirizzo: support@captarget-archery.com</p>
    <p>Faremo del nostro meglio per risolvere la tua problematica in modo amichevole e che entrambe le parti ne traggano vantaggio.</p>
    <p></p>
    <p></p>
    <h4>Costi di Utilizzo della Piattaforma</h4>
    <p></p>
    <p>La creazione di un Account e di un Gruppo è gratuita per un Utente. Tuttavia, i costi di connessione e comunicazione legati all'utilizzo della Piattaforma rimangono a carico esclusivo dell'Utente.</p>
    <p>L'accesso alle versioni per allenatori è a pagamento, ad eccezione delle versioni di prova e della versione beta. I prezzi sono disponibili a partire dal lancio della versione, sul sito web.</p>
    <h4>Acquisti tramite le Piattaforme</h4>
    <p></p>
    <p>È possibile acquistare con denaro "reale" una licenza limitata, personale, non trasferibile, non cedibile e revocabile che consente l'utilizzo di una "moneta virtuale", inclusi punti o diamanti virtuali, destinati a essere utilizzati nei giochi di CapTarget Archery. È anche possibile ottenere questa moneta durante eventi, concorsi, giochi, guardando annunci o come premio per il raggiungimento di obiettivi sulla piattaforma, o distribuiti da CapTarget Archery.</p>
    <p>Questa moneta consente l'acquisto di vari prodotti venduti dall'applicazione.</p>
    <p>Il trasferimento di Oggetti virtuali è vietato, a meno che ciò sia espressamente consentito nel Servizio. Salvo quanto espressamente consentito nel Servizio, dovrai astenerti dal vendere, acquistare, convertire o trasferire in altro modo Oggetti virtuali a beneficio di qualsiasi persona o entità, o tentare una delle azioni sopra menzionate, in particolare a beneficio di CapTarget Archery, di un altro utente o di un terzo.</p>
    <p>CapTarget Archery può gestire, regolare, controllare, modificare o eliminare Oggetti virtuali in qualsiasi momento, con o senza preavviso. CapTarget Archery non sarà responsabile nei tuoi confronti o nei confronti di terzi per l'esercizio di questi diritti.</p>
    <p>Tutti gli acquisti e le conversioni di oggetti virtuali di terzi effettuati tramite il servizio sono definitivi e non rimborsabili.</p>
    <p>Accetti di pagare tutte le tariffe e le imposte applicabili dovute da te o da chiunque utilizzi un account registrato a tuo nome. CapTarget Archery può modificare il prezzo degli oggetti virtuali offerti tramite il servizio in qualsiasi momento. Riconosci che CapTarget Archery non è tenuta a fornirti un rimborso per qualsiasi motivo e che non riceverai alcuna somma o altra compensazione per oggetti virtuali non utilizzati alla chiusura di un account, sia che la chiusura sia volontaria o involontaria.</p>
    <h4>Pubblicità e Partner</h4>
    <p></p>
    <p>CapTarget Archery può fornire collegamenti ad altri siti pubblicitari e/o partner su diversi servizi offerti.</p>
    <p>Dal momento che CapTarget Archery non può controllarli, CapTarget Archery non fa alcun impegno per la disponibilità di questi siti e fonti esterne e non può essere ritenuta responsabile per il contenuto, la pubblicità, i prodotti, i servizi o qualsiasi altro materiale disponibile su di essi.</p>
    <p>Allo stesso modo, CapTarget Archery non sarà responsabile per danni o perdite effettive o presunte derivanti o in relazione all'uso dei contenuti o dei servizi disponibili su questi siti pubblicitari, partner o fonti esterne.</p>
    <h4>Chiusura dell'Account</h4>
    <p></p>
    <p>Un Utente può chiudere il proprio Account o l'Account che gestisce per conto di un minore in qualsiasi momento. In conformità con la nostra Politica sulla privacy dei dati, la chiusura di un Account comporterà la cancellazione dei dati personali correlati.</p>
    <p>Senza pregiudizio per i danni e gli interessi che CapTarget Archery potrebbe richiedere, CapTarget Archery si riserva il diritto di chiudere, senza preavviso o indennizzo, temporaneamente o definitivamente, l'Account di un Utente in caso di, tra le altre cose:</p>
    <p></p>
    <p>violazione delle presenti Condizioni d'uso</p>
    <p>fornitura di informazioni false durante la registrazione;</p>
    <p>azioni contrarie agli interessi commerciali di CapTarget Archery.</p>
    <p>La chiusura di un account su iniziativa di CapTarget Archery in caso di violazione delle presenti CGU o delle CGV non dà diritto a alcuna compensazione o rimborso, compresi gli abbonamenti a pagamento. Tutte le somme versate per l'Abbonamento rimarranno di proprietà di CapTarget Archery.</p>
    <h4>Chiusura dell'Accesso per Allenatori</h4>
    <p></p>
    <p>Un Accesso per Allenatori può essere chiuso conformemente alle CGV applicabili. Invitiamo l'utente a fare riferimento a queste ultime per la chiusura di un accesso per allenatori a pagamento. Una versione di prova viene automaticamente messa in pausa alla fine del periodo e può essere riattivata sottoscrivendo un abbonamento. La versione beta è rinnovabile a discrezione di CapTarget Archery. Per chiudere una versione di prova o una versione beta, inviare una e-mail all'indirizzo support@captarget-archery.com</p>
    <h4>Eliminazione di un Gruppo</h4>
    <p></p>
    <p>Un Amministratore può eliminare un Gruppo in qualsiasi momento. L'eliminazione dello Spazio di un'Organizzazione comporta la cancellazione di tutti i dati e i vantaggi correlati.</p>
    <p>L'eliminazione dello Spazio di un'Organizzazione su iniziativa di CapTarget Archery</p>
    <p></p>
    <p>Senza pregiudizio per i danni e gli interessi che CapTarget Archery potrebbe richiedere, CapTarget Archery si riserva il diritto di eliminare, senza preavviso o indennizzo, temporaneamente o definitivamente, lo Spazio di un'Organizzazione in caso di:</p>
    <p>violazione delle CGV;</p>
    <p>fornitura di informazioni false durante la registrazione dell'Organizzazione;</p>
    <p>azioni contrarie agli interessi commerciali di CapTarget Archery.</p>
    <p>L'eliminazione dello Spazio di un'Organizzazione su iniziativa di CapTarget Archery non dà diritto a alcuna compensazione o rimborso, compresi gli abbonamenti a pagamento. Tutte le somme versate per l'Abbonamento rimarranno di proprietà di CapTarget Archery, e l'eliminazione dello Spazio di un'Organizzazione non esonera l'Organizzazione/Amministratore dall'obbligo di pagare l'Abbonamento fino alla sua scadenza.</p>
    <h4>Informazioni e Contenuti delle Piattaforme</h4>
    <p></p>
    <p>L'Utente si impegna a usare il discernimento durante l'utilizzo della Piattaforma, in particolare nella valutazione delle informazioni e dei contenuti presenti in un Gruppo di cui è membro.</p>
    <p>In questo contesto, l'Utente riconosce che le informazioni e i contenuti disponibili sulla Piattaforma non sono esaustivi e hanno principalmente lo scopo di aiutare l'Amministratore nella gestione dell'Organizzazione.</p>
    <p>All'Utente è vietato vendere, rivendere o sfruttare in qualsiasi modo e per qualsiasi scopo tutto o parte dei contenuti disponibili sulla Piattaforma.</p>
    <p>L'Utente è l'unico responsabile delle comunicazioni, informazioni, file, video, foto, documenti o altri contenuti che pubblica e trasmette tramite la Piattaforma.</p>
    <p>Le comunicazioni, informazioni, file, video, foto, documenti o altri contenuti pubblicati dall'Utente sulla Piattaforma rappresentano le opinioni e i punti di vista dell'Utente e non quelli di CapTarget Archery o dei suoi dipendenti e agenti.</p>
    <p>Nel contesto della Piattaforma, l'Utente si impegna a fornire solo informazioni oggettive, complete e veritiere, nel rispetto delle regole di buona educazione, cortesia e rispetto degli altri.</p>
    <p>CapTarget Archery incoraggia l'Utente a esercitare moderazione e cautela nell'espressione delle comunicazioni, informazioni, file, video, foto, documenti o altri contenuti che desidera pubblicare sulla Piattaforma. A tal fine, l'Utente si astiene dal pubblicare contenuti sulla Piattaforma che siano contrari alle leggi e ai regolamenti vigenti, in particolare:</p>
    <p></p>
    <p>diffondere informazioni contrarie all'ordine pubblico o alle buone maniere;</p>
    <p>deviare lo scopo della Piattaforma per propaganda o proselitismo, sollecitazione o richieste commerciali; pubblicare informazioni a scopo commerciale o pubblicitario o costituire propaganda a favore del tabacco, dell'alcol o di qualsiasi altra sostanza, prodotto o servizio regolamentato;</p>
    <p>diffondere contenuti che violano i diritti della personalità di terzi o sono diffamatori, offensivi, osceni, pornografici, offensivi, violenti o incitano alla discriminazione, alla violenza politica, al razzismo, alla xenofobia, al sessismo o all'omofobia;</p>
    <p>pubblicare informazioni che violano la legge sulla protezione dei dati personali, consentendo l'identificazione delle persone fisiche senza il loro consenso, in particolare il loro cognome, indirizzo postale e/o elettronico, numero di telefono, fotografia, registrazione audio o audiovisiva;</p>
    <p>ledere i diritti di proprietà intellettuale di terzi, in particolare rimuovendo o cancellando ogni menzione di diritto d'autore o marchio, nonché ogni didascalia restrittiva.</p>
    <p>L'Utente si impegna inoltre a rispettare i diritti altrui, in particolare:</p>
    <p> </p>
    <p>i diritti della personalità (come il diritto all'immagine, il diritto alla privacy);</p>
    <p>i diritti di marchio;</p>
    <p>i diritti d'autore (in particolare sui software, i suoni, le immagini, le fotografie, i testi, le immagini animate) e i diritti connessi (artisti interpreti o esecutori, produttori di fonogrammi e videogrammi e diritti sui generis dei produttori di basi di dati);</p>
    <p>in generale, i diritti delle persone e dei beni.</p>
    <p> </p>

    <p>L'Utente si impegna inoltre a non utilizzare la Piattaforma per scopi promozionali e, in generale, a non proporre prodotti e servizi che lo remunerino direttamente o indirettamente;</p>
    <p>a non creare file di archivio dai contenuti della Piattaforma;</p>
    <p>a non riprodurre, rappresentare, utilizzare o fare riferimento (in particolare nei meta-tag dei motori di ricerca), in tutto o in parte, ai contenuti, marchi, loghi, segni distintivi presenti sulla Piattaforma.</p>
    <p></p>
    <p>La mancata osservanza delle presenti CGU può comportare la cancellazione immediata e automatica dei contenuti incriminati, un avvertimento e/o la chiusura dell'Account dell'Utente, senza pregiudizio per eventuali altre azioni legali e/o richieste di risarcimento che CapTarget Archery potrebbe intraprendere o avanzare.</p>
    <p></p>
    <p>CapTarget Archery non è responsabile delle informazioni, file, video, foto, documenti o di qualsiasi altro contenuto presente sulla Piattaforma, incluso, ma non limitato a, qualsiasi contenuto illecito, errori od omissioni, o qualsiasi perdita o danno derivante dall'uso di tale materiale da parte di qualsiasi Utente.</p>
    <p></p>
    <p>Ogni collegamento ipertestuale a pagine secondarie del Sito è strettamente vietato, salvo espressa autorizzazione da parte di CapTarget Archery ottenuta dall'Utente. Inoltre, qualsiasi collegamento deve essere rimosso dall'Utente su richiesta di CapTarget Archery.</p>
    <p></p>
    <h4>Ruolo di moderazione dell'Amministratore</h4>
    <p></p>
    <p>L'Amministratore, in qualità di coordinatore e supervisore del Gruppo, si impegna a far rispettare dai Membri i termini delle CGU. Appena ne viene a conoscenza, si impegna a segnalare o eliminare il contenuto (se possibile) che viola la legge e/o l'ordine pubblico e/o le presenti CGU, in particolare qualsiasi contenuto violento, pornografico, razzista, antisemita, xenofobo, lesivo del rispetto della persona umana o della sua dignità, lesivo della vita privata, lesivo dei diritti d'autore, dei diritti connessi, dei diritti delle marche, dei diritti della personalità di terzi.</p>
    <p></p>
    <p>In caso di mancato rispetto di queste regole e usi, CapTarget Archery si riserva il diritto di escludere l'Amministratore e/o il Membro colpevole e di intraprendere qualsiasi azione legale contro l'autore o gli autori, che potrebbe porre fine al disturbo manifestamente illecito derivante dall'uso improprio della Piattaforma.</p>
    <p></p>
    <h4>Invio di e-mail, notifiche push e SMS</h4>
    <p></p>
    <p>Nell'ambito dei servizi offerti, CapTarget Archery offre comunicazioni tramite e-mail, notifiche push sull'Applicazione e SMS per comunicare tra i Membri di un'Organizzazione, con l'unico scopo di scambiare informazioni relative all'Organizzazione, utilizzando i numeri di telefono cellulare e gli indirizzi e-mail forniti dai Membri durante la creazione del loro Account.</p>
    <p></p>
    <p>L'Utente può decidere in qualsiasi momento di non ricevere più e-mail, notifiche push o SMS da parte di CapTarget Archery modificando le impostazioni dei propri dispositivi o facendone richiesta al supporto.</p>
    <p></p>
    <h4>Accesso alla Piattaforma - Responsabilità e impegni</h4>
    <p></p>
    <p>CapTarget Archery fa del suo meglio per rendere la Piattaforma disponibile 24 ore su 24, 7 giorni su 7, ad eccezione delle operazioni di manutenzione programmate e fatte salve le disposizioni del presente articolo.</p>
    <p></p>
    <p>CapTarget Archery si riserva il diritto di apportare alla Piattaforma tutte le modifiche e i miglioramenti che riterrà necessari in base all'evoluzione delle tecniche. Pertanto, CapTarget Archery si riserva il diritto, senza preavviso né indennizzo, di sospendere temporaneamente l'accesso alla Piattaforma per eseguire operazioni di manutenzione evolutiva o correttiva. L'Utente solleva CapTarget Archery da qualsiasi responsabilità a tale riguardo e rinuncia a qualsiasi reclamo in merito.</p>
    <p></p>
    <p>CapTarget Archery può richiedere che accetti gli aggiornamenti del Servizio e dei giochi di CapTarget Archery che hai installato sul tuo dispositivo o computer. Riconosci e accetti che CapTarget Archery può aggiornare il Servizio e i giochi di CapTarget Archery, notificandoti o senza notificartelo. Potresti dover aggiornare software di terze parti per poter ricevere il Servizio e giocare ai Giochi di CapTarget Archery.</p>
    <p></p>
    <p>CapTarget Archery non fornisce alcuna garanzia, esplicita o implicita, inclusa, ma non solo, la garanzia di qualità e idoneità della Piattaforma a uno scopo particolare.</p>
    <p></p>
    <p>CapTarget Archery non garantisce che il funzionamento continuo e/o la continuità della Piattaforma saranno garantiti in caso di forza maggiore o di circostanze impreviste, come definite all'articolo 1218 del Codice civile francese, in tal caso la responsabilità di CapTarget Archery non sarà impegnata.</p>
    <p></p>
    <p>Nel caso in cui la responsabilità di CapTarget Archery sia impegnata dall'Utente per una violazione di uno dei suoi obblighi ai sensi delle presenti CGU, il risarcimento sarà limitato ai danni diretti, personali, certi e prevedibili.</p>
    <p></p>
    <p>CapTarget Archery non garantisce la pertinenza e l'accuratezza delle informazioni pubblicate sulla Piattaforma da terzi.</p>
    <p></p>
    <p>CapTarget Archery non è responsabile di controversie, azioni o reclami di terzi che potrebbero avere diritti, in particolare diritti di proprietà, su qualsiasi contenuto non creato da CapTarget Archery.</p>
    <p></p>
    <p>Le condizioni in cui CapTarget Archery raccoglie e tratta i dati sono dettagliate nella politica sulla privacy dei dati.</p>
    <p></p>
    <p>CapTarget Archery non sarà responsabile nei tuoi confronti per alcun danno indiretto, accessorio, consequenziale o altro danno simile e, in particolare, per alcuna perdita di entrate, alcuna perdita di profitti, alcuna perdita di dati o perdita di attività o altro danno non materiale (qualsiasi sia la qualificazione attribuita a tali perdite), relativo in qualche modo alle presenti condizioni di servizio o al servizio stesso, e non sarà tenuta a corrisponderti danni speciali o punitivi, che siano su un fondamento contrattuale, extracontrattuale o altro, e che CapTarget Archery sia stata informata o meno della possibilità di tale danno. La responsabilità di CapTarget Archery nei tuoi confronti non potrà superare l'importo che avrai versato a CapTarget Archery in conformità alle presenti condizioni di servizio nei sei (6) mesi precedenti immediatamente la data in cui formulerai una reclamo per la prima volta. Riconosci e accetti che quando non avrai effettuato nessun pagamento a CapTarget Archery durante tale periodo, il tuo unico rimedio (e la responsabilità esclusiva di CapTarget Archery) per qualsiasi controversia con CapTarget Archery sarà di smettere di utilizzare il servizio e chiudere il tuo account.</p>
    <p>In particolare, nessuna delle disposizioni delle presenti condizioni di servizio potrà compromettere i diritti legali di qualsiasi consumatore o escludere o limitare qualsiasi responsabilità per morte o lesioni personali derivanti da qualsiasi negligenza o frode da parte di CapTarget Archery.</p>
    <p></p>
    <p>Ti impegni a difendere, proteggere e sollevare CapTarget Archery (nonché i suoi sviluppatori, amministratori e creatori di contenuti) da qualsiasi responsabilità per reclami, richieste, danni o altre perdite, inclusi gli onorari dell'avvocato per un importo ragionevole, che terze parti potrebbero avanzare e che potrebbero derivare dal tuo utilizzo del Servizio o da qualsiasi violazione da parte tua delle presenti Condizioni di Servizio. Tuttavia, quanto sopra non si applicherà in caso di violazione dei diritti non imputabili al tuo comportamento intenzionale o negligente.</p>
    <p></p>
    <p>CapTarget Archery non sarà responsabile per alcun ritardo o inadempimento derivante da cause legittimamente al di fuori del suo controllo e, in particolare, per qualsiasi inadempimento delle presenti dovuto a circostanze imprevedibili o cause al di fuori del controllo di CapTarget Archery, come ad esempio catastrofi naturali, guerra, atti di terrorismo, sommosse, embargo, misure da parte delle autorità civili o militari, incendi, inondazioni, incidenti, scioperi o carenze di mezzi di trasporto, carburante, energia, manodopera o materiali.</p>
    <p></p>
    <p>Le indicazioni relative alle attività come descritte nell'applicazione sono indicative; è responsabilità dell'Utente adattare tali indicazioni alla sua salute o chiedere consiglio a un medico. La responsabilità dell'attività sportiva spetta all'Utente; CapTarget Archery non è in alcun modo responsabile per lesioni fisiche o morali derivanti dalla pratica di un'attività con l'aiuto dell'applicazione.</p>
    <p></p>
    <p>L'Utente si impegna a segnalare qualsiasi vulnerabilità di sicurezza e a non utilizzarle per trarne vantaggio per sé o per conto di altre persone/entità. L'Utente si impegna a non intraprendere azioni che possano danneggiare il corretto funzionamento dei servizi offerti da CapTarget Archery e a non sfruttare eventuali errori.</p>
    <p></p>
    <h4>Proprietà intellettuale</h4>
    <p></p>
    <p>I servizi e i loro contenuti (inclusi dati, database, software, fotografie, informazioni, illustrazioni, loghi, marchi, ecc.) che appaiono o sono disponibili sulla Piattaforma sono protetti da tutti i diritti di proprietà intellettuale esistenti, dai diritti dei produttori di database e/o da qualsiasi altro diritto riconosciuto dalla legislazione vigente.</p>
    <p></p>
    <p>Ogni copia, riproduzione, rappresentazione, adattamento, alterazione, modifica o diffusione non autorizzata, totale o parziale, dei servizi e/o dei contenuti della Piattaforma, che tali contenuti appartengano a CapTarget Archery, a un Utente, a un'Organizzazione o a un terzo, è illecita e può comportare la responsabilità penale e civile dell'autore.</p>
    <p></p>
    <p>CapTarget Archery concede all'Utente il diritto di utilizzare la Piattaforma per le proprie esigenze, escluso qualsiasi utilizzo a scopo di lucro.</p>
    <p></p>
    <p>Quando l'Utente carica un contenuto sulla Piattaforma, concede automaticamente a CapTarget Archery una licenza personale e non esclusiva per l'uso di tale contenuto a titolo gratuito.</p>
    <p></p>
    <p>Questa licenza è concessa per tutto il mondo, per tutte le utilizzazioni su tutti i supporti e tramite tutti i processi di diffusione conosciuti o sconosciuti fino ad oggi, e per tutta la durata della protezione legale dei diritti di proprietà intellettuale (comprese rinnovazioni e proroghe):</p>
    <p></p>
    <p>il diritto di riproduzione: il diritto di creare o far creare un numero illimitato di copie del contenuto, in qualsiasi forma o supporto, conosciuto o sconosciuto fino a oggi, in particolare su qualsiasi supporto cartaceo, magnetico, digitale o altro (ad esempio, tutte le brochure, i documenti stampa, i manifesti, le pagine web, i rapporti e qualsiasi supporto di registrazione come CD, DVD, floppy disk, dischi rigidi interni o esterni o qualsiasi altro supporto di registrazione), con qualsiasi processo tecnico conosciuto o sconosciuto fino a oggi;</p>
    <p>il diritto di rappresentazione: il diritto di comunicare, diffondere, far comunicare o far diffondere i contenuti ai membri dell'Organizzazione dell'Utente mediante qualsiasi mezzo, conosciuto o sconosciuto fino a oggi, in particolare tramite qualsiasi rete di telecomunicazioni o qualsiasi mezzo di lettura, scambio di informazioni e comunicazione (Internet, rete terrestre, cavo, satellite, download, trasmissione, ecc.);</p>
    <p>il diritto di tradurre i contenuti in qualsiasi lingua (compresi tutti i linguaggi informatici).</p>
    <p></p>
    <p>L'Utente garantisce a CapTarget Archery che il contenuto caricato sulla Piattaforma non viola alcun diritto di proprietà intellettuale o qualsiasi altro diritto appartenente a terzi.</p>
    <p></p>
    <p>Di conseguenza, l'Utente accetta di difendere CapTarget Archery e di assumersi la responsabilità e/o risarcire CapTarget Archery per qualsiasi danno che potrebbe derivare da qualsiasi azione o reclamo di terzi riguardo ai contenuti postati dall'Utente.</p>
    <p></p>
    <h4>Documenti contrattuali</h4>
    <p></p>
    <p>Le presenti Condizioni d'Utilizzo disciplinano tutte le relazioni commerciali contrattuali o precontrattuali tra CapTarget Archery, l'Utente e/o l'Organizzazione, relative all'uso della Piattaforma e alla sottoscrizione degli Abbonamenti proposti da CapTarget Archery.</p>
    <p></p>
    <p>Le presenti CGU prevalgono su qualsiasi accordo o disposizione contraria tra le parti. Esse annullano e sostituiscono tutte le CGV precedenti che potrebbero avere regolato le relazioni tra le parti.</p>
    <p></p>
    <p>Le presenti CGU costituiscono l'intero documento contrattuale tra le parti.</p>
    <p></p>
    <p>CapTarget Archery si riserva il diritto di modificare, in qualsiasi momento, il contenuto delle CGU. Queste modifiche daranno luogo a una nuova versione che si applicherà automaticamente a tutti i servizi forniti successivamente.</p>
    <p></p>
    <h4>Legge applicabile, Mediazione e Giurisdizione</h4>
    <p></p>
    <p>Le CGU sono soggette alla legge francese.</p>
    <p></p>
    <p>Le parti convengono di cercare una soluzione amichevole prima di intraprendere qualsiasi azione legale. In caso di reclamo che non possa essere risolto in via amichevole tra le parti, l'Utente può ricorrere gratuitamente a una mediazione dei consumatori. Si propone di ricorrere al mediatore seguente: Centre de Médiation et d'Arbitrage de Paris (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p></p>
    <p>L'Utente può inoltre utilizzare la piattaforma di risoluzione delle controversie messa a disposizione dalla Commissione europea all'indirizzo seguente: www.ec.europa.eu/consumers/odr/.</p>
</div>
