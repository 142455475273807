<section id="partners" class="section benifits-area ptb_100 bg-gray">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.partners_section_title' | translate }}</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 text-hover" (click)="goTo('https://www.archerieducentre.fr/')">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon mx-auto mb-2">
                        <img src="assets/img/partenaires/archerie-du-centre.png" style="width: 200px; object-fit: contain" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.partner_1_name' | translate }}</h3>
                        <p>{{ 'TRANSLATE.partner_1_description' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 text-hover" (click)="goTo('https://hartemis.com/')">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon mx-auto mb-2">
                        <img src="assets/img/partenaires/hartemis.png" style="width: 200px; object-fit: contain" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.partner_2_name' | translate }}</h3>
                        <p>{{ 'TRANSLATE.partner_2_description' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
