<section class="section service-area bg-gray overflow-hidden ptb_100" id="coachs">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">Version coach</h2>
                    <p >La version coach est en cours de développement et sera bientôt disponible. Participez à son élaboration en proposant vos fonctionnalités !</p>
                    <p style="margin-bottom: 15px">Un site puis une application à part entière seront dédiés à la version coach.</p>
                    <!-- Service List -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><img src="./assets/img/icons/graph.svg" alt="" style="padding-bottom: 8px; padding-inline: 10px; object-fit: contain"></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Statistiques avancées, comparaison des archers, métriques d'évolution, suivi général et individuel ...</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><img src="./assets/img/icons/counted.svg" alt="" style="padding-bottom: 8px; padding-inline: 10px; object-fit: contain"></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Planifiez les séances de vos archers en leur fixant des objectifs !</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><img src="./assets/img/icons/blueExercice.svg" alt="" style="padding-bottom: 8px; padding-inline: 10px; object-fit: contain"></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Proposez des exercices en lignes adaptés pour travailler des points spécifiques ! </p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-icon pr-4">
                                <span><img src="./assets/img/icons/mess.svg" alt="" style="padding-bottom: 8px; padding-inline: 10px; object-fit: contain"></span>
                            </div>
                            <div class="service-text media-body">
                                <p>Echangez avec vos archers ou vos groupes grâce au chat intégré, chiffré de bout en bout !</p>
                            </div>
                        </li>
                    </ul>
                    <a href="#" class="btn btn-bordered mt-4" (click)="openGoogleForm()">Participer au développement</a>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2" *ngIf="displayCoachScreen()">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
                    <!-- Thumb Top -->
                    <div class="thumb-top single-screenshot" data-aos="zoom-in" data-aos-offset="300" data-aos-duration="700">
                        <img src="assets/img/screens/coach.svg" alt="">
                        <div class="screenshots-overlay">
                            <a href="assets/img/screens/coach.svg" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
<!--                    &lt;!&ndash; Thumb Certer &ndash;&gt;-->
<!--                    <div class="thumb-center">-->
<!--                        <img src="assets/img/service_thumb.png" alt="">-->
<!--                    </div>-->
<!--                    &lt;!&ndash; Thumb Bottom &ndash;&gt;-->
<!--                    <div class="thumb-bottom" data-aos="fade-left" data-aos-duration="500">-->
<!--                        <img src="assets/img/service_thumb_bottom.png" alt="">-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</section>