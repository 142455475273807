import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ThemeSixComponent } from './themes/theme-six/theme-six.component';
import { FaqPageComponent } from './components/inner-pages/faq-page/faq-page.component';
import {CguComponent} from './components/cgu/cgu.component';
import {ConfidencialityComponent} from './components/confidenciality/confidenciality.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {SuccessPaymentComponent} from './components/success-payment/success-payment.component';
import { AddsComponent } from './components/adds/adds.component';


const routes: Routes = [
  {path: '', component: ThemeSixComponent},
  {path: 'cgu', component: CguComponent},
  {path: 'confidentiality', component: ConfidencialityComponent},
  {path: 'confidentiality', component: ConfidencialityComponent},
  {path: 'redirect', component: ThemeSixComponent},
  {path: 'success', component: SuccessPaymentComponent},
  {path: 'success-payment', component: SuccessPaymentComponent},
  {path: 'adds', component: AddsComponent},
  {path: 'faq', component: FaqPageComponent},
  {path: '', component: FaqPageComponent},
  { path: '**', redirectTo: ''},
  // {path: 'login', component: LoginComponent},
  // {path: 'signup', component: SignupComponent},
  // {path: 'download', component: DownloadPageComponent},
  // {path: 'thank-you', component: ThankYouComponent},
  // {path: 'forgot', component: ForgotComponent},
  // {path: 'newsletter', component: NewsletterComponent},
  // {path: 'error-one', component: ErrorOneComponent},
  // {path: 'error-two', component: ErrorTwoComponent},
  // {path: 'contact', component: ContactPageComponent},
  // {path: 'maintenance', component: MaintenanceComponent},
  // {path: 'coming-soon', component: ComingSoonComponent},
  // {path: 'blog-two-column', component: BlogTwoColumnComponent},
  // {path: 'blog-three-column', component: BlogThreeColumnComponent},
  // {path: 'blog-left-sidebar', component: BlogLeftSidebarComponent},
  // {path: 'blog-right-sidebar', component: BlogRightSidebarComponent},
  // {path: 'blog-details-left-sidebar', component: BlogDetailsLeftSidebarComponent},
  // {path: 'blog-details-right-sidebar', component: BlogDetailsRightSidebarComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
