import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions-material',
  templateUrl: './questions-material.component.html',
  styleUrls: ['./questions-material.component.css']
})
export class QuestionsMaterialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
