<div>
    <div class="inner-wrapper d-flex flex-column p-4">
        <a class="align-self-center" href="/">
            <img src="assets/img/logo.svg" alt="CapTarget Archery">
        </a>
        <!-- ***** Login Area Start ***** -->
        <div class="login-area py-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6 inner-content text-center">
                        <h2 class="text-primary fw-4">Sign Up</h2>
                        <p class="pt-3 pb-5">Already have an account? <a class="color-primary" href="/login">Login now</a></p>
                        <form class="inner-form text-left">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Enter Username</label>
                                <input type="text" class="form-control" placeholder="Enter Username">
                            </div>
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address">
                            </div>
                            <button type="submit" class="btn btn-primary btn-lg btn-block">Sign Up</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Login Area End ***** -->
        <div class="footer-bottom align-self-center">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>