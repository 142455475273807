<div class="row" style="margin-bottom: 150px">
    <div class="container">
        <h4>Préambule</h4>
        <p>Nous, CapTarget Archery, avons créé cette politique de confidentialité pour vous partager la façon
            dont les informations que vous nous fournissez sont utilisées et partagées. Cette politique de
            confidentialité concerne la collecte d'informations et les pratiques d'utilisation de CapTarget
            Archery en relation avec nos services, qui sont mis à votre disposition par le biais de nos
            plateformes. En visitant notre site Web ou notre application mobile et / ou en utilisant les
            services associés, vous acceptez les termes de la présente politique de confidentialité et les
            conditions d'utilisation qui l'accompagnent. Cette page a été mise à jour le 28/10/2022.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Les informations que nous recueillons</h4>
        <p></p>
        <p class="subtitle-1">1. Informations personnelles</p>
        <p></p>
        <p>Lorsque vous vous inscrivez pour devenir un utilisateur enregistré, que vous participez à une
            promotion ou un concours, ou que vous vous inscrivez à notre newsletter ou à d'autres publications,
            vous devrez nous fournir des informations personnelles vous concernant, qui peuvent inclure votre
            nom, prénom, votre adresse e-mail et votre numéro de téléphone. Toutes les informations collectées
            conformément à la présente section sont collectivement désignées par le terme "Informations
            personnelles". Nous ne recueillons ni n'accédons à aucune information personnelle vous concernant
            lorsque vous utilisez les Services, à moins que vous ne nous fournissiez volontairement les
            informations personnelles ou que vous nous autorisiez à y accéder.</p>
        <p></p>
        <p></p>
        <p></p>
        <p class="subtitle-1">2. Autres informations</p>
        <p></p>
        <p>Nous pouvons recueillir des informations supplémentaires. Ces autres informations peuvent inclure
            :</p>
        <p></p>
        <p class="subtitle-2">a. Des informations supplémentaires vous concernant que vous nous fournissez
            volontairement, telles que les préférences en matière de produits et de services ; votre catégorie
            de sexe, votre catégorie d’âge, votre numéro de licence, identifiant mondial, rôle (utilisateur,
            coach) et le type d’arc utilisé.</p>
        <p></p>
        <p class="subtitle-2">b. À partir de votre activité. Les informations que nous recueillons
            automatiquement lorsque vous utilisez les Services, y compris, sans limitation : Les adresses IP, le
            type et la langue du navigateur, les pages et URL de référence et de sortie, la date et l'heure, les
            sections du site Web visitées, etc. ; Les informations relatives à un appareil mobile, notamment
            l'identifiant unique universel (" UUID "), le type et la version de la plateforme (par ex, iOS ou
            Android), l'opérateur et la localisation du pays, les informations sur le matériel et le processeur
            (stockage, vitesse de la puce, résolution de l'appareil photo, NFC activé et type de réseau (WiFi,
            2G, 3G, 4G) ; et les informations sur l'activité et l'utilisation survenant via la Plateforme, y
            compris les données de marquage, les favoris, les réponses aux enquêtes, les préférences, la durée
            des sessions ; et les données similaires.</p>
        <p></p>
        <p class="subtitle-2">c. Les données de service. Cela concerne toutes les données que vous rentrez
            volontairement et relative à votre pratique du sport, votre matériel, l’utilisation des médias
            intégrés. (Exemples : Séances, tirs comptés, matériel, images envoyées dans la messagerie intégrée,
            lieu de compétition …)</p>
        <p></p>
        <p class="subtitle-2">d. Les données liées aux payements. Pour tout achat en ligne sur nos applications
            ou sites web, nous ne sauvegardons pas les données de payements. Pour plus de sécurité, ces
            dernières sont transmises et gérées directement par nos prestataires de paiement : Apple, Google et
            Paypal.</p>
        <p></p>
        <p class="subtitle-2">e. À partir de cookies. Les informations que nous recueillons en utilisant la
            technologie des "cookies". Les cookies sont de petits paquets de données qu'un site web stocke sur
            le disque dur de votre ordinateur ou de votre appareil mobile afin que votre ordinateur "se
            souvienne" des informations relatives à votre visite. Nous pouvons utiliser à la fois des cookies de
            session (qui expirent lorsque vous fermez votre navigateur web) et des cookies persistants (qui
            restent sur votre ordinateur jusqu'à ce que vous les supprimiez) pour nous aider à collecter
            d'autres informations et pour améliorer votre expérience d'utilisation de la plate-forme. Si vous ne
            souhaitez pas que nous placions un cookie sur votre disque dur, vous pouvez désactiver cette
            fonction sur votre ordinateur ou votre appareil mobile. Veuillez consulter la documentation de votre
            navigateur Internet pour savoir comment procéder et comment supprimer les cookies persistants.
            Toutefois, si vous décidez de ne pas accepter nos cookies, la Plateforme peut ne pas fonctionner
            correctement.</p>
        <p></p>
        <p class="subtitle-2">f. D'autres sources. Les informations que nous collectons ou recevons de médias
            sociaux tiers sur lesquels vous avez utilisé les Services, conformément à leurs conditions
            d'utilisation et politiques de confidentialité.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Où sont stockées les données</h4>
        <p></p>
        <p></p>
        <p></p>
        <p>La plateforme CapTarget Archery est actuellement hébergée en Europe chez Firebase, chez Google.</p>
        <p></p>
        <p></p>
        <p></p>
        <p>L'accès et la modification des informations personnelles et des préférences de communication</p>
        <p></p>
        <p></p>
        <p></p>
        <p>Si vous vous êtes inscrit sur la plate-forme, vous pouvez accéder à vos informations personnelles et
            à certaines autres informations, les consulter et les modifier en réalisant une demande auprès du
            support dédié. En outre, vous pouvez gérer votre réception de communications marketing et non
            transactionnelles en cliquant sur le lien "désabonnement" situé au bas de tout e-mail marketing
            CapTarget Archery. Nous déploierons des efforts commercialement raisonnables pour traiter ces
            demandes dans un délai raisonnable.</p>
        <p></p>
        <p></p>
        <p></p>
        <p>À moins qu'il ne soit marqué "privé" par la fonctionnalité de la plate-forme, toute donnée et/ou tout
            contenu que vous publiez sur la plate-forme sera publiquement consultable sur la plate-forme et
            partageable par les autres utilisateurs. De même, tout ce que vous publiez sur un compte social via
            la plate-forme sera consultable et accessible sur ces comptes sociaux comme si vous l'aviez publié
            directement sur le compte social. Avec votre autorisation, nous pouvons partager vos Informations
            personnelles et certaines Autres informations avec d'autres Utilisateurs enregistrés de votre réseau
            personnel que vous désignez comme ayant le droit de consulter ces informations lorsque vous
            rejoignez un groupe ou vous associez à un coach. Vous pouvez modifier ces désignations à tout moment
            en suivant les instructions de la Plateforme. Dans un effort continu pour mieux comprendre nos
            utilisateurs et notre Plateforme et nos Services, nous pouvons analyser les Autres informations sous
            forme agrégée afin d'exploiter, de maintenir, de gérer et d'améliorer les Services et/ou la
            Plateforme. Ces informations globales ne vous identifient pas personnellement. Nous pouvons partager
            ces données agrégées avec nos sociétés affiliées, nos agents et nos partenaires commerciaux. Nous
            pouvons également divulguer des statistiques agrégées sur les utilisateurs afin de décrire nos
            produits, notre plate-forme et nos services à nos partenaires commerciaux actuels et potentiels et à
            d'autres tiers à d'autres fins légales. Nous pouvons employer d'autres sociétés et individus pour
            remplir des fonctions en notre nom. Il peut s'agir, par exemple, de fournir une assistance
            marketing, une assistance informatique et un service clientèle. Ces autres sociétés n'auront accès
            aux informations que dans la mesure nécessaire à l'exécution de leurs fonctions et dans les limites
            autorisées par la loi. Avec votre autorisation, des applications ou services tiers peuvent accéder à
            vos Informations personnelles. Nous utilisons la norme OAuth (autorisation ouverte) pour vous
            permettre de donner l'autorisation de partager vos Informations personnelles avec d'autres sites Web
            et services (par exemple, lorsque vous acceptez un pop-up vous demandant d'autoriser une autre
            application à accéder aux informations de votre compte). Nous utilisons également OAuth pour nous
            permettre de partager des informations vous concernant qui sont stockées par nous sans partager vos
            informations d'identification de sécurité. Au fur et à mesure que nous développons nos activités,
            nous pouvons vendre ou acheter des entreprises ou des actifs. En cas de vente d'entreprise, de
            fusion, de réorganisation, de vente d'actifs, de dissolution ou d'événement similaire, les
            Informations peuvent faire partie des actifs transférés. Dans la mesure où la loi le permet, nous
            pouvons également divulguer des informations lorsque la loi, une ordonnance du tribunal, une autre
            autorité gouvernementale ou policière ou un organisme de réglementation l'exigent, ou lorsque nous
            pensons que la divulgation de ces informations est nécessaire ou souhaitable, par exemple pour
            protéger les droits, la propriété ou la sécurité de CapTarget Archery ou d'autres personnes.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Comment nous protégeons vos informations</h4>
        <p></p>
        <p>Nous prenons des mesures commercialement raisonnables pour protéger les informations contre la perte,
            l'utilisation abusive, l'accès non autorisé, la divulgation, l'altération ou la destruction.
            Veuillez comprendre, cependant, qu'aucun système de sécurité n'est impénétrable. Nous ne pouvons pas
            garantir la sécurité de nos bases de données, ni garantir que les informations que vous fournissez
            ne seront pas interceptées lors de leur transmission vers et à partir de nous sur Internet. En
            particulier, les courriers électroniques envoyés vers ou depuis la plate-forme peuvent ne pas être
            sécurisés, et vous devez donc faire preuve d'une attention particulière dans le choix des
            informations que vous nous envoyez par courrier électronique.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Protection de certaines informations d'identification personnelle</h4>
        <p></p>
        <p>CapTarget Archery ne divulgue des informations potentiellement identifiantes et personnelles qu'à ses
            employés, contractants et organisations affiliées qui (i) ont besoin de connaître ces informations
            afin de les traiter au nom de CapTarget Archery ou de fournir des services disponibles sur les sites
            Web et applications mobiles de CapTarget Archery, et (ii) qui ont accepté de ne pas les divulguer à
            d'autres. Certains de ces employés, sous-traitants et organisations affiliées peuvent être situés en
            dehors de votre pays d'origine ; en utilisant services de CapTarget Archery, vous consentez au
            transfert de ces informations vers eux. CapTarget Archery ne louera ni ne vendra à quiconque des
            informations potentiellement identifiables et identifiantes sur le plan personnel. À l'exception de
            ses employés, entrepreneurs et organisations affiliées, comme décrit ci-dessus, CapTarget Archery ne
            divulgue des informations potentiellement identifiantes et personnellement identifiables qu'en
            réponse à une assignation, une ordonnance du tribunal ou toute autre demande gouvernementale, ou
            lorsque CapTarget Archery estime en toute bonne foi que la divulgation est raisonnablement
            nécessaire pour protéger la propriété ou les droits de CapTarget Archery, de tiers ou du public en
            général. Si vous êtes un utilisateur enregistré d'un service de CapTarget Archery et que vous avez
            fourni votre adresse e-mail, CapTarget Archery peut occasionnellement vous envoyer un e-mail pour
            vous informer des nouvelles fonctionnalités, solliciter vos commentaires, ou simplement vous tenir
            au courant de ce qui se passe sur CapTarget Archery.</p>
        <p></p>
        <p></p>
        <p></p>
        <p>Nous utilisons principalement nos différents blogs de produits pour communiquer ce type
            d'information, nous espérons donc limiter ce type d'email au minimum. Si vous nous envoyez une
            demande (par exemple via un e-mail d'assistance ou via l'un de nos mécanismes de feedback), nous
            nous réservons le droit de la publier afin de nous aider à clarifier ou à répondre à votre demande
            ou pour nous aider à assister d'autres utilisateurs. CapTarget Archery prend toutes les mesures
            raisonnablement nécessaires pour protéger contre l'accès non autorisé, l'utilisation, l'altération
            ou la destruction des informations potentiellement identifiantes et personnellement
            identifiables.</p>
        <p></p>
        <p></p>
        <p></p>
        <p>Suite aux accords passés avec la Fédération Française de Tir à l'arc, CapTarget
            Archery
            dispose d'un accès temporaire à la base de données nationale et peut l'utiliser
            selon la
            convention
            établie par les deux parties.</p>
        <p>En échange de cet accès, CapTarget Archery s'est engagé à partager des données
            anonymes sur l'utilisation de l'application par ces utilisateurs.</p>
        <p>Ces données concernent le profil des utilisateurs, les fonctionnalités utilisées,
            les statistiques d'utilisations générales comme le trafic d'utilisation par
            exemple.</p>
        <p>Aucune donnée à caractère personnel n'est transmise à la Fédération Française de
            Tir à l'arc.</p>
        <p>Au terme de la convention, certaines fonctionnalités de CapTarget dépendant de
            ces accès peuvent être désactivées sans préavis.</p>
        <p>CapTarget Archery s'engage à n'utiliser ces données que pour ses propres besoins
            internes et dans le but exclusif de développer l'application. Ces données ne
            peuvent être exploitées pour un autre objectif. Elles ne peuvent être cédées,
            revendues ou faire l'objet d'un sous traitement par un tiers.</p>
        <p>Les données à caractères personnel du profil de l'utilisateur ne seront partagées
            sur CapTarget qu'à partir du moment où l'utilisateur accepte le partage des
            dites données.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Enfants</h4>
        <p></p>
        <p>Nous ne recueillons pas sciemment des informations personnelles d'enfants de moins de 13 ans par le
            biais de la plate-forme. Si vous avez moins de 13 ans, veuillez ne pas nous fournir de
            renseignements personnels. Nous encourageons les parents et les tuteurs légaux à surveiller
            l'utilisation d'Internet par leurs enfants et à contribuer à l'application de notre politique de
            confidentialité en demandant à leurs enfants de ne jamais fournir de renseignements personnels par
            l'intermédiaire de la plate-forme sans leur autorisation. Si vous avez des raisons de croire qu'un
            enfant de moins de 13 ans nous a fourni des informations personnelles, veuillez nous contacter et
            nous nous efforcerons de supprimer ces informations de nos bases de données.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Droit de l’utilisateur</h4>
        <p></p>
        <p></p>
        <p></p>
        <p>L’Utilisateur peut exercer ses droits en s’adressant par email à
            support@captarget-archery.com. </p>
        <p></p>
        <p></p>
        <p></p>
        <p>L’Utilisateur peut, en cas de contestation, former une réclamation auprès de la CNIL dont les
            coordonnées figurent à l’adresse internet http://www.cnil.fr.</p>
        <p></p>
        <p></p>
        <p></p>
        <p class="subtitle-1">Droit de retrait du consentement</p>
        <p></p>
        <p>L’Utilisateur peut à tout moment révoquer son consentement à tout traitement de ses données
            personnelles. Cette action se soldera par une fermeture du compte.</p>
        <p></p>
        <p>Droit d’opposition</p>
        <p></p>
        <p>L’Utilisateur peut à tout moment faire opposition au traitement de ses données personnelles. S’il
            exerce ce droit, CapTarget Archery cessera le traitement des données concernées, sauf si CapTarget
            Archery justifie de motifs légitimes et impérieux prévalant sur les droits et libertés fondamentaux
            de l’Utilisateur ou démontre son droit de continuer le traitement en vue de la constatation,
            l’exercice ou la défense de droits en justice. L’Utilisateur peut toutefois s’opposer à tout moment
            au traitement de ses données personnelles aux fins de prospection.</p>
        <p></p>
        <p></p>
        <p></p>
        <p class="subtitle-1">Droit à la portabilité</p>
        <p></p>
        <p>Lorsque cela est applicable, l’Utilisateur peut demander un accès de ses données personnelles dans un
            format structuré.</p>
        <p></p>
        <p>Droits d’accès, de rectification, d'effacement et de limitation du traitement des données à caractère
            personnel</p>
        <p></p>
        <p>Conformément à la réglementation en vigueur, l’Utilisateur dispose des droits suivants :</p>
        <p></p>
        <p>Un droit d’accès, un droit de rectification, un droit d’effacement, un droit de limitation du
            traitement de ses données à caractère personnel, un droit de définir des directives concernant ses
            données personnelles après sa mort.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Modifications de la présente politique de confidentialité</h4>
        <p></p>
        <p>La présente politique de confidentialité entre en vigueur à la date indiquée en haut de la présente
            politique de confidentialité. Nous pouvons modifier cette politique de confidentialité de temps à
            autre. En accédant à la plate-forme et/ou en utilisant les services après que nous ayons apporté de
            telles modifications à la présente politique de confidentialité, vous êtes réputé avoir accepté ces
            modifications. Veuillez noter que, dans la mesure où la loi applicable le permet, notre utilisation
            des informations est régie par la politique de confidentialité en vigueur au moment où nous
            recueillons les informations. Veuillez-vous référer régulièrement à cette politique de
            confidentialité.</p>
        <p></p>
        <p></p>
        <p></p>
        <h4>Comment nous contacter</h4>
        <p></p>
        <p>Si vous avez des questions concernant la présente politique de confidentialité, veuillez nous envoyer
            un courriel à l'adresse support@captarget-archery.com ou suivre les instructions figurant sur la
            plate-forme.</p>
    </div>
</div>
