<section class="section faq-area ptb_100" id="faq">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">{{ 'TRANSLATE.faq_section_title' | translate }}</h2>
                    <p class="d-none d-sm-block mt-4">{{ 'TRANSLATE.faq_description' | translate }} <a (click)="sendMail()" class="text-hover">{{ 'TRANSLATE.contact_spport' | translate }}</a> </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                {{ 'TRANSLATE.faq_1_question' | translate }}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            {{ 'TRANSLATE.faq_1_answer' | translate }}
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                                                {{ 'TRANSLATE.faq_2_question' | translate }}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            {{ 'TRANSLATE.faq_2_answer' | translate }} <a (click)="sendMail()" class="text-hover">{{ 'TRANSLATE.click_here' | translate }}</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
                                                {{ 'TRANSLATE.faq_5_question' | translate }}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            {{ 'TRANSLATE.faq_5_answer' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
                                                {{ 'TRANSLATE.faq_3_question' | translate }}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            {{ 'TRANSLATE.faq_3_answer' | translate }} : <a (click)="sendMail()" class="text-hover">{{ 'TRANSLATE.click_here' | translate }}</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseFive">
                                                {{ 'TRANSLATE.faq_4_question' | translate }}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            {{ 'TRANSLATE.faq_4_answer' | translate }}
                                        </div>
                                        <div class="card-body">
                                            <!-- Social Icons -->
                                            <div class="social-icons d-flex">
                                                <a class="apple" href="https://apps.apple.com/us/app/captarget-archery/id1584542210">
                                                    <i class="fab fa-apple"></i>
                                                    <i class="fab fa-apple"></i>
                                                </a>
                                                <a class="android" href="https://play.google.com/store/apps/details?id=captarget.archery">
                                                    <i class="fab fa-android"></i>
                                                    <i class="fab fa-android"></i>
                                                </a>
                                                <a class="facebook" href="https://www.facebook.com/CapTarget-Archery-105780235174881/videos/282299766893476/">
                                                    <i class="fab fa-facebook-f"></i>
                                                    <i class="fab fa-facebook-f"></i>
                                                </a>
                                                <a class="twitter" [href]="languageService.currentLanguage === 'fr' ? 'https://www.instagram.com/captarget.france/' : 'https://www.instagram.com/captarget.archery/'">
                                                    <i class="fab fa-instagram"></i>
                                                    <i class="fab fa-instagram"></i>
                                                </a>
                                                <a class="google-plus" href="https://www.youtube.com/watch?v=oVsIVZGnt2U&ab_channel=CapTargetArchery">
                                                    <i class="fab fa-youtube"></i>
                                                    <i class="fab fa-youtube"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                {{ 'TRANSLATE.faq_6_question' | translate }}
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            {{ 'TRANSLATE.faq_6_answer' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
