<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
        <a href="/">
            <img src="assets/img/logo.svg" alt="">
        </a>
        <!-- ***** Error Area Start ***** -->
        <div class="error-area style-two">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center mb-5">
                        <h1 class="text-center mb-3">404</h1>
                        <p class="mt-3">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <a class="btn btn-bordered mt-4" href="/">Go to homepage</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Error Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>