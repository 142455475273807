<section id="benifits" class="section benifits-area style-two ptb_100">
    <div class="container">
        <div class="row benifits-content">
            <!-- Benifits Item -->
            <div class="col-6 col-sm-6 col-lg-2 offset-lg-1 p-0 cursor-select" data-aos="fade-up" (click)="openIos()" style="height: 100%">
                <div class="benifits-item text-center p-5"  style="padding-bottom: 30px !important; padding-left: 30px !important; padding-right: 30px !important">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/external/apple.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.apple_title_1' | translate }}</h3>
                        <p>{{ 'TRANSLATE.apple_description_2' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-6 col-sm-6 col-lg-2 p-0 cursor-select" data-aos="fade-up" data-aos-delay="200" (click)="openAndroid()">
                <div class="benifits-item text-center p-5"  style="padding-bottom: 30px !important; padding-left: 30px !important; padding-right: 30px !important">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/external/android.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.android_title_3' | translate }}</h3>
                        <p>{{ 'TRANSLATE.android_description_4' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-6 col-sm-6 col-lg-2 p-0 cursor-select" data-aos="fade-up" data-aos-delay="400" (click)="openInstagram()">
                <div class="benifits-item text-center p-5"  style="padding-bottom: 30px !important; padding-left: 30px !important; padding-right: 30px !important">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/external/insta.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.instagram_title_5' | translate }}</h3>
                        <p>{{ 'TRANSLATE.instagram_description_6' | translate }}</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-6 col-sm-6 col-lg-2 p-0 cursor-select" data-aos="fade-up" data-aos-delay="600" (click)="openFacebook()">
                <div class="benifits-item text-center p-5"  style="padding-bottom: 30px !important; padding-left: 30px !important; padding-right: 30px !important">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/external/facebook.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">{{ 'TRANSLATE.facebook_title_7' | translate }}</h3>
                        <p>{{ 'TRANSLATE.facebook_description_8' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-2 p-0 offset-3 offset-sm-3 offset-md-0  cursor-select" data-aos="fade-up" data-aos-delay="800"
                 *ngIf="languageService.currentLanguage === 'fr'" (click)="openForm()">
                <div class="benifits-item text-center p-5" style="padding-bottom: 30px !important; padding-left: 30px !important; padding-right: 30px !important">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/external/captarget.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text text-center">
                        <h3 class="mb-2">{{ 'TRANSLATE.participe_title_9' | translate }}</h3>
                        <p>{{ 'TRANSLATE.participe_description_10' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
