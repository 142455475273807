import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-content-five',
    templateUrl: './content-five.component.html',
    styleUrls: ['./content-five.component.css']
})
export class ContentFiveComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    openGoogleForm(): void {
        window.open('https://docs.google.com/forms/d/1IJ0J-uUcZ4FXCNftjpbOUIQTXhski1IpUn4bXAHAC1Y/edit#responses', '_system');
    }

    displayCoachScreen(): boolean {
        return window.outerWidth >= 1010;
    }
}
