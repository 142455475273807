<div class="container">
    <h4>Määratlused</h4>
    <p> </p>
    <p>Allpool toodud terminid, mille esimene täht on Kasutustingimustes suurtäht, omavad käesoleva artikli kohaselt neile antud tähendust.</p>
    <p> </p>
    <p>Liitumine: viitab igasugusele tasuta või tasulisele tellimusele platvormi teenustele.</p>
    <p> </p>
    <p>Vibulaskja: vibulaskmisega tegelev Kasutaja, kes kasutab või ei kasuta rakenduse teenuseid.</p>
    <p> </p>
    <p>Treener: vibulaskmise treener, kes on tellinud tasuta või tasulise "Treener" tüüpi liikmelisuse ning omab juurdepääsu sellega seotud teenustele.</p>
    <p> </p>
    <p>Rakendus: viitab mobiilirakendusele "CapTarget Archery", mis on saadaval Androidi ja iOS-i platvormidel.</p>
    <p> </p>
    <p>Leht: viitab CapTarget Archery veebilehele.</p>
    <p> </p>
    <p>Leht treener: viitab lehele, millele on juurdepääs ainult treeneritel.</p>
    <p> </p>
    <p>Kasutustingimused: viitab platvormi üldtingimustele.</p>
    <p> </p>
    <p>Konto: viitab Kasutajale antud kontole, mis annab juurdepääsu platvormile ja erinevatele teenustele, millele on seotud isiklik identifikaator ja parool.</p>
    <p> </p>
    <p>Grupp: viitab rakenduses CapTarget Archery loodud grupile, mis koondab Kasutajaid ja treenereid.</p>
    <p> </p>
    <p>Partner: viitab füüsilisele või juriidilisele isikule, kes on sõlminud partnerluse CapTarget Archeryga ning kes on platvormi kaudu nähtav Kasutajatele.</p>
    <p> </p>
    <p>Platvorm: viitab kõikidele teenustele, mis on kättesaadavad Veebilehtedel ja/või Rakenduses.</p>
    <p> </p>
    <p>Kasutaja: viitab füüsilisele või juriidilisele isikule, kellel on juurdepääs platvormile ning kes seda kasutab, olgu see siis Konto kaudu või mitte.</p>
    <p> </p>
    <p>Pärlid: viitab virtuaalsele valuutale, mida kasutatakse CapTarget Archerys.</p>
    <p> </p>
    <h4>Eesmärk</h4>
    <p> </p>
    <p>Kasutustingimuste eesmärk on määratleda tingimused, mis kehtivad kõikidele Platvormi Kasutajatele.</p>
    <p> </p>
    <p>Iga registreerimine, juurdepääs ja/või Platvormi kasutamine eeldab Kasutajalt tingimusteta nõustumist ja kõigi Kasutustingimuste järgimist.</p>
    <p> </p>
    <p>Iga alaealine Kasutaja deklareerib ja tunnistab, et tal on vanemate või vanemliku võimu omanike luba registreeruda Platvormile.</p>
    <p> </p>
    <p>Iga automaatne roboti loodud registreerimistaotlus või muu meetodiga loodud taotlus lükatakse tagasi.</p>
    <p> </p>
    <h4>Rakenduse tutvustus</h4>
    <p> </p>
    <p>Rakendus on peamiselt pühendatud vibulaskjatele või treeneritele ning võimaldab eriti spordiga seotud tegevuste haldamist ja korraldamist, teabe edastamist rühma liikmete vahel või valdkonna uudiste kohta.</p>
    <p> </p>
    <p>Rakenduse kaudu saavad Kasutajad eriti järgmisi funktsioone:</p>
    <p> </p>
    <p>Treeningute ja võistluste haldamine</p>
    <p>Arvestatud laske ja matšide haldamine</p>
    <p>Laskmise varustuse haldamine</p>
    <p>Andmete kasutamine statistika esitamiseks</p>
    <p>Sotsiaalsed ja mängulised suhted, Kasutaja treeneritele andmete kättesaadavus</p>
    <p>Sotsiaalsed ja mängulised suhted rühma liikmete vahel</p>
    <p>Isikupärastamiseks mõeldud esemete ostmiseks virtuaalne valuuta "Pärl"</p>
    <p>"Pärlte" ostmiseks läbi mobiilse maksesüsteemi (ANDROID või APPLE)</p>
    <p>Sõnumside</p>
    <p> </p>
    <h4>Kasutaja registreerimine</h4>
    <p> </p>
    <p>Igal Kasutajal on võimalus luua Konto (isiklikult või alaealise Membri konto juhul, kui Kasutaja omab vanemlikku võimu), mis annab juurdepääsu rakendusele ja selle teenustele.</p>
    <p> </p>
    <p>Registreerimine toimub Kasutaja poolt Platvormil, alustades Rakendusest. Selleks tuleb täita erinevad registreerimisvormi väljad, sealhulgas kehtiv e-posti aadress, nimi ja eesnimi ning erinevad andmed tema vibulaskmise praktika kohta. Tuleb tutvuda ja nõustuda Kasutustingimuste ja Andmekaitse poliitikaga.</p>
    <p> </p>
    <p>Registreerimisvormi täites kohustub Kasutaja esitama ainult täpset, ajakohast ja täielikku teavet.</p>
    <p> </p>
    <p>Kasutaja kohustub hoidma salajas oma Konto parooli. Parooli volitamatu kasutamise korral teavitab Kasutaja sellest viivitamatult CapTarget Archeryt.</p>
    <p> </p>
    <p>Kasutaja vastutab ainuisikuliselt oma Konto kasutamise eest. Iga ühendus või andmete edastamine Platvormi kaudu loetakse tehtuks Kasutaja poolt ja tema ainuisikulise vastutusega.</p>
    <p> </p>
    <p>CapTarget Archery ei vastuta, kui Kasutaja registreerimisandmed ei jõua CapTarget Archeryni põhjusel, mis ei ole temale süüks pandav (näiteks Kasutaja internetiühenduse probleem, ajutine rikkepäev tema serverites jne) või kui andmed jõuavad CapTarget Archeryni töödeldavas vormis (näiteks kui Kasutajal on sobimatu riistvara või tarkvara).</p>
    <p> </p>
    <p>Iga registreerimine kehtib määramata ajaks ja Kasutaja Konto jääb aktiivseks kuni selle sulgemiseni.</p>
    <p> </p>
    <p> </p>
    <h4>Rühma registreerimine</h4>
    <p> </p>
    <p>Grupi funktsioonidele juurdepääsemiseks peab Kasutaja looma või liituma Grupiga.</p>
    <p> </p>
    <p>Registreerimisvormi täites kohustub administraator esitama ainult täpset, ajakohast ja täielikku teavet. Ta tagab, et CapTarget Archery on saanud eelneva nõusoleku nendelt Liikmetelt, kelle e-posti aadresse ja telefoninumbreid ta edastab, ning need Liikmed võivad igal ajal nõuda nende eemaldamist CapTarget Archeryst (vt meie Andmekaitse poliitika).</p>
    <p> </p>
    <p>Administraatorid vastutavad ainuisikuliselt Grupi kasutamise eest.</p>
    <p>Samuti on täpsustatud, et Administraator vastutab täielikult ja ainuisikuliselt Organisatsiooni Ruumi kasutamise eest nii iseenda kui ka igasuguse kolmanda osapoole poolt.</p>
    <p> </p>
    <p>CapTarget Archery ei vastuta, kui Organisatsiooni registreerimisandmed ei jõua CapTarget Archeryni põhjusel, mis ei ole CapTarget Archeryle süüks pandav (näiteks administraatori poolt põhjustatud internetiühenduse probleem, ajutine rikkepäev tema serverites jne) või kui andmed jõuavad CapTarget Archeryni töödeldavas vormis (näiteks kui Administraatoril on sobimatu riistvara või registreerimistarkvara).</p>
    <p> </p>
    <p>Iga registreerimine kehtib määramata ajaks, Grupp jääb aktiivseks kuni selle ühe administraatori sulgemiseni.</p>
    <p> </p>
    <p> </p>
    <h4>Treeneri registreerimine</h4>
    <p> </p>
    <p>Treeneri funktsioonidele juurdepääsemiseks peab Kasutaja tellima liikmelisuse, mis võimaldab juurdepääsu CapTarget Archery treenerite teenustele veebisaidi ja/või mobiilirakenduse kaudu vastavalt tellitud pakkumisele.</p>
    <p>Liikmelisuse litsents võib talle saada kolmandalt osapoolelt, kes on tellinud liikmelisuse tema eest. Litsentsi kasutamisõigus on rangelt ainult kasutajal, kes selle litsentsiga registreerus. Konto ei saa jagada teiste inimestega.</p>
    <p>Eri pakkumuste eelised võivad muutuda. Kui liikmelisus on käimas, ei rakendu muudatused või neid tuleb kasutaja kasuks arvestada.</p>
    <p>Registreerimine toimub Kasutaja poolt veebisaidil. Selleks tuleb täita registreerimisvormi erinevad väljad, sealhulgas kehtiv e-posti aadress, nimi ja eesnimi ning erinevad andmed tema vibulaskmise praktika kohta. Tuleb tutvuda ja nõustuda Kasutustingimuste, Teenuse tingimuste ja Andmekaitse poliitikaga.</p>
    <p>Registreerimisvormi täites kohustub treener ja litsentsi tellija esitama ainult täpset, ajakohast ja täielikku teavet.
        Kasutaja, kes kasutab oma litsentsi, vastutab ainuisikuliselt oma Konto kasutamise eest. Iga ühendus või andmete edastamine Platvormi kaudu loetakse tehtuks Kasutaja poolt ja tema ainuisikulise vastutusega.</p>
    <p>CapTarget Archery ei vastuta, kui Kasutaja registreerimisandmed ei jõua CapTarget Archeryni põhjusel, mis ei ole temale süüks pandav (näiteks Kasutaja internetiühenduse probleem, ajutine rikkepäev tema serverites jne) või kui andmed jõuavad CapTarget Archeryni töödeldavas vormis (näiteks kui Kasutajal on sobimatu riistvara või tarkvara).</p>
    <p>Iga tasuline liikmelisuse tellimus on tehtud määramata ajaks, välja arvatud juhul, kui kasutaja esitab selgesõnalise soovi, leping uuendatakse automaatselt pärast miinimumtähtaja lõppu. Palume tutvuda Teenuse tingimustega seoses lahkumise tingimustega.</p>
    <p> </p>

    <h4>Andmete Jagamine</h4>
    <p>CapTarget Archery kaudu andmete jagamise funktsioonidele juurdepääsuks pakub meie rakendus fännide ja jälgimise süsteemi. Teil, kui kasutajal, on võimalus aktsepteerida kutseid või kutsuda teisi kasutajaid oma võrgustikku liituma.</p>
    <p>Avaliku profiili korral pole teistelt kasutajatelt jälgimiseks nõutav heakskiit. Kui kasutaja hakkab teid jälgima, võivad mõned teie andmed automaatselt või käsitsi jagatud saada, sealhulgas:</p>
    <p>- Teie võistluste või treeningute tulemused.</p>
    <p>- Erinevad spordistatistikad, nagu teie litsentsi kestus, parim tulemus või ranking.</p>
    <p>- Andmed teie rakenduse kasutamise kohta, nagu teie lemmikmängud ja -harjutused.</p>
    <p>- Teie klubi, keel, riik ja muud andmed, nagu teie ees- ja perekonnanimi, kasutatava vibu tüüp või laskekategooria.</p>
    <p>Teil on võimalus otsustada, milliseid neist andmetest te ei soovi jagada, muutes oma isiklikke seadeid või olukorra seadeid.</p>
    <p>Konto loomisel nõustute olema nähtav CapTargeti platvormil. Teid võivad teised kasutajad otsida teie ees- ja perekonnanime kasutades. Teie profiil võib samuti automaatselt soovitada teistele kasutajatele eesmärgiga luua spordiväljakutseid või jälgimisühendusi.</p>
    <p>Palun märkige, et võite olla kokkupuutes CapTarget Archery kaudu CapTargeti, selle partnerite, reklaamijate või teiste kasutajate jagatud teabega. Julgustame teil tutvuma meie privaatsuspõhimõtetega lisateabe saamiseks oma isiklike andmete haldamise kohta.</p>
    <p>Tahame rõhutada, et kõik need tingimused vastavad kehtivatele isikuandmete kaitse ja privaatsuse seadustele ja määrustele. Kui teil on täiendavaid küsimusi või muresid, ärge kõhelge meiega ühendust võtmast.</p>

    <h4>Hoiatus</h4>
    <p>Juurdepääs CapTarget Archery treeneriversioonidele nõuab arvuti, tahvelarvuti või telefoni kasutamist veebiversiooni jaoks ning Androidi või iOSi telefoni või tahvelarvutit mobiiliversiooni jaoks. Internetiühendus on vajalik vajalike andmete allalaadimiseks. Kasutaja kinnitab selle vajaduse teadmist teenuste kasutamiseks.</p>
    <p>Teenustes pakutavad harjutused ja erinevad olukorrad eeldavad, et kasutajad on heas füüsilises vormis ja võimelised neid läbi viima. Tellija kohustub võtma vajalikud ettevaatusabinõud tegevuse turvaliseks praktiseerimiseks, hoides nii oma tervist kui ka teiste inimeste turvalisust.</p>

    <h4>Müügitingimused</h4>
    <p>Tellimuse vormistamisel või ühe CapTarget Archery poolt kättesaadavaks tehtud digitaalse teenuse või toote ostmisel nõustute allpool kirjeldatud müügitingimustega. Kui te ei nõustu nende tingimustega, palun ärge midagi ostke meie rakendusest ega meie veebisaidilt.</p>
    <h6>Tellimused</h6>
    <p>Ostes CapTargeti sisu, annab CapTarget teile piiratud, tühistatava, mittesiduva ja mittetransfitseeritava litsentsi teatud tasuliste funktsioonidele meie rakenduses või veebisaidil. Meie rakendus pakub kuulist ja aastast tellimust digitaalsetele teenustele automaatse pikendamisega. Tellimustasu võetakse teie valitud makseviisilt maha tellimuse aastapäeval. Gemsidega (CapTarget Archery virtuaalne valuuta) makstud tellimused ei uuene automaatselt.</p>
    <p>Kui te ei soovi oma tellimust uuesti pikendada, peate selle vähemalt 24 tundi enne tellimuse perioodi lõppu keelama, minnes oma konto seadetesse.</p>
    <h6>Hinnad</h6>
    <p>Meie tellimuste hinnad on avaldatud meie rakenduses ja võivad igal ajal etteteatamata muutuda. Tellimuse hinnad sisaldavad kõiki kohaldatavaid makse. Te vastutate kõigi meie rakenduse kasutamisega seotud tasude eest, sealhulgas internetiühenduse ja mobiilsete andmete kasutamise eest. Vaatamata kõigile meie pingutustele võib meie kataloogis olla piiratud arv kaupu, mis võivad olla valesti hinnatud. Hinnad võivad muutuda isegi praeguste tellijate jaoks. Sõltuvalt valitud süsteemist (Apple, Google, Stripe või muu) võite olla teavitatud tellimuse hetkehinna tõusust ja saate tellimuse enne pikendamist soovi korral tühistada.</p>
    <h6>Kao risk</h6>
    <p>Kui CapTargeti sisu on teile kättesaadavaks tehtud, võtate enda peale CapTargeti sisu kaotamise riski pärast ostmist, sealhulgas igasuguse kaotuse, mis tuleneb faili korruptsioonist või seadme või kõvaketta rikkest. Ostetud CapTargeti sisu jääb tavaliselt teie CapTargeti kontole kättesaadavaks, kuid võib erinevatel põhjustel lõpetada kättesaadavuse. Sellistel juhtudel CapTarget teie jaoks CapTargeti sisu edasise kättesaadavuse puudumise eest vastutust ei kanna ja teil ei ole õigust saada tagasimakset, osaliselt või tervikuna, juba makstud tasude eest.</p>
    <h6>Kohaletoimetamine</h6>
    <p>CapTargeti sisu kättesaadavus antakse teile toote või tellimuse ostmisel. Sisu on saadaval teie CapTargeti kontol. Rakenduse taaskäivitamine võib olla vajalik ning teenuste kättesaadavaks muutumiseks tuleb arvestada mõistliku ajavahemikuga (tavaliselt mõni minut).</p>
    <h6>Makse</h6>
    <p>Teil peab olema kehtiv makseviis (nagu krediitkaart või muu lubatud makseviis) teie CapTargeti kontol, kui soovite kasutada maksefunktsioone. Teie tellimuse makse võetakse automaatselt maha teie kontolt vastavalt teie Apple'i või Google Play kontol sisestatud makseviisile või stripe'is või mujal, kui olete tellimuse ostnud meie veebisaidilt. Nõustute esitama täpset ja ajakohast makseinformatsiooni. Kui makset mingil põhjusel ei tehta, jätame endale õiguse teie tellimus peatada või tühistada.</p>
    <h6>Rakenduse kasutamine</h6>
    <p>Teil on lubatud meie rakendust kasutada isiklikel ja mitteärilistel eesmärkidel. Te ei tohi muuta, levitada, müüa ega edastada meie rakenduse sisu ilma meie eelneva kirjaliku loata. Te ei tohi meie rakendust kasutada viisil, mis rikub kehtivaid seadusi, rikub kolmanda osapoole õigusi või tekitab talle kahju.</p>
    <h6>Tagasimaksed</h6>
    <p>Meie tellimusi saab eelnevalt 3 nädala jooksul testida ja seetõttu neid ei tagastata. Enne ühe või teise tellimuse sõlmimist palun kontrollige iga tellimuse funktsioone.
        Digitaalselt müüdavad tooted on selged ega ole tagastatavad.</p>
    <h6>Lõpetamine</h6>
    <p>Jätame endale õiguse lõpetada teie juurdepääs meie rakendusele, kui rikute müügi- või kasutustingimusi või kui meil on põhjust arvata, et kasutate meie rakendust mis tahes viisil. Tellimuse rakendusse sõlmides nõustute müügitingimustega.</p>

    <h6>Meiega ühendust võtmine</h6>
    <p>Kui teil on küsimusi või muresid, võtke meiega ühendust aadressil: support@captarget-archery.com</p>
    <p>Teeme endast parima, et lahendada teie mure rahumeelselt ning et kõik osapooled leiaksid sellest kasu.</p>
    <p></p>
    <p></p>
    <h4>Platvormi kasutamise kulud</h4>
    <p> </p>
    <p>Konto ja grupi loomine on kasutajale tasuta. Siiski jäävad platvormi kasutamisega seotud ühenduse ja suhtluse kulud kasutaja ainupädevusse.</p>
    <p>Juurtreenerite versioonidele juurdepääs on tasuline, välja arvatud testversioonid ja beetaversioon. Hinnakirjad on saadaval versiooni väljalaske ajast alates veebisaidil.</p>
    <h4>Ostud platvormide kaudu</h4>
    <p> </p>
    <p>On võimalik osta "reaalse" raha eest piiratud, isiklik, mittesiiretav ja tühistatav litsents, mis võimaldab teil kasutada virtuaalset valuutat, eriti virtuaalseid münte või teemante, mis on mõeldud kasutamiseks CapTarget Archery mängudes. Seda valuutat on võimalik saada ka sündmustelt, võistlustelt, mängudest, reklaamide vaatamisest või platvormil eesmärkide saavutamise eest antud preemiana või CapTarget Archery poolt jagatuna.</p>
    <p> </p>
    <p>Selle valuutaga saab osta erinevaid rakenduse poolt müüdavaid tooteid.</p>
    <p> </p>
    <p>Virtuaalsete esemete ülekandmine on keelatud, välja arvatud juhul, kui see on teenuses selgesõnaliselt lubatud. Teenuse raames lubatu piires peate hoiduma virtuaalsete esemete müümisest, ostmisest, konverteerimisest või muul viisil üleandmisest kasu saamise eesmärgil isikule, asutusele või kolmandale osapoolele, sealhulgas CapTarget Archery'le, teisele kasutajale või kolmandale osapoolele.</p>
    <p> </p>
    <p> </p>
    <p>CapTarget Archeryl on õigus igal ajal, etteteatamisega või ilma, hallata, reguleerida, kontrollida, muuta või kustutada virtuaalseid esemeid. CapTarget Archeryl ei lasu vastutus teie või kolmanda osapoole ees nende õiguste kasutamise korral.</p>
    <p> </p>
    <p>Kõik teenuse kaudu tehtud kolmandate osapoolte virtuaalsete esemete ostud ja konversioonid on lõplikud ega kuulu tagastamisele.</p>
    <p> </p>
    <p>Te nõustute tasuma kõik asjakohased tasud ja maksud, mis on teie või teie nimel registreeritud kontot kasutava isiku poolt tasumisele kuuluvad. CapTarget Archery võib teenuse kaudu pakutavate virtuaalsete esemete hindu igal ajal muuta. Nõustute, et CapTarget Archeryl pole kohustust mingil põhjusel teile tagasimakset teha ja te ei saa mingit summat ega hüvitist seoses kasutamata jäänud virtuaalsete esemetega, kui konto suletakse, olgu see vabatahtlikult või tahtmatult.</p>
    <p> </p>
    <h4>Reklaamid ja partnerid</h4>
    <p> </p>
    <p>CapTarget Archeryl võib pakkuda linke teistele reklaamilehekülgedele ja/või partneritele erinevatel pakutavatel teenustel.</p>
    <p> </p>
    <p>Kuna CapTarget Archeryl pole neid võimalik kontrollida, ei võta CapTarget Archeryl mingit kohustust seoses nende lehtede ja väliste allikate kättesaadavusega ning ei vastuta nende sisu, reklaamide, toodete, teenuste või muu sellise eest.</p>
    <p> </p>
    <p>Sama kehtib ka CapTarget Archery osas seoses kõikvõimalike kahjude või kaotustega, mis tulenevad või on seotud sisu või teenuste kasutamisega nendel reklaamilehekülgedel, partneritel või välistel allikatel.</p>
    <p> </p>
    <h4>Konto sulgemine</h4>
    <p> </p>
    <p>Kasutaja võib igal ajal sulgeda oma konto või konto, mida ta haldab alaealise nimel. Vastavalt meie andmekaitse privaatsuspoliitikale kaasneb konto sulgemisega isikuandmete seotuse kustutamine.</p>
    <p> </p>
    <p>Ilma et see mõjutaks CapTarget Archery võimalikke nõudeid kahju hüvitamiseks, jätab CapTarget Archeryl õiguse ilma etteteatamise või hüvituseta ajutiselt või lõplikult sulgeda kasutaja konto juhul, kui:</p>
    <p>ei järgita käesolevaid kasutustingimusi</p>
    <p>esitati registreerimisel valeandmeid;</p>
    <p>tuvastatakse tegevused, mis kahjustavad CapTarget Archery ärilisi huve.</p>
    <p> </p>
    <p>CapTarget Archery poolt algatatud konto sulgemine seoses käesolevate kasutustingimuste või üldtingimustega (CGV) mittejärgimisega ei anna õigust mingeid hüvitisi või tagasimakseid, sh tasuliste tellimuste puhul. Kõik tellimuste eest tasutud summad jäävad CapTarget Archery omandisse.</p>
    <p> </p>
    <p>Juurtreeneri juurdepääsu sulgemise tingimused kehtivad vastavalt kehtivatele üldtingimustele. Palume kasutajal nende kohta lisateabe saamiseks viidata vastavatele tingimustele. Testversioon paigutatakse pärast perioodi lõppu automaatselt pausile ning seda saab tellimuse sõlmimisel uuesti avada. Beetaversiooni saab pikendada vastavalt CapTarget Archery soovile. Testversiooni või beetaversiooni sulgemiseks saatke palun e-kiri aadressile support@captarget-archery.com</p>
    <p> </p>
    <h4>Grupi eemaldamine</h4>
    <p> </p>
    <p>Administraator saab igal ajal grupi eemaldada. Organisatsiooni ala eemaldamine toob kaasa sellega seotud andmete ja eeliste kustutamise.</p>
    <p> </p>
    <p>CapTarget Archery poolt algatatud organisatsiooni ala eemaldamine</p>
    <p> </p>
    <p>Ilma et see mõjutaks CapTarget Archery võimalikke nõudeid kahju hüvitamiseks, jätab CapTarget Archeryl õiguse ilma etteteatamise või hüvituseta ajutiselt või lõplikult eemaldada organisatsiooni ala juhul, kui:</p>
    <p>ei järgita üldtingimusi;</p>
    <p>esitati registreerimisel valeandmeid;</p>
    <p>tuvastatakse tegevused, mis kahjustavad CapTarget Archery ärilisi huve.</p>
    <p> </p>
    <p>CapTarget Archery algatatud organisatsiooni ala eemaldamise õigus ei anna õigust mingeid hüvitisi või tagasimakseid, sh tasuliste tellimuste puhul. Kõik tellimuste eest tasutud summad jäävad CapTarget Archery omandisse ning organisatsiooni/administraatori kohustus maksta tellimus tähtaja lõpuni jääb kehtima ka pärast organisatsiooni ala eemaldamist.</p>
    <p> </p>
    <h4>Platvormide teave ja sisu</h4>
    <p> </p>
    <p>Kasutaja nõustub platvormi kasutamisel otsustavaks olema, eriti hinnates teabe ja sisu usaldusväärsust grupis, kus ta on liige.</p>
    <p> </p>
    <p>Sellega seoses tunnistab kasutaja eriti, et platvormil saadaval olev teave ja sisu pole ammendavad ning nende peamine eesmärk on aidata administraatorit organisatsiooni haldamisel.</p>
    <p> </p>
    <p>Kasutajal on keelatud müüa, edasi müüa või mingil viisil ja eesmärgil ära kasutada platvormil saadaval olevat sisu osaliselt või täielikult.</p>
    <p> </p>
    <p>Kasutaja vastutab ainuisikuliselt suhtluste, teabe, failide, videote, fotode, dokumentide või muu sisu eest, mida ta platvormi kaudu avaldab ja edastab.</p>
    <p> </p>
    <p>Kasutaja poolt platvormil avaldatud suhtluste, teabe, failide, videote, fotode, dokumentide või muu sisu esindavad kasutaja isiklikke vaateid ja arvamusi ning need ei kajasta mingil viisil CapTarget Archery või selle töötajate ja esindajate seisukohti.</p>
    <p> </p>
    <p>Platvormi raames kohustub kasutaja pakkuma vaid objektiivset, täielikku ja tõepärast teavet, järgides viisakuse, viisakuse ja teiste austamise reegleid.</p>
    <p> </p>
    <p>CapTarget Archery julgustab kasutajat olema tagasihoidlik ja ettevaatlik suhtluste, teabe, failide, videote, fotode, dokumentide või muu sisu avaldamisel platvormil. Sellega seoses keelab kasutaja postitada platvormil sisu, mis on vastuolus kehtivate seaduste ja määrustega, eriti:</p>
    <p> </p>
    <p>lekitada teavet, mis on vastuolus avaliku korra või heade tavade põhimõtetega;</p>
    <p>kasutada platvormi eesmärgi kõrvalekaldamiseks propageerimiseks või propaganda tegemiseks, läbirääkimiste või ärilise taotluse tegemiseks, kommerts- või reklaamiteabena või propagandana tubaka, alkoholi või mõne muu reguleeritud aine, toote või teenuse kasuks;</p>
    <p>levitada sisu, mis rikub kolmandate isikute isikliku väärikuse õigusi või mis on laimav, solvav, vulgaarne, pornograafiline, solvav, vägivaldne või mis soodustab diskrimineerimist, poliitilist vägivalda, rassismi, ksenofoobiat, seksismi või homofoobiat;</p>
    <p>avalikustada teavet, mis rikub isikuandmete kaitse seadust, võimaldades füüsiliste isikute tuvastamist ilma nende nõusolekuta, sealhulgas nende perekonnanimi, posti- ja/või e-posti aadress, telefoninumber, fotograafia, heli- või audiovisuaalne salvestus;</p>
    <p>rikkuda kolmandate isikute intellektuaalomandi õigusi, eelkõige kustutades autoriõiguse või kaubamärgi mainimisi ning iga piirangut kandvaid allkirju.</p>
    <p> </p>
    <p>Kasutaja kohustub samuti austama teiste õigusi, eriti:</p>
    <p> </p>
    <p>isikuvabaduse õigusi (nagu õigus pildile ja privaatsusele);</p>
    <p>kaubamärgiõigusi;</p>
    <p>autoriõigusi (eriti tarkvaras, helis, piltides, fotodes, tekstides, liikuvates piltides) ja lähedasi õigusi (esitajate, heliplaatide ja videote tootjate õigused ning tootjatele kohaldatavad erilised andmebaaside tootjate õigused);</p>
    <p>üldisemalt inimeste ja varade õigusi.</p>
    <p> </p>

    <p>Kasutaja keelab endale ka ilma CapTarget Archery eelneva kirjaliku loata järgmiste tegevuste sooritamise, kuid mitte ainult nendega piirduvate tegevuste sooritamise:</p>
    <p></p>
    <p>Kasutada platvormi reklaamimise eesmärgil ja üldiselt pakkuda tooteid ja teenuseid, mis tasustavad teda otse või kaudselt;</p>
    <p>Luua arhiivifaile platvormi sisu põhjal;</p>
    <p>Reprodutseerida, kujutada, kasutada, viidata (sealhulgas otsingumootorite metasõnades), osaliselt või täielikult platvormil esinevat sisu, kaubamärke, logosid, eristavaid märke.</p>
    <p></p>
    <p>Käesolevate kasutustingimuste rikkumine võib kaasa tuua viivitamatu ja automaatse vastava sisu eemaldamise, hoiatuse ja/või kasutaja konto sulgemise, sõltumata CapTarget Archery'l teostatavast muust õiguslikust meetmest ja/või hüvitisnõudest, mida CapTarget Archeryl on õigus algatada või esitada.</p>
    <p></p>
    <p>CapTarget Archery ei vastuta platvormi sisu, sealhulgas, kuid mitte ainult, ebaseadusliku sisu, vigade või puuduste, kasutaja poolt selle materjali kasutamisest tulenevate kaotuste või kahju eest, sealhulgas failide, videote, fotode, dokumentide või muu sisu eest.</p>
    <p></p>
    <p>Iga link alamlehtedele saidil on rangelt keelatud ilma CapTarget Archery väljendusliku kasutaja loata. Lisaks tuleb kasutajal link CapTarget Archery lihtsa taotluse korral eemaldada.</p>
    <p></p>
    <h4>Administraatori modereerimisroll</h4>
    <p></p>
    <p>Administraatorina, kes toimib rühma koordinaatorina ja järelevalvajana, kohustub ta tagama liikmete vastavuse kasutustingimuste tingimustele. Kui ta sellest teada saab, kohustub ta teavitama või eemaldama vastava sisu (kui see on võimalik), mis oleks vastuolus seaduse, avaliku korra või käesolevate kasutustingimustega, eriti vägivaldse, pornograafilise, rassistliku, antisemiitliku, ksenofoobse, isiku väärikust ründava, privaatsust ründava, autoriõigusi, naaberõigusi, kaubamärgiõigusi, kolmanda osapoole isikuõigusi rikkumisega.</p>
    <p></p>
    <p>Käesolevate reeglite ja tavadega mittevastavuse korral jätab CapTarget Archery endale õiguse väärkäitunud administraatori ja/või liikme välistamiseks ning võib alustada kõiki õiguslikke samme administraatori suhtes või liikmete suhtes, kes võivad lõpetada platvormi ebaõige kasutamise tulemusena ilmse õigusvastase rahutuse.</p>
    <p></p>
    <h4>E-kirjade, teavituste ja SMS-ide saatmine</h4>
    <p></p>
    <p>CapTarget Archery pakub pakutavate teenuste raames e-posti teel, rakenduses teavituste ja SMS-idega suhtlemist, et liikmed saaksid organisatsioonis omavahel teavet vahetada, kasutades liikmete telefoninumbreid ja e-posti aadresse, mis on esitatud nende konto loomisel.</p>
    <p></p>
    <p>Kasutaja võib igal ajal otsustada mitte enam saada CapTarget Archery e-kirju, teavitusi või SMS-e, muutes oma seadmete seadeid või pöördudes toe poole.</p>
    <p></p>
    <h4>Juurdepääs platvormile - Vastutus ja kohustused</h4>
    <p></p>
    <p>CapTarget Archery teeb kõik endast oleneva, et platvorm oleks saadaval 24 tundi ööpäevas, 7 päeva nädalas, va programmiliste hooldustoimingute ja käesoleva artikli sätete kohaldamise erandite korral.</p>
    <p></p>
    <p>CapTarget Archeryl on õigus teha platvormile vajalikke muudatusi ja täiustusi vastavalt tehnoloogia arengule. Seega jätab CapTarget Archeryl endale õiguse ilma etteteatamise ja kompensatsioonita peatada ajutiselt platvormi juurdepääs, et teostada arengu- või parandustoiminguid. Kasutaja loobub sellega seoses igasugusest vastutusest CapTarget Archery ees ning loobub kõikidest sellega seotud nõuetest.</p>
    <p></p>
    <p>CapTarget Archeryl võib nõuda, et aktsepteerite teenuse ja CapTarget Archery mängude värskendusi, mida olete oma seadmesse või arvutisse installinud. Tunnete ja nõustute, et CapTarget Archeryl on õigus uuendada teenust ja CapTarget Archery mänge, teavitades teid sellest või teavitamata. Teil võib olla vajalik uuendada kolmandate osapoolte tarkvara teenuse saamiseks ja CapTarget Archery mänge mängimiseks.</p>
    <p></p>
    <p></p>
    <p>CapTarget Archery ei anna mingit garantiid, väljendit või kaudset, sealhulgas, kuid mitte ainult, kvaliteedi ja platvormi sobivuse osas kindlale otstarbele.</p>
    <p></p>
    <p>CapTarget Archeryl ei ole tagatud, et platvormi katkematu toimimine ja/või järjepidevus on tagatud vastava mõjutava jõu või juhusliku asjaolu korral, nagu määratletud Prantsuse tsiviilseadustiku artiklis 1218, mille korral CapTarget Archery vastutus ei oleks seatud.</p>
    <p></p>
    <p>Juhul kui CapTarget Archery vastutus oleks kasutaja rikkumise tõttu ühegi käesolevate kasutustingimuste kohustuse eest, on hüvitis piiratud otsese, isikliku, kindla ja ettenähtava kahjuga.</p>
    <p></p>
    <p>CapTarget Archeryl ei ole tagatud platvormil avaldatud kolmandate osapoolte teabe asjakohasus ja täpsus.</p>
    <p></p>
    <p>CapTarget Archeryl ei vastuta kolmandate isikute vaidluste, hagide või nõuete eest, kellel võivad olla õigused, eriti omandiõigused, mis puudutavad sisu, mida ei ole loonud CapTarget Archery.</p>
    <p></p>
    <p>Andmete kogumise ja töötlemise tingimused on detailsemalt kirjeldatud andmekaitse privaatsuspoliitikas.</p>
    <p></p>
    <p>CapTarget Archeryl ei vastuta teie ees ühegi kaudse, juhusliku, tagajärgede või muu sarnase kahju eest, eriti tulu kaotuse, tulu vähenemise, andmete kaotuse või tegevuse kaotuse või muu mittemateriaalse kahju (kuidas iganes nimetate neid kahjusid), mis on seotud käesolevate kasutustingimustega või teenusega ise ning ei ole kohustatud teile maksma erilist või karistuslikku hüvitist, olgu see siis lepingulise, õigusvastase või muu alusega, olgu CapTarget Archery teavitatud või mitte võimaliku sellise kahju võimalusest. CapTarget Archery vastutus teie ees ei ületa summat, mille olete CapTarget Archeryle vastavalt käesolevatele kasutustingimustele kuue (6) kuu jooksul enne selle kuupäeva, millal te esitate esmakordselt hüvitise nõude. Tunnete ja nõustute, et kui te ei ole sellisel perioodil CapTarget Archeryle midagi tasunud, on teie ainus õiguskaitsevahend (ja ainus vastutus CapTarget Archery ees) igasuguste vaidluste korral CapTarget Archeryga teenuse kasutamise lõpetamine ja oma konto sulgemine.</p>
    <p>Eelkõige ei tohi käesolevate kasutustingimuste ükski sätetest piirata tarbija seaduslikke õigusi ega välistada või piirata vastutust surma või kehavigastuste eest, mis tulenevad CapTarget Archery hooletusest või pettusest.</p>
    <p></p>
    <p>Olete kohustatud tagama, kaitsma ja vabastama CapTarget Archery (samuti selle arendajad, administraatorid ja sisuloojad) vastutusest kõikide nõuete, nõudmiste, kahjude või muude kaotuste suhtes, sealhulgas mõistliku summa ulatuses õigustasude suhtes, mida kolmas isik teeb teie teenuse kasutamise või käesolevate kasutustingimuste rikkumise tagajärjel. Siiski ei kohaldata eeltoodut juhul, kui rikkumine ei ole teie tahtliku või hooletu käitumise tulemus.</p>
    <p></p>
    <p>CapTarget Archeryl ei saa vastutada ühegi viivituse või mittejärgimise eest, mis tuleneb seaduslikult tema kontrollist ja eriti mitte käesolevate kasutustingimuste täitmatajätmisest ettenägematute asjaolude tõttu või põhjustest, mis jäävad CapTarget Archeryl legitiimse kontrolli alt välja, eriti loodusõnnetused, sõda, terrorismiaktid, rahutused, embargod, tsiviil- või sõjaliste asutuste meetmed, tulekahju, üleujutused, õnnetused, streigid või transpordivahendite, kütuse, energia, tööjõu või materjalide puudused.</p>
    <p></p>
    <p>Tegevuste, nagu rakenduses kirjeldatud tegevuste nõuandeteenuste, on näitlikud, kasutaja vastutus on kohandada nõuandeid vastavalt tema tervisele või küsida nõu arstilt. Sporditegevuse vastutus lasub kasutajal, CapTarget Archeryl ei vastuta mingil juhul füüsiliste või vaimsete vigastuste eest, mis on tingitud tegevusest rakenduse abil.</p>
    <p></p>
    <p>Kasutaja kohustub teavitama igasugustest turvaaugudest ja neid mitte kasutama oma kasuks ega kellegi teise kasuks. Kasutaja kohustub mitte tegema toiminguid, mis võiksid kahjustada CapTarget Archery pakutavate teenuste sujuvat toimimist, ning mitte kasutama võimalikke vigu.</p>
    <p></p>
    <h4>Intellektuaalomand</h4>
    <p></p>
    <p>Platvormil esitatud teenused ja sisu (sealhulgas andmed, andmebaasid, tarkvara, fotod, teave, illustratsioonid, logod, kaubamärgid jne), mis ilmuvad või on saadaval, on kaitstud kõigi olemasolevate intellektuaalomandi õigustega, andmebaaside tootjate õigustega ja/või muude õigustega, mis on kehtiva seadusandlusega tunnustatud.</p>
    <p></p>
    <p>Mittevolitatud, täielik või osaline koopia, reprodutseerimine, esitamine, kohandamine, muutmine või lubamatu levitamine teenuste ja/või platvormisisu osas, olenemata sellest, kas sisu kuulub CapTarget Archerylle, kasutajale, organisatsioonile või kolmandale isikule, on seadusevastane ja võib selle autori vastutusele võtta.</p>
    <p></p>
    <p>CapTarget Archeryl on kasutajale antud õigus kasutada platvormi oma vajadusteks, välistades kasumit taotleva kasutuse.</p>
    <p></p>
    <p>Kasutaja laadides platvormile sisu üles, annab automaatselt CapTarget Archerylle isikliku ja mitteeksklusiivse litsentsi selle sisu tasuta kasutamiseks.</p>
    <p></p>
    <p>See litsents kehtib kogu maailmas, kõigile kasutustele kõikidel teadaolevatel või tundmatutel kandevahenditel ja kõigi praegu teadaolevate või teadmata levimeetodite abil, ning kõikide intellektuaalomandi õiguste kaitse seadusliku kestuse jooksul (sh uuenemised ja pikendused):</p>
    <p></p>
    <p>reprodutseerimisõigus: õigus teha või lasta teha piiramatu arv koopiaid sisust, igal kujul või igal teadaoleval või tundmatul kandekandel, eriti igasugusel paberikandjal, magnetilisel, digitaalsel või muul kandekandel (näiteks brošüürid, pressidokumendid, plakatid, veebilehed, aruanded ja kõik salvestusmaterjalide kandjad, näiteks CD-d, DVD-d, kettad, sise- või välisseadmete kõvakettad või muud salvestusmaterjalid), mis tahes teadaoleva või tundmatu tehnilise protsessi abil;</p>
    <p>esitamisõigus: õigus suhelda, levitada, edastada või teha sisu liikmetele kättesaadavaks kasutaja organisatsioonis igal viisil, igasuguste teadaolevate või tundmatute sidevõrkude või teabevahetuse ja suhtlemise vahendite abil (internet, maapealne võrk, kaabel, satelliit, allalaadimine, teleülekanne jne);</p>
    <p>õigus sisu tõlkida igasse keelde (kaasa arvatud kõik arvutikeeled).</p>
    <p>Kasutaja tagab, et platvormile üles laaditud sisu ei riku mingit intellektuaalomandi õigust ega muud kolmanda isiku õigust.</p>
    <p></p>
    <p>Selle tulemusena nõustub kasutaja kaitsma CapTarget Archeryd ja vastutama CapTarget Archerylile kahju eest, mis võib tuleneda sellest, kui kasutaja postitab sisu.</p>
    <p></p>
    <h4>Lepingulised dokumendid</h4>
    <p></p>
    <p>Käesolevad kasutustingimused reguleerivad kõiki lepingulisi või eellepingulisi ärilisi suhteid CapTarget Archery, kasutaja ja/või organisatsiooni vahel, mis on seotud platvormi kasutamise ja CapTarget Archeryl pakutavate tellimuste sõlmimisega.</p>
    <p></p>
    <p>Käesolevad kasutustingimused on ülimuslikud igasuguste vastupidiste kokkulepete või tingimuste suhtes osapoolte vahel. Need tühistavad ja asendavad kõik varasemad tingimused, mis võisid reguleerida osapoolte vahelisi suhteid.</p>
    <p></p>
    <p>Käesolevad kasutustingimused moodustavad osapoolte vahelise kõigi lepinguliste dokumentide täielikkuse.</p>
    <p></p>
    <p>CapTarget Archeryl on õigus igal ajal muuta kasutustingimuste sisu. Need muudatused toovad kaasa uue versiooni, mis kehtib automaatselt kõigi edaspidi pakutavate teenuste suhtes.</p>
    <p></p>
    <h4>Rakendatav õigus, vahendus ja kohtuvaidlus</h4>
    <p></p>
    <p>Kasutustingimused kuuluvad Prantsuse seaduste alla.</p>
    <p></p>
    <p>Osapooled nõustuvad otsima lepituslahenduse enne igasugust kohtumenetlust. Kui vaidlust ei saa osapoolte vahel lepituslikult lahendada, võib kasutaja tasuta pöörduda tarbijavaidluste lepitusorgani poole. Soovitatav on pöörduda järgmise lepitaja poole: Pariisi Lepitus- ja Vahekohtu Keskus (CMAP), www.mediateur-conso.cmap.fr.</p>
    <p></p>
    <p>Kasutaja võib kasutada ka Euroopa Komisjoni poolt veebis avaldatud vaidluste lahendamise platvormi aadressil: www.ec.europa.eu/consumers/odr/.</p>
</div>
