<section id="home" class="section welcome-area d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-6 col-lg-6">
                <div class="welcome-intro">
                    <h1 class="text-white">Create Landing pages Easily.</h1>
                    <p class="text-white my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <div class="button-group">
                        <a href="#" class="btn btn-bordered-white">Download</a>
                        <a href="#" class="btn btn-bordered-white d-none d-sm-inline-block">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5">
                <!-- Contact Box -->
                <div class="contact-box bg-white text-center rounded p-4 p-sm-5 p-md-4 p-lg-5 mt-5 mt-md-0 shadow-lg">
                    <!-- Contact Form -->
                    <form id="contact-form" method="POST" action="assets/php/mail.php">
                        <h3 class="contact-title mb-4">Get a free quote</h3>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" placeholder="Email" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="required">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <textarea class="form-control" name="message" placeholder="Message" required="required"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-bordered w-100 mt-3 mt-sm-4" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape-bottom">
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
            <path class="fill" d="M0,6V0h1000v100L0,6z"></path>
        </svg>
    </div>
</section>