import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-questions-practice',
  templateUrl: './questions-practice.component.html',
  styleUrls: ['./questions-practice.component.css']
})
export class QuestionsPracticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
