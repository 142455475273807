import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-et',
  templateUrl: './et.component.html',
  styleUrls: ['./et.component.css']
})
export class EtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
